import React, { useState } from "react";
import { HeadersPatients, PosPatientCard } from "./components";
import { TextInputFieldSearch } from "../../../../components/atoms";
import { BlackAndWhite } from "../../../../themes/Colors";

const PatientDisplay = ({ setLoading, admin, setSelectedPatient, loading }) => {
  const [search, setSearch] = useState("");
  const [customers, setCustomers] = useState(null);

  const OnUserSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/searchForUserPos",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchParameter: search,
            userId: admin.adminUserData.userId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setCustomers(responseData.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  return (
    <div
      style={{
        height: "93%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ minHeight: 60, display: "flex", alignItems: "center" }}>
        <div style={{ marginLeft: 10, marginRight: 10, marginBottom: -5 }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              // width: 300,

              borderWidth: 0.5,
            }}
          >
            <TextInputFieldSearch
              placeholder={"Type here to search"}
              height={30}
              borderRadius={4}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <div
              style={{
                height: 35,
                width: 70,
                backgroundColor: BlackAndWhite.primary,
                borderRadius: 4,
                display: "flex",
                color: BlackAndWhite.secondary,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: 5,
              }}
            >
              <div
                onClick={() => {
                  setSearch("");
                  setCustomers(null);
                }}
                style={{ fontSize: 12, fontWeight: "bold" }}
              >
                CLEAR
              </div>
            </div>
            <div
              style={{
                height: 35,
                width: 70,
                backgroundColor: BlackAndWhite.primary,
                borderRadius: 4,
                display: "flex",
                color: BlackAndWhite.secondary,
                justifyContent: "center",
                alignItems: "center",
                cursor: search === "" ? "not-allowed" : "pointer",
                marginLeft: 10,
              }}
            >
              <div
                onClick={search === "" ? null : () => OnUserSearch()}
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  opacity: search === "" ? 0.5 : 1,
                }}
              >
                SEARCH
              </div>
            </div>
          </div>
        </div>
      </div>
      <HeadersPatients />
      <div
        style={{
          flex: 1,
          overflowY: "scroll",
          overflowX: "hidden",
          scrollbarWidth: "none",
        }}
      >
        {customers ? (
          customers.map((patient, index) => {
            return (
              <div key={index}>
                <PosPatientCard
                  firstName={patient ? patient.firstName : "loading"}
                  lastName={patient ? patient.lastName : "loading"}
                  idNum={patient ? patient.idDoc : "loading"}
                  email={patient ? patient.email : "loading"}
                  status={"in progress"}
                  cell={patient ? patient.cellNumber : "loading"}
                  onClick={() => {
                    setSelectedPatient(patient);
                  }}
                />
              </div>
            );
          })
        ) : (
          <div style={{ width: "100%", textAlign: "center", marginTop: 40 }}>
            SEARCH FOR USER ABOVE ... WHEN YOUR READY
          </div>
        )}
      </div>
    </div>
  );
};
export default PatientDisplay;
