import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import MessageCard from "./message-card/MessageCard";
import { BaseButtons } from "../../../../../../components/atoms";
import ReplyPopUp from "./reply-pop-up/ReplyPopUp";
import { ClosedMessageCard } from "../../../../../../components";

const MessageLayout = ({
  messageArray,
  userId,
  messageDisplay,
  generalMessagesArray,
}) => {
  let messageArrayDirection = messageArray.reverse();

  const [replyPopUp, setReplyPopUp] = useState(false);
  return (
    <div
      style={{
        flex: 1,

        overflowY: "scroll",
        overflowX: "hidden",
        position: "relative",
        marginTop: 40,
      }}
    >
      {messageDisplay
        ? generalMessagesArray.length >= 1
          ? generalMessagesArray.map((message, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    position: "relative",
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <ClosedMessageCard
                    // width={}
                    messageTitle={message.messageTitle}
                    quickMessage={message.quickMessage}
                    date={message.createdOn}
                    tags={message.tags}
                    fullDescription={message.fullMessage}
                    signOff={message.signOff}
                    href={message.goToLink}
                    imageId={message.image}
                  />
                </div>
              );
            })
          : null
        : messageArrayDirection.length >= 1
        ? messageArrayDirection.map((message, index) => {
            return (
              <div
                key={index}
                style={{
                  width: "100%",
                  position: "relative",
                  display: "flex",
                  justifyContent:
                    message.sentBy === userId ? "flex-end" : "flex-start",
                  alignItems: "center",
                  marginTop: 25,
                }}
              >
                <MessageCard
                  message={message}
                  userId={userId}
                  name={message.sentBy === userId ? "You" : "Doctor"}
                  isUser={message.sentBy !== userId ? true : false}
                  dateSent={message.dateCreated}
                />
              </div>
            );
          })
        : null}
      <div style={{ padding: 55 }} />
      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {messageDisplay ? null : messageArray.length >= 1 ? (
          <BaseButtons
            label={"REPLY"}
            width={"80%"}
            size={"90%"}
            onClick={() => setReplyPopUp(true)}
          />
        ) : null}
      </div>
      <ReplyPopUp
        display={replyPopUp}
        lastMessage={
          messageArray.length >= 1
            ? messageArray[messageArray.length - 1].message
            : "loading"
        }
        lastMessageData={
          messageArray.length >= 1
            ? messageArray[messageArray.length - 1]
            : null
        }
        setReplyPopUp={setReplyPopUp}
      />
    </div>
  );
};

export default MessageLayout;
