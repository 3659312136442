import React from "react";
import { BlackAndWhite } from "../../../themes/Colors";

const SectionTitle = ({ title, width, colorSwap, marker, noHighlight }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: marker ? "row" : "column",
        width: width,
        alignItems: "center",
        marginBottom: 20,
        marginTop: 10,
      }}
    >
      {marker ? (
        <div
          style={{
            backgroundColor: colorSwap
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
            height: 10,
            width: 20,
            borderRadius: 50,
            marginLeft: 20,
          }}
        />
      ) : null}

      <h2
        style={{
          marginLeft: 8,
          fontWeight: "bold",
          color: colorSwap ? BlackAndWhite.secondary : BlackAndWhite.primary,
          backgroundColor: colorSwap && noHighlight ? "#000000cc" : null,
          padding: 10,
          borderRadius: 50,
          fontFamily: "Montserrat",
          fontSize: width > 899 ? 30 : 18,
        }}
      >
        {title}
      </h2>
    </div>
  );
};

export default SectionTitle;
