import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const stores = [
  "Malmesbury",
  "Woodstock",
  "Langebaan",
  "Mossel Bay",
  "Worcester",
  "Panorama",
  "Hermanus",
  "Wellington",
  "Stellenbosch",
  "Tyger Falls",
  "Faerie Glen",
];

const StoreSelector = ({
  store,
  setStore,
  open,
  onStoreClick,
  setStoreOPen,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: open ? 0 : 20,
          width: "80%",
          textAlign: "center",
        }}
      >
        {open ? "Click to select Store" : "Select your favourite store."}
      </div>
      <div
        onClick={onStoreClick}
        style={{
          height: open ? 0 : 45,
          width: 250,
          borderStyle: "solid",

          borderRadius: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderWidth: open ? 0 : 0.5,
          transition: "all 0.3s",
          overflow: "hidden",
        }}
      >
        {store ? store : "Click here"}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: open ? null : 0,
          overflow: "hidden",

          flexWrap: "wrap",
        }}
      >
        {stores.map((store, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                setStore(store);
                setStoreOPen(false);
              }}
              style={{
                height: 40,
                width: "80%",
                borderStyle: "solid",
                margin: 5,
                // borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: 0.5,
                transition: "all 0.3s",
                overflow: "hidden",
                fontSize: store ? 14 : 14,
                textAlign: "center",
              }}
            >
              {store ? store : "loading"}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StoreSelector;
