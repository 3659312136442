import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const FilterPopUp = ({ data, display, setSelectedFilter, setFliterOpen }) => {
  const [hover, setHover] = useState(null);
  return (
    <div
      style={{
        backgroundColor: BlackAndWhite.secondary,
        overflow: display ? "scroll" : "hidden",
        height: display ? null : 0,
        width: 100,
        WebkitBoxShadow: display ? "0px 0px 10px 1px #00000033" : null,
        borderRadius: 8,
        borderStyle: "solid",
        borderWidth: display ? 3 : 0,
        borderColor: BlackAndWhite.primary,
        transition: "all 0.2s",
      }}
    >
      {data ? (
        data.map((option, index) => {
          return (
            <div
              onMouseOver={() => setHover(index)}
              onMouseOut={() => setHover(null)}
              onClick={() => {
                setSelectedFilter(option === "NONE" ? "NONE" : option);
                setFliterOpen(false);
              }}
              key={index}
              style={{
                width: "100%",
                fontSize: 12,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor:
                  hover === index
                    ? BlackAndWhite.primary
                    : BlackAndWhite.secondary,
                color:
                  hover === index
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
              }}
            >
              {option}
            </div>
          );
        })
      ) : (
        <div>loading</div>
      )}
    </div>
  );
};

export default FilterPopUp;
