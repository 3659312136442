import React from "react";
import ItemCard from "../item-card/ItemCard";

const FourthGroupPageOne = ({ page1, page3, selectedStore, refresh }) => {
  return (
    <div
      style={{
        flex: 0.5,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[12].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[12].strain
                  : "TBA"
                : page1.length > 1
                ? page1[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[12].type
                  : "TBA"
                : page1.length > 1
                ? page1[12].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[12].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[12].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[12].description
                  : "TBA"
                : page1.length > 1
                ? page1[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[12].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[12].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[12].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[12].strain
                  : "TBA"
                : page1.length > 1
                ? page1[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[12].type
                  : "TBA"
                : page1.length > 1
                ? page1[12].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[12].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[12].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[12].description
                  : "TBA"
                : page1.length > 1
                ? page1[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[12].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[12].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[12].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[12].strain
                  : "TBA"
                : page1.length > 1
                ? page1[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[12].type
                  : "TBA"
                : page1.length > 1
                ? page1[12].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[12].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[12].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[12].description
                  : "TBA"
                : page1.length > 1
                ? page1[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[12].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[12].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[12].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[12].strain
                  : "TBA"
                : page1.length > 1
                ? page1[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[12].type
                  : "TBA"
                : page1.length > 1
                ? page1[12].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[12].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[12].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[12].description
                  : "TBA"
                : page1.length > 1
                ? page1[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[12].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[12].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[12].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[12].strain
                  : "TBA"
                : page1.length > 1
                ? page1[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[12].type
                  : "TBA"
                : page1.length > 1
                ? page1[12].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[12].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[12].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[12].description
                  : "TBA"
                : page1.length > 1
                ? page1[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[12].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[12].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[12].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[12].strain
                  : "TBA"
                : page1.length > 1
                ? page1[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[12].type
                  : "TBA"
                : page1.length > 1
                ? page1[12].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[12].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[12].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[12].description
                  : "TBA"
                : page1.length > 1
                ? page1[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[12].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[12].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[12].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[12].strain
                  : "TBA"
                : page1.length > 1
                ? page1[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[12].type
                  : "TBA"
                : page1.length > 1
                ? page1[12].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[12].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[12].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[12].description
                  : "TBA"
                : page1.length > 1
                ? page1[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[12].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[12].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[12].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[12].strain
                  : "TBA"
                : page1.length > 1
                ? page1[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[12].type
                  : "TBA"
                : page1.length > 1
                ? page1[12].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[12].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[12].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[12].description
                  : "TBA"
                : page1.length > 1
                ? page1[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[12].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[12].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[13].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[13].strain
                  : "TBA"
                : page1.length > 1
                ? page1[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[13].type
                  : "TBA"
                : page1.length > 1
                ? page1[13].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[13].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[13].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[13].description
                  : "TBA"
                : page1.length > 1
                ? page1[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[13].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[13].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[13].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[13].strain
                  : "TBA"
                : page1.length > 1
                ? page1[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[13].type
                  : "TBA"
                : page1.length > 1
                ? page1[13].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[13].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[13].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[13].description
                  : "TBA"
                : page1.length > 1
                ? page1[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[13].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[13].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[13].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[13].strain
                  : "TBA"
                : page1.length > 1
                ? page1[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[13].type
                  : "TBA"
                : page1.length > 1
                ? page1[13].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[13].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[13].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[13].description
                  : "TBA"
                : page1.length > 1
                ? page1[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[13].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[13].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[13].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[13].strain
                  : "TBA"
                : page1.length > 1
                ? page1[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[13].type
                  : "TBA"
                : page1.length > 1
                ? page1[13].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[13].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[13].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[13].description
                  : "TBA"
                : page1.length > 1
                ? page1[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[13].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[13].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[13].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[13].strain
                  : "TBA"
                : page1.length > 1
                ? page1[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[13].type
                  : "TBA"
                : page1.length > 1
                ? page1[13].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[13].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[13].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[13].description
                  : "TBA"
                : page1.length > 1
                ? page1[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[13].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[13].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[13].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[13].strain
                  : "TBA"
                : page1.length > 1
                ? page1[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[13].type
                  : "TBA"
                : page1.length > 1
                ? page1[13].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[13].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[13].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[13].description
                  : "TBA"
                : page1.length > 1
                ? page1[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[13].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[13].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[13].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[13].strain
                  : "TBA"
                : page1.length > 1
                ? page1[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[13].type
                  : "TBA"
                : page1.length > 1
                ? page1[13].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[13].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[13].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[13].description
                  : "TBA"
                : page1.length > 1
                ? page1[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[13].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[13].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[13].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[13].strain
                  : "TBA"
                : page1.length > 1
                ? page1[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[13].type
                  : "TBA"
                : page1.length > 1
                ? page1[13].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[13].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[13].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[13].description
                  : "TBA"
                : page1.length > 1
                ? page1[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[13].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[13].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[14].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[14].strain
                  : "TBA"
                : page1.length > 1
                ? page1[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[14].type
                  : "TBA"
                : page1.length > 1
                ? page1[14].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[14].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[14].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[14].description
                  : "TBA"
                : page1.length > 1
                ? page1[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[14].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[14].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[14].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[14].strain
                  : "TBA"
                : page1.length > 1
                ? page1[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[14].type
                  : "TBA"
                : page1.length > 1
                ? page1[14].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[14].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[14].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[14].description
                  : "TBA"
                : page1.length > 1
                ? page1[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[14].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[14].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[14].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[14].strain
                  : "TBA"
                : page1.length > 1
                ? page1[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[14].type
                  : "TBA"
                : page1.length > 1
                ? page1[14].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[14].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[14].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[14].description
                  : "TBA"
                : page1.length > 1
                ? page1[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[14].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[14].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[14].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[14].strain
                  : "TBA"
                : page1.length > 1
                ? page1[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[14].type
                  : "TBA"
                : page1.length > 1
                ? page1[14].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[14].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[14].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[14].description
                  : "TBA"
                : page1.length > 1
                ? page1[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[14].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[14].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[14].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[14].strain
                  : "TBA"
                : page1.length > 1
                ? page1[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[14].type
                  : "TBA"
                : page1.length > 1
                ? page1[14].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[14].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[14].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[14].description
                  : "TBA"
                : page1.length > 1
                ? page1[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[14].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[14].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[14].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[14].strain
                  : "TBA"
                : page1.length > 1
                ? page1[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[14].type
                  : "TBA"
                : page1.length > 1
                ? page1[14].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[14].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[14].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[14].description
                  : "TBA"
                : page1.length > 1
                ? page1[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[14].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[14].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[14].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[14].strain
                  : "TBA"
                : page1.length > 1
                ? page1[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[14].type
                  : "TBA"
                : page1.length > 1
                ? page1[14].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[14].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[14].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[14].description
                  : "TBA"
                : page1.length > 1
                ? page1[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[14].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[14].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[14].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[14].strain
                  : "TBA"
                : page1.length > 1
                ? page1[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[14].type
                  : "TBA"
                : page1.length > 1
                ? page1[14].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[14].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[14].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[14].description
                  : "TBA"
                : page1.length > 1
                ? page1[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[14].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[14].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[15].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[15].strain
                  : "TBA"
                : page1.length > 1
                ? page1[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[15].type
                  : "TBA"
                : page1.length > 1
                ? page1[15].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[15].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[15].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[15].description
                  : "TBA"
                : page1.length > 1
                ? page1[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[15].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[15].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[15].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[15].strain
                  : "TBA"
                : page1.length > 1
                ? page1[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[15].type
                  : "TBA"
                : page1.length > 1
                ? page1[15].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[15].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[15].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[15].description
                  : "TBA"
                : page1.length > 1
                ? page1[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[15].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[15].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[15].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[15].strain
                  : "TBA"
                : page1.length > 1
                ? page1[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[15].type
                  : "TBA"
                : page1.length > 1
                ? page1[15].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[15].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[15].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[15].description
                  : "TBA"
                : page1.length > 1
                ? page1[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[15].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[15].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[15].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[15].strain
                  : "TBA"
                : page1.length > 1
                ? page1[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[15].type
                  : "TBA"
                : page1.length > 1
                ? page1[15].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[15].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[15].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[15].description
                  : "TBA"
                : page1.length > 1
                ? page1[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[15].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[15].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[15].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[15].strain
                  : "TBA"
                : page1.length > 1
                ? page1[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[15].type
                  : "TBA"
                : page1.length > 1
                ? page1[15].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[15].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[15].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[15].description
                  : "TBA"
                : page1.length > 1
                ? page1[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[15].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[15].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[15].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[15].strain
                  : "TBA"
                : page1.length > 1
                ? page1[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[15].type
                  : "TBA"
                : page1.length > 1
                ? page1[15].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[15].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[15].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[15].description
                  : "TBA"
                : page1.length > 1
                ? page1[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[15].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[15].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[15].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[15].strain
                  : "TBA"
                : page1.length > 1
                ? page1[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[15].type
                  : "TBA"
                : page1.length > 1
                ? page1[15].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[15].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[15].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[15].description
                  : "TBA"
                : page1.length > 1
                ? page1[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[15].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[15].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[15].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[15].strain
                  : "TBA"
                : page1.length > 1
                ? page1[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[15].type
                  : "TBA"
                : page1.length > 1
                ? page1[15].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[15].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[15].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[15].description
                  : "TBA"
                : page1.length > 1
                ? page1[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[15].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[15].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
    </div>
  );
};
export default FourthGroupPageOne;
