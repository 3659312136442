import React, { useState } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import MenuButton from "../../components/atoms/menu-button/MenuBuuton";

const Header = ({ logo, onMenuClick }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      style={{
        height: 60,
        width: "100%",
        backgroundColor: BlackAndWhite.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        alt="icon"
        src={require("../../images/merryjanelogooptions/Merry-Jane Plain Light Logo.png")}
        style={{ height: 45, marginLeft: 20 }}
      />
      <div
        style={{
          marginLeft: 10,
          marginRight: 20,
          color: BlackAndWhite.secondary,
        }}
      >
        www.merry-jane.com
      </div>
      <div style={{ flex: 1 }}></div>
      <div style={{ color: BlackAndWhite.secondary, marginRight: 10 }}>
        <MenuButton width={50} onClick={onMenuClick} />
      </div>
    </div>
  );
};

export default Header;
