import React from "react";
import { BlackAndWhite } from "../../../../../../../../../../themes/Colors";
import {
  BaseButtons,
  TextAreaInput,
} from "../../../../../../../../../../components/atoms";

const RecievePopUp = ({
  display,
  setRecievePopUp,
  RecieveStockAndComment,
  comment,
  setComment,
}) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        width: "100%",
        height: display ? "100%" : 0,
        backgroundColor: "#00000050",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          zIndex: 200,
          backgroundColor: BlackAndWhite.secondary,
          //   width: "80%",
          padding: display ? 10 : 0,

          height: display ? null : 0,
          transition: "all 0.3s",
          borderRadius: 10,
          overflow: "hidden",
          WebkitBoxShadow: display
            ? "0px 0px 7px 2px #00000050"
            : "0px 0px 0px 0px #00000050",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ marginLeft: 0, fontSize: 24 }}>
            WOULD YOU LIKE TO RECIEVE THIS TRANSFER DOCUMENT?
          </div>
        </div>
        <div style={{ width: "100%" }}>
          You can add a note if there is something missing so that admin can
          correct the issue
        </div>
        <TextAreaInput
          labelTitle={"COMMENTS"}
          height={200}
          width={"100%"}
          size={"90%"}
          mini={true}
          color={BlackAndWhite.primary}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BaseButtons
            label={"CANCEL"}
            mini={true}
            borderRadius={8}
            marginRight={20}
            width={"40%"}
            size={"80%"}
            onClick={() => setRecievePopUp(false)}
          />
          <BaseButtons
            label={"RECIEVE"}
            mini={true}
            borderRadius={8}
            width={"40%"}
            size={"80%"}
            onClick={() => RecieveStockAndComment()}
          />
        </div>
      </div>
    </div>
  );
};

export default RecievePopUp;
