import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const UserItem = ({
  role,
  firstName,
  surname,
  store,
  email,
  cell,
  onManageClick,
}) => {
  const [hover, setHover] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: "98%",
        height: 50,
        // borderStyle: "solid",
        display: "flex",
        fontSize: 12,
        backgroundColor: hover ? "#faa91966" : BlackAndWhite.secondary,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {role}
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {firstName}
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {surname}
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {store}
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {email}
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {cell}
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{
            backgroundColor: BlackAndWhite.primary,
            borderRadius: 4,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
            color: BlackAndWhite.secondary,
            cursor: "pointer",
          }}
        >
          Block
        </div>
        <div style={{ width: 20 }} /> */}
        <div
          onClick={onManageClick}
          onMouseOver={() => setButtonHover(true)}
          onMouseOut={() => setButtonHover(false)}
          style={{
            backgroundColor: buttonHover
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
            borderRadius: 4,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
            color: buttonHover
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
            cursor: "pointer",
            transition: "all 0.3s",
          }}
        >
          Manage
        </div>
        <div style={{ width: 20 }} />
      </div>
    </div>
  );
};

export default UserItem;
