import React, { useState, useEffect } from "react";
import FormFields from "../../../form/FormFields";
import TabMobileButton from "../tab-mobile-button/TabMobileButton";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { TermsDisplay } from "./components";

const Profile = ({
  selectedDisplay,
  profileProgress,
  customerName,
  patientNumber,
  firstName,
  lastName,
  idDoc,
  dateOfBirth,
  cellNumber,
  email,
  refreshHandler,
  loading,
  setValidateEdit,
  auth,
  prescriptionData,
  accountType,
  setLoading,
  setToastPop,
  setMessage,
  agreements,
  setAgreements,
  viewTerms,
  setViewTerms,
}) => {
  // console.log("auth.customerUserData", auth.customerUserData.agreement.length);

  const [signedAt, setSignedAt] = useState("");

  const AcceptAgreement = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/acceptAgreements",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            signedAt: signedAt,
            email: email,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setLoading(false);
        setMessage(respnseData.success);
        setToastPop(true);
      } else {
        setLoading(false);
        setMessage(respnseData.message);
        setToastPop(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (auth) {
  //     checkForAgreements();
  //   }
  // }, [loading]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#000000cc",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        // position: "relative",
        //   justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          color: BlackAndWhite.secondary,
          height: selectedDisplay ? 215 : 120,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // backgroundColor: BlackAndWhite.primary,
        }}
      >
        <div
          style={{
            flex: 0.6,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <img
            alt="Edit"
            src={require("../../../../../../images/material-icons/profileWhite.png")}
            style={{
              height: 55,
              marginBottom: 10,
              marginLeft: 20,
              marginTop: 20,
            }}
          />

          <div
            style={{
              width: 180,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 80,
              fontSize: 18,
              marginLeft: 20,
              flexDirection: "column",
              position: "relative",
            }}
          >
            {customerName}
            {auth ? (
              auth.customerUserData.status ? (
                <div
                  onClick={
                    auth.customerUserData.status === "Active"
                      ? null
                      : () => setValidateEdit(true)
                  }
                  style={{
                    // position: "absolute",
                    // bottom: 0,
                    // right: 0,
                    width: 150,
                    fontSize: 14,
                    borderStyle: "solid",
                    height: 30,
                    borderRadius: 8,
                    marginTop: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#48A14D",
                    borderWidth: 0.5,
                    // marginLeft: 50,
                  }}
                >
                  Status: {auth.customerUserData.status}
                </div>
              ) : (
                <div
                  onClick={() => setValidateEdit(true)}
                  style={{
                    // position: "absolute",
                    // bottom: 0,
                    // right: 0,
                    width: 180,
                    fontSize: 14,
                    borderStyle: "solid",
                    height: 30,
                    borderRadius: 8,
                    marginTop: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#B33F40",
                    borderWidth: 0.5,
                  }}
                >
                  Validate Account
                </div>
              )
            ) : (
              <div
                onClick={() => setValidateEdit(true)}
                style={{
                  // position: "absolute",
                  // bottom: 0,
                  // right: 0,
                  width: 180,
                  fontSize: 14,
                  borderStyle: "solid",
                  height: 30,
                  borderRadius: 8,
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#48A14D",
                }}
              >
                Account Validated
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 5,
            right: 10,
            backgroundColor: BlackAndWhite.primary,
            borderRadius: "100%",
          }}
        >
          <TabMobileButton
            icon={
              loading
                ? require("../../../../../../images/material-icons/loadingWhte.png")
                : require("../../../../../../images/material-icons/refreshwhite.png")
            }
            onClick={() => {
              refreshHandler();
            }}
            height={40}
          />
        </div>
      </div>
      {/* <div
        style={{
          height: 50,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ marginLeft: 20, color: BlackAndWhite.secondary }}>
          PRESCRIPTION STATUS :
        </div>
        <div
          style={{
            marginRight: 20,
            color: prescriptionData
              ? prescriptionData.data.length >= 1 ||
                prescriptionData.dataPending.length >= 1
                ? BlackAndWhite.green
                : "red"
              : BlackAndWhite.secondary,
          }}
        >
          {prescriptionData
            ? prescriptionData.data.length >= 1 ||
              prescriptionData.dataPending.length >= 1
              ? "Active"
              : "Inactive"
            : "loading"}
        </div>
      </div> */}
      <FormFields
        selectedDisplay={selectedDisplay}
        patientNumber={patientNumber}
        firstName={firstName}
        lastName={lastName}
        idDoc={idDoc}
        dateOfBirth={dateOfBirth}
        cellNumber={cellNumber}
        email={email}
        profileProgress={profileProgress}
        accountType={accountType}
        onTermsClick={() => setViewTerms(true)}
        agreed={false}
        termsBackgroundColorButton={
          auth
            ? agreements
              ? agreements.length !== 0
                ? BlackAndWhite.secondary
                : "red"
              : "red"
            : "red"
        }
      />
      <TermsDisplay
        terms={"social"}
        onCloseClick={() => setViewTerms(false)}
        display={viewTerms}
        isSigned={agreements ? (agreements.length !== 0 ? true : false) : false}
        userName={firstName + " " + lastName}
        signedAtAlready={
          auth
            ? agreements
              ? agreements.length !== 0
                ? agreements[0].signedAt
                : "loading"
              : "loading"
            : "loading"
        }
        isActive={
          auth
            ? agreements
              ? agreements.length !== 0
                ? true
                : false
              : false
            : false
        }
        dateCreated={
          auth
            ? agreements
              ? agreements.length !== 0
                ? agreements[0].dateCreated
                : "loading"
              : "loading"
            : "loading"
        }
        signedAt={signedAt}
        setSignedAt={setSignedAt}
        onAcceptClick={() => {
          AcceptAgreement();
          setViewTerms(false);
        }}
      />
    </div>
  );
};

export default Profile;
