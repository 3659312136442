import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextAreaInput,
  TextInputField,
} from "../../../../../../../../components/atoms";
import { LoadingPopUp } from "../../../../../../../../components";

const CategorySelectorPopUp = ({
  display,
  onCloseClick,
  categoryArray,
  setMessage,
  setToastPop,
  setCategorySelected,
  setCategoryOpen,
  setSubcategorySelected,
  selectedSubCategory,
  categorySelected,
  refresh,
  setRefresh,
}) => {
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [description, setDescription] = useState(null);
  const [hasSubCategories, setHasSubCategories] = useState(false);
  const [extraInfo, setExtraInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [subCategoryArray, setSubCategoryArray] = useState(null);

  const [screen, setScreen] = useState("catlist");
  const uploadCategory = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/createCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryName: categoryName,
            description: description,
            hasSubCategory: hasSubCategories || "Na",
            moreInfo: extraInfo || "Na",
            imageIcon: selectedIcon || "Na",
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.category) {
        // const wait = await updateListFetch();
        setToastPop(true);
        setMessage("Category uploaded");
        setLoading(false);
        setSelectedIcon(null);
        setCategoryName(null);
        setDescription(null);
        setExtraInfo(null);
        setRefresh(refresh ? false : true);
        setScreen("catlist");
      } else {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };
  const [subCategoryName, setSubCategoryName] = useState(null);
  const [subCatdescription, setSubCatDescription] = useState(null);
  const [moreInfo, setMoreInfo] = useState(null);
  const [subCategoryType, setSubCategoryType] = useState(null);

  const uploadSubCategory = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/createSubCategories",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subCategoryName,
            categoryId: categorySelected.categoryId,
            description: subCatdescription,
            moreInfo: moreInfo || "Na",
            subCategoryType: subCategoryType || "Na",
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.subCategory) {
        // const wait = await updateListFetch();
        setToastPop(true);
        setMessage("Sub Category uploaded");
        setLoading(false);
        setScreen("catlist");
        setCategorySelected(null);
        setSubcategorySelected(null);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };
  const updateSubCategorydisplay = async (info) => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getSubCatergoriesId",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId: categorySelected.categoryId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.subCategory) {
        // const wait = await updateListFetch();
        setSubCategoryArray(respnseData.subCategory);
        setLoading(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (categorySelected) {
      //here will be the download trigger
      updateSubCategorydisplay(categorySelected);
    }
  }, [categorySelected]);
  return (
    <div
      style={{
        position: "fixed",
        overflow: "hidden",
        backgroundColor: BlackAndWhite.primary,
        height: display ? "100%" : 0,
        width: "100%",
        top: 0,
        opacity: display ? 1 : 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
        transition: "all 0.4s",
      }}
    >
      <div
        style={{
          height: 600,
          width: 500,
          backgroundColor: BlackAndWhite.secondary,
          borderRadius: 20,
          marginTop: 40,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        {categorySelected ? null : (
          <div style={{ position: "absolute", left: 10, top: 10 }}>
            <CloseButton label={"x"} onClick={onCloseClick} />
          </div>
        )}
        {screen === "subCats" || screen === "subCatlist" ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <BaseButtons
              label={"Select from list"}
              onClick={() => setScreen("subCatlist")}
            />
            <BaseButtons
              label={"Add new SubCategory"}
              onClick={() => setScreen("subCats")}
            />
          </div>
        ) : null}
        {screen === "catlist" || screen === "newCat" ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <BaseButtons
              label={"Select from list"}
              onClick={() => setScreen("catlist")}
            />
            <BaseButtons
              label={"Add new Category"}
              onClick={() => setScreen("newCat")}
            />
          </div>
        ) : null}

        {/* //list of category */}
        {screen === "catlist" ? (
          <div
            style={{
              flex: 1,
              width: "100%",
              overflow: "scroll",
              display: "flex",
              flexDirection: "column",
              margintop: 10,
            }}
          >
            {categoryArray
              ? categoryArray.map((category, index) => {
                  return (
                    <div
                      onMouseOver={() => setHoverIndex(index)}
                      onMouseOut={() => setHoverIndex(null)}
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        onClick={() => {
                          setCategorySelected(category);
                        }}
                        style={{
                          width: "80%",
                          height: 60,

                          display: "flex",
                          flexDirection: "column",
                          marginTop: 10,
                          borderWidth: 0.5,
                          borderColor: categorySelected
                            ? categorySelected.categoryId ===
                              category.categoryId
                              ? "#FAA919"
                              : BlackAndWhite.primary
                            : null,
                          borderRadius: 10,
                          backgroundColor: categorySelected
                            ? categorySelected.categoryId ===
                              category.categoryId
                              ? "#FAA91950"
                              : index === hoverIndex
                              ? "#00000040"
                              : BlackAndWhite.secondary
                            : null,
                          WebkitBoxShadow: "0px 0px 5px 1px #00000033",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: 10,
                            marginTop: 10,
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          {category.categoryName}
                        </div>
                        <div
                          style={{
                            marginLeft: 10,
                            marginTop: 2,
                            fontSize: 12,
                          }}
                        >
                          {category.description}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}
        {screen === "newCat" ? (
          <div
            style={{
              flex: 1,
              width: "100%",
              overflow: "scroll",
              display: "flex",
              flexDirection: "column",
              margintop: 10,
            }}
          >
            <TextInputField
              labelTitle={"Category name:"}
              invert
              size={"70%"}
              onChange={(e) => setCategoryName(e.target.value)}
              mini={true}
            />
            <TextInputField
              labelTitle={"SKU Marker:"}
              invert
              size={"70%"}
              onChange={(e) => setExtraInfo(e.target.value)}
              mini={true}
            />
            <TextAreaInput
              labelTitle={"Description :"}
              invert
              size={"72%"}
              color={BlackAndWhite.primary}
              onChange={(e) => setDescription(e.target.value)}
              mini={true}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BaseButtons
                marginTopFalse={false}
                label={"SUBMIT"}
                onClick={() => uploadCategory()}
              />
            </div>
          </div>
        ) : null}
        {screen === "subCatlist" ? (
          <div
            style={{
              flex: 1,
              width: "100%",
              overflow: "scroll",
              display: "flex",
              flexDirection: "column",
              margintop: 10,
            }}
          >
            {subCategoryArray
              ? subCategoryArray.map((subCategory, index) => {
                  return (
                    <div
                      onMouseOver={() => setHoverIndex(index)}
                      onMouseOut={() => setHoverIndex(null)}
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        onClick={() => {
                          setSubcategorySelected(subCategory);
                        }}
                        style={{
                          width: "80%",
                          height: 60,

                          display: "flex",
                          flexDirection: "column",
                          marginTop: 10,
                          borderWidth: 0.5,
                          borderRadius: 10,
                          backgroundColor: selectedSubCategory
                            ? selectedSubCategory.subCategoryId ===
                              subCategory.subCategoryId
                              ? "#FAA91950"
                              : index === hoverIndex
                              ? "#00000040"
                              : BlackAndWhite.secondary
                            : null,
                          WebkitBoxShadow: "0px 0px 5px 1px #00000033",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: 10,
                            marginTop: 10,
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          {subCategory.subCategoryName}
                        </div>
                        <div
                          style={{
                            marginLeft: 10,
                            marginTop: 2,
                            fontSize: 12,
                          }}
                        >
                          {subCategory.description}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}
        {screen === "subCats" ? (
          <div
            style={{
              flex: 1,
              width: "100%",
              overflow: "scroll",
              display: "flex",
              flexDirection: "column",
              margintop: 10,
            }}
          >
            <TextInputField
              labelTitle={"Sub-Category name:"}
              invert
              size={"70%"}
              onChange={(e) => setSubCategoryName(e.target.value)}
              mini={true}
            />
            <TextAreaInput
              labelTitle={"Description :"}
              invert
              size={"72%"}
              color={BlackAndWhite.primary}
              onChange={(e) => setSubCatDescription(e.target.value)}
              mini={true}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BaseButtons
                marginTopFalse={false}
                label={"SUBMIT"}
                onClick={() => uploadSubCategory()}
              />
            </div>
          </div>
        ) : null}
        {screen === "catlist" ? (
          <div
            style={{
              width: "100%",
              justifyContent: "space-evenly",
              display: "flex",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <BaseButtons
              label={"CANCEL"}
              onClick={() => {
                setCategorySelected(null);
                setCategoryOpen(false);
              }}
            />
            <BaseButtons
              label={"SUBMIT"}
              disable={categorySelected ? false : true}
              onClick={() => setScreen("subCatlist")}
            />
          </div>
        ) : null}
        {screen === "subCatlist" ? (
          <div
            style={{
              width: "100%",
              justifyContent: "space-evenly",
              display: "flex",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <BaseButtons
              label={"BACK"}
              onClick={() => {
                setCategorySelected(null);
                setSubcategorySelected(null);
                setScreen("catlist");
              }}
            />
            <BaseButtons
              label={"FINISH"}
              disable={selectedSubCategory ? false : true}
              onClick={() => {
                setScreen("catlist");
                setCategoryOpen(false);
              }}
            />
          </div>
        ) : null}
      </div>
      <LoadingPopUp display={loading} width={"100%"} />
    </div>
  );
};
export default CategorySelectorPopUp;
