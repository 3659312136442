import React from "react";
import ItemCard from "../item-card/ItemCard";

const ThirdGroupPageOne = ({ page1, page3, refresh, selectedStore }) => {
  return (
    <div
      style={{
        flex: 0.5,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[8].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[8].strain
                  : "TBA"
                : page1.length > 1
                ? page1[8].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[8].type
                  : "TBA"
                : page1.length > 1
                ? page1[8].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[8].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[8].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[8].description
                  : "TBA"
                : page1.length > 1
                ? page1[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[8].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[8].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[8].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[8].strain
                  : "TBA"
                : page1.length > 1
                ? page1[8].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[8].type
                  : "TBA"
                : page1.length > 1
                ? page1[8].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[8].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[8].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[8].description
                  : "TBA"
                : page1.length > 1
                ? page1[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[8].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[8].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[8].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[8].strain
                  : "TBA"
                : page1.length > 1
                ? page1[8].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[8].type
                  : "TBA"
                : page1.length > 1
                ? page1[8].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[8].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[8].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[8].description
                  : "TBA"
                : page1.length > 1
                ? page1[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[8].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[8].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[8].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[8].strain
                  : "TBA"
                : page1.length > 1
                ? page1[8].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[8].type
                  : "TBA"
                : page1.length > 1
                ? page1[8].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[8].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[8].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[8].description
                  : "TBA"
                : page1.length > 1
                ? page1[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[8].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[8].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[8].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[8].strain
                  : "TBA"
                : page1.length > 1
                ? page1[8].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[8].type
                  : "TBA"
                : page1.length > 1
                ? page1[8].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[8].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[8].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[8].description
                  : "TBA"
                : page1.length > 1
                ? page1[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[8].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[8].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[8].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[8].strain
                  : "TBA"
                : page1.length > 1
                ? page1[8].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[8].type
                  : "TBA"
                : page1.length > 1
                ? page1[8].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[8].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[8].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[8].description
                  : "TBA"
                : page1.length > 1
                ? page1[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[8].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[8].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[8].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[8].strain
                  : "TBA"
                : page1.length > 1
                ? page1[8].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[8].type
                  : "TBA"
                : page1.length > 1
                ? page1[8].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[8].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[8].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[8].description
                  : "TBA"
                : page1.length > 1
                ? page1[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[8].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[8].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[8].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[8].strain
                  : "TBA"
                : page1.length > 1
                ? page1[8].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[8].type
                  : "TBA"
                : page1.length > 1
                ? page1[8].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[8].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[8].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[8].description
                  : "TBA"
                : page1.length > 1
                ? page1[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[8].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[8].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[9].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[9].strain
                  : "TBA"
                : page1.length > 1
                ? page1[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[9].type
                  : "TBA"
                : page1.length > 1
                ? page1[9].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[9].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[9].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[9].description
                  : "TBA"
                : page1.length > 1
                ? page1[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[9].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[9].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[9].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[9].strain
                  : "TBA"
                : page1.length > 1
                ? page1[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[9].type
                  : "TBA"
                : page1.length > 1
                ? page1[9].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[9].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[9].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[9].description
                  : "TBA"
                : page1.length > 1
                ? page1[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[9].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[9].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[9].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[9].strain
                  : "TBA"
                : page1.length > 1
                ? page1[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[9].type
                  : "TBA"
                : page1.length > 1
                ? page1[9].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[9].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[9].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[9].description
                  : "TBA"
                : page1.length > 1
                ? page1[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[9].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[9].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[9].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[9].strain
                  : "TBA"
                : page1.length > 1
                ? page1[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[9].type
                  : "TBA"
                : page1.length > 1
                ? page1[9].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[9].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[9].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[9].description
                  : "TBA"
                : page1.length > 1
                ? page1[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[9].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[9].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[9].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[9].strain
                  : "TBA"
                : page1.length > 1
                ? page1[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[9].type
                  : "TBA"
                : page1.length > 1
                ? page1[9].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[9].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[9].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[9].description
                  : "TBA"
                : page1.length > 1
                ? page1[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[9].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[9].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[9].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[9].strain
                  : "TBA"
                : page1.length > 1
                ? page1[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[9].type
                  : "TBA"
                : page1.length > 1
                ? page1[9].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[9].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[9].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[9].description
                  : "TBA"
                : page1.length > 1
                ? page1[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[9].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[9].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[9].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[9].strain
                  : "TBA"
                : page1.length > 1
                ? page1[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[9].type
                  : "TBA"
                : page1.length > 1
                ? page1[9].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[9].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[9].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[9].description
                  : "TBA"
                : page1.length > 1
                ? page1[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[9].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[9].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[9].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[9].strain
                  : "TBA"
                : page1.length > 1
                ? page1[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[9].type
                  : "TBA"
                : page1.length > 1
                ? page1[9].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[9].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[9].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[9].description
                  : "TBA"
                : page1.length > 1
                ? page1[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[9].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[9].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[10].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[10].strain
                  : "TBA"
                : page1.length > 1
                ? page1[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[10].type
                  : "TBA"
                : page1.length > 1
                ? page1[10].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[10].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[10].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[10].description
                  : "TBA"
                : page1.length > 1
                ? page1[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[10].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[10].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[10].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[10].strain
                  : "TBA"
                : page1.length > 1
                ? page1[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[10].type
                  : "TBA"
                : page1.length > 1
                ? page1[10].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[10].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[10].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[10].description
                  : "TBA"
                : page1.length > 1
                ? page1[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[10].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[10].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[10].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[10].strain
                  : "TBA"
                : page1.length > 1
                ? page1[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[10].type
                  : "TBA"
                : page1.length > 1
                ? page1[10].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[10].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[10].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[10].description
                  : "TBA"
                : page1.length > 1
                ? page1[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[10].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[10].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[10].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[10].strain
                  : "TBA"
                : page1.length > 1
                ? page1[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[10].type
                  : "TBA"
                : page1.length > 1
                ? page1[10].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[10].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[10].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[10].description
                  : "TBA"
                : page1.length > 1
                ? page1[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[10].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[10].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[10].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[10].strain
                  : "TBA"
                : page1.length > 1
                ? page1[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[10].type
                  : "TBA"
                : page1.length > 1
                ? page1[10].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[10].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[10].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[10].description
                  : "TBA"
                : page1.length > 1
                ? page1[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[10].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[10].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[10].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[10].strain
                  : "TBA"
                : page1.length > 1
                ? page1[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[10].type
                  : "TBA"
                : page1.length > 1
                ? page1[10].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[10].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[10].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[10].description
                  : "TBA"
                : page1.length > 1
                ? page1[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[10].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[10].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[10].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[10].strain
                  : "TBA"
                : page1.length > 1
                ? page1[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[10].type
                  : "TBA"
                : page1.length > 1
                ? page1[10].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[10].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[10].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[10].description
                  : "TBA"
                : page1.length > 1
                ? page1[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[10].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[10].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[10].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[10].strain
                  : "TBA"
                : page1.length > 1
                ? page1[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[10].type
                  : "TBA"
                : page1.length > 1
                ? page1[10].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[10].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[10].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[10].description
                  : "TBA"
                : page1.length > 1
                ? page1[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[10].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[10].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[11].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[11].strain
                  : "TBA"
                : page1.length > 1
                ? page1[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[11].type
                  : "TBA"
                : page1.length > 1
                ? page1[11].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[11].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[11].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[11].description
                  : "TBA"
                : page1.length > 1
                ? page1[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[11].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[11].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[11].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[11].strain
                  : "TBA"
                : page1.length > 1
                ? page1[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[11].type
                  : "TBA"
                : page1.length > 1
                ? page1[11].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[11].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[11].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[11].description
                  : "TBA"
                : page1.length > 1
                ? page1[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[11].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[11].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[11].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[11].strain
                  : "TBA"
                : page1.length > 1
                ? page1[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[11].type
                  : "TBA"
                : page1.length > 1
                ? page1[11].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[11].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[11].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[11].description
                  : "TBA"
                : page1.length > 1
                ? page1[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[11].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[11].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[11].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[11].strain
                  : "TBA"
                : page1.length > 1
                ? page1[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[11].type
                  : "TBA"
                : page1.length > 1
                ? page1[11].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[11].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[11].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[11].description
                  : "TBA"
                : page1.length > 1
                ? page1[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[11].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[11].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[11].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[11].strain
                  : "TBA"
                : page1.length > 1
                ? page1[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[11].type
                  : "TBA"
                : page1.length > 1
                ? page1[11].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[11].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[11].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[11].description
                  : "TBA"
                : page1.length > 1
                ? page1[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[11].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[11].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[11].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[11].strain
                  : "TBA"
                : page1.length > 1
                ? page1[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[11].type
                  : "TBA"
                : page1.length > 1
                ? page1[11].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[11].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[11].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[11].description
                  : "TBA"
                : page1.length > 1
                ? page1[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[11].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[11].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[11].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[11].strain
                  : "TBA"
                : page1.length > 1
                ? page1[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[11].type
                  : "TBA"
                : page1.length > 1
                ? page1[11].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[11].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[11].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[11].description
                  : "TBA"
                : page1.length > 1
                ? page1[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[11].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[11].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[11].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[11].strain
                  : "TBA"
                : page1.length > 1
                ? page1[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[11].type
                  : "TBA"
                : page1.length > 1
                ? page1[11].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[11].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[11].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[11].description
                  : "TBA"
                : page1.length > 1
                ? page1[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[11].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[11].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
    </div>
  );
};

export default ThirdGroupPageOne;
