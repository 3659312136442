import React, { useState } from "react";
import { BaseButtons } from "../../components/atoms";
import DescriptionCard from "./description-card/DescriptionCard";

const Description = ({
  title,
  fontSize,
  descriptionArray,
  refresh,
  setRefresh,
  AddNewParagraph,
}) => {
  const [edit, setEdit] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          marginTop: 130,
          width: "100%",
          justifyContent: "ceneter",
          alignItems: "center",
          textAlign: "center",
          fontFamily: "Montserrat",
        }}
      >
        <div style={{ fontSize: fontSize ? fontSize : 30, fontWeight: "bold" }}>
          {title}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: 40,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: 0.25 }}></div>
        <div
          style={{
            flex: 0.5,
            textAlign: "center",
            fontSize: 20,
          }}
        >
          {descriptionArray
            ? descriptionArray.map((description, index) => {
                return (
                  <div
                    key={index}
                    style={{ marginBottom: 15, fontSize: description.fontsize }}
                  >
                    <DescriptionCard
                      description={description}
                      displayEdit={edit}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                  </div>
                );
              })
            : null}
        </div>
        <div style={{ flex: 0.25 }}></div>
      </div>

      <div style={{ right: 10, bottom: 10, position: "absolute" }}>
        {edit ? (
          <>
            <BaseButtons
              label={"ADD NEW PARAGRAPH"}
              size={120}
              onClick={() => {
                AddNewParagraph(
                  descriptionArray[0].descriptionId,
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                );
              }}
            />

            <BaseButtons
              label={"CANCEL"}
              size={120}
              onClick={() => {
                setEdit(false);
              }}
            />
          </>
        ) : (
          <BaseButtons
            label={"EDIT"}
            size={120}
            onClick={() => {
              setEdit(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Description;
