import React, { useState } from "react";
import { BaseButtons } from "../atoms";
import { BlackAndWhite } from "../../themes/Colors";

const DropDownWithButton = ({
  width,
  title,
  marginTop,
  data,
  dropdownHeight,
  setSelected,
  support,
}) => {
  const [hover, setHover] = useState(null);
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{
        width: width,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: marginTop,
        position: "relative",
      }}
    >
      <BaseButtons
        mini={true}
        marginTopFalse={true}
        borderRadius={8}
        label={title}
        width={"100%"}
        size={"80%"}
        onClick={() => setOpen(true)}
        height={support ? 40 : null}
      />
      <div
        style={{
          height: open ? (dropdownHeight ? dropdownHeight : 200) : 0,
          width: "80%",
          backgroundColor: BlackAndWhite.secondary,
          borderStyle: "solid",
          position: "absolute",
          top: support ? null : 0,
          bottom: support ? 0 : null,
          borderRadius: 8,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          transition: "all 0.3s",
          borderWidth: open ? 1 : 0,
          zIndex: 200,
        }}
      >
        {data
          ? data.map((type, index) => {
              return (
                <div
                  onClick={() => {
                    setSelected(type);
                    setOpen(false);
                  }}
                  onMouseOver={() => setHover(index)}
                  onMouseOut={() => setHover(null)}
                  key={index}
                  style={{
                    width: "100%",
                    backgroundColor:
                      hover === index
                        ? BlackAndWhite.primary
                        : BlackAndWhite.secondary,
                    color:
                      hover === index
                        ? BlackAndWhite.secondary
                        : BlackAndWhite.primary,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 80,
                    cursor: "pointer",
                  }}
                >
                  {type}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default DropDownWithButton;
