import React from "react";
import { CheckInput } from "../atoms";

const ImageCheckMark = ({
  image,
  onClick,
  title,
  imageHeight,
  invert,
  width,
  check,
}) => {
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        width,
      }}
    >
      <CheckInput
        onClick={onClick}
        question={title}
        invert={invert}
        size={250}
        check={check}
      />
      <img
        style={{
          height: imageHeight ? imageHeight : 60,
          position: "absolute",
          right: 0,
        }}
        alt="icon"
        src={image}
      />
    </div>
  );
};

export default ImageCheckMark;
