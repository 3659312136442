import React from "react";
import useWindowDimensions from "../../../../../../components/get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  CheckInput,
  CloseButton,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";
import AddCategory from "./AddCategory";
import AddSubCategory from "./AddSubCategory";
import AddProduct from "./AddProduct";
import AddCompletePath from "./AddCompletePath";

const AddPopUp = ({
  display,
  onCloseClick,
  setToastPop,
  setMessage,
  setSelectedAdd,
  categorySelected,
  setCategorySelected,
  selectedSubCategory,
  refresh,
  setRefresh,
  setSectionDisplay,
  data,
  newLoad,
  setNewLoad,
}) => {
  const { width, height } = useWindowDimensions();

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        transition: "height 0.4s",
        overflow: "hidden",
        zIndex: 1000000000000,
      }}
    >
      <div style={{ fontSize: 26, position: "relative" }}>
        <div style={{ marginLeft: 60, marginTop: 12 }}>
          {display === "completePath"
            ? "Add Product"
            : "Add a" + display
            ? display
            : null}
        </div>{" "}
        <div style={{ position: "absolute", top: 10, left: 10 }}>
          <CloseButton label={"X"} onClick={onCloseClick} />
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", flex: 1 }}>
        {display === "category" ? (
          <AddCategory
            setSelectedAdd={setSelectedAdd}
            setMessage={setMessage}
            setToastPop={setToastPop}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        ) : null}
        {display === "subCategory" ? (
          <AddSubCategory
            setSectionDisplay={setSectionDisplay}
            setSelectedAdd={setSelectedAdd}
            setMessage={setMessage}
            setToastPop={setToastPop}
            categorySelected={categorySelected}
          />
        ) : null}
        {display === "product" ? (
          <AddProduct
            selectedSubCategory={selectedSubCategory}
            setSelectedAdd={setSelectedAdd}
            setMessage={setMessage}
            setToastPop={setToastPop}
            categorySelected={categorySelected}
          />
        ) : null}
        {display === "completePath" ? (
          <AddCompletePath
            setMessage={setMessage}
            setToastPop={setToastPop}
            data={data}
            refresh={refresh}
            setRefresh={setRefresh}
            setSelectedAdd={setSelectedAdd}
            newLoad={newLoad}
            setNewLoad={setNewLoad}
            onCloseClick={onCloseClick}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AddPopUp;
