import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../../../components/atoms";

const InventoryProductCard = ({
  index,
  product,
  auth,
  setStoreInventoryList,
}) => {
  const [hover, setHover] = useState(false);
  const [productInfo, setProductInfo] = useState(false);

  const [loading, setLoading] = useState(false);

  // product value needs to be added as extra check for adjustment

  const [stockValue, setStockValue] = useState(0);

  const [stockCheckValue, setStockCheckValue] = useState(null);

  // value={stockValue ? stockValue : product.currentStock}
  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;
      return name;
    }
  };
  const storeInventoryItem = async () => {
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/storeInventoryItem",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId: product.productId,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.success) {
        // const wait = await updateListFetch();

        setProductInfo(respnseData.product);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // const AddTo = () => {
  //   const value = Number(stockCheckValue) + 1;
  //   const valueCheck = Number(stockValue) + 1;
  //   if (stockValue >= stockCheckValue) {
  //     setStockValue(valueCheck);
  //   } else {
  //     setStockValue(value);
  //   }
  // };
  // const MinusFrom = () => {
  //   const value = Number(stockCheckValue) - 1;
  //   const valueCheck = Number(stockValue) - 1;
  // };

  const AdjustStockLevel = async () => {
    // https://merry-jane-api.onrender.com/cannaPos/reg
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/adjustStoreStock",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId: product.productId,
            stockValue: stockValue,
            editedBy: auth.firstName + " " + auth.lastName,
            storeId: product.storeId,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.success) {
        // const wait = await updateListFetch();
        setLoading(false);
        setStoreInventoryList(respnseData.fullStoreInventory);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("err", err);
    }
  };

  useEffect(() => {
    storeInventoryItem();

    setStockCheckValue(product.currentStock);
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: 60,
        // borderStyle: "solid",
        display: "flex",
        fontSize: 12,
        backgroundColor: hover ? "#9E3AC350" : BlackAndWhite.secondary,
        cursor: "pointer",
        position: "relative",
        transition: "all 0.3s",
        alignItems: "center",
        borderBottomWidth: 0.8,
        borderBottomStyle: "solid",
        borderBottomColor: "#00000050",
      }}
    >
      <div
        style={{
          flex: 0.05,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <img
          alt="product"
          src={productInfo ? productInfo.imageUrl : null}
          style={{ height: 50 }}
        />
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 15, width: "100%", textAlign: "left" }}>
          {productInfo ? NameAssemble({ product: productInfo }) : "loading"}
        </div>
      </div>

      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "center" }}>
          {productInfo ? productInfo.sku : "loading"}
        </div>
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 25, width: "100%", textAlign: "left" }}>
          {productInfo ? productInfo.supplier : "loading"}
        </div>
      </div>
      <div
        style={{
          flex: 0.25,

          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        {/* <div
            style={{
              fontSize: 14,
              marginTop: 15,
              marginRight: 5,
            }}
          >
            UNIT
          </div> */}

        <div>
          <TextInputField
            invert={true}
            mini={true}
            height={10}
            marginTop={-5}
            size={200}
            labelTitle={"In Store's"}
            type={"Number"}
            readOnly={false}
            value={stockValue ? stockValue : product.currentStock}
            onChange={(e) => setStockValue(e.target.value)}
          />
        </div>
        {/* <BaseButtons
          label={"MINUS"}
          mini={true}
          height={30}
          borderRadius={8}
          marginRight={10}
          marginTopFalse={true}
          size={100}
          marginLeft={10}
          invert={true}
          borderWidth={2}
          onClick={() => MinusFrom()}
        />
        <BaseButtons
          label={"ADD"}
          mini={true}
          height={30}
          borderRadius={8}
          marginRight={10}
          marginTopFalse={true}
          size={100}
          invert={true}
          borderWidth={2}
          onClick={() => AddTo()}
        /> */}
      </div>

      <div
        style={{
          flex: 0.25,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <BaseButtons
          label={loading ? "LOADING" : "SAVE"}
          mini={true}
          marginTopFalse={true}
          borderRadius={8}
          height={35}
          size={200}
          disable={
            loading
              ? true
              : Number(product.currentStock) === Number(stockValue)
              ? true
              : false
          }
          onClick={() => AdjustStockLevel()}
        />
      </div>
    </div>
  );
};

export default InventoryProductCard;
