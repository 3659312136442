import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const SupportHeader = ({ onClick, marginTop }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        minHeight: 45,
        maxHeight: 45,
        display: "flex",
        borderTopColor: BlackAndWhite.primary,
        backgroundColor: BlackAndWhite.primary,
        color: BlackAndWhite.secondary,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginTop: marginTop,
        alignItems: "center",
        fontSize: 12,
      }}
    >
      <div style={{ marginLeft: 20, flex: 0.3, textAlign: "left" }}>
        TICKET NO
      </div>
      <div style={{ flex: 0.2, textAlign: "center" }}>TYPE</div>
      <div style={{ flex: 0.2 }}>LAST VIEWED</div>

      <div style={{ flex: 0.15, textAlign: "center", marginRight: 5 }}>
        STATUS
      </div>
      <div style={{ flex: 0.15, textAlign: "center" }}>ACTIONS</div>
    </div>
  );
};

export default SupportHeader;
