import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextAreaInput,
  TextInputField,
} from "../../../../../../../../components/atoms";
import {
  ClosedMessageCard,
  ImageUploadAWS,
} from "../../../../../../../../components";

const validFileTypes = ["image/jpg", "image/jpeg", "image/png"];

const CreateMessagePopUp = ({
  display,
  onCloseClick,
  setMessage,
  setLoading,
  setToastPop,
  user,
  setMessageCreatePopUp,
  setRefresh,
  refresh,
}) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const [file, setFile] = useState(null);

  const [messageTitle, setMessageTitle] = useState("");
  const [tags, setTags] = useState("");
  const [goToLink, setGoToLink] = useState("");
  const [fullMessage, setFullMessage] = useState("");
  const [quickMessage, setQuickMessage] = useState("");
  const [signOff, setSignOff] = useState("");

  const ClearData = () => {
    setMessageTitle("");
    setTags("");
    setFile(null);
    setGoToLink("");
    setFullMessage("");
    setQuickMessage("");
    setSignOff("");
    setImage(null);
    setPreviewImage(null);
  };

  const [previewDisplay, setPreviewDisplay] = useState("closed");

  const handleFileChange = (e) => {
    // Uploaded file
    const file = e.target.files[0];
    // Changing file state
    setFile(file);
  };

  const CreateNewMessage = async (e) => {
    setLoading(true);

    if (!validFileTypes.find((type) => type === file.type)) {
      setImageError("File type must be JPG or PNG format");
      return;
    } else {
      setImageError("");
    }
    try {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("messageTitle", messageTitle);
      formData.append(
        "cratedBy",
        user ? user.firstName + " " + user.lastName : ""
      );
      formData.append("tags", tags !== "" ? tags : "none");
      formData.append("goToLink", goToLink !== "" ? goToLink : "none");
      formData.append("fullMessage", fullMessage);
      formData.append("quickMessage", quickMessage);
      formData.append("signOff", signOff);

      const response = await fetch(
        "https://merry-jane-api.onrender.com/adminMessageRoutes/createNewAdminMessage",
        {
          method: "POST",
          body: formData,
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setToastPop(true);
        setMessage(respnseData.success);
        setFile(null);
        setLoading(false);
        setMessageCreatePopUp(false);
        ClearData();
        setRefresh(refresh ? false : true);
        // setVehicleSearchArray(respnseData.enatisVehicles);
      } else {
        setToastPop(true);
        setMessage(respnseData.message);

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  useEffect(() => {
    if (!file) {
      //   if (!sigfile) {
      //     return;
      //   }
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewImage(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);
  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        position: "absolute",
        zIndex: 200,
        overflow: "hidden",
        // marginTop: 60,
        top: 0,
        transition: "all 0.3s",
        opacity: display ? 1 : 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ position: "absolute", top: 10, left: 10 }}>
        <CloseButton
          label={"X"}
          onClick={() => {
            setMessageCreatePopUp(false);
            ClearData();
          }}
        />
      </div>
      <div
        style={{
          flex: 0.5,
          height: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            marginTop: 18,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: 18,
          }}
        >
          NEW MESSAGE
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <TextInputField
            labelTitle={"MESSAGE TITLE"}
            mini={true}
            invert={true}
            width={"40%"}
            size={"87%"}
            value={messageTitle}
            onChange={(e) => setMessageTitle(e.target.value)}
          />
          <TextInputField
            labelTitle={"# TAGS"}
            mini={true}
            invert={true}
            width={"40%"}
            size={"87%"}
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <TextInputField
            labelTitle={"GO TO LINK"}
            mini={true}
            invert={true}
            width={"40%"}
            size={"87%"}
            value={goToLink}
            onChange={(e) => setGoToLink(e.target.value)}
          />
          <TextInputField
            labelTitle={"NOTICE TYPE (READ ONLY)"}
            mini={true}
            invert={true}
            width={"40%"}
            size={"87%"}
            value={"GENERAL"}
            readOnly={true}
          />
        </div>
        <TextAreaInput
          labelTitle={"FULL MESSAGE"}
          mini={true}
          invert={true}
          width={"100%"}
          size={"82%"}
          color={BlackAndWhite.primary}
          value={fullMessage}
          onChange={(e) => setFullMessage(e.target.value)}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <TextAreaInput
            labelTitle={"QUICK DESCRIPTION"}
            mini={true}
            invert={true}
            color={BlackAndWhite.primary}
            width={"40%"}
            size={"90%"}
            value={quickMessage}
            onChange={(e) => setQuickMessage(e.target.value)}
          />

          <TextAreaInput
            labelTitle={"SIGN OFF"}
            mini={true}
            invert={true}
            color={BlackAndWhite.primary}
            width={"40%"}
            size={"90%"}
            value={signOff}
            onChange={(e) => setSignOff(e.target.value)}
          />
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImageUploadAWS
            mini={true}
            width={"100%"}
            size={"81%"}
            onChange={(e) => handleFileChange(e)}
            labelTitle={"UPLOAD IMAGE"}
            errorDisplay={imageError}
            value={file}
          />
        </div>
      </div>
      <div
        style={{
          flex: 0.5,
          height: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            marginTop: 18,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: 18,
          }}
        >
          MESAGE PREVIEW
        </div>

        {/* <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 50,
            overflow: "hidden",
          }}
        >
          <BaseButtons
            label={"CLOSED PREVIEW"}
            size={"50%"}
            // marginRight={20}
            onClick={() => setPreviewDisplay("closed")}
            invert={previewDisplay === "closed" ? false : true}
            mini={true}
            height={50}
          />
          <BaseButtons
            label={"OPEN PREVIEW"}
            size={"50%"}
            onClick={() => setPreviewDisplay("open")}
            invert={previewDisplay === "open" ? false : true}
            mini={true}
            height={50}
          />
        </div> */}
        <div
          style={{
            flex: 1,

            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ClosedMessageCard
            image={previewImage}
            messageTitle={messageTitle !== "" ? messageTitle : "MESSAGE TITLE"}
            quickMessage={
              quickMessage !== ""
                ? quickMessage
                : "SOMETHING SHORT TO GRAB ATTENTION"
            }
            date={new Date().toDateString()}
            tags={tags}
            fullDescription={
              fullMessage !== ""
                ? fullMessage
                : "SOMETHING that yu can sharea long pargraph and long missage saying something about the socail media"
            }
            signOff={
              signOff !== ""
                ? signOff
                : "some thing leading into the click and to read the message or got the main message(our social links)"
            }
            href={goToLink}
          />
          <div
            style={{
              marginTop: 20,
              width: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BaseButtons
              label={"SEND MESSAGE"}
              mini={true}
              marginTopFalse={true}
              height={40}
              width={"100%"}
              size={"100%"}
              onClick={() => CreateNewMessage()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMessagePopUp;
