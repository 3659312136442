import React, { useState, useEffect } from "react";

import {
  TransferFilter,
  Transferheader,
  TransferItem,
  TransferManageView,
} from "./components";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  TextInputFieldSearch,
} from "../../../../../../components/atoms";

const Transfers = ({ auth, setLoading, setMessage, setToastPop }) => {
  const [screenDisplay, setScreenDisplay] = useState("all");
  const [search, setSearch] = useState("");
  const [transferDocsArray, setTransferDocsArray] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [selectedTransfer, setSelectedTransfer] = useState(null);

  const GetTransferDocsAll = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getTransferDocs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setTransferDocsArray(responseData.tansferDocs);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const SelectTranfersForView = (transfer) => {
    setSelectedTransfer(transfer);
  };
  //   console.log("transfer", selectedTransfer);
  useEffect(() => {
    GetTransferDocsAll();
  }, [refresh]);
  return (
    <div
      style={{
        width: "99%",
        flex: 1,

        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          minHeight: 60,
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            // width: 300,

            borderWidth: 0.5,
          }}
        >
          <TextInputFieldSearch
            placeholder={"TYPE HERE TO SEARCH STORE"}
            height={25}
            borderRadius={4}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <div
            style={{
              height: 30,
              width: 70,
              backgroundColor: BlackAndWhite.primary,
              borderRadius: 4,
              display: "flex",
              color: BlackAndWhite.secondary,
              justifyContent: "center",
              alignItems: "center",
              marginRight: 20,
            }}
          >
            <div
              onClick={() => setSearch("")}
              style={{ fontSize: 12, fontWeight: "bold" }}
            >
              CLEAR
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }} />
        <div style={{}}>
          <TransferFilter filterName={"FILTER"} setScreen={setScreenDisplay} />
        </div>

        <div style={{ minWidth: 10 }} />
      </div>
      <Transferheader />
      <div
        style={{
          maxHeight: "70%",
          //   minHeight: 600,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          justifyContent: "flex-start",
        }}
      >
        {screenDisplay === "all"
          ? transferDocsArray
            ? transferDocsArray.map((doc, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      minHeight: 60,

                      display: "flex",
                      alignItems: "center",
                      marginBottom: transferDocsArray
                        ? index === transferDocsArray.length - 1
                          ? 350
                          : 5
                        : 5,
                      backgroundColor: BlackAndWhite.secondary,
                    }}
                  >
                    <TransferItem
                      doc={doc}
                      SelectTranfersForView={SelectTranfersForView}
                    />
                  </div>
                );
              })
            : null
          : null}
      </div>
      <TransferManageView
        display={selectedTransfer}
        setSelectedTransfer={setSelectedTransfer}
        setRefresh={setRefresh}
        refresh={refresh}
        auth={auth}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
      />
    </div>
  );
};
export default Transfers;
