import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../../../components/atoms";

const ProductListCard = ({
  productName,
  sku,
  category,
  subCat,
  size,
  unitMeasure,
  marginPrice,
  productPref,
  productFeature,
  retail,
  actions,
  onClick,
  image,
  warehouseSelect,
  onSelectClick,
  transferDisplay,
  wareHouseStore,
  onTransferAmountChange,
  onRemoveClick,
  multiRecieveArrayItem,
  isProduct,
  onSAveClick,
  AddNewItemToArray,
  product,
  index,
  removeSelectedItemsQty,
  noRemoveOption,
  wareHouseCheck,

  productId,
}) => {
  const [hover, setHover] = useState(false);
  const [displayItem, setDisplayItem] = useState(false);
  const [qtyCheck, setQtyCheck] = useState(0);
  const [isSaved, setIsSaved] = useState(false);

  const [count, setCount] = useState(0);

  const getWareIventoryValue = async ({ productId }) => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getLineItemWarehouseStockLevel",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId: productId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setCount(responseData.stockAmount);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  useEffect(() => {
    if (wareHouseCheck && productId) {
      getWareIventoryValue({ productId });
    }
  }, []);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={warehouseSelect ? onSelectClick : null}
      style={{
        width: "100%",
        height: 50,
        display: "flex",
        backgroundColor: hover ? "#9E3AC350" : BlackAndWhite.secondary,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          flex: 0.25,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div
          style={{
            height: "100%",
            width: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="icon"
            src={
              image === "Na"
                ? require("../../../../../../../../images/material-icons/editBlack.png")
                : image
            }
            style={{
              height: image === "Na" ? 20 : "70%",
              width: image === "Na" ? 20 : "70%",
            }}
          />
        </div>

        <div style={{ marginLeft: 5, width: "95%" }}>{productName}</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ marginLeft: 5, width: "95%" }}>{sku}</div>
      </div>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ marginLeft: 5, width: "95%" }}>{category}</div>
      </div>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ marginLeft: 5, width: "95%" }}>{subCat}</div>
      </div>
      {transferDisplay ? null : (
        <div
          style={{
            flex: 0.1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "left",
          }}
        >
          <div style={{ marginLeft: 5, width: "95%" }}>{productFeature}</div>
        </div>
      )}
      {transferDisplay ? null : (
        <div
          style={{
            flex: 0.1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "left",
          }}
        >
          <div style={{ marginLeft: 5, width: "95%" }}>{productPref}</div>
        </div>
      )}
      {transferDisplay ? (
        <div
          style={{
            flex: 0.125,
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          {count ? count.currentStock : wareHouseStore}
        </div>
      ) : (
        <div
          style={{
            flex: 0.05,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          {size}
        </div>
      )}
      {transferDisplay ? (
        <div
          style={{
            flex: 0.125,
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          <TextInputField
            onChange={(e) => setQtyCheck(e.target.value)}
            mini={true}
            height={15}
            width={"100%"}
            size={"100%"}
            marginTop={-5}
            type={"Number"}
            value={noRemoveOption ? 0 : qtyCheck}
            readOnly={isSaved ? true : false}
          />
          <div style={{ minWidth: 20, marginRight: 20 }} />
        </div>
      ) : (
        <div
          style={{
            flex: 0.1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          {warehouseSelect ? (
            isProduct ? (
              "ADD"
            ) : (
              <div style={{ fontWeight: "bold" }} onClick={onRemoveClick}>
                REMOVE
              </div>
            )
          ) : unitMeasure ? (
            unitMeasure
          ) : null}
        </div>
      )}
      {warehouseSelect ? null : (
        <div
          style={{
            flex: 0.07,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          {retail}
        </div>
      )}
      {warehouseSelect ? null : (
        <div
          style={{
            flex: 0.07,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          {marginPrice}
        </div>
      )}
      {warehouseSelect ? null : (
        <div
          style={{
            flex: 0.1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60%",
              width: "100%",
            }}
          >
            <BaseButtons
              label={"MANAGE"}
              fontSize={12}
              height={20}
              marginTopFalse={true}
              size={"80%"}
              onClick={onClick}
              borderRadius={4}
            />
          </div>
        </div>
      )}
      {transferDisplay ? (
        <div
          style={{
            flex: 0.1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60%",
              width: "100%",
            }}
          >
            <BaseButtons
              label={noRemoveOption ? "ADD" : isSaved ? "EDIT" : "SAVE"}
              fontSize={12}
              height={30}
              marginTopFalse={true}
              size={"80%"}
              disable={noRemoveOption ? false : qtyCheck >= 1 ? false : true}
              onClick={
                noRemoveOption
                  ? () => {
                      AddNewItemToArray({
                        product,
                        qtyCard: {
                          qty: qtyCheck,
                          productId: product._id,
                        },
                      });
                    }
                  : isSaved
                  ? () => {
                      removeSelectedItemsQty({ product, index });
                      setIsSaved(false);
                    }
                  : () => {
                      AddNewItemToArray({
                        product,
                        qtyCard: {
                          qty: qtyCheck,
                          productId: product._id,
                        },
                      });
                      setIsSaved(true);
                    }
              }
              borderRadius={4}
            />
            {noRemoveOption ? null : (
              <BaseButtons
                label={"X"}
                fontSize={12}
                height={23}
                marginTopFalse={true}
                size={36}
                onClick={onRemoveClick}
                borderRadius={4}
                borderWidth={1}
                invert={true}
                marginRight={10}
                marginLeft={10}
              />
            )}
          </div>
        </div>
      ) : null}
      <div
        style={{
          width: "99%",
          borderStyle: "solid",
          borderWidth: 0.5,
          borderColor: "#00000030",
          position: "absolute",
          bottom: 0,
        }}
      />
    </div>
  );
};

export default ProductListCard;
