import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import {
  AdminForm,
  CashierForm,
  CategoryButton,
  DoctorForm,
  InvestorForm,
  LoadingPopUp,
  ManagerForm,
  SupportForm,
  ToastNotification,
} from "../../../components";
import HeadersTitle from "./table-blocks/HeadersTitle";
import UserItem from "./table-blocks/UserItem";
import UserPopUp from "./user-pop-up/UserPopUp";
import { BaseButtons, TextInputFieldSearch } from "../../../components/atoms";
import { Filter } from "./facility-components";
import AddUserPopUp from "./add-user-pop-up/AddUserPopUp";
import ManageUserPopUp from "./manage-user-pop-up/ManageUserPopUp";
import { UseFilter, UserStoreCard } from "./user-screen-components";

const Users = ({
  subSelection,
  userDisplay,
  setUserdisplay,
  setSubSelection,
}) => {
  const [selectedType, setSelectedType] = useState("none");
  const [userArray, setUserArray] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("error");

  const [userSelected, setUserSelected] = useState(null);

  // register fields
  const [addUserPopUp, setAddUSerPopUp] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cellNumber, setCellNumber] = useState("none");
  const [email, setEmail] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [province, setProvince] = useState("none");
  const [accountNumber, setAccountNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [storeName, setStoreName] = useState("none");
  const [selectedStore, setSelectedStore] = useState(null);
  const [pin, setPin] = useState("");

  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");

  const [storesArray, setStoreArray] = useState([]);

  const [screen, setScreen] = useState("users");

  const [storeUsersOpen, setUsersStoreOpen] = useState("");

  const registerSubmit = async () => {
    setLoading(true);
    // setError("Loading");
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/cannaPos/reg",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            cellNumber: cellNumber,
            email: email,
            idDoc: idNumber,
            province: province || selectedStore.province,
            storeName:
              selectedType === "owner"
                ? "Owner"
                : selectedType === "Admin" || selectedType === "Sub-Admin"
                ? "All"
                : selectedType === "Doctor"
                ? "ALL"
                : selectedStore.storeName,
            accountNumber: accountNumber,
            userName: email,
            dateOfBirth: idNumber.substring(0, 6),
            password: password,
            acceptedTandC: true,
            type: selectedType,
            profileImage: "coming soon",
            storeId: selectedStore
              ? selectedType === "Doctor"
                ? "ALL"
                : selectedStore.storeId
              : "NA",
            pin: pin,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.type) {
        setLoading(false);
        setToastPop(true);
        setAddUSerPopUp(false);
        setMessage(
          "An email invite has been sent to" +
            " " +
            email +
            " ," +
            " " +
            firstName +
            " " +
            lastName +
            "," +
            selectedType +
            " user " +
            "has been registered succefully"
        );
        setSelectedType("none");
        setRefresh(refresh ? false : true);
      } else {
        setToastPop(true);
        setMessage("Unable to create user" + " ," + respnseData.message);
        setError("invalid credentials");
        setLoading(false);
        console.log("error data is missing");
      }
    } catch (err) {
      setToastPop(true);
      setMessage("something went wrong contact support");
      setLoading(false);
      setError("invalid credentials");
    }
  };

  // const CheckCount = (id) => {
  //   let count;

  //   try {
  //     const response = fetch("http://localhost:3001/cannaPos/getUserCount", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         id: "0879036f-4f8c-4a25-8c13-b18a2edec742",
  //       }),
  //     });
  //     console.log("check count", response);
  //     const respnseData = response.json();
  //     console.log("check count", respnseData);
  //   } catch (err) {
  //     console.log("error", err);
  //   }
  // };

  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/facilities/getFacilities")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setStoreArray(resData);
      });
    fetch("https://merry-jane-api.onrender.com/cannaPos/fetchAllUsers")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setUserArray(resData);
      });
  }, [refresh]);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {subSelection === "ALL USERS" || subSelection === "none" ? (
        <div
          style={{
            height:
              subSelection === "ALL USERS" || subSelection === "none"
                ? "100%"
                : 0,
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            transition: "all 0.5s",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              minHeight: 60,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: 10, marginRight: 10, width: 220 }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 150,
                  width: 300,

                  borderWidth: 0.5,
                }}
              >
                <TextInputFieldSearch
                  placeholder={"Type here to search"}
                  height={25}
                  borderRadius={4}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  mini={true}
                />
                <div
                  style={{
                    height: 30,
                    width: 70,
                    backgroundColor: BlackAndWhite.primary,
                    borderRadius: 4,
                    display: "flex",
                    color: BlackAndWhite.secondary,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => setSearch("")}
                    style={{ fontSize: 12, fontWeight: "bold" }}
                  >
                    CLEAR
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <UseFilter filterName={"FILTER"} setScreen={setScreen} />
            </div>
            <BaseButtons
              label={"+ ADD USER"}
              mini={true}
              marginTop={-2}
              // marginTopFalse={true}
              height={35}
              fontSize={12}
              borderRadius={20}
              onClick={() => setAddUSerPopUp(true)}
            />
            <div style={{ width: 10 }} />
          </div>
          <HeadersTitle />
          <div style={{ width: "100%", overflowY: "auto" }}>
            {screen === "users" ? (
              userArray.data ? (
                userArray.data.map((user, index) => {
                  return user.type !== "Practitioner" ? (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <UserItem
                        onManageClick={() => setUserSelected(user)}
                        firstName={user.firstName}
                        surname={user.lastName}
                        role={user.type}
                        store={user.storeName}
                        email={user.email}
                        cell={user.cellNumber}
                        status={user.status}
                        province={user.province}
                      />
                    </div>
                  ) : null;
                })
              ) : (
                <div style={{ marginTop: 100 }}>loading . . .</div>
              )
            ) : null}

            {screen === "stores" ? (
              <UserStoreCard
                title={"Merry-Jane Administration"}
                store={{ storeName: "ALL", status: "All ACCESS USERS" }}
                users={userArray ? userArray : []}
                index={"all"}
                setUserSelected={setUserSelected}
              />
            ) : null}
            {screen === "stores" ? (
              storesArray.store ? (
                storesArray.store.map((store, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <UserStoreCard
                        title={store.storeName}
                        store={store}
                        users={userArray ? userArray : []}
                        index={index}
                        setUserSelected={setUserSelected}
                        // count={CheckCount(store.storeId)}
                      />
                    </div>
                  );
                })
              ) : (
                <div style={{ marginTop: 100 }}>loading . . .</div>
              )
            ) : null}
            <div style={{ padding: 20 }} />
          </div>
        </div>
      ) : null}
      <LoadingPopUp display={loading} />
      <ToastNotification display={toastPop} message={message.toUpperCase()} />
      <AddUserPopUp
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        display={addUserPopUp}
        onCloseClick={() => setAddUSerPopUp(false)}
        firstName={firstName}
        setFirstName={setFirstName}
        setLastName={setLastName}
        lastName={lastName}
        cellNumber={cellNumber}
        setCellNumber={setCellNumber}
        email={email}
        setEmail={setEmail}
        setIdNumber={setIdNumber}
        idNumber={idNumber}
        province={province}
        setProvince={setProvince}
        setAccountNumber={setAccountNumber}
        accountNumber={accountNumber}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        selectedStore={selectedStore}
        setSelectedStore={setSelectedStore}
        onSubmitClick={() => registerSubmit()}
        pin={pin}
        setPin={setPin}
      />
      <ManageUserPopUp
        display={userSelected ? true : false}
        onCloseClick={() => setUserSelected(null)}
        selectedUser={userSelected}
        setUserSelected={setUserSelected}
        loading={loading}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
        setError={setError}
      />
      {/* <UserPopUp
        display={userSelected ? true : false}
        onCloseClick={() => setUserSelected(null)}
        userSelected={userSelected}
      /> */}
    </div>
  );
};

export default Users;
