const BlackAndWhite = {
  primary: "#000000cc",
  secondary: "#ffffff",
  green: "#5EEB5B",
  purple: "#9E3AC3",
  purple50: "#9E3AC350",
  orange: "#FAA919",
  orange50: "#Faa91950",
  blue: "#6792AC",
  blue50: "#6792AC50",
};

export { BlackAndWhite };
