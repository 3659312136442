import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";

const SubCategoryCard = ({
  title,
  description,
  hasSubCategory,
  imageIcon,
  imageIcon2,
  onClick,
  width,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        margin: 15,
        borderRadius: 8,
        display: "flex",

        transition: "all 0.1s",
        cursor: "pointer",
        flexDirection: "column",
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        borderStyle: hover ? "solid" : "none",
        borderWidth: hover ? 1 : 0,
        borderColor: "#FAA919",
        WebkitBoxShadow: "0px 0px 10px 1px #00000066",
      }}
    >
      <div
        style={{
          height: 200,
          width: width ? width : 200,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            fontSize: 22,
            textAlign: "center",
            height: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            position: "absolute",
            color: BlackAndWhite.secondary,
          }}
        >
          {title}
        </div>

        <img
          alt="Logo"
          src={require("../../../../../images/marijuana-buds-with-marijuana-joints-cannabis-oil.jpg")}
          style={{
            width: "100%",
          }}
        />
        <div
          style={{
            width: 200,
            fontWeight: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              // marginLeft: 10,
              fontSize: 14,
              width: "92%",
              // borderStyle: "solid",
              fontWeight: "bold",
            }}
          >
            Description:
          </div>
          <div
            style={{
              // marginLeft: 10,
              fontSize: 11,
              width: "92%",
              // borderStyle: "solid",
              marginTop: 4,
            }}
          >
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCategoryCard;
