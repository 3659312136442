import React, { useContext } from "react";
import { BaseButtons } from "../../../../components/atoms";
import useWindowDimensions from "../../../../components/get-diemensions/UseWindowDiemensions";
import { AuthContext } from "../../../../context/auth-context";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const { width, height } = useWindowDimensions();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Logout = () => {
    auth.logout();
    navigate("/");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          width: width > 899 ? "40%" : "80%",
          textAlign: "center",
          marginBottom: 80,
          fontSize: 24,
          fontFamily: "Montserrat",
          //   textAlign: "center",
          lineHeight: 1.6,
          marginTop: 160,
        }}
      >
        ARE YOU SURE YOU WANT TO LOG-OUT?
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: width > 899 ? "row" : "column",
        }}
      >
        <BaseButtons label={"YES"} onClick={() => Logout()} />
        <div style={{ width: 40 }} />
      </div>
    </div>
  );
};

export default Logout;
