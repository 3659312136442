import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const WeekdayButton = ({
  setHover,
  hover,
  des,
  title,
  onClick,
  height,
  width,
  margin,
}) => {
  return (
    <div
      onClick={onClick}
      onMouseOver={() => setHover(title)}
      onMouseOut={() => setHover("")}
      style={{
        cursor: "pointer",
        height: height ? height : 60,
        width: width ? width : 100,
        borderStyle: "solid",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.3s",
        backgroundColor:
          hover === title ? BlackAndWhite.primary : BlackAndWhite.secondary,
        color:
          hover === title ? BlackAndWhite.secondary : BlackAndWhite.primary,
        margin: margin ? margin : 10,
      }}
    >
      <div style={{ marginBottom: 5 }}>{title}</div>
      {des ? (
        <div
          style={{
            fontSize: 10,
            textAlign: "center",
            width: "80%",
          }}
        >
          {des}
        </div>
      ) : null}
    </div>
  );
};

export default WeekdayButton;
