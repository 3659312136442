import React, { useState } from "react";

import FilterButtonSmall from "../filter-button-small/FilterButtonSmall";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const InventoryFilter = ({ filterName, setScreen }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("PRODUCTS");
  const [selectedHover, setSelectedHover] = useState(false);
  return (
    <div
      onClick={open ? null : () => setOpen(true)}
      style={{
        cursor: "pointer",
        height: 50,
        width: open ? 350 : 40,
        // borderStyle: "solid",
        // borderWidth: open ? 1 : 0,
        transition: "all 0.4s",
        marginRight: open ? 10 : 20,
        borderRadius: 100,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: open ? BlackAndWhite.primary : BlackAndWhite.secondary,
      }}
    >
      {open ? null : (
        <div
          onClick={open ? null : () => setOpen(true)}
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? BlackAndWhite.primary
                : BlackAndWhite.primary,
              color: open ? BlackAndWhite.secondary : BlackAndWhite.secondary,
              height: 35,
              width: 120,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: 14,
            }}
          >
            <div>{selected}</div>
          </div>
        </div>
      )}

      <div
        style={{
          flex: open ? 1 : 0,
          display: "flex",
          overflow: open ? null : "hidden",
        }}
      >
        <div
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: open ? 5 : null,
          }}
        >
          <div
            onMouseOver={() => setSelectedHover("stores")}
            onMouseOut={() => setSelectedHover(null)}
            onClick={() => {
              setSelected("STORES");
              setOpen(false);
              setScreen("stores");
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? selected === "STORES"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
                : null,
              color: open
                ? selected === "STORES"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
                : null,
              height: selectedHover === "stores" ? 40 : 35,
              width: selectedHover === "stores" ? 135 : 120,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: selectedHover === "stores" ? 14 : 12,
              transition: "all 0.3s",
              WebkitBoxShadow:
                selectedHover === "stores"
                  ? "0px 0px 5px 5px #FFFFFF90"
                  : "0px 0px 0px 0px #00000040",
            }}
          >
            <div>STORES</div>
          </div>
        </div>
        <div
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onMouseOver={() => setSelectedHover("products")}
            onMouseOut={() => setSelectedHover(null)}
            onClick={() => {
              setSelected("PRODUCTS");
              setOpen(false);
              setScreen("products");
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? selected === "PRODUCTS"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
                : null,
              color: open
                ? selected === "PRODUCTS"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
                : null,
              height: selectedHover === "products" ? 40 : 35,
              width: selectedHover === "products" ? 135 : 120,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: selectedHover === "products" ? 14 : 12,
              transition: "all 0.3s",
              WebkitBoxShadow:
                selectedHover === "products"
                  ? "0px 0px 5px 5px #FFFFFF90"
                  : "0px 0px 0px 0px #00000040",
            }}
          >
            <div>PRODUCTS</div>
          </div>
        </div>
      </div>
      <FilterButtonSmall
        icon1={
          open
            ? require("../../../../../../images/material-icons/setupAndMangeWhite.png")
            : require("../../../../../../images/material-icons/setupAndMangeBlack.png")
        }
        icon2={
          open
            ? require("../../../../../../images/material-icons/setupAndMangeWhite.png")
            : require("../../../../../../images/material-icons/setupAndMangeBlack.png")
        }
        label={open ? "CLOSE" : filterName}
        iconHeight={20}
        iconWidth={20}
        fontSize={12}
        fontColor={open ? BlackAndWhite.secondary : BlackAndWhite.primary}
        fontColor1={open ? BlackAndWhite.secondary : BlackAndWhite.primary}
        onClick={() => setOpen(false)}
      />
      <div style={{ width: 10 }} />
    </div>
  );
};

export default InventoryFilter;
