import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import { BaseButtons, TextInputField } from "../../components/atoms";
import { RefistrationPopUp } from "./components";
import { LoadingPopUp, ToastNotification } from "../../components";

const PractionerRegistration = () => {
  const { width, height } = useWindowDimensions();
  const [regPopUp, setRegPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [foundInvite, setFoundInvite] = useState(null);

  const SearchForInvite = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/practioner/searchForInvite",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            code: inviteCode,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        setFoundInvite(responseData.invitation);
        setMessage(responseData.success);
        setToastPop(true);
        setLoading(false);
        setRegPopUp(true);
      } else {
        setMessage(responseData.error);
        setToastPop(true);
        setLoading(false);
      }
    } catch (err) {
      setMessage("Please check your connection then try again");
      setToastPop(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 2500);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        width: width,
        height: height,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="logo"
          src={require("../../images/newLogos/1. MJ Logo Black with White Surround.png")}
          style={{ height: 150 }}
        />
        <div
          style={{
            marginTop: 50,
            marginBottom: 20,
            width: 300,
            textAlign: "center",
          }}
        >
          Merry-Jane wants to know if you where invited?
        </div>
        <TextInputField
          invert={true}
          labelTitle={"Email"}
          mini={true}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextInputField
          invert={true}
          labelTitle={"Code"}
          mini={true}
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
        />
        <BaseButtons
          label={"SUBMIT"}
          marginTopFalse={false}
          mini={true}
          borderRadius={4}
          onClick={() => SearchForInvite()}
        />
      </div>
      <img
        alt="logo"
        src={require("../../images/merryjanelogooptions/Merry-Jane Plain Light Logo.png")}
        style={{
          height: 450,
          position: "absolute",
          opacity: 0.5,
          bottom: -90,
          right: 0,
          transform: `rotateZ(-45deg)`,
        }}
      />
      <img
        alt="logo"
        src={require("../../images/merryjanelogooptions/Merry-Jane Plain Light Logo.png")}
        style={{
          height: 550,
          position: "absolute",
          opacity: 0.4,
          bottom: -50,
          left: 0,
          transform: `rotateZ(45deg)`,
        }}
      />
      <img
        alt="logo"
        src={require("../../images/merryjanelogooptions/Merry-Jane Plain Light Logo.png")}
        style={{
          height: 250,
          position: "absolute",
          opacity: 0.4,
          top: 50,
          right: 300,
          transform: `rotateZ(-35deg)`,
        }}
      />
      <img
        alt="logo"
        src={require("../../images/merryjanelogooptions/Merry-Jane Plain Light Logo.png")}
        style={{
          height: 150,
          position: "absolute",
          opacity: 0.4,
          top: 50,
          left: 400,
          transform: `rotateZ(35deg)`,
        }}
      />
      <RefistrationPopUp
        display={regPopUp && foundInvite ? true : false}
        onCloseClick={() => setRegPopUp(false)}
        setRegPopUp={setRegPopUp}
        setFoundInvite={setFoundInvite}
        foundInvite={foundInvite}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
      />
      <LoadingPopUp display={loading} />
      <ToastNotification display={toastPop} message={message.toUpperCase()} />
    </div>
  );
};
export default PractionerRegistration;
