import React from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import BaseButtons from "../base-buttons/BaseButtons";

const MenuPopUp = ({
  display,
  onLoginClick,
  onHomeClick,
  onContactUs,
  onOpportunitiesUs,
  onEnquireClick,
  onLocationClick,
  onStoreClick,
  onMemberClick,
  facilities,
}) => {
  return (
    <div
      style={{
        height: "100%",
        width: display ? 250 : 0,
        backgroundColor: "#000000cc",
        position: "fixed",
        top: 80,
        right: 0,
        overflowY: "auto",
        transition: "all 0.6s",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        zIndex: 300,
        overflowX:"hidden"
      }}
    >
      <BaseButtons
        mini={true}
        width={"100%"}
        size={"100%"}
        label={"LOGIN / REGISTER"}
        height={40}
        onClick={onLoginClick}
        marginTopFalse={true}
      />
      <BaseButtons
        width={"100%"}
        size={"100%"}
        label={"WELCOME"}
        height={40}
        onClick={onHomeClick}
        marginTopFalse={true}
        mini={true}
      />
      {facilities ? null : (
        <BaseButtons
          width={"100%"}
          size={"100%"}
          label={"ONLINE STORE"}
          height={40}
          onClick={onStoreClick}
          marginTopFalse={true}
          mini={true}
        />
      )}
      {facilities ? null : (
        <BaseButtons
          width={"100%"}
          size={"100%"}
          label={"PATIENT REGISTRY"}
          height={40}
          onClick={onMemberClick}
          marginTopFalse={true}
          mini={true}
        />
      )}
      {facilities ? null : (
        <BaseButtons
          width={"100%"}
          size={"100%"}
          label={"FACILITIES"}
          height={40}
          onClick={onLocationClick}
          marginTopFalse={true}
          mini={true}
        />
      )}
      {/* {facilities ? null : (
        <BaseButtons
          width={"100%"}
          size={"100%"}
          label={"OPPORTUNITIES"}
          height={40}
          onClick={onOpportunitiesUs}
          marginTopFalse={true}
          mini={true}
        />
      )} */}

      {facilities ? null : (
        <BaseButtons
          width={"100%"}
          size={"100%"}
          label={"CONTACT US"}
          height={40}
          onClick={onEnquireClick}
          marginTopFalse={true}
          mini={true}
        />
      )}
      {facilities ? null : (
        <a
          href="https://merry-jane.blog/"
          style={{ textDecoration: "none", width: "100%" }}
        >
          <BaseButtons
            width={"100%"}
            size={"100%"}
            label={"OFFICIAL BLOG"}
            height={40}
            // onClick={onEnquireClick}
            marginTopFalse={true}
            mini={true}
          />
        </a>
      )}
      {facilities ? null : (
        <a
          style={{ textDecoration: "none", width: "100%" }}
          href="https://merry-jane.blog/"
        >
          <BaseButtons
            width={"100%"}
            size={"100%"}
            label={"MERRY JANE ART"}
            height={40}
            // onClick={onEnquireClick}
            marginTopFalse={true}
            mini={true}
          />
        </a>
      )}

      <div style={{ height: 10 }} />
    </div>
  );
};

export default MenuPopUp;
