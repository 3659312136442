import React, { useState } from "react";
import {
  BaseButtons,
  CheckInput,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  ImageCheckMark,
  LoadingPopUp,
  ToastNotification,
} from "../../../../../../components";

const AddCategory = ({
  setMessage,
  setToastPop,
  setSelectedAdd,
  setRefresh,
  refresh,
}) => {
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [description, setDescription] = useState(null);
  const [hasSubCategories, setHasSubCategories] = useState(false);
  const [extraInfo, setExtraInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const uploadCategory = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/createCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryName: categoryName,
            description: description,
            hasSubCategory: hasSubCategories,
            moreInfo: extraInfo,
            imageIcon: selectedIcon,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.category) {
        // const wait = await updateListFetch();
        setToastPop(true);
        setMessage("Category uploaded");
        setLoading(false);
        setSelectedAdd(null);
        setSelectedIcon(null);
        setCategoryName(null);
        setDescription(null);
        setExtraInfo(null);
        setRefresh(refresh ? false : true);
      } else {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ flex: 0.5, position: "relative" }}>
        <div style={{ height: 40 }} />
        <TextInputField
          labelTitle={"Category name:"}
          invert
          size={"70%"}
          onChange={(e) => setCategoryName(e.target.value)}
        />
        <TextAreaInput
          labelTitle={"Description :"}
          invert
          size={"70%"}
          color={BlackAndWhite.primary}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div>
          <CheckInput
            check={hasSubCategories}
            question={"Has sub sections?"}
            invert={true}
            onClick={() => setHasSubCategories(hasSubCategories ? false : true)}
          />
        </div>
      </div>
      <div style={{ flex: 0.5 }}>
        <div style={{ height: 40 }} />
        <TextInputField
          labelTitle={"Extra info:"}
          invert
          size={"70%"}
          onChange={(e) => setExtraInfo(e.target.value)}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: 35,
              marginBottom: 20,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Category icon type
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImageCheckMark
            title={"Flower 1"}
            image={require("../../../../../../images/product-icons/flowerBlack.png")}
            imageHeight={50}
            invert={true}
            width={200}
            check={selectedIcon === "flowers1" ? true : false}
            onClick={() => setSelectedIcon("flowers1")}
          />
          <div style={{ width: 20 }} />
          <ImageCheckMark
            title={"Flower 2"}
            image={require("../../../../../../images/product-icons/flowerBlack2.png")}
            imageHeight={50}
            invert={true}
            width={200}
            check={selectedIcon === "flowers2" ? true : false}
            onClick={() => setSelectedIcon("flowers2")}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImageCheckMark
            title={"Edibles"}
            image={require("../../../../../../images/product-icons/edibleBlack.png")}
            imageHeight={50}
            invert={true}
            width={200}
            check={selectedIcon === "edibles" ? true : false}
            onClick={() => setSelectedIcon("edibles")}
          />
          <div style={{ width: 20 }} />
          <ImageCheckMark
            title={"Drinks"}
            image={require("../../../../../../images/product-icons/drinkBlack.png")}
            imageHeight={50}
            invert={true}
            width={200}
            check={selectedIcon === "drinks" ? true : false}
            onClick={() => setSelectedIcon("drinks")}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImageCheckMark
            title={"Products 1"}
            image={require("../../../../../../images/product-icons/otherProducts1.png")}
            imageHeight={50}
            invert={true}
            width={200}
            check={selectedIcon === "products1" ? true : false}
            onClick={() => setSelectedIcon("products1")}
          />
          <div style={{ width: 20 }} />
          <ImageCheckMark
            title={"Products 2"}
            image={require("../../../../../../images/product-icons/otherProdyctsBlack2.png")}
            imageHeight={40}
            invert={true}
            width={200}
            check={selectedIcon === "products2" ? true : false}
            onClick={() => setSelectedIcon("products2")}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <BaseButtons label={"SUBMIT"} onClick={() => uploadCategory()} />
          <BaseButtons label={"CANCEL"} onClick={() => setSelectedAdd(null)} />
        </div>
      </div>
      <LoadingPopUp display={loading} />
    </>
  );
};

export default AddCategory;
