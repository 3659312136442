import React, { useEffect, useState } from "react";
import {
  BaseButtons,
  CloseButton,
  TextInputField,
} from "../../../../components/atoms";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  LoadingPopUp,
  NewPrescriptionCard,
  ToastNotification,
} from "../../../../components";
import PrescriptionCard from "../../../customer-portal/components/prescription/components/PrescriptionCard";

const UserInfoPopUp = ({
  display,
  onCloseClick,
  selectedPatient,
  setMessage,
  setToastPop,
  loading,
  setLoading,
  setRefresh,
  refresh,
  toastPop,
  message,
}) => {
  const [tabDisplay, setTabDisplay] = useState("prescriptions");
  const [updateEmail, setUpdateEmail] = useState(false);
  const [updateCell, setUpdateCell] = useState(false);

  const [email, setEmail] = useState("");
  const [cellNumber, setCellNumber] = useState("");

  const [prescriptionList, setPrescriptionList] = useState(null);
  const [selectedPrescription, setSelectedPrescription] = useState("approved");

  const [forgotPasswordToken, setForgotPasswordToken] = useState(null);
  const [validateToken, setValidateToken] = useState(null);

  const UpdateUserInformation = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/support/updateUserContacts",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idNumber: selectedPatient.idDoc,
            email: email || selectedPatient.email,
            cellnumber: cellNumber || selectedPatient.cellNumber,
          }),
        }
      );
      const respnseData = await response.json();
      if (respnseData.message === "user has been updated") {
        // const wait = await updateListFetch();

        setEmail("");
        setCellNumber("");
        setUpdateEmail(false);
        setUpdateCell(false);
        setMessage(respnseData.message);
        setLoading(false);
        setRefresh(refresh ? false : true);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      setToastPop(true);
      setMessage(
        "error has occured if it happens again check your signal then contact support"
      );
      setLoading(false);
    }
  };

  const FetchForgotPasswordToken = async () => {
    await fetch(
      "https://merry-jane-api.onrender.com/support/getForgotPassword",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idNumber: selectedPatient.idDoc,
          email: selectedPatient.email,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setForgotPasswordToken(resData);
      });
  };

  const FetchValidationTokens = async () => {
    await fetch(
      "https://merry-jane-api.onrender.com/support/getValidationTken",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idNumber: selectedPatient.idDoc,
          email: selectedPatient.email,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setValidateToken(resData);
      });
  };
  const FetchPrescriptions = async () => {
    await fetch(
      "https://merry-jane-api.onrender.com/prescription/fetchUserPrescriptions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: selectedPatient.idDoc,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setPrescriptionList(resData);
      });
  };
  useEffect(() => {
    if (selectedPatient) {
      FetchPrescriptions();
    }
  }, [selectedPatient]);
  useEffect(() => {
    if (selectedPatient) {
      FetchForgotPasswordToken();
      FetchValidationTokens();
    }
  }, [selectedPatient, refresh]);

  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: "100%",
        position: "absolute",
        top: 0,
        backgroundColor: BlackAndWhite.secondary,
        zIndex: 2000000000,
        transition: "all 0.2s",
        overflow: "hidden",
        display: "flex",
      }}
    >
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <CloseButton invert={false} label={"X"} onClick={onCloseClick} />
      </div>
      {/* patient info */}
      <div
        style={{
          flex: 0.4,
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "90%",
            width: 400,
            borderRadius: 20,
            borderStyle: "solid",
            // borderWidth: 0.5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ width: "90%", textAlign: "left", marginTop: 20 }}>
            <div style={{ fontSize: 10, fontWeight: "bold", opacity: 0.5 }}>
              NAME:
            </div>
            <div style={{ marginTop: 5 }}>
              {selectedPatient
                ? selectedPatient.firstName + " " + selectedPatient.lastName
                : "loading"}
            </div>
          </div>
          <div style={{ width: "90%", textAlign: "left", marginTop: 10 }}>
            <div style={{ fontSize: 10, fontWeight: "bold", opacity: 0.5 }}>
              ACCOUNT TYPE:
            </div>
            <div style={{ marginTop: 5 }}>
              {selectedPatient ? selectedPatient.type : "loading"}
            </div>
          </div>
          <div style={{ width: "90%", textAlign: "left", marginTop: 10 }}>
            <div style={{ fontSize: 10, fontWeight: "bold", opacity: 0.5 }}>
              DATE CREATED:
            </div>
            <div style={{ marginTop: 5 }}>
              {selectedPatient ? selectedPatient.dateCreated : "loading"}
            </div>
          </div>
          <div style={{ width: "90%", textAlign: "left", marginTop: 10 }}>
            <div style={{ fontSize: 10, fontWeight: "bold", opacity: 0.5 }}>
              ID NUMBER | PASSPORT:
            </div>
            <div style={{ marginTop: 5 }}>
              {selectedPatient ? selectedPatient.idDoc : "loading"}
            </div>
          </div>
          <div style={{ width: "90%", textAlign: "left", marginTop: 10 }}>
            <div style={{ fontSize: 10, fontWeight: "bold", opacity: 0.5 }}>
              DATE OF BIRTH:
            </div>
            <div style={{ marginTop: 5 }}>
              {selectedPatient ? selectedPatient.dateOfBirth : "loading"}
            </div>
          </div>
          <div style={{ width: "90%", textAlign: "left", marginTop: 10 }}>
            <div style={{ fontSize: 10, fontWeight: "bold", opacity: 0.5 }}>
              GENDER:
            </div>
            <div style={{ marginTop: 5 }}>
              {selectedPatient ? selectedPatient.gender : "loading"}
            </div>
          </div>
          <div style={{ width: "90%", textAlign: "left", marginTop: 10 }}>
            <div style={{ fontSize: 10, fontWeight: "bold", opacity: 0.5 }}>
              Address:
            </div>
            <div style={{ marginTop: 5 }}>
              <div>{selectedPatient ? selectedPatient.street : "loading"}</div>
              <div>{selectedPatient ? selectedPatient.suburb : "loading"}</div>
              <div>{selectedPatient ? selectedPatient.town : "loading"}</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: 60,
              marginTop: 10,
            }}
          >
            <div
              style={{
                flex: 0.5,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12, marginBottom: 5 }}>
                Profile progress
              </div>
              <div
                style={{
                  width: 120,
                  borderRadius: 4,
                  backgroundColor: "#00000033",
                  height: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {selectedPatient ? selectedPatient.profileProgress : "loading"}
              </div>
            </div>
            <div
              style={{
                flex: 0.5,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 12, marginBottom: 5 }}>Reward points</div>
              <div
                style={{
                  width: 120,
                  borderRadius: 4,
                  backgroundColor: "#00000033",
                  height: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {selectedPatient ? selectedPatient.rewardPoints : "loading"}
              </div>
            </div>
          </div>

          <div style={{ width: "90%", textAlign: "left", marginTop: 10 }}>
            <div style={{ fontSize: 10, fontWeight: "bold", opacity: 0.5 }}>
              Accepted terms:
            </div>
            <div style={{ marginTop: 5 }}>
              {selectedPatient ? selectedPatient.acceptedTandC : "loading"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <div>
              <div style={{ fontSize: 10, fontWeight: "bold", opacity: 0.5 }}>
                EMAIL:
              </div>
              <div style={{ marginBottom: 10 }}>
                {selectedPatient ? selectedPatient.email : "loading"}
              </div>
              <BaseButtons
                label={"update email"}
                mini={true}
                marginTopFalse={true}
                onClick={() => {
                  setUpdateEmail(true);
                  setUpdateCell(false);
                }}
              />
            </div>
            <div>
              <div style={{ fontSize: 10, fontWeight: "bold", opacity: 0.5 }}>
                CELL NO:
              </div>
              <div style={{ marginBottom: 10 }}>
                {selectedPatient ? selectedPatient.cellNumber : "loading"}
              </div>
              <BaseButtons
                onClick={() => {
                  setUpdateCell(true);
                  setUpdateEmail(false);
                }}
                label={"update cell"}
                mini={true}
                marginTopFalse={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 0.6,
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "90%",
            width: "90%",
            borderRadius: 20,
            borderStyle: "solid",
            // borderWidth: 0.5,
            display: "flex",
            flexDirection: "column",
            justifyContent: updateEmail || updateCell ? "center" : "flex-start",
            alignItems: "center",
            overflow: "hidden",
            marginLeft: 20,
          }}
        >
          {updateEmail || updateCell ? null : (
            <div
              style={{
                height: 50,
                width: "100%",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <BaseButtons
                label={"Forgot Password Tokens"}
                mini={true}
                marginTopFalse={true}
                invert={tabDisplay === "forgot" ? true : false}
                onClick={() => setTabDisplay("forgot")}
                // height={48.4}
              />
              <BaseButtons
                label={"Validation Tokens"}
                mini={true}
                marginTopFalse={true}
                invert={tabDisplay === "validate" ? true : false}
                onClick={() => setTabDisplay("validate")}
              />
              <BaseButtons
                label={"Prescriptions"}
                mini={true}
                marginTopFalse={true}
                invert={tabDisplay === "prescriptions" ? true : false}
                onClick={() => setTabDisplay("prescriptions")}
              />
            </div>
          )}

          {/* _id
660041a0b8fd04e4a1837df3
email
"harmoniekelder@gmail.com"
code
"723949"
idNumber
"7905290156085"
dateCreated
"Sun Mar 24 2024" */}
          {tabDisplay === "forgot" ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {forgotPasswordToken ? (
                <>
                  {forgotPasswordToken.message ? (
                    <div
                      style={{
                        marginTop: 50,
                        fontSize: 20,
                      }}
                    >
                      {forgotPasswordToken.message}
                    </div>
                  ) : null}
                  {forgotPasswordToken.forgotPasswordTicket ? (
                    <div style={{ width: "90%", marginTop: 20 }}>
                      <div
                        style={{
                          fontSize: 13,
                          marginBottom: 4,
                          color: "#00000070",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        Date created:
                      </div>
                      <div>
                        {forgotPasswordToken.forgotPasswordTicket.dateCreated}
                      </div>

                      <div
                        style={{
                          fontSize: 13,
                          marginBottom: 4,
                          color: "#00000070",
                          fontWeight: "bold",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        Patient ID:
                      </div>
                      <div>
                        {forgotPasswordToken.forgotPasswordTicket.idNumber}
                      </div>
                      <div
                        style={{
                          fontSize: 13,
                          marginBottom: 4,
                          color: "#00000070",
                          fontWeight: "bold",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        Patient Email:
                      </div>
                      <div>
                        {forgotPasswordToken.forgotPasswordTicket.email}
                      </div>
                      <div
                        style={{
                          fontSize: 13,
                          marginBottom: 4,
                          color: "#00000070",
                          fontWeight: "bold",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        CODE:
                      </div>
                      <div style={{ fontSize: 22, fontWeight: "bold" }}>
                        {forgotPasswordToken.forgotPasswordTicket.code}
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                "LOADING"
              )}
            </div>
          ) : null}
          {tabDisplay === "prescriptions" && !updateEmail && !updateCell ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {/* prescriopn list tab buttons */}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  height: 40,
                  backgroundColor: "#00000050",
                }}
              >
                <BaseButtons
                  label={"Pending"}
                  marginTopFalse={true}
                  mini={true}
                  height={40}
                  size={"25%"}
                  invert={selectedPrescription === "pending" ? false : true}
                  onClick={() => setSelectedPrescription("pending")}
                />
                <BaseButtons
                  label={"Approved"}
                  marginTopFalse={true}
                  mini={true}
                  height={40}
                  size={"25%"}
                  invert={selectedPrescription === "approved" ? false : true}
                  onClick={() => setSelectedPrescription("approved")}
                />
                <BaseButtons
                  label={"Expired"}
                  marginTopFalse={true}
                  mini={true}
                  height={40}
                  size={"25%"}
                  invert={selectedPrescription === "expired" ? false : true}
                  onClick={() => setSelectedPrescription("expired")}
                />
                <BaseButtons
                  label={"Declined"}
                  marginTopFalse={true}
                  mini={true}
                  height={40}
                  size={"25%"}
                  invert={selectedPrescription === "declined" ? false : true}
                  onClick={() => setSelectedPrescription("declined")}
                />
              </div>
              {/* list display and changes */}
              <div style={{ flex: 1, width: "100%", overflow: "hidden" }}>
                <div
                  style={{
                    overflow: "scroll",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {prescriptionList ? (
                    <>
                      {selectedPrescription === "pending"
                        ? prescriptionList.dataPending.map(
                            (prescription, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <NewPrescriptionCard
                                    height={500}
                                    width={"100%"}
                                    patientNo={
                                      prescription ? prescription.id : "loading"
                                    }
                                    patientId={
                                      prescription
                                        ? prescription.patientId
                                        : "loading"
                                    }
                                    doctor={
                                      prescription
                                        ? prescription.doctor
                                        : "loading"
                                    }
                                    prescriptionNo={
                                      prescription
                                        ? prescription.prescriptionNum
                                        : "loading"
                                    }
                                    createdDate={
                                      prescription
                                        ? prescription.date
                                        : "loading"
                                    }
                                    validUntil={
                                      prescription
                                        ? prescription.validUntil
                                        : "loading"
                                    }
                                    status={
                                      prescription
                                        ? prescription.approved
                                        : "loading"
                                    }
                                    diagonosis={
                                      prescription
                                        ? prescription.diagnosis
                                        : "loading"
                                    }
                                    prescribed={
                                      prescription
                                        ? prescription.prescription
                                        : "loading"
                                    }
                                  />
                                </div>
                              );
                            }
                          )
                        : null}
                      {selectedPrescription === "approved"
                        ? prescriptionList.data.map((prescription, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <NewPrescriptionCard
                                  height={500}
                                  width={"100%"}
                                  patientNo={
                                    prescription ? prescription.id : "loading"
                                  }
                                  patientId={
                                    prescription
                                      ? prescription.patientId
                                      : "loading"
                                  }
                                  doctor={
                                    prescription
                                      ? prescription.doctor
                                      : "loading"
                                  }
                                  prescriptionNo={
                                    prescription
                                      ? prescription.prescriptionNum
                                      : "loading"
                                  }
                                  createdDate={
                                    prescription ? prescription.date : "loading"
                                  }
                                  validUntil={
                                    prescription
                                      ? prescription.validUntil
                                      : "loading"
                                  }
                                  status={
                                    prescription
                                      ? prescription.approved
                                      : "loading"
                                  }
                                  diagonosis={
                                    prescription
                                      ? prescription.diagnosis
                                      : "loading"
                                  }
                                  prescribed={
                                    prescription
                                      ? prescription.prescription
                                      : "loading"
                                  }
                                />
                              </div>
                            );
                          })
                        : null}
                      {selectedPrescription === "expired"
                        ? prescriptionList.dataExpired.map(
                            (prescription, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <NewPrescriptionCard
                                    height={500}
                                    width={"100%"}
                                    patientNo={
                                      prescription ? prescription.id : "loading"
                                    }
                                    patientId={
                                      prescription
                                        ? prescription.patientId
                                        : "loading"
                                    }
                                    doctor={
                                      prescription
                                        ? prescription.doctor
                                        : "loading"
                                    }
                                    prescriptionNo={
                                      prescription
                                        ? prescription.prescriptionNum
                                        : "loading"
                                    }
                                    createdDate={
                                      prescription
                                        ? prescription.date
                                        : "loading"
                                    }
                                    validUntil={
                                      prescription
                                        ? prescription.validUntil
                                        : "loading"
                                    }
                                    status={
                                      prescription
                                        ? prescription.approved
                                        : "loading"
                                    }
                                    diagonosis={
                                      prescription
                                        ? prescription.diagnosis
                                        : "loading"
                                    }
                                    prescribed={
                                      prescription
                                        ? prescription.prescription
                                        : "loading"
                                    }
                                  />
                                </div>
                              );
                            }
                          )
                        : null}
                      {selectedPrescription === "declined"
                        ? prescriptionList.dataDeclined.map(
                            (prescription, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <NewPrescriptionCard
                                    height={500}
                                    width={"100%"}
                                    patientNo={
                                      prescription ? prescription.id : "loading"
                                    }
                                    patientId={
                                      prescription
                                        ? prescription.patientId
                                        : "loading"
                                    }
                                    doctor={
                                      prescription
                                        ? prescription.doctor
                                        : "loading"
                                    }
                                    prescriptionNo={
                                      prescription
                                        ? prescription.prescriptionNum
                                        : "loading"
                                    }
                                    createdDate={
                                      prescription
                                        ? prescription.date
                                        : "loading"
                                    }
                                    validUntil={
                                      prescription
                                        ? prescription.validUntil
                                        : "loading"
                                    }
                                    status={
                                      prescription
                                        ? prescription.approved
                                        : "loading"
                                    }
                                    diagonosis={
                                      prescription
                                        ? prescription.diagnosis
                                        : "loading"
                                    }
                                    prescribed={
                                      prescription
                                        ? prescription.prescription
                                        : "loading"
                                    }
                                  />
                                </div>
                              );
                            }
                          )
                        : null}
                      <div style={{ padding: 40 }} />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          {tabDisplay === "validate" ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {validateToken ? (
                <>
                  {validateToken.message ? (
                    <div
                      style={{
                        marginTop: 50,
                        fontSize: 20,
                      }}
                    >
                      {validateToken.message}
                    </div>
                  ) : null}
                  {validateToken.validationToken ? (
                    <div style={{ width: "90%", marginTop: 20 }}>
                      <div
                        style={{
                          fontSize: 13,
                          marginBottom: 4,
                          color: "#00000070",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        Date created:
                      </div>
                      <div>{validateToken.validationToken.dateCreated}</div>
                      <div
                        style={{
                          fontSize: 13,
                          marginBottom: 4,
                          color: "#00000070",
                          fontWeight: "bold",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        Patient Link:
                      </div>
                      <div>{validateToken.validationToken.patientLink}</div>
                      <div
                        style={{
                          fontSize: 13,
                          marginBottom: 4,
                          color: "#00000070",
                          fontWeight: "bold",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        Patient ID:
                      </div>
                      <div>{validateToken.validationToken.patientId}</div>
                      <div
                        style={{
                          fontSize: 13,
                          marginBottom: 4,
                          color: "#00000070",
                          fontWeight: "bold",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        Patient Email:
                      </div>
                      <div>{validateToken.validationToken.patientEmail}</div>
                      <div
                        style={{
                          fontSize: 13,
                          marginBottom: 4,
                          color: "#00000070",
                          fontWeight: "bold",
                          width: "100%",
                          marginTop: 10,
                        }}
                      >
                        CODE:
                      </div>
                      <div style={{ fontSize: 22, fontWeight: "bold" }}>
                        {validateToken.validationToken.code}
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                "LOADING"
              )}
            </div>
          ) : null}
          {updateEmail ? (
            <>
              <div
                style={{ width: "90%", textAlign: "center", marginBottom: 20 }}
              >
                Are you sure you want to update the clients email? if so fill
                out the new email address below
              </div>
              <div style={{ marginBottom: 10 }}>Current email</div>
              <div>{selectedPatient ? selectedPatient.email : null}</div>
              <TextInputField
                labelTitle={"New email"}
                mini={true}
                invert={true}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <div style={{ display: "flex" }}>
                <BaseButtons
                  label={"CANCEL"}
                  onClick={() => setUpdateEmail(false)}
                />
                <div style={{ width: 20 }} />
                <BaseButtons
                  label={"UPDATE EMAIL"}
                  disable={email.length > 6 ? false : true}
                  onClick={() => UpdateUserInformation()}
                />
              </div>
            </>
          ) : null}
          {updateCell ? (
            <>
              <div
                style={{ width: "90%", textAlign: "center", marginBottom: 20 }}
              >
                Are you sure you want to update the clients cell number? if so
                fill out the new cell number below
              </div>
              <div style={{ marginBottom: 10 }}>Current Cell No</div>
              <div>{selectedPatient ? selectedPatient.cellNumber : null}</div>
              <TextInputField
                labelTitle={"New cell"}
                mini={true}
                invert={true}
                onChange={(e) => setCellNumber(e.target.value)}
              />
              <div style={{ display: "flex" }}>
                <BaseButtons
                  label={"CANCEL"}
                  onClick={() => setUpdateCell(false)}
                />
                <div style={{ width: 20 }} />
                <BaseButtons
                  label={"UPDATE CELL"}
                  disable={cellNumber.length > 8 ? false : true}
                  onClick={() => UpdateUserInformation()}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      <LoadingPopUp newWidth={"100%"} display={loading} />
      <ToastNotification message={message} display={toastPop} />
    </div>
  );
};

export default UserInfoPopUp;
