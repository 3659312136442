import React, { useState } from "react";

const MedicalUseCard = ({ onDeleteClick, use }) => {
  const [deleted, setDelete] = useState(false);
  return (
    <div
      style={{
        width: "90%",
        height: 40,
        // WebkitBoxShadow: "0px 0px 5px 1px #00000030",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0.5,
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          marginLeft: 10,
          fontSize: 14,
        }}
      >
        {use.use}
      </div>
      <div onClick={() => setDelete(true)}>
        <div onClick={onDeleteClick} style={{ fontSize: 12, marginRight: 5 }}>
          {deleted ? "Busy removing" : "REMOVE"}
        </div>
      </div>
    </div>
  );
};

export default MedicalUseCard;
