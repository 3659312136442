import React, { useState } from "react";

import SupportTicketCard from "../support-ticket-card/SupportTicketCard";

const SystemTicketsDisplayPanel = ({
  tickets,
  filterState,
  user,
  setMessage,
  setToastPop,
  setLoading,
  setRefresh,
  refresh,
}) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
        scrollbarWidth: "none",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {tickets ? (
        tickets.length >= 1 ? (
          tickets.map((tickets, index) => {
            return tickets.status === filterState.toLocaleUpperCase() ? (
              <div
                key={index}
                style={{
                  width: "100%",
                  minHeight: 250,
                  maxHeight: 250,
                  display: "flex",
                }}
              >
                <SupportTicketCard
                  tickets={tickets}
                  isSystem={true}
                  admin={user}
                  setMessage={setMessage}
                  setToastPop={setToastPop}
                  setLoading={setLoading}
                  setRefresh={setRefresh}
                  refresh={refresh}
                />
              </div>
            ) : null;
          })
        ) : (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: 150,
              fontSize: 20,
            }}
          >
            NO TICKETS YET
          </div>
        )
      ) : null}
    </div>
  );
};

export default SystemTicketsDisplayPanel;
