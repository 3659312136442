import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const strains = [
  "Indica Hybrid",
  "Pure Indica",
  "Sativa Hybrid",
  "Pure Sativa",
  "Balanced",
];

const StrainSelector = ({
  strain,
  setStrain,
  open,
  onStrainClick,
  setStrainOpen,
  edibles,
  mini,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          marginTop: mini ? 10 : 20,
          marginBottom: open ? 0 : 20,
          width: "80%",
          textAlign: "center",
        }}
      >
        {open
          ? edibles
            ? "Click to select THC type"
            : "Click to select Strain type"
          : edibles
          ? "Click to select Thc type"
          : "Select your strain."}
      </div>
      <div
        onClick={onStrainClick}
        style={{
          height: mini ? (open ? 0 : 30) : open ? 0 : 45,
          width: "70%",
          borderStyle: "solid",

          borderRadius: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderWidth: open ? 0 : 0.5,
          transition: "all 0.3s",
          overflow: "hidden",
          cursor: "pointer",
          fontSize: mini ? 14 : null,
        }}
      >
        {strain ? strain : "Click here"}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: open ? null : 0,
          overflow: "hidden",

          flexWrap: "wrap",
          marginTop: 5,
          flexDirection: "column",
        }}
      >
        {edibles
          ? edibles.map((strain, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setStrain(strain);
                    setStrainOpen(false);
                  }}
                  style={{
                    height: mini ? 30 : 45,
                    width: "70%",
                    borderStyle: "solid",
                    margin: mini ? 0 : 5,
                    borderRadius: mini ? null : 60,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderWidth: 0.5,
                    transition: "all 0.3s",
                    overflow: "hidden",
                    fontSize: strain ? 14 : 14,
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {strain ? strain : "loading"}
                </div>
              );
            })
          : strains.map((strain, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setStrain(strain);
                    setStrainOpen(false);
                  }}
                  style={{
                    height: 50,
                    width: "70%",
                    borderStyle: "solid",
                    margin: 5,
                    borderRadius: 60,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderWidth: 0.5,
                    transition: "all 0.3s",
                    overflow: "hidden",
                    fontSize: strain ? 14 : 14,
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {strain ? strain : "loading"}
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default StrainSelector;
