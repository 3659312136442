import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const StatCard = ({
  title,
  Section1Title,
  section1Image,
  section1Amount,
  section2Amount,
  Section2Title,
  section3Amount,
  Section3Title,
  section2Image,
  section3Image,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: 450,
        height: 300,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        onClick={onClick}
        style={{
          height: "90%",
          width: "95%",
          backgroundColor: BlackAndWhite.secondary,
          borderRadius: 20,
          display: "flex",
          position: "relative",
          overflow: "hidden",
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          borderWidth: hover ? 4 : 0,
          transition: "borderWidth 0.3s",
          borderStyle: "solid",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 20,
            fontWeight: "bold",
            fontSize: 22,
          }}
        >
          {title}
        </div>
        <div
          style={{
            flex: 0.5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ fontSize: 32, fontWeight: "bold", height: "100%" }}>
                {section1Amount}
              </div>

              {/* <div style={{ width: 150 }}>more info coming soon</div> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                src={section1Image}
                style={{
                  height: 30,
                  width: 30,
                  marginRight: 5,
                  marginLeft: 10,
                }}
              />
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginRight: 10,
                  width: 100,
                }}
              >
                {Section1Title}
              </div>
            </div>
          </>
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 10,
              }}
            >
              <div style={{ fontSize: 32, fontWeight: "bold", height: "100%" }}>
                {section2Amount}
              </div>

              {/* <div style={{ width: 150 }}>more info coming soon</div> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                src={section2Image}
                style={{ height: 30, width: 30, marginRight: 5 }}
              />
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginRight: 10,
                  width: 100,
                }}
              >
                {Section2Title}
              </div>
            </div>
          </>
        </div>

        <div
          style={{
            flex: 0.5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "65%",
              width: "80%",
              borderStyle: "solid",
              borderRadius: 20,
              borderWidth: 0.5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 48, fontWeight: "bold" }}>
                {section3Amount}
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  textAlign: "center",
                  width: 130,
                }}
              >
                {Section3Title}
              </div>
              {/* <div style={{ width: 150 }}>more info coming soon</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
