import React from "react";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import MenuLoadingPage1 from "../../components/menus-loading-pages/MenuLoadingPage1";

const Menu2 = () => {
  const { width, height } = useWindowDimensions();
  const video1 = require("./testVid.mp4");
  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <video
        width={width}
        height={height}
        autoplay={true}
        muted={true}
        loop
        controls
      >
        <source src={video1} type="video/mp4" />
      </video> */}
      <div>menu 2</div>
    </div>
  );
};

export default Menu2;
