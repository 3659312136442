import React from "react";
import { BlackAndWhite } from "../../themes/Colors";
import { BaseButtons } from "../atoms";

const SubmitPopUp = ({ display, incomplete, onClick }) => {
  return (
    <div
      style={{
        height: 80,
        width: display ? 370 : 120,
        backgroundColor: display ? "#FAA919" : BlackAndWhite.secondary,
        borderRadius: 8,
        transition: "all 0.4s",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div>{display ? "All information complete" : ""}</div>
      {display ? <div style={{ width: 10 }} /> : null}
      {display ? (
        <BaseButtons
          label={"Submit"}
          size={160}
          marginTopFalse={true}
          onClick={onClick}
        />
      ) : null}
      {display ? <div style={{ width: 10 }} /> : null}
    </div>
  );
};

export default SubmitPopUp;
