import React from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";

const TopMenuBar = () => {
  return (
    <div
      style={{
        width: "100%",
        height: 50,
        backgroundColor: BlackAndWhite.primary,
        color: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "row",
        WebkitBoxShadow: "0px 0px 10px 1px #00000055",
        alignItems: "center",
        position: "relative",
        justifyContent: "center",
        top: 0,
        zIndex: 2000000000,
        minHeight: 50,
      }}
    >
      <div
        style={{
          height: 50,
          width: 50,
          borderRadius: 100,
          backgroundColor: BlackAndWhite.primary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          WebkitBoxShadow: "0px 0px 10px 1px #00000055",
          position: "absolute",
          top: 20,
          left: 5,
        }}
      >
        <img
          style={{ height: 35 }}
          alt="icon"
          src={require("../../../../../images/newLogos/2. Merry-Jane Logo White for Dark Background.png")}
        />
      </div>

      <div style={{ fontWeight: "bold", marginLeft: 15, marginTop: 15 }}>
        IKS Prescriptions
      </div>
    </div>
  );
};

export default TopMenuBar;
