import React from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const StatusDisplay = ({ step, count }) => {
  return (
    <div
      style={{
        height: 60,
        width: 300,
        borderRadius: 20,
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 3,
      }}
    >
      <div style={{ fontWeight: "bold" }}>STEP {count}</div>{" "}
      <div
        style={{
          height: 40,
          width: 40,
          borderRadius: 40,
          backgroundColor: count === 2 ? BlackAndWhite.green : "#000000",
          color:
            count === 2 ? BlackAndWhite.secondary : BlackAndWhite.secondary,
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.4s",
        }}
      >
        1
      </div>
      <div
        style={{
          height: 40,
          width: 40,
          borderRadius: 40,
          backgroundColor: count !== 2 ? BlackAndWhite.blue50 : "#000000",
          color: BlackAndWhite.secondary,
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.4s",
        }}
      >
        2
      </div>
    </div>
  );
};

export default StatusDisplay;
