import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import { BaseButtons, CloseButton } from "../atoms";

const SelectStorePopUp = ({ display, onCloseClick, setSelectedStore }) => {
  const [storeArray, setStoreArray] = useState(null);
  const [clickedStore, setClickedStore] = useState(null);
  const [hover, setHover] = useState(null);
  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/facilities/getFacilities")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setStoreArray(resData);
      });
  }, [display]);
  return (
    <div
      style={{
        height: "100%",
        width: display ? "100%" : 0,
        backgroundColor: "#00000066",
        overflow: "hidden",
        transition: "width 0.3s",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <CloseButton label={"X"} onClick={onCloseClick} invert={true} />
      </div>
      <div
        style={{
          height: 600,
          width: 500,
          backgroundColor: BlackAndWhite.secondary,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            height: 120,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            WebkitBoxShadow: "0px 0px 10px 1px #00000066",
            zIndex: 1,
          }}
        >
          <div>{clickedStore ? clickedStore.storeName : "Select Store"}</div>
          <div>{clickedStore ? clickedStore.storeId : null}</div>
        </div>
        <div
          style={{
            width: "100%",
            overflow: "scroll",
            flex: 1,
            backgroundColor: "#00000033",
          }}
        >
          {storeArray
            ? storeArray.store.map((store, index) => {
                return (
                  <div
                    style={{
                      color: BlackAndWhite.primary,
                      height: 200,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "all 0.2s",
                    }}
                    key={index}
                  >
                    <div
                      onMouseOver={() => setHover(index)}
                      onMouseOut={() => setHover(null)}
                      onClick={() => setClickedStore(store)}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: clickedStore
                          ? clickedStore.storeId === store.storeId
                            ? "#faa919"
                            : BlackAndWhite.secondary
                          : BlackAndWhite.secondary,
                        color: clickedStore
                          ? clickedStore.storeId === store.storeId
                            ? BlackAndWhite.secondary
                            : BlackAndWhite.primary
                          : BlackAndWhite.primary,

                        width: "80%",
                        height: "90%",
                        borderRadius: 10,
                        flexDirection: "column",
                        cursor: "pointer",
                        borderStyle: "solid",
                        borderWidth: hover === index ? 2 : 0,
                      }}
                    >
                      <div
                        style={{
                          fontWeight: clickedStore
                            ? clickedStore.storeId === store.storeId
                              ? "bold"
                              : null
                            : null,
                          fontSize: clickedStore
                            ? clickedStore.storeId === store.storeId
                              ? 22
                              : null
                            : null,
                        }}
                      >
                        {store ? store.storeName : "loading"}
                      </div>
                      <div>{store ? store.storeId : "loading"}</div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: 20,
            WebkitBoxShadow: "0px 0px 10px 1px #00000066",
            zIndex: 1,
          }}
        >
          <BaseButtons
            label={"SELECT"}
            size={300}
            onClick={() => setSelectedStore(clickedStore)}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectStorePopUp;
