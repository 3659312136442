import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const CardItem = ({
  image,
  productName,
  category,
  subcategory,
  sku,
  productfeature,
  productPref,
  saleSize,
  unitMeasure,
  retail,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        height: 200,
        width: 200,
        display: "flex",
        alignItems: "center",
        fontSize: 12,
        flexDirection: "row",
        cursor: "pointer",
        borderRadius: 10,
        margin: 30,

        position: "relative",
        WebkitBoxShadow: hover
          ? "0px 0px 15px 8px #00000090"
          : "0px 0px 5px 4px #00000030",
        justifyContent: "center",
        transition: "all 0.3s",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          overflow: "hidden",
        }}
      >
        <img alt="icon" src={image} style={{ height: "100%", width: "100%" }} />
      </div>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          bottom: hover ? -40 : -30,
          height: 60,
          width: hover ? "95%" : "95%",
          backgroundColor: hover ? "#000000" : BlackAndWhite.secondary,
          WebkitBoxShadow: hover
            ? "0px 0px 5px 2px #00000080"
            : "0px 0px 5px 2px #00000050",
          borderRadius: hover ? 10 : 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
          transition: "all 0.3s",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: hover ? "90%" : "90%",
            fontWeight: "bold",
            transition: "color 0.3s",
            fontSize: hover ? 30 : 12,
            color: hover ? BlackAndWhite.secondary : BlackAndWhite.primary,
            overflow: "hidden",
          }}
        >
          {hover ? retail : productName.toLocaleUpperCase()}
        </div>
      </div>
      {/* <div style={{ flex: 0.2, textAlign: "center" }}>{category}</div>
      <div style={{ flex: 0.2 }}>{subcategory}</div>
      <div style={{ flex: 0.15 }}>{sku}</div>

      <div style={{ flex: 0.1, textAlign: "center", marginRight: 5 }}>
        {productPref}
      </div>
      <div style={{ flex: 0.1, textAlign: "center" }}>{productfeature}</div>
      <div style={{ flex: 0.1, textAlign: "center" }}>{saleSize}</div>
      <div style={{ flex: 0.1, textAlign: "center" }}>{unitMeasure}</div> */}
      {/* <div
        style={{
          position: "absolute",
          textAlign: "right",
          bottom: hover ? 22 : -10,

          // width: 220,
          color: hover ? "#000000" : BlackAndWhite.secondary,
          backgroundColor: hover ? "#ffffff50" : null,
          borderRadius: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: hover ? 45 : 20,
          fontWeight: "bold",
          transition: "all 0.3s",
        }}
      ></div> */}
    </div>
  );
};
export default CardItem;
