import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import FilterButtonSmall from "../../../filter-button-small/FilterButtonSmall";

const TransferFilter = ({ filterName, setScreen }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("ALL");
  const [selectedHover, setSelectedHover] = useState(false);
  return (
    <div
      onClick={open ? null : () => setOpen(true)}
      style={{
        cursor: "pointer",
        height: 50,
        width: open ? 950 : 40,
        // borderStyle: "solid",
        // borderWidth: open ? 1 : 0,
        transition: "all 0.4s",
        marginRight: open ? 10 : 20,
        borderRadius: 100,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: open ? BlackAndWhite.primary : BlackAndWhite.secondary,
      }}
    >
      {open ? null : (
        <div
          onClick={open ? null : () => setOpen(true)}
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? BlackAndWhite.primary
                : BlackAndWhite.primary,
              color: open ? BlackAndWhite.secondary : BlackAndWhite.secondary,
              height: 35,
              minWidth: 150,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: 14,
              paddingRight: 5,
              paddingLeft: 5,
            }}
          >
            <div style={{ width: "90%", textAlign: "center" }}>{selected}</div>
          </div>
        </div>
      )}

      <div
        style={{
          flex: open ? 1 : 0,
          display: "flex",
          overflow: open ? null : "hidden",
        }}
      >
        <div
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: open ? 5 : null,
          }}
        >
          <div
            onMouseOver={() => setSelectedHover("all")}
            onMouseOut={() => setSelectedHover(null)}
            onClick={() => {
              setSelected("ALL");
              setOpen(false);
              setScreen("all");
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? selected === "ALL"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
                : null,
              color: open
                ? selected === "ALL"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
                : null,
              height: selectedHover === "all" ? 40 : 35,
              width: selectedHover === "all" ? 135 : 120,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: selectedHover === "all" ? 14 : 12,
              transition: "all 0.3s",
              WebkitBoxShadow:
                selectedHover === "all"
                  ? "0px 0px 5px 5px #FFFFFF90"
                  : "0px 0px 0px 0px #00000040",
            }}
          >
            <div>ALL</div>
          </div>
        </div>
        <div
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onMouseOver={() => setSelectedHover("stores")}
            onMouseOut={() => setSelectedHover(null)}
            onClick={() => {
              setSelected("STORES");
              setOpen(false);
              setScreen("stores");
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? selected === "STORES"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
                : null,
              color: open
                ? selected === "STORES"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
                : null,
              height: selectedHover === "stores" ? 40 : 35,
              width: selectedHover === "stores" ? 135 : 120,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: selectedHover === "stores" ? 14 : 12,
              transition: "all 0.3s",
              WebkitBoxShadow:
                selectedHover === "stores"
                  ? "0px 0px 5px 5px #FFFFFF90"
                  : "0px 0px 0px 0px #00000040",
            }}
          >
            <div>STORES</div>
          </div>
        </div>
        <div
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onMouseOver={() => setSelectedHover("completed")}
            onMouseOut={() => setSelectedHover(null)}
            onClick={() => {
              setSelected("COMPLETED");
              setOpen(false);
              setScreen("completed");
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? selected === "COMPLETED"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
                : null,
              color: open
                ? selected === "COMPLETED"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
                : null,
              height: selectedHover === "completed" ? 40 : 35,
              width: selectedHover === "completed" ? 135 : 120,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: selectedHover === "completed" ? 14 : 12,
              transition: "all 0.3s",
              WebkitBoxShadow:
                selectedHover === "completed"
                  ? "0px 0px 5px 5px #FFFFFF90"
                  : "0px 0px 0px 0px #00000040",
            }}
          >
            <div>COMPLETED</div>
          </div>
        </div>
        <div
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onMouseOver={() => setSelectedHover("recieved")}
            onMouseOut={() => setSelectedHover(null)}
            onClick={() => {
              setSelected("RECIEVED");
              setOpen(false);
              setScreen("recieved");
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? selected === "RECIEVED"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
                : null,
              color: open
                ? selected === "RECIEVED"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
                : null,
              height: selectedHover === "recieved" ? 40 : 35,
              width: selectedHover === "recieved" ? 135 : 130,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: selectedHover === "recieved" ? 14 : 12,
              transition: "all 0.3s",
              WebkitBoxShadow:
                selectedHover === "recieved"
                  ? "0px 0px 5px 5px #FFFFFF90"
                  : "0px 0px 0px 0px #00000040",
            }}
          >
            <div>RECIEVED</div>
          </div>
        </div>
        <div
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onMouseOver={() => setSelectedHover("inTransit")}
            onMouseOut={() => setSelectedHover(null)}
            onClick={() => {
              setSelected("IN-TRANSIT");
              setOpen(false);
              setScreen("inTransit");
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? selected === "IN-TRANSIT"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
                : null,
              color: open
                ? selected === "IN-TRANSIT"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
                : null,
              height: selectedHover === "inTransit" ? 40 : 35,
              width: selectedHover === "inTransit" ? 135 : 130,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: selectedHover === "inTransit" ? 14 : 12,
              transition: "all 0.3s",
              WebkitBoxShadow:
                selectedHover === "inTransit"
                  ? "0px 0px 5px 5px #FFFFFF90"
                  : "0px 0px 0px 0px #00000040",
            }}
          >
            <div>IN-TRANSIT</div>
          </div>
        </div>
        <div
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onMouseOver={() => setSelectedHover("pending")}
            onMouseOut={() => setSelectedHover(null)}
            onClick={() => {
              setSelected("PENDING");
              setOpen(false);
              setScreen("pending");
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? selected === "PENDING"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
                : null,
              color: open
                ? selected === "PENDING"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
                : null,
              height: selectedHover === "pending" ? 40 : 35,
              width: selectedHover === "pending" ? 135 : 130,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: selectedHover === "pending" ? 14 : 12,
              transition: "all 0.3s",
              WebkitBoxShadow:
                selectedHover === "pending"
                  ? "0px 0px 5px 5px #FFFFFF90"
                  : "0px 0px 0px 0px #00000040",
            }}
          >
            <div>PENDING</div>
          </div>
        </div>
      </div>
      <FilterButtonSmall
        icon1={
          open
            ? require("../../../../../../../../images/material-icons/setupAndMangeWhite.png")
            : require("../../../../../../../../images/material-icons/setupAndMangeBlack.png")
        }
        icon2={
          open
            ? require("../../../../../../../../images/material-icons/setupAndMangeWhite.png")
            : require("../../../../../../../../images/material-icons/setupAndMangeBlack.png")
        }
        label={open ? "CLOSE" : filterName}
        iconHeight={20}
        iconWidth={20}
        fontSize={12}
        fontColor={open ? BlackAndWhite.secondary : BlackAndWhite.primary}
        fontColor1={open ? BlackAndWhite.secondary : BlackAndWhite.primary}
        onClick={() => setOpen(false)}
      />
      <div style={{ width: 10 }} />
    </div>
  );
};

export default TransferFilter;
