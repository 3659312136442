import React from "react";
import QuestionButton from "../button/QuestionButton";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const QuestionaireDropDown = ({
  data,
  setItem,
  meidcalCheck,
  check,
  width,
  height,
}) => {
  return data.length >= 1
    ? data.map((answer, index) => {
        return (
          <div
            key={index}
            style={{
              width: "100%",
              marginTop: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <QuestionButton
              onClick={
                meidcalCheck
                  ? () => setItem(data[index])
                  : () => setItem(data[index].text)
              }
              title={answer.text}
              backgroundColor={BlackAndWhite.secondary}
              backgroundColorAlt={BlackAndWhite.primary}
              color={BlackAndWhite.primary}
              colorAlt={BlackAndWhite.secondary}
              width={width ? width : meidcalCheck ? "85%" : "80%"}
              height={
                height
                  ? height
                  : meidcalCheck
                  ? 120
                  : answer.text.length > 100
                  ? 100
                  : 60
              }
              borderRadius={
                meidcalCheck ? 20 : answer.text.length > 100 ? 20 : 200
              }
              borderOn={true}
              fontSize={meidcalCheck ? 14 : 16}
              description={meidcalCheck ? answer.description : null}
              fontWeight={meidcalCheck ? "bold" : null}
              checkLeft={meidcalCheck}
            />
          </div>
        );
      })
    : null;
};

export default QuestionaireDropDown;
