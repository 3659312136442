import React, { useEffect, useState } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";

import {
  BaseButtons,
  TextInputFieldSearch,
} from "../../../../../components/atoms";

import AddPopUp from "./add-pop-ups/AddPopUp";
import { LoadingPopUp, ToastNotification } from "../../../../../components";

import EditPopUp from "./edit-pop-up/EditPopUp";
import ListView from "./list-view/ListView";
import CategoryEditPopUp from "./category-edit-popup/CategoryEditPopup";
import UseFilter from "./user-filter/UserFilter";

const Categories = ({ setScreenDisplay }) => {
  const checkIcon = (check) => {
    if (check === "flowers1") {
      return {
        imageIcon: require("../../../../../images/product-icons/flowerBlack.png"),
        imageIcon2: require("../../../../../images/product-icons/flowerWhite2.png"),
      };
    } else {
      return {
        imageIcon: require("../../../../../images/product-icons/otherProdyctsBlack2.png"),
        imageIcon2: require("../../../../../images/product-icons/otherProductsWhite2.png"),
      };
    }
  };

  const [refresh, setRefresh] = useState(false);

  const [filterOption, setFilterOPtion] = useState("product");

  const [displayEditPopUp, setDisplayEditPopUp] = useState(false);

  const [categoryEditPopUp, setCategoryEditPop] = useState(null);
  const [subcategoryEditPopUp, setSubcategoryEditPop] = useState(null);

  const [sectionDisplay, setSectionDisplay] = useState("cat");
  const [category, setCategory] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [subCategoryArray, setSubCategoryArray] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [subCategoryProductsArray, setSubCategoryProductsArray] =
    useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [message, setMessage] = useState(null);
  const [toastPop, setToastPop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isList, setIsList] = useState(true);

  const [search, setSearch] = useState("");
  const [searchOptions, setSearchOptions] = useState("name");

  const [newLoad, setNewLoad] = useState(false);

  const [selectedAdd, setSelectedAdd] = useState(null);

  const [screen, setScreen] = useState("");

  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/productUpload/getCatergories")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setCategory(resData);
      });
  }, [refresh, newLoad]);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 2500);
    }
  }, [toastPop]);
  return (
    <div>
      {/* category display */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          height: sectionDisplay === "cat" ? "96%" : 0,
          overflowY: "hidden",
          transition: "all 0.3s",
          position: "absolute",
        }}
      >
        <div
          style={{
            height: 60,
            width: "100%",
            backgroundColor: BlackAndWhite.secondary,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              marginLeft: 20,
              fontSize: 18,
              fontWeight: "bold",
              color: BlackAndWhite.primary,
              transition: "all 0.3s",
              marginRight: 20,
            }}
          >
            {isList ? "ITEMS" : "Product Categories"}
          </div>

          <div style={{ width: 10 }} />

          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              height: "100%",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginRight: 150,
                width: 300,

                borderWidth: 0.5,
              }}
            >
              <TextInputFieldSearch
                placeholder={"Type here to search"}
                height={25}
                borderRadius={4}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <div
                style={{
                  height: 30,
                  width: 70,
                  backgroundColor: BlackAndWhite.primary,
                  borderRadius: 4,
                  display: "flex",
                  color: BlackAndWhite.secondary,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => setSearch("")}
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  CLEAR
                </div>
              </div>
            </div>
            {isList ? (
              <div style={{ marginTop: 5 }}>
                <UseFilter filterName={"FILTER"} setScreen={setFilterOPtion} />
              </div>
            ) : // <div
            //   style={{
            //     height: 50,
            //     display: "flex",
            //     marginLeft: 20,
            //     width: 330,
            //     marginRight: 20,
            //     borderRadius: 30,
            //     backgroundColor: BlackAndWhite.primary,
            //     justifyContent: "space-evenly",
            //     alignItems: "center",
            //     color: BlackAndWhite.secondary,
            //     borderStyle: "solid",
            //     borderWidth: 1,
            //     borderColor: "#FAA919",
            //     marginTop: 4,
            //   }}
            // >

            //   {/* <BaseButtons
            //     label={"Products"}
            //     height={30}
            //     marginTopFalse={true}
            //     invert={filterOption === "all" ? true : false}
            //     onClick={() => setFilterOPtion("all")}
            //   />
            //   <BaseButtons
            //     label={"Categories"}
            //     height={30}
            //     marginTopFalse={true}
            //     invert={filterOption === "categories" ? true : false}
            //     onClick={() => setFilterOPtion("categories")}
            //   /> */}
            // </div>
            null}
          </div>
          <div
            style={{
              marginRight: 20,
              // marginTop: 0,
              marginBottom: 17,
              display: "flex",
            }}
          >
            {isList ? (
              <BaseButtons
                height={35}
                label={"+ ADD PRODUCT"}
                fontSize={12}
                onClick={() => setSelectedAdd("completePath")}
              />
            ) : null}
            {isList ? null : (
              <BaseButtons
                height={isList ? 35 : null}
                label={"+ ADD CATEGORY"}
                fontSize={isList ? 12 : null}
                onClick={() => setSelectedAdd("category")}
              />
            )}
          </div>
        </div>
        {isList ? (
          <ListView
            search={search}
            refresh={refresh}
            setRefresh={setRefresh}
            setSubcategoryEditPop={setSubcategoryEditPop}
            data={category ? category.categories : []}
            setMessage={setMessage}
            setToastPop={setToastPop}
            filterOption={filterOption}
            setFilterOPtion={setFilterOPtion}
            setSectionDisplay={setSectionDisplay}
            setSelectedProduct={setSelectedProduct}
            setCategoryEditPop={setCategoryEditPop}
          />
        ) : null}
      </div>
      {/* sub category display */}

      {/* prudcts for sub-sections */}

      {/* single productDisplay */}

      <AddPopUp
        data={category ? category.categories : []}
        setSectionDisplay={setSectionDisplay}
        display={selectedAdd}
        onCloseClick={() => setSelectedAdd(null)}
        setToastPop={setToastPop}
        setSelectedAdd={setSelectedAdd}
        setMessage={setMessage}
        categorySelected={categorySelected}
        setCategorySelected={setCategorySelected}
        selectedSubCategory={selectedSubCategory}
        setRefresh={setRefresh}
        refresh={refresh}
        newLoad={newLoad}
        setNewLoad={setNewLoad}
      />

      <ToastNotification message={message} display={toastPop} />
      <LoadingPopUp display={loading} newWidth={"100%"} />
      <EditPopUp
        display={displayEditPopUp}
        onCloseClick={() => setDisplayEditPopUp(false)}
        setMessage={setMessage}
        setToastPop={setToastPop}
        selectedProduct={selectedProduct}
        setLoading={setLoading}
        setDisplayEditPopUp={setDisplayEditPopUp}
        setSectionDisplay={setSectionDisplay}
      />
      <CategoryEditPopUp
        display={categoryEditPopUp || subcategoryEditPopUp ? true : false}
        onCloseClick={() => {
          setCategoryEditPop(null);
          setSubcategoryEditPop(null);
        }}
        categoryEditPopUp={categoryEditPopUp}
        subcategoryEditPopUp={subcategoryEditPopUp}
        setSubcategoryEditPop={setSubcategoryEditPop}
        setCategoryEditPop={setCategoryEditPop}
        setMessage={setMessage}
        setToastPop={setToastPop}
        setLoading={setLoading}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </div>
  );
};
export default Categories;
