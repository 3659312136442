import React, { useState, useContext, useEffect } from "react";
import { BottomTabs } from "./components";
import MedicalHistory from "../medical-history/MedicalHistory";
import Profile from "./components/profile/Profile";
import { BlackAndWhite } from "../../../../themes/Colors";
import Logout from "../log-out/LogOut";
import Prescription from "../prescription/Prescription";
import Rewards from "../rewards/Rewards";
import { AuthContext } from "../../../../context/auth-context";
import ModalPopUp from "../../../../components/modal-pop-up/ModalPopUp";
import errorSpec from "./error-modal-data/errorModalData";
import { HelpModal, ToastNotification } from "../../../../components";
import Store from "./components/store/Store";
import CartButton from "./components/cart-button/CartButton";
import CartPop from "./components/cart-pop-up/CartPopUp";
import ValidateProfile from "../validate-profile/ValidateProfile";
import MessageLayout from "./components/message-layout/MessageLayout";
import { BaseButtons, CloseButton } from "../../../../components/atoms";
import MenuDisplay from "./components/menu-display/MenuDisplay";

const MobileLayout = ({
  onSelected,
  profileProgress,
  setProfileProgress,
  width,
}) => {
  const [secrenDisplayed, setScreenDisplayed] = useState("profile");

  const [selectedStore, setSelectedStore] = useState("");

  const auth = useContext(AuthContext);
  const [popUp, setPopUp] = useState(false);
  const [errorIndex, setErrorIndex] = useState(0);
  const [medicalCheck, setMedicalCheck] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");
  const [prescriptionData, setPrescriptionData] = useState(null);

  const [doctorMessageArrays, setDoctorMessageArray] = useState([]);
  const [generalMessagesArray, setGeneralMessagesArray] = useState([]);

  const [agreements, setAgreements] = useState([]);
  const [viewTerms, setViewTerms] = useState(false);

  const [cartPopUp, setCartPopUp] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [helpPopUp, setHelpPopUp] = useState(false);
  const [helpTitle, setHelpTitle] = useState(null);
  const [helpMessage, setHelpMessage] = useState(null);

  const [validateEdit, setValidateEdit] = useState(null);

  const [hasBeenValidated, setHasBeenValidated] = useState(null);
  const [validatedPopUp, setValidatedPopUp] = useState(false);

  const [messageDisplay, setMessageDisplay] = useState("general");

  const refreshHandler = async () => {
    setLoading(true);
    try {
      await fetch(
        "https://merry-jane-api.onrender.com/medicalHistory/getMedicalHistoryByUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: auth.customerUserData.idDoc,
          }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((resData) => {
          setMedicalCheck(resData.lifestyleData);
        });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setPopUp(true);
      setErrorIndex(3);
    }
  };

  const PrescriptionHelpPop = () => {
    setHelpPopUp(true);
    setHelpTitle("Need some help with Prescriptions?");
    setHelpMessage({
      point1:
        "You can switch between your tabs by tapping on the approved text or the pending text.",
      point2: "Prescription can take up to 48 hours to be approved",
      point3:
        "You can request a new prescription below if you dont have a active prescription or one that is already pending.",
    });
  };

  const checkForAgreements = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/getAgreements",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.customerUserData.userId,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.agreements) {
        setAgreements(respnseData.agreements);
      } else {
        setAgreements(respnseData.agreements);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkForMessages = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/requestPrescriptionUser/userDoctorMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            patientUserId: auth.customerUserData.userId,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setDoctorMessageArray(respnseData.messages);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const checkGeneralMessages = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/adminMessageRoutes/getGeneralMessages",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "general",
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setGeneralMessagesArray(respnseData.messages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetch(
      "https://merry-jane-api.onrender.com/prescription/fetchUserPrescriptions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: auth.customerUserData.idDoc,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setPrescriptionData(resData);
      });
  }, [loading]);

  useEffect(() => {
    fetch(
      "https://merry-jane-api.onrender.com/medicalHistory/getMedicalHistoryByUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: auth.customerUserData.idDoc,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setMedicalCheck(resData.lifestyleData);
      });
  }, []);

  useEffect(() => {
    // if (medicalCheck) {
    //   if (medicalCheck.length < 4) {
    //     setPopUp(true);
    //     setErrorIndex(0);
    //   }
    // }

    if (auth) {
      if (auth.customerUserData.status === "Active") {
        setValidatedPopUp(false);
      } else {
        setValidatedPopUp(true);
      }
    }
  }, [agreements]);
  useEffect(() => {
    // if (medicalCheck) {
    //   if (medicalCheck.length < 4) {
    //     setPopUp(true);
    //     setErrorIndex(0);
    //   }
    // }
    if (auth) {
      checkForAgreements();
      setTimeout(() => {
        if (agreements.length !== 0) {
          setViewTerms(true);
        } else {
          setViewTerms(false);
        }
      }, 2000);
    }
  }, [auth]);
  useEffect(() => {
    if (auth) {
      checkForAgreements();
      checkForMessages();
      checkGeneralMessages();
    }
  }, [loading]);

  useEffect(() => {
    if (auth) {
      checkForMessages();
    }
  }, [refresh]);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        zIndex: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {secrenDisplayed === "prescription" ? null : (
        <div
          style={{
            width: "100%",
            // marginLeft: 10,
            height: 40,
            display: "flex",
            alignItems: "center",
            fontSize: 24,
            backgroundColor: BlackAndWhite.primary,
            color: BlackAndWhite.secondary,
            paddingLeft: 10,
            position: "relative",
            // zIndex: 2,
          }}
        >
          {secrenDisplayed === "medical"
            ? "PROFILE INFORMATION"
            : secrenDisplayed.toLocaleUpperCase()}
          {/* {secrenDisplayed === "ONLINE STORE" ? (
            <div style={{ position: "absolute", right: 20, bottom: -5 }}>
              <CartButton
                backgroundColor={BlackAndWhite.secondary}
                iconImage={require("../../../../images/material-icons/productBlack.png")}
                onClick={() => setCartPopUp(true)}
              />
            </div>
          ) : null} */}
        </div>
      )}

      <div
        style={{
          flex: 1,
          display: "flex",
          width: "100%",
          flexDirection: "column",
          // overflow: "hidden",
          backgroundColor: "#FFFFFFcc",
          position: "relative",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {secrenDisplayed === "menu" ? (
          <>
            {selectedStore !== "" ? (
              <div style={{ position: "fixed", top: 50, left: 10, zIndex: 10 }}>
                <CloseButton label={"X"} onClick={() => setSelectedStore("")} />
              </div>
            ) : null}
            {selectedStore !== "" ? (
              <div
                style={{ position: "fixed", top: 50, right: 15, zIndex: 10 }}
              >
                <div
                  style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 8,
                    backgroundColor: BlackAndWhite.primary,
                    color: BlackAndWhite.secondary,
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  {selectedStore
                    ? selectedStore === "langabaan"
                      ? "LANGEBAAN"
                      : selectedStore === "woester"
                      ? "WORCESTER"
                      : selectedStore.toLocaleUpperCase()
                    : null}
                </div>
              </div>
            ) : null}
            {selectedStore === "" ? (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  position: "relative",
                  overflowY: "scroll",
                  // backgroundColor: BlackAndWhite.secondary,
                }}
              >
                {selectedStore === "" ? (
                  <div
                    style={{
                      width: "100%",
                      marginTop: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {selectedStore === "" ? (
                      <div style={{ width: "80%", textAlign: "center" }}>
                        WHICH STORE WOULD YOU LIKE TO VIEW?
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div
                  style={{
                    flex: selectedStore === "" ? 1 : 0,
                    overflowY: "scroll",
                    transition: "all 0.4s",
                    display: "flex",
                    width: "100%",

                    justifyContent: "center",
                    alignItems: "center",

                    flexDirection: "column",
                  }}
                >
                  <BaseButtons
                    label={"Hermanus"}
                    onClick={() => setSelectedStore("hermanus")}
                    minHeight={50}
                    mini={true}
                    borderRadius={8}
                    width={"100%"}
                    size={"80%"}
                    marginTop={180}
                  />
                  <BaseButtons
                    label={"Langebaan"}
                    onClick={() => setSelectedStore("langabaan")}
                    minHeight={50}
                    mini={true}
                    borderRadius={8}
                    width={"100%"}
                    size={"80%"}
                  />
                  <BaseButtons
                    label={"Malmesbury"}
                    onClick={() => setSelectedStore("malmesbury")}
                    minHeight={50}
                    mini={true}
                    borderRadius={8}
                    width={"100%"}
                    size={"80%"}
                  />

                  <BaseButtons
                    label={"Mossel Bay"}
                    onClick={() => setSelectedStore("mosselBay")}
                    minHeight={50}
                    mini={true}
                    borderRadius={8}
                    width={"100%"}
                    size={"80%"}
                  />
                  <BaseButtons
                    label={"Panorama"}
                    onClick={() => setSelectedStore("panorama")}
                    invert={false}
                    minHeight={50}
                    mini={true}
                    borderRadius={8}
                    width={"100%"}
                    size={"80%"}
                  />
                  <BaseButtons
                    label={"Wellington"}
                    onClick={() => setSelectedStore("wellington")}
                    minHeight={50}
                    mini={true}
                    borderRadius={8}
                    width={"100%"}
                    size={"80%"}
                  />
                  <BaseButtons
                    label={"Woodstock"}
                    onClick={() => setSelectedStore("woodstock")}
                    minHeight={50}
                    mini={true}
                    borderRadius={8}
                    width={"100%"}
                    size={"80%"}
                  />

                  <BaseButtons
                    label={"Worcester"}
                    onClick={() => setSelectedStore("woester")}
                    minHeight={50}
                    mini={true}
                    borderRadius={8}
                    width={"100%"}
                    size={"80%"}
                  />
                </div>
              </div>
            ) : null}

            {selectedStore !== "" ? (
              <MenuDisplay selectedStore={selectedStore} />
            ) : null}
          </>
        ) : null}
        {secrenDisplayed === "medical" ? (
          <MedicalHistory
            profileProgress={profileProgress}
            setProfileProgress={setProfileProgress}
            width={width}
            setPopUp={setPopUp}
            setErrorIndex={setErrorIndex}
          />
        ) : null}
        {secrenDisplayed === "profile" ? (
          <Profile
            prescriptionData={prescriptionData}
            auth={auth}
            setValidateEdit={setValidateEdit}
            loading={loading}
            refreshHandler={refreshHandler}
            profileProgress={auth.customerUserData.profileProgress}
            customerName={
              auth.customerUserData.firstName +
              " " +
              auth.customerUserData.lastName
            }
            patientNumber={auth.customerUserData.userId}
            firstName={auth.customerUserData.firstName}
            lastName={auth.customerUserData.lastName}
            idDoc={auth.customerUserData.idDoc}
            dateOfBirth={auth.customerUserData.dateOfBirth}
            cellNumber={auth.customerUserData.cellNumber}
            email={auth.customerUserData.email}
            accountType={auth.customerUserData.type}
            setLoading={setLoading}
            setMessage={setMessage}
            setToastPop={setToastPop}
            agreements={agreements}
            setAgreements={setAgreements}
            viewTerms={viewTerms}
            setViewTerms={setViewTerms}
          />
        ) : null}
        {secrenDisplayed === "logout" ? <Logout /> : null}
        {secrenDisplayed === "prescription" ? (
          <Prescription
            isCustomer={true}
            medicalCheck={medicalCheck}
            setToastPop={setToastPop}
            setMessage={setMessage}
            setScreenDisplayed={setScreenDisplayed}
            onHelpClick={() => PrescriptionHelpPop()}
          />
        ) : null}
        {secrenDisplayed === "ONLINE STORE" ? <Store /> : null}
        {secrenDisplayed === "message" ? (
          <MessageLayout
            messageArray={doctorMessageArrays}
            userId={auth.customerUserData.userId}
            messageDisplay={messageDisplay}
            generalMessagesArray={generalMessagesArray}
          />
        ) : null}
      </div>
      {secrenDisplayed === "message" ? (
        <div style={{ position: "fixed", right: 0 }}>
          {" "}
          <BaseButtons
            label={"REFRESH"}
            mini={true}
            marginTop={-1}
            height={40}
            onClick={() => setRefresh(refresh ? false : true)}
          />
        </div>
      ) : null}
      {secrenDisplayed === "message" ? (
        <div
          style={{ position: "fixed", width: "100%", top: 40, display: "flex" }}
        >
          <BaseButtons
            label={"GENERAL"}
            mini={true}
            marginTop={-1}
            height={40}
            width={"50%"}
            size={"100%"}
            invert={messageDisplay ? false : true}
            onClick={() => setMessageDisplay(true)}
          />

          <BaseButtons
            label={"DOCTOR COMMUNICATION"}
            mini={true}
            marginTop={-1}
            height={40}
            width={"50%"}
            size={"100%"}
            invert={!messageDisplay ? false : true}
            onClick={() => setMessageDisplay(false)}
          />
        </div>
      ) : null}

      <BottomTabs
        medicalCheck={medicalCheck}
        onMedicalClick={() => setScreenDisplayed("medical")}
        selected={secrenDisplayed}
        onProfileClick={() => setScreenDisplayed("profile")}
        onPrescriptionClick={() => setScreenDisplayed("prescription")}
        onStoreClick={() => setScreenDisplayed("ONLINE STORE")}
        onMenuClick={() => setScreenDisplayed("menu")}
        onMessageClick={() => setScreenDisplayed("message")}
      />
      <ModalPopUp
        display={popUp}
        title={errorSpec[errorIndex].title}
        description={errorSpec[errorIndex].message}
        description2={errorSpec[errorIndex].message2}
        medicalHistoryNotice={
          errorSpec[errorIndex].code === "h01" ? true : false
        }
        onCloseClick={() => setPopUp(false)}
        onGoToClick={() => {
          setScreenDisplayed("medical");
          setPopUp(false);
        }}
      />
      <ModalPopUp
        display={validatedPopUp}
        title={"Validate your account"}
        description={
          "Lets confirm all your data and add some extra security..."
        }
        description2={"lets start by validating your email"}
        validatedNotice={
          auth
            ? auth.customerUserData.status === "Active"
              ? false
              : true
            : true
        }
        // onCloseClick={() => setValidatedPopUp(false)}
        onGoToClick={() => {
          setValidateEdit(true);
          setValidatedPopUp(false);
        }}
      />
      <ValidateProfile
        setValidateEdit={setValidateEdit}
        auth={auth}
        setToastPop={setToastPop}
        setMessage={setMessage}
        display={validateEdit}
        onCloseClick={() => setValidateEdit(false)}
      />
      <ToastNotification display={toastPop} message={message.toUpperCase()} />
      <HelpModal
        display={helpPopUp}
        onCloseClick={() => {
          setHelpPopUp(false);
          setHelpTitle(null);
          setHelpMessage(null);
        }}
        title={helpTitle ? helpTitle : "loading"}
        point1={helpMessage ? helpMessage.point1 : "loading"}
        point2={helpMessage ? helpMessage.point2 : "loading"}
        point3={helpMessage ? helpMessage.point3 : "loading"}
      />
      {/* <CartPop display={cartPopUp} onCloseClick={() => setCartPopUp(false)} /> */}
    </div>
  );
};

export default MobileLayout;
