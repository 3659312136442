import React, { useState } from "react";
import DisplayImage from "../../display-image/DisplayImage";

const PicsDisplay = ({ data, websiteData }) => {
  const [index, setIndex] = useState(0);
  const [hover, setHover] = useState(null);
  return (
    <div
      style={{
        width: "100%",
        paddingTop: 40,
        paddingBottom: 40,
        display: "flex",
        position: "relative",
      }}
    >
      <div
        onMouseOver={() => setHover("left")}
        onMouseOut={() => setHover(null)}
        style={{
          flex: 0.25,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          onClick={() => setIndex(index === 0 ? 0 : index - 1)}
          style={{
            height: hover === "left" ? 50 : 40,
            transition: "all 0.3s",
            marginRight: 20,
            position: "absolute",
          }}
          src={require("../../../images/material-icons/backLeftBlack.png")}
        />
      </div>
      <div
        style={{
          flex: 0.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DisplayImage
          display={index === 0 ? true : false}
          image={require("../../../images/merry-jane-art/40JArt 420 39.png")}
        />
        <DisplayImage
          display={index === 1 ? true : false}
          image={require("../../../images/merry-jane-art/40JArt 420 46.png")}
        />
        <DisplayImage
          display={index === 2 ? true : false}
          image={require("../../../images/merry-jane-art/40JArt 420 45.png")}
        />
        <DisplayImage
          display={index === 3 ? true : false}
          image={require("../../../images/merry-jane-art/MJArt 420 24.png")}
        />
        <DisplayImage
          display={index === 4 ? true : false}
          image={require("../../../images/merry-jane-art/MJArt 420 37.png")}
        />
      </div>

      <div
        onMouseOver={() => setHover("right")}
        onMouseOut={() => setHover(null)}
        style={{
          flex: 0.25,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          onClick={() => setIndex(index === 4 ? 0 : index + 1)}
          style={{
            height: hover === "right" ? 50 : 40,
            transition: "all 0.3s",
            transform: `rotate(180deg)`,
            marginLeft: 20,
            position: "absolute",
          }}
          src={require("../../../images/material-icons/backLeftBlack.png")}
        />
      </div>
    </div>
  );
};

export default PicsDisplay;
