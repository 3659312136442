import React from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";

const MenuItem = ({
  productName,
  type,
  price,
  description,
  added,
  strain,
  thc,
}) => {
  return (
    <div
      style={{
        width: "90%",
        // height: 200,
        borderRadius: 10,
        backgroundColor: BlackAndWhite.secondary,
        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
        position: "relative",
        paddingBottom: 10,
      }}
    >
      <div
        style={{
          fontSize: 20,
          fontWeight: "bold",
          marginTop: 10,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "92%",
            textAlign: "left",
          }}
        >
          <div style={{ width: "60%" }}>{productName}</div>
        </div>
      </div>
      <div
        style={{
          fontSize: 14,
          fontWeight: "normal",
          marginTop: 5,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "92%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{type}</div>
          <div>{strain}</div>
        </div>
      </div>
      <div
        style={{
          fontSize: 14,
          fontWeight: "normal",
          marginTop: 5,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "92%", textAlign: "left" }}>
          THC CONTENT : {thc}
        </div>
      </div>

      <div
        style={{
          fontSize: 20,
          fontWeight: "bold",
          //   marginTop: 10,

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 10,
          right: 10,
        }}
      >
        R {price}
      </div>
      <div
        style={{
          fontSize: 14,
          fontWeight: "normal",
          marginTop: 5,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <div style={{ width: "92%", textAlign: "left" }}>{description}</div>
      </div>
      <div>{added}</div>
    </div>
  );
};

export default MenuItem;
