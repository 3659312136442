import React from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import MenuButton from "../menu-button/MenuBuuton";

const HeaderButton = ({
  width,
  display,
  onMenuClick,
  onHomeClick,
  facilities,
}) => {
  return (
    <div
      style={{
        height: display ? (facilities ? 80 : 80) : 0,
        width: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: BlackAndWhite.primary,
        transition: "all 1s",
        overflow: "hidden",
        position: "fixed",
        top: 0,
        zIndex: 20,
      }}
    >
      <div
        style={{
          flex: 0.8,
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
          height: "100%",
        }}
      >
        <img
          onClick={onHomeClick}
          alt="logo"
          src={require("../../../images/merryjanelogooptions/Merry-Jane Logo Black on White Alt.png")}
          style={{ height: 60, marginLeft: 20 }}
        />
        <div
          style={{
            color: BlackAndWhite.secondary,
            marginLeft: 10,
            fontFamily: "Montserrat",
          }}
        >
          www.merry-jane.com
        </div>
      </div>
      <div
        style={{
          flex: 0.2,
          justifyContent: "flex-end",
          alignItems: "center",
          display: "flex",
          height: "100%",
        }}
      >
        <div style={{ color: BlackAndWhite.secondary, marginRight: 10 }}>
          <MenuButton width={50} onClick={onMenuClick} />
        </div>
      </div>
    </div>
  );
};

export default HeaderButton;
