import React, { useState } from "react";
import { BaseButtons, TextInputField } from "../../../../components/atoms";
import Categories from "./components/Categories";
import ProductKnowlegde from "./components/ProductKnowlegde";

const MainScreenHolder = ({
  subSelection,
  userDisplay,
  setUserdisplay,
  setSubSelection,
  readOnly,
}) => {
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [productNo, setProductNo] = useState("");
  return (
    //add product info

    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
        {subSelection === "PRODUCTS" ? <Categories /> : null}
        {subSelection === "PRODUCT KNOWLEDGE" ? <ProductKnowlegde /> : null}

        {subSelection === "STATS" ? (
          <>
            <TextInputField
              labelTitle={"Product name"}
              onChange={(event) => setProductName(event.target.value)}
              // size={300}TextInputField
            />
            <TextInputField
              labelTitle={"Product No"}
              onChange={(event) => setProductNo(event.target.value)}
              // size={300}
            />
            <TextInputField
              labelTitle={"Price"}
              onChange={(event) => setPrice(event.target.value)}
              // size={300}
            />
            <TextInputField
              labelTitle={"Description"}
              onChange={(event) => setDescription(event.target.value)}
              // size={300}
            />
            <TextInputField
              labelTitle={"Image url"}
              onChange={(event) => setImageUrl(event.target.value)}
              // size={300}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BaseButtons label={"ADD PRODUCT"} />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default MainScreenHolder;
