import React, { useState, useEffect } from "react";
import {
  BaseButtons,
  CloseButton,
} from "../../../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import ProductDisplayItem from "../product-display-item/ProductDisplayItem";
import ProcessRefundOrExhange from "../process-refund-or-exhange/ProcessRefundOrExhange";

const RefundOrExcahnge = ({
  title,
  onCloseClick,
  display,
  setExchangeRefundArray,
  exchangeRefundArray,
  selectedTransactionDisplay,
  user,
  setLoading,
  setMessage,
  setToastPop,
  setCloseUp,
  storeInfo,
}) => {
  //   console.log("exchangeRefundArray", exchangeRefundArray);
  //   console.log("selectedTransactionDisplay", selectedTransactionDisplay);

  const [totalIncluding, setTotalIncluding] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [exDiscountAmount, setExDiscountAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountIsPercentage, setDiscountIsPercentage] = useState(false);

  const [stateCheck, setStateCheck] = useState(false);

  const [completePopUp, setCompletePopUp] = useState(false);

  let productPriceArray = [];
  let totalArray = [];
  const CalculateTotal = () => {
    productPriceArray = [];
    totalArray = [];
    var array = [...exchangeRefundArray];

    array.map((item) =>
      productPriceArray.push({
        productPrice: item.product.retailPriceInc,
        productId: item.product._id,
        qty: item.qtyCard.qty,
        total: Number(item.qtyCard.qty) * Number(item.product.retailPriceInc),
      })
    );

    productPriceArray.map((item) => totalArray.push(item.total));

    let valueCheck = totalArray.reduce((a, b) => a + b, 0);
    // let discountcheck = (valueCheck.toFixed(2) / 100) * discount;

    setTotalIncluding(
      valueCheck.toFixed(2) - Number(selectedTransactionDisplay.discount)
    );
    //   setDiscountAmount(
    //     valueCheck.toFixed(2) - (valueCheck.toFixed(2) - discount)
    //   );
    //   setExDiscountAmount(valueCheck.toFixed(2));
  };

  const removeFromRecipets = (index) => {
    var array = [...exchangeRefundArray]; // make a separate copy of the array

    array.splice(index, 1);
    setExchangeRefundArray(array);
    setStateCheck(stateCheck ? false : true);
  };
  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;
      return name;
    }
  };

  const ReturnArrayRecieptArray = () => {
    // CalculateTotal();
    return exchangeRefundArray;
  };

  useEffect(() => {
    // ReturnArrayRecieptArray();
    CalculateTotal();
  }, [exchangeRefundArray]);
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: display ? "100%" : 0,
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 20,
          left: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        <CloseButton
          label={"X"}
          onClick={() => {
            setCloseUp(false);
            ReturnArrayRecieptArray();
          }}
        />
        <div style={{ marginLeft: 20, fontSize: 18, fontWeight: "bold" }}>
          {" "}
          {title}
        </div>
        <div style={{ marginLeft: 20, fontSize: 14, fontWeight: "bold" }}>
          {" "}
          SLIP NO:{" "}
          {selectedTransactionDisplay
            ? selectedTransactionDisplay.slipNo
            : "loading"}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        <BaseButtons
          label={`COMPLETE ${title.toLocaleUpperCase()}`}
          mini={true}
          //   marginTopFalse={true}
          marginTop={-1}
          borderRadius={8}
          size={210}
          height={40}
          onClick={() => {
            setCompletePopUp(true);
          }}
        />
      </div>
      <div style={{ minHeight: 60, width: "100%" }}></div>

      <div
        style={{
          flex: 1,

          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              //   marginLeft: 75,
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            CLICK TO REMOVE THE ITEMS YOU DONT WANT TO{" "}
            {title.toLocaleUpperCase()}
          </div>
        </div>
        {exchangeRefundArray
          ? exchangeRefundArray.map((product, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <ProductDisplayItem
                    product={product}
                    NameAssemble={NameAssemble}
                    isRefund={exchangeRefundArray.length >= 2 ? true : false}
                    onRemoveClick={() => removeFromRecipets(index)}
                  />
                </div>
              );
            })
          : "loading"}
      </div>

      <ProcessRefundOrExhange
        setCompletePopUp={setCompletePopUp}
        display={completePopUp}
        user={user}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
        totalIncluding={totalIncluding}
        exchangeRefundArray={exchangeRefundArray}
        selectedTransactionDisplay={selectedTransactionDisplay}
        CalculateTotal={CalculateTotal}
        title={title}
        storeInfo={storeInfo}
      />
    </div>
  );
};

export default RefundOrExcahnge;
