import React, { useState } from "react";
import {
  BaseButtons,
  CloseButton,
  SectionTitle,
  TextAreaInput,
} from "../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../themes/Colors";
import useWindowDimensions from "../../../../../components/get-diemensions/UseWindowDiemensions";
import JsPDF from "jspdf";
import { LoadingPopUp, SmallButton } from "../../../../../components";

const PrescriptionCard = ({
  prescriptionNumber,
  prescriptionText,
  reasonText,
  healerName,
  address,
  onDownloadClick,
  patientName,
  dob,
  idNumber,
  diagnosis,
  date,
  idKey,
  statusCheck,
  sizeWidth,
  isDoc,
  isCustomer,
  data,
  onApproveClick,
  onDeclineClick,
  onClick,
  heightCheck,
  invert,
  onEditClick,
  onChange,
  idCheckMark,
  UpdateRefeshlist,
  onExipreClick,
  validDate,
  startedDate,
  widthNew,
}) => {
  const { width, height } = useWindowDimensions();
  const [pdfDisplay, setPdfDisplay] = useState(false);
  const [textInput, setTextInput] = useState(false);
  const [medicine, setMedicine] = useState("");
  const [loading, setLoading] = useState(false);

  const UpdateDiagnosis = async () => {
    setLoading(true);
    // https://merry-jane-api.onrender.com/prescription/updateprescriptionbyId
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/prescription/updateprescriptionbyIdforDiagnosis",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: idCheckMark,
            medicine: medicine,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.data) {
        setTextInput(false);
      } else {
        setMedicine("could not update, please try again in 5 minutes");
      }
      setLoading(false);
      return responseData;
    } catch (err) {
      setLoading(false);

      console.log("error", err);
      return err;
    }
  };

  return (
    <div
      style={{
        height: heightCheck
          ? heightCheck
          : width > 899
          ? 320
          : pdfDisplay
          ? height
          : 600,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // marginLeft: 40,
      }}
    >
      <div
        style={{
          height: isDoc ? "100%" : "90%",
          width: sizeWidth ? sizeWidth : "80%",
          borderStyle: "solid",
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
          borderTopRightRadius: isDoc ? 0 : 8,
          borderTopLeftRadius: isDoc ? 0 : 8,
          borderColor: invert ? BlackAndWhite.primary : BlackAndWhite.secondary,
          // marginLeft: 40,
          display: "flex",
          overflow: "hidden",
          flexDirection: width > 899 ? "row" : "column",
        }}
      >
        {loading ? (
          <LoadingPopUp display={loading} />
        ) : (
          <div
            style={{
              flex: 0.7,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              color: invert ? BlackAndWhite.primary : BlackAndWhite.secondary,
            }}
          >
            <div style={{ width: "90%", marginLeft: 20 }}>
              PRESCRIPTION NO: {prescriptionNumber}
            </div>
            <div
              style={{
                width: "90%",
                marginLeft: 20,
                fontWeight: "bold",
                fontSize: 18,
                position: "relative",
              }}
            >
              PRESCRIPTION:
              {isDoc ? (
                <SmallButton
                  onClick={() => setTextInput(true)}
                  right={width > 899 ? -40 : 100}
                />
              ) : null}
            </div>
            {textInput ? (
              medicine === "could not update, please try again in 5 minutes" ? (
                <div>{medicine}</div>
              ) : (
                <TextAreaInput
                  // invert={true}
                  color={BlackAndWhite.primary}
                  height={120}
                  labelTitle={"Please provide details:"}
                  width={widthNew > 899 ? "90%" : "100%"}
                  size={"90%"}
                  onChange={(e) => setMedicine(e.target.value)}
                />
              )
            ) : (
              <>
                <div
                  style={{
                    width: "80%",
                    marginLeft: 40,
                    fontSize: 16,
                    position: "relative",
                  }}
                >
                  {prescriptionText}
                </div>
                <div
                  style={{
                    width: "90%",
                    marginLeft: 20,
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  DIAGNOSIS:
                </div>
                <div style={{ width: "80%", marginLeft: 40, fontSize: 16 }}>
                  {reasonText}
                </div>
              </>
            )}
          </div>
        )}

        <div
          style={{
            flex: width > 899 ? 0.3 : 0.35,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            color: BlackAndWhite.secondary,
            backgroundColor: BlackAndWhite.primary,
            fontSize: widthNew > 899 ? null : 12,
          }}
        >
          {textInput ? null : (
            <>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 5,
                }}
              >
                <div>
                  <div style={{ marginLeft: 20 }}>STATUS:</div>
                  <div
                    style={{ color: BlackAndWhite.secondary, marginLeft: 20 }}
                  >
                    {statusCheck}
                  </div>
                </div>
                {isDoc ? (
                  <>
                    <div>
                      <div style={{ marginLeft: 20 }}>Vaild Until: </div>
                      <div
                        style={{
                          marginLeft: 20,
                          width: widthNew > 899 ? 100 : 120,
                        }}
                      >
                        {validDate}
                      </div>
                    </div>
                    <div>
                      <div style={{ marginLeft: 20 }}>Started date: </div>
                      <div style={{ marginLeft: 20, width: 140 }}>
                        {startedDate}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div
                style={{
                  display: "flex",
                  width: "80%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                DOCTOR/HEALER:
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {healerName}
              </div>
            </>
          )}

          {/* <div>LOCATION:</div>
          <div>{address}</div> */}

          {isCustomer ? (
            <>
              <BaseButtons
                label={"VIEW"}
                size={160}
                invert={true}
                onClick={onClick}
              />
            </>
          ) : null}

          {isDoc ? (
            textInput ? (
              <>
                <BaseButtons
                  label={"SUBMIT"}
                  size={160}
                  invert={true}
                  onClick={() => {
                    UpdateDiagnosis();
                    UpdateRefeshlist();
                  }}
                />
                <BaseButtons
                  label={"CANCEL"}
                  size={160}
                  invert={true}
                  onClick={() => setTextInput(false)}
                />
              </>
            ) : (
              <>
                <BaseButtons
                  label={"APPROVE"}
                  size={160}
                  invert={true}
                  onClick={onApproveClick}
                />
                <div style={{ display: "flex" }}>
                  <BaseButtons
                    label={"DECLINE"}
                    size={120}
                    invert={true}
                    onClick={onDeclineClick}
                  />
                  <div style={{ width: 10 }} />
                  <BaseButtons
                    label={"EXPIRE"}
                    size={120}
                    invert={true}
                    onClick={onExipreClick}
                  />
                </div>
              </>
            )
          ) : null}
          <div style={{ height: 5 }} />
        </div>
      </div>
    </div>
  );
};

export default PrescriptionCard;
