import React from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import { BaseButtons, CloseButton } from "../../../components/atoms";
import OwnerDisplayCard from "./owner-selected-card/OwnerDisplayCard";

const SelectOwnerPopUp = ({
  display,
  selected,
  setSelectedOwner,
  onCloseClick,
  ownerArray,
  onCreateClick,
  onCancelClick,
}) => {
  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: "100%",
        overflow: "hidden",
        backgroundColor: "#FFFFFF66",
        position: "absolute",
        top: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "height 0.3s",
        zIndex: 500000000,
      }}
    >
      <div
        style={{
          backgroundColor: BlackAndWhite.primary,
          height: selected === "" ? "85%" : "50%",
          width: "50%",
          borderRadius: 20,
          position: "relative",
          color: BlackAndWhite.secondary,
          display: "flex",
          flexDirection: "column",
          transition: "height 0.4s",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          Select owner from List below
        </div>
        <div
          onClick={() => setSelectedOwner(selected === "" ? "none" : "")}
          style={{
            height: selected !== "" ? 200 : 30,
            width: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "height 0.4s",
          }}
        >
          {selected.firstName ? (
            <OwnerDisplayCard
              onClick={() => setSelectedOwner("none")}
              width={"80%"}
              height={80}
              firstName={selected ? selected.firstName : "loading"}
              lastName={selected ? selected.lastName : "loading"}
            />
          ) : (
            "Please select owner from list below"
          )}
        </div>
        <div
          // onClick={() => }
          style={{
            height: selected !== "" ? 0 : 400,
            width: "100%",
            borderTopStyle: "solid",
            borderBottomStyle: "solid",
            overflow: selected !== "" ? "hidden" : "scroll",
            transition: "height 0.4s",
          }}
        >
          {ownerArray.data
            ? ownerArray.data.map((owner, index) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={index}
                  >
                    <OwnerDisplayCard
                      onClick={() => {
                        setSelectedOwner(owner);
                      }}
                      width={"80%"}
                      height={80}
                      firstName={owner.firstName}
                      lastName={owner.lastName}
                    />
                  </div>
                );
              })
            : null}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <BaseButtons label={"CANCEL"} onClick={onCancelClick} />
          <BaseButtons label={"CREATE"} onClick={onCreateClick} />
        </div>
        <div style={{ height: 20 }} />
        <div style={{ position: "absolute", left: 10, top: 10 }}>
          <CloseButton label={"X"} invert={true} onClick={onCloseClick} />
        </div>
      </div>
    </div>
  );
};

export default SelectOwnerPopUp;
