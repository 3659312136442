import React from "react";
import ItemCard from "../../item-card/ItemCard";

const SecondPageGroup4 = ({ refresh, selectedStore, page2, page4 }) => {
  return (
    <>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[12].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[12].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[12].type
                  : "TBA"
                : page2.length >= 1
                ? page2[12].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[12].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[12].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[12].description
                  : "TBA"
                : page2.length >= 1
                ? page2[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[12].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[12].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[12].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[12].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[12].type
                  : "TBA"
                : page2.length >= 1
                ? page2[12].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[12].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[12].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[12].description
                  : "TBA"
                : page2.length >= 1
                ? page2[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[12].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[12].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[12].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[12].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[12].type
                  : "TBA"
                : page2.length >= 1
                ? page2[12].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[12].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[12].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[12].description
                  : "TBA"
                : page2.length >= 1
                ? page2[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[12].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[12].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[12].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[12].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[12].type
                  : "TBA"
                : page2.length >= 1
                ? page2[12].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[12].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[12].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[12].description
                  : "TBA"
                : page2.length >= 1
                ? page2[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[12].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[12].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[12].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[12].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[12].type
                  : "TBA"
                : page2.length >= 1
                ? page2[12].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[12].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[12].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[12].description
                  : "TBA"
                : page2.length >= 1
                ? page2[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[12].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[12].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[12].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[12].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[12].type
                  : "TBA"
                : page2.length >= 1
                ? page2[12].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[12].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[12].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[12].description
                  : "TBA"
                : page2.length >= 1
                ? page2[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[12].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[12].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[12].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[12].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[12].type
                  : "TBA"
                : page2.length >= 1
                ? page2[12].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[12].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[12].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[12].description
                  : "TBA"
                : page2.length >= 1
                ? page2[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[12].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[12].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[12].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[12].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[12].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[12].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[12].type
                  : "TBA"
                : page2.length >= 1
                ? page2[12].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[12].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[12].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[12].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[12].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[12].description
                  : "TBA"
                : page2.length >= 1
                ? page2[12].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[12].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[12].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}

        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[13].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[13].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[13].type
                  : "TBA"
                : page2.length >= 1
                ? page2[13].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[13].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[13].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[13].description
                  : "TBA"
                : page2.length >= 1
                ? page2[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[13].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[13].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[13].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[13].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[13].type
                  : "TBA"
                : page2.length >= 1
                ? page2[13].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[13].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[13].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[13].description
                  : "TBA"
                : page2.length >= 1
                ? page2[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[13].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[13].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[13].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[13].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[13].type
                  : "TBA"
                : page2.length >= 1
                ? page2[13].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[13].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[13].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[13].description
                  : "TBA"
                : page2.length >= 1
                ? page2[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[13].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[13].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[13].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[13].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[13].type
                  : "TBA"
                : page2.length >= 1
                ? page2[13].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[13].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[13].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[13].description
                  : "TBA"
                : page2.length >= 1
                ? page2[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[13].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[13].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[13].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[13].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[13].type
                  : "TBA"
                : page2.length >= 1
                ? page2[13].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[13].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[13].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[13].description
                  : "TBA"
                : page2.length >= 1
                ? page2[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[13].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[13].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[13].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[13].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[13].type
                  : "TBA"
                : page2.length >= 1
                ? page2[13].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[13].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[13].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[13].description
                  : "TBA"
                : page2.length >= 1
                ? page2[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[13].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[13].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[13].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[13].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[13].type
                  : "TBA"
                : page2.length >= 1
                ? page2[13].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[13].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[13].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[13].description
                  : "TBA"
                : page2.length >= 1
                ? page2[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[13].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[13].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[13].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[13].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[13].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[13].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[13].type
                  : "TBA"
                : page2.length >= 1
                ? page2[13].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[13].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[13].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[13].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[13].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[13].description
                  : "TBA"
                : page2.length >= 1
                ? page2[13].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[13].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[13].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[14].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[14].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[14].type
                  : "TBA"
                : page2.length >= 1
                ? page2[14].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[14].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[14].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[14].description
                  : "TBA"
                : page2.length >= 1
                ? page2[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[14].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[14].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[14].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[14].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[14].type
                  : "TBA"
                : page2.length >= 1
                ? page2[14].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[14].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[14].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[14].description
                  : "TBA"
                : page2.length >= 1
                ? page2[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[14].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[14].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[14].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[14].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[14].type
                  : "TBA"
                : page2.length >= 1
                ? page2[14].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[14].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[14].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[14].description
                  : "TBA"
                : page2.length >= 1
                ? page2[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[14].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[14].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[14].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[14].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[14].type
                  : "TBA"
                : page2.length >= 1
                ? page2[14].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[14].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[14].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[14].description
                  : "TBA"
                : page2.length >= 1
                ? page2[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[14].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[14].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[14].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[14].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[14].type
                  : "TBA"
                : page2.length >= 1
                ? page2[14].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[14].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[14].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[14].description
                  : "TBA"
                : page2.length >= 1
                ? page2[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[14].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[14].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[14].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[14].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[14].type
                  : "TBA"
                : page2.length >= 1
                ? page2[14].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[14].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[14].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[14].description
                  : "TBA"
                : page2.length >= 1
                ? page2[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[14].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[14].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[14].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[14].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[14].type
                  : "TBA"
                : page2.length >= 1
                ? page2[14].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[14].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[14].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[14].description
                  : "TBA"
                : page2.length >= 1
                ? page2[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[14].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[14].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[14].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[14].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[14].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[14].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[14].type
                  : "TBA"
                : page2.length >= 1
                ? page2[14].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[14].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[14].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[14].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[14].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[14].description
                  : "TBA"
                : page2.length >= 1
                ? page2[14].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[14].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[14].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[15].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[15].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[15].type
                  : "TBA"
                : page2.length >= 1
                ? page2[15].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[15].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[15].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[15].description
                  : "TBA"
                : page2.length >= 1
                ? page2[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[15].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[15].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[15].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[15].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[15].type
                  : "TBA"
                : page2.length >= 1
                ? page2[15].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[15].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[15].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[15].description
                  : "TBA"
                : page2.length >= 1
                ? page2[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[15].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[15].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[15].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[15].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[15].type
                  : "TBA"
                : page2.length >= 1
                ? page2[15].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[15].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[15].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[15].description
                  : "TBA"
                : page2.length >= 1
                ? page2[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[15].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[15].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[15].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[15].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[15].type
                  : "TBA"
                : page2.length >= 1
                ? page2[15].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[15].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[15].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[15].description
                  : "TBA"
                : page2.length >= 1
                ? page2[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[15].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[15].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[15].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[15].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[15].type
                  : "TBA"
                : page2.length >= 1
                ? page2[15].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[15].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[15].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[15].description
                  : "TBA"
                : page2.length >= 1
                ? page2[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[15].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[15].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[15].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[15].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[15].type
                  : "TBA"
                : page2.length >= 1
                ? page2[15].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[15].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[15].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[15].description
                  : "TBA"
                : page2.length >= 1
                ? page2[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[15].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[15].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}

        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[15].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[15].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[15].type
                  : "TBA"
                : page2.length >= 1
                ? page2[15].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[15].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[15].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[15].description
                  : "TBA"
                : page2.length >= 1
                ? page2[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[15].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[15].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[15].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[15].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[15].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[15].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[15].type
                  : "TBA"
                : page2.length >= 1
                ? page2[15].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[15].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[15].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[15].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[15].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[15].description
                  : "TBA"
                : page2.length >= 1
                ? page2[15].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[15].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[15].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
    </>
  );
};

export default SecondPageGroup4;
