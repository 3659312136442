import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../../../components/atoms";

const RecievingItem = ({
  productName,
  category,
  subCategory,
  warehouseAmount,
  qty,
  image,
  onSaveClick,

  onEditClick,
  AddNewItemToArray,
  product,
  removeSelectedItems,
  index,
}) => {
  const [qtyCheck, setQtyCheck] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  return (
    <div
      style={{
        maxHeight: 90,
        width: "98%",
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        alignItems: "center",
        height: "95%",
        borderRadius: 10,
        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          height: "100%",
          minWidth: 80,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="icon"
          src={
            image === "Na"
              ? require("../../../../../../../../images/material-icons/editBlack.png")
              : image
          }
          style={{
            height: image === "Na" ? 20 : "70%",
            width: image === "Na" ? 20 : "70%",
          }}
        />
      </div>
      <div style={{ marginLeft: 20, fontSize: 18 }}>{productName}</div>
      <div
        style={{
          displayl: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <div style={{ fontSize: 12, opacity: 0.6 }}>Category</div>
        <div style={{ fontSize: 16 }}>{category}</div>
      </div>
      <div
        style={{
          displayl: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div style={{ fontSize: 12, opacity: 0.6 }}>Sub-Category</div>
        <div style={{ fontSize: 16 }}>{subCategory}</div>
      </div>
      <div style={{ flex: 1 }} />
      <div
        style={{
          displayl: "flex",
          flexDirection: "column",
          alignItems: "flex-start",

          marginRight: 30,
        }}
      >
        <div style={{ fontSize: 12, opacity: 0.6 }}>In-stock (Warehouse)</div>
        <div style={{ fontSize: 20 }}>{warehouseAmount}</div>
      </div>
      <div
        style={{
          displayl: "flex",
          flexDirection: "column",
          alignItems: "flex-start",

          marginRight: 10,
        }}
      >
        <TextInputField
          labelTitle={"Recieving QTY (Warehouse)"}
          size={200}
          mini={true}
          invert={true}
          height={20}
          value={qtyCheck}
          onChange={(e) => setQtyCheck(e.target.value)}
        />
      </div>
      <div style={{ marginRight: 10 }}>
        {isSaved ? (
          <BaseButtons
            label={"EDIT"}
            onClick={() => {
              setIsSaved(false);
              removeSelectedItems(product, index);
            }}
            mini={true}
            height={40}
            borderRadius={8}
            marginTopFalse={true}
            size={120}
          />
        ) : (
          <BaseButtons
            label={"SAVE"}
            onClick={() => {
              AddNewItemToArray({
                product,
                qtyCard: {
                  qty: qtyCheck,
                  productId: product._id,
                },
              });
              setIsSaved(true);
            }}
            mini={true}
            height={40}
            borderRadius={8}
            marginTopFalse={true}
            size={120}
          />
        )}
      </div>
    </div>
  );
};

export default RecievingItem;
