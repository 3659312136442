import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const HeadersTitleFacilities = ({ onClick }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      style={{
        height: 60,
        width: "98%",
        display: "flex",
        flexDirection: "row",
        fontSize: 12,
      }}
    >
      <div
        style={{
          flex: 0.1,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        STORE NAME
      </div>
      <div
        style={{
          flex: 0.15,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        PROVINCE
      </div>
      <div
        style={{
          flex: 0.1,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        TYPE
      </div>
      <div
        style={{
          flex: 0.1,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        CITY
      </div>
      <div
        style={{
          flex: 0.2,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        EMAIL
      </div>
      <div
        style={{
          flex: 0.1,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        CELL
      </div>

      <div
        style={{
          flex: 0.3,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onClick={onClick}
          style={{
            backgroundColor: hover
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
            borderRadius: 4,
            paddingTop: 7.5,
            paddingBottom: 7.5,
            paddingLeft: 10,
            paddingRight: 15,
            color: hover ? BlackAndWhite.secondary : BlackAndWhite.primary,
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {hover ? (
            <img
              alt="add"
              src={require("../../../../images/material-icons/addWhite.png")}
              style={{ height: 25, marginRight: 5 }}
            />
          ) : (
            <img
              alt="add"
              src={require("../../../../images/material-icons/addBlack.png")}
              style={{ height: 25, marginRight: 5, opacity: 0.7 }}
            />
          )}
          ADD FACILITIES
        </div>
        <div style={{ width: 20 }} />
        {/* <div> Edit users</div>
        <div style={{ width: 20 }} /> */}
      </div>
    </div>
  );
};

export default HeadersTitleFacilities;
