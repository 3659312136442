import React, { useState, useEffect } from "react";
import { MenuItem } from "./components";
import { LoadingPopUp } from "../../../../../../components";

const MenuDisplay = ({ selectedStore }) => {
  const [menu, setMenu] = useState(null);
  const [langabaan, setLangabaan] = useState(null);
  const [woodstock, setWoodstock] = useState(null);
  const [malmesbury, setMalmesbury] = useState(null);
  const [hermanus, setHermanus] = useState(null);
  const [mosselBay, setMosselBay] = useState(null);
  const [panorama, setPanorama] = useState(null);
  const [wellington, setWellington] = useState(null);
  const [worcester, setWorcester] = useState(null);

  let LangebaanArray = langabaan ? langabaan : [];

  let WoodstockArray = woodstock ? woodstock : [];
  let MalmesburyArray = malmesbury ? malmesbury : [];
  let HermanusArray = hermanus ? hermanus : [];
  let MosselBayArray = mosselBay ? mosselBay : [];
  let PanoramaArray = panorama ? panorama : [];
  let WellingtonArray = wellington ? wellington : [];
  let WorcesterArray = worcester ? worcester : [];

  let LangebaanArrayAlpha = LangebaanArray.sort(function (a, b) {
    if (a.productName < b.productName) {
      return -1;
    }
    if (a.productName > b.productName) {
      return 1;
    }
    return 0;
  });
  let WoodstockArrayAlpha = WoodstockArray.sort(function (a, b) {
    if (a.productName < b.productName) {
      return -1;
    }
    if (a.productName > b.productName) {
      return 1;
    }
    return 0;
  });
  let MalmesburyArrayAlpha = MalmesburyArray.sort(function (a, b) {
    if (a.productName < b.productName) {
      return -1;
    }
    if (a.productName > b.productName) {
      return 1;
    }
    return 0;
  });

  let HermanusArrayAlpha = HermanusArray.sort(function (a, b) {
    if (a.productName < b.productName) {
      return -1;
    }
    if (a.productName > b.productName) {
      return 1;
    }
    return 0;
  });
  let MossellBayArrayAlpha = MosselBayArray.sort(function (a, b) {
    if (a.productName < b.productName) {
      return -1;
    }
    if (a.productName > b.productName) {
      return 1;
    }
    return 0;
  });
  let PanoramaArrayAlpha = PanoramaArray.sort(function (a, b) {
    if (a.productName < b.productName) {
      return -1;
    }
    if (a.productName > b.productName) {
      return 1;
    }
    return 0;
  });
  let WellingtonArrayAlpha = WellingtonArray.sort(function (a, b) {
    if (a.productName < b.productName) {
      return -1;
    }
    if (a.productName > b.productName) {
      return 1;
    }
    return 0;
  });
  let WorcesterArrayAlpha = WorcesterArray.sort(function (a, b) {
    if (a.productName < b.productName) {
      return -1;
    }
    if (a.productName > b.productName) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/menu/getStoreFlowerMenu")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setMenu(resData);
        setLangabaan(resData.langabaan.items);
        setWoodstock(resData.woodstock.items);
        setMalmesbury(resData.malmesbury.items);
        setHermanus(resData.hermanus.items);
        setMosselBay(resData.mosselBay.items);
        setPanorama(resData.panorama.items);
        setWellington(resData.wellington.items);
        setWorcester(resData.worcester.items);
      });
  }, [selectedStore]);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {" "}
      {selectedStore === "langabaan"
        ? langabaan
          ? LangebaanArrayAlpha.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: index === 0 ? 60 : 20,
                    marginBottom: index === menu.langabaan.howmany - 1 ? 30 : 0,
                  }}
                >
                  <MenuItem
                    productName={item.productName}
                    type={item.type}
                    thc={item.thcContent}
                    strain={item.strain}
                    description={item.description}
                    price={item.price}
                  />
                </div>
              );
            })
          : null
        : null}
      {selectedStore === "woodstock"
        ? woodstock
          ? WoodstockArrayAlpha.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: index === 0 ? 60 : 20,
                    marginBottom: index === menu.woodstock.howmany - 1 ? 30 : 0,
                  }}
                >
                  <MenuItem
                    productName={item.productName}
                    type={item.type}
                    thc={item.thcContent}
                    strain={item.strain}
                    description={item.description}
                    price={item.price}
                  />
                </div>
              );
            })
          : null
        : null}
      {selectedStore === "malmesbury"
        ? malmesbury
          ? MalmesburyArrayAlpha.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: index === 0 ? 60 : 20,
                    marginBottom:
                      index === menu.malmesbury.howmany - 1 ? 30 : 0,
                  }}
                >
                  <MenuItem
                    productName={item.productName}
                    type={item.type}
                    thc={item.thcContent}
                    strain={item.strain}
                    description={item.description}
                    price={item.price}
                  />
                </div>
              );
            })
          : null
        : null}
      {selectedStore === "hermanus"
        ? hermanus
          ? HermanusArrayAlpha.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: index === 0 ? 60 : 20,
                    marginBottom: index === menu.hermanus.howmany - 1 ? 30 : 0,
                  }}
                >
                  <MenuItem
                    productName={item.productName}
                    type={item.type}
                    thc={item.thcContent}
                    strain={item.strain}
                    description={item.description}
                    price={item.price}
                  />
                </div>
              );
            })
          : null
        : null}
      {selectedStore === "mosselBay"
        ? mosselBay
          ? MossellBayArrayAlpha.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: index === 0 ? 60 : 20,
                    marginBottom: index === menu.mosselBay.howmany - 1 ? 30 : 0,
                  }}
                >
                  <MenuItem
                    productName={item.productName}
                    type={item.type}
                    thc={item.thcContent}
                    strain={item.strain}
                    description={item.description}
                    price={item.price}
                  />
                </div>
              );
            })
          : null
        : null}
      {selectedStore === "panorama"
        ? panorama
          ? PanoramaArrayAlpha.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: index === 0 ? 60 : 20,
                    marginBottom: index === menu.panorama.howmany - 1 ? 30 : 0,
                  }}
                >
                  <MenuItem
                    productName={item.productName}
                    type={item.type}
                    thc={item.thcContent}
                    strain={item.strain}
                    description={item.description}
                    price={item.price}
                  />
                </div>
              );
            })
          : null
        : null}
      {selectedStore === "woester"
        ? worcester
          ? WorcesterArrayAlpha.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: index === 0 ? 60 : 20,
                    marginBottom: index === menu.worcester.howmany - 1 ? 30 : 0,
                  }}
                >
                  <MenuItem
                    productName={item.productName}
                    type={item.type}
                    thc={item.thcContent}
                    strain={item.strain}
                    description={item.description}
                    price={item.price}
                  />
                </div>
              );
            })
          : null
        : null}
      {selectedStore === "wellington" ? (
        wellington ? (
          WellingtonArrayAlpha.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: index === 0 ? 60 : 20,
                  marginBottom: index === menu.wellington.howmany - 1 ? 30 : 0,
                }}
              >
                <MenuItem
                  productName={item.productName}
                  type={item.type}
                  thc={item.thcContent}
                  strain={item.strain}
                  description={item.description}
                  price={item.price}
                />
              </div>
            );
          })
        ) : null
      ) : (
        <LoadingPopUp newWidth={"100%"} display={menu ? false : true} />
      )}
    </div>
  );
};

export default MenuDisplay;
