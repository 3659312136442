import React from "react";
import useWindowDimensions from "../get-diemensions/UseWindowDiemensions";
import { BaseButtons, CloseButton } from "../atoms";

const ModalPopUp = ({
  display,
  title,
  description,
  description2,
  onCloseClick,
  medicalHistoryNotice,
  submitButton,
  onGoToClick,
  onSubmitClick,
  validatedNotice,
}) => {
  const { width, height } = useWindowDimensions();
  return (
    <div
      style={{
        height: display ? height : 0,
        width: display ? width : 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        backgroundColor: "#00000066",
        overflow: "hidden",
        zIndex: 100000000,
      }}
    >
      <div
        style={{
          position: "relative",
          backgroundColor: "#FFFFFF",
          width: "90%",
          height: "70%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 20,
        }}
      >
        <div style={{ width: "80%", textAlign: "center", fontSize: 20 }}>
          Merry-Jane says :
        </div>
        <div style={{ width: "80%", textAlign: "center", fontSize: 20 }}>
          {title}
        </div>
        <div
          style={{
            width: "80%",
            textAlign: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {description}
        </div>
        <div style={{ width: "80%", textAlign: "center" }}>{description2}</div>
        {submitButton ? (
          <BaseButtons label={"SUBMIT"} onClick={onSubmitClick} />
        ) : null}

        {medicalHistoryNotice ? (
          <BaseButtons label={"GO TO HISTORY"} onClick={onGoToClick} />
        ) : null}

        {validatedNotice ? (
          <BaseButtons label={"Validate Account"} onClick={onGoToClick} />
        ) : null}

        {medicalHistoryNotice ? null : (
          <div style={{ position: "absolute", top: 20, left: 20 }}>
            {onCloseClick ? (
              <CloseButton label={"X"} onClick={onCloseClick} />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalPopUp;
