import React from "react";
import useWindowDimensions from "../../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../../themes/Colors";
import CloseButton from "../close-button/CloseButton";

const OnlineStore = ({ display, onCloseClick }) => {
  const { width, height } = useWindowDimensions();
  return (
    <div
      style={{
        height: display ? height : 0,
        width: width,
        backgroundColor: "#00000033",
        overflow: "hidden",
        transition: "all 0.5s",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        zIndex: 10,
        top: 0,
      }}
    >
      <div
        style={{
          height: width > 899 ? 600 : height > 680 ? 530 : 500,
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            height: width > 899 ? "100%" : height > 680 ? 530 : 530,
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            overflowY: "auto",
            overflowX:"hidden"
            // paddingLeft: width > 899 ? 15 : 15,
          }}
        >
          <div style={{ color: BlackAndWhite.secondary, flex: 0.15 }} />
          <div
            style={{
              color: BlackAndWhite.primary,
              flex: 0.85,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              //   borderStyle: "solid",
              backgroundColor: "#000000cc",
              width: width > 899 ? "50%" : "95%",
              position: "relative",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          >
            <div style={{ position: "absolute", top: 20, left: 20 }}>
              <CloseButton
                onClick={onCloseClick}
                label={"X"}
                width={50}
                invert
              />
            </div>
            <img
              alt="logo"
              src={require("../../../images/newLogos/2. Merry-Jane Logo White for Dark Background.png")}
              style={{ height: 80, marginTop: 20 }}
            />
            <h2
              style={{
                marginTop: 35,
                color: BlackAndWhite.secondary,
                fontFamily: "Montserrat",
              }}
            >
              Online Store
            </h2>
            <p
              style={{
                color: BlackAndWhite.secondary,
                width: width > 899 ? "80%" : "80%",
                fontFamily: "Montserrat",
                marginBottom: 5,
                lineHeight: 1.6,
              }}
            >
              “Merry-Jane Says…”
            </p>
            <p
              style={{
                color: BlackAndWhite.secondary,
                width: width > 899 ? "80%" : "80%",
                fontFamily: "Montserrat",
                marginBottom: 5,
                marginTop: 8,
                lineHeight: 1.6,
                fontSize: 16,
              }}
            >
              We're in the process of cultivating the most exquisite green
              delights for your alternative wellness and traditional treatment.
            </p>
            <p
              style={{
                color: BlackAndWhite.secondary,
                width: width > 899 ? "80%" : "80%",
                fontFamily: "Montserrat",
                marginBottom: 5,
                marginTop: 8,
                lineHeight: 1.6,
              }}
            >
              If you're in a rush, drop by one of our physical collection
              points, or stay tuned – our chronic forest is nearly complete!
            </p>

            {/* <h1
          style={{
            marginTop: 35,
            color: BlackAndWhite.secondary,
            fontFamily: "Montserrat",
            marginTop: 100,
            fontSize: 26,
          }}
        >
          COMING SOON!! */}
            {/* </h1> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineStore;
