import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";
import TabCategoryOptions from "./tab-category-options/TabCategoryOptions";
import { LoadingPopUp } from "../../../../../components";
import SubCategoryCard from "./SubCategoryCard";
import ProductCard from "./ProductCard";
import { BaseButtons } from "../../../../../components/atoms";
import ProductInfoPopUp from "./product-info-pop-up/ProductInfoPopUp";

const ProductKnowlegde = () => {
  const [refresh, setRefresh] = useState(false);

  const [displayEditPopUp, setDisplayEditPopUp] = useState(false);

  const [allProducts, setAllProducts] = useState(null);

  const [sectionDisplay, setSectionDisplay] = useState("cat");
  const [category, setCategory] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [subCategoryArray, setSubCategoryArray] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [subCategoryProductsArray, setSubCategoryProductsArray] =
    useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [message, setMessage] = useState(null);
  const [toastPop, setToastPop] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedAdd, setSelectedAdd] = useState(null);
  const updateSubCategorydisplay = async ({ info }) => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getSubCatergoriesId",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId: info.categoryId,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.subCategory) {
        // const wait = await updateListFetch();
        setCategorySelected(info.categoryId);
        setSubCategoryArray(respnseData.subCategory);
        setLoading(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  const updateProductDisplay = async ({ info }) => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsBySubCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subCategoryId: info.subCategoryId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setSubCategoryProductsArray(respnseData.product);
        setLoading(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/productUpload/getCatergories")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setCategory(resData);
      });
  }, [refresh]);
  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/productUpload/getAllProducts")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setAllProducts(resData.product);
      });
  }, [refresh]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        // backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ height: 60, display: "flex", alignItems: "center" }}>
        <div style={{ marginLeft: 20, marginRight: 20, fontSize: 20 }}>
          Product Knowledge
        </div>{" "}
        search bar
      </div>

      <div
        style={{
          height: 55,
          backgroundColor: BlackAndWhite.primary,
          display: "flex",
          alignItems: "center",
        }}
      >
        {subCategoryProductsArray ? null : (
          <div style={{ marginLeft: 15 }}>
            <TabCategoryOptions
              title={"All products"}
              onClick={() => {
                setSubCategoryArray(null);
                setCategorySelected(null);
              }}
              selected={categorySelected ? false : true}
            />
          </div>
        )}
        {subCategoryProductsArray ? null : category ? (
          category.categories.map((info, index) => {
            return (
              <div key={index} style={{ marginLeft: 15 }}>
                <TabCategoryOptions
                  title={info.categoryName}
                  onClick={() => updateSubCategorydisplay({ info })}
                  selected={
                    categorySelected
                      ? categorySelected === info.categoryId
                        ? true
                        : false
                      : null
                  }
                />
              </div>
            );
          })
        ) : (
          <div>Loading . . .</div>
        )}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          // backgroundColor: "#00000033",
          position: "relative",
        }}
      >
        {subCategoryProductsArray ? (
          <div style={{ position: "absolute", top: -65, left: 10 }}>
            <BaseButtons
              label={"BACK"}
              onClick={() => setSubCategoryProductsArray(null)}
              height={35}
              size={100}
              invert={true}
            />
            <div> </div>
          </div>
        ) : null}
        {subCategoryProductsArray ? (
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {subCategoryProductsArray
              ? subCategoryProductsArray.map((info, index) => (
                  <div key={index}>
                    <ProductCard
                      title={info.productName}
                      onViewProductClick={() => setSelectedProduct(info)}
                      retail={info.retailPrice}
                      thcContent={info.thcContent}
                    />
                  </div>
                ))
              : null}
          </div>
        ) : (
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {subCategoryArray
              ? subCategoryArray.map((info, index) => (
                  <div key={index}>
                    <SubCategoryCard
                      title={info.subCategoryName}
                      onClick={() => {
                        updateProductDisplay({ info });
                        setSelectedSubCategory(info);
                      }}
                      description={info.description}
                    />
                  </div>
                ))
              : allProducts
              ? allProducts.map((item, index) => (
                  <div key={index}>
                    <ProductCard
                      title={item.productName}
                      retail={item.retailPrice}
                      onViewProductClick={() => setSelectedProduct(item)}
                      thcContent={item.thcContent}
                    />
                  </div>
                ))
              : null}
          </div>
        )}
      </div>
      <LoadingPopUp display={loading} />
      <ProductInfoPopUp
        display={selectedProduct ? true : false}
        onCloseClick={() => setSelectedProduct(null)}
        selectedProduct={selectedProduct}
      />
    </div>
  );
};
export default ProductKnowlegde;
