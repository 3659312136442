import React, { useState } from "react";

const FilterButtonSmall = ({
  label,
  icon1,
  icon2,
  iconHeight,
  iconWidth,
  fontSize,
  fontColor,
  fontWeight,
  fontColor1,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 60,
      }}
    >
      <img
        alt="icon"
        src={hover ? icon1 : icon2}
        style={{
          transition: "all 0.3s",
          height: hover ? iconHeight + 7 : iconHeight,
          width: hover ? iconWidth + 7 : iconWidth,
        }}
      />
      <div
        style={{
          fontSize: hover ? fontSize - 3 : fontSize,
          color: hover ? fontColor1 : fontColor,
          fontWeight: hover ? "normal" : fontWeight,
          transition: "all 0.3s",
        }}
      >
        {label}
      </div>
    </div>
  );
};

export default FilterButtonSmall;
