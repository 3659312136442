import React, { useState, useEffect, useContext } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import QuestionButton from "./components/button/QuestionButton";
import { CloseButton } from "../../../../components/atoms";
import Lifestyle from "./components/lifestyle/LifeStyle";
import { AuthContext } from "../../../../context/auth-context";
import MedicalQuestions from "./components/medical-questions/MedicalQuestions";
import OccupationalInfo from "./components/occupational-info/OccupationalInfo";
import Consent from "./components/consent/Consent";
import useWindowDimensions from "../../../../components/get-diemensions/UseWindowDiemensions";

const MedicalHistory = () => {
  const { width, height } = useWindowDimensions();
  const [section, setSelectedSection] = useState("none");
  const [data, setData] = useState(null);
  const [lifeStyle, setLifeStyle] = useState(false);
  const [medicalHistory, setMedicalHistory] = useState(false);
  const [occupationalDetails, setOccupationalDetails] = useState(false);
  const [consent, setConsent] = useState(false);
  const [lifeStyleInfo, setLifeStyleInfo] = useState(null);
  const [occupationaleInfo, setOccupationalInfo] = useState(null);
  const [medicalInfo, setMedicalInfo] = useState(null);
  const auth = useContext(AuthContext);
  useEffect(() => {
    fetch(
      "https://merry-jane-api.onrender.com/medicalHistory/getMedicalHistoryByUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: auth.customerUserData.idDoc,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setData(resData.lifestyleData);
      });
  }, []);

  useEffect(() => {
    if (data) {
      data.map(
        (section) => section.section === "lifestyle" && setLifeStyle(true)
      );
      data.map(
        (section) =>
          section.section === "lifestyle" && setLifeStyleInfo(section)
      );
    }
    if (data) {
      data.map(
        (section) =>
          section.section === "medical history" && setMedicalHistory(true)
      );
      data.map(
        (section) =>
          section.section === "medical history" && setMedicalInfo(section)
      );
    }
    if (data) {
      data.map(
        (section) =>
          section.section === "occupational" && setOccupationalDetails(true)
      );
      data.map(
        (section) =>
          section.section === "occupational" && setOccupationalInfo(section)
      );
    }
    if (data)
      data.map((section) => section.section === "consent" && setConsent(true));
  }, [data]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {section === "none" ? (
        <>
          <div
            style={{
              width: "80%",
              textAlign: "center",
              fontSize: height > 550 ? null : 10,
            }}
          >
            Update your profile by filling out each section when you have the
            time. Our team is enthusiastic about aligning alternative health
            remedies to meet your requirements.
          </div>
          {lifeStyle ? (
            <QuestionButton
              backgroundColor={"#2E933Ccc"}
              backgroundColorAlt={"#2E933Ccc"}
              borderRadius={200}
              width={"80%"}
              height={60}
              title={"Lifestyle Information"}
              borderOn={true}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.secondary}
              questionAmount={true}
              icon={require("../../../../images/material-icons/lifestyle.png")}
              onClick={
                lifeStyleInfo ? () => setSelectedSection("lifestyle") : null
              }
            />
          ) : (
            <QuestionButton
              title={"Lifestyle Information"}
              icon={require("../../../../images/material-icons/lifestyle.png")}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={"#FFFFFF66"}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              borderRadius={200}
              width={"80%"}
              height={60}
              onClick={() => setSelectedSection("lifestyle")}
              questionAmount={true}
            />
          )}
          {occupationalDetails ? (
            <QuestionButton
              backgroundColor={"#2E933Ccc"}
              backgroundColorAlt={"#2E933Ccc"}
              borderRadius={200}
              width={"80%"}
              height={60}
              title={"Occupational Details"}
              complete={true}
              borderOn={true}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.secondary}
              questionAmount={true}
              icon={require("../../../../images/material-icons/jobIconWhite.png")}
              onClick={
                occupationaleInfo
                  ? () => setSelectedSection("occupational")
                  : null
              }
            />
          ) : (
            <QuestionButton
              title={"Occupational Details"}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={"#FFFFFF66"}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              borderRadius={200}
              width={"80%"}
              height={60}
              onClick={() => setSelectedSection("occupational")}
              questionAmount={true}
              icon={require("../../../../images/material-icons/jobIconWhite.png")}
            />
          )}
          {medicalHistory ? (
            <QuestionButton
              backgroundColor={"#2E933Ccc"}
              backgroundColorAlt={"#2E933Ccc"}
              borderRadius={200}
              width={"80%"}
              height={60}
              title={"Medical History"}
              complete={true}
              borderOn={true}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.secondary}
              questionAmount={true}
              icon={require("../../../../images/material-icons/medicalhistory.png")}
              onClick={
                medicalInfo ? () => setSelectedSection("medicalHistory") : null
              }
            />
          ) : (
            <QuestionButton
              title={"Medical History"}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={"#FFFFFF66"}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              borderRadius={200}
              width={"80%"}
              height={60}
              onClick={() => setSelectedSection("medicalHistory")}
              borderOn={true}
              questionAmount={true}
              icon={require("../../../../images/material-icons/medicalhistory.png")}
            />
          )}

          {consent ? (
            <QuestionButton
              backgroundColor={"#2E933Ccc"}
              backgroundColorAlt={"#2E933Ccc"}
              borderRadius={200}
              width={"80%"}
              height={60}
              title={"Consent form"}
              complete={true}
              borderOn={true}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.secondary}
              questionAmount={true}
              icon={require("../../../../images/material-icons/consent.png")}
            />
          ) : (
            <QuestionButton
              title={"Consent form"}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={"#FFFFFF66"}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              borderRadius={200}
              width={"80%"}
              height={60}
              onClick={() => setSelectedSection("consent")}
              questionAmount={true}
              icon={require("../../../../images/material-icons/consent.png")}
            />
          )}
        </>
      ) : null}

      {section === "lifestyle" ? (
        <Lifestyle
          setSelectedSection={setSelectedSection}
          setLifeStyle={setLifeStyle}
          lifeStyleInfo={lifeStyleInfo}
          weight={
            lifeStyleInfo
              ? "WEIGHT" + " " + lifeStyleInfo.answer1 + " KG"
              : "loading"
          }
          height={
            lifeStyleInfo
              ? "HEIGHT" + " " + lifeStyleInfo.answer2 + "CM"
              : "loading"
          }
          answer3Complete={lifeStyleInfo ? lifeStyleInfo.answer3 : "loading"}
          answer4Complete={lifeStyleInfo ? lifeStyleInfo.answer4 : "loading"}
          answer5Complete={lifeStyleInfo ? lifeStyleInfo.answer5 : "loading"}
          answer6Complete={lifeStyleInfo ? lifeStyleInfo.answer6 : "loading"}
          answer7Complete={lifeStyleInfo ? lifeStyleInfo.answer7 : "loading"}
          answer8Complete={lifeStyleInfo ? lifeStyleInfo.answer8 : "loading"}
          answer9Complete={lifeStyleInfo ? lifeStyleInfo.answer9 : "loading"}
          answer10Complete={lifeStyleInfo ? lifeStyleInfo.answer10 : "loading"}
          answer11Complete={lifeStyleInfo ? lifeStyleInfo.answer11 : "loading"}
        />
      ) : null}
      {section === "medicalHistory" ? (
        <MedicalQuestions
          setSelectedSection={setSelectedSection}
          setMedicalHistory={setMedicalHistory}
          medicalInfo={medicalInfo}
          answer1Complete={medicalInfo ? medicalInfo.answer1 : "loading"}
          answer2Complete={medicalInfo ? medicalInfo.answer2 : "loading"}
          answer3Complete={medicalInfo ? medicalInfo.answer3 : "loading"}
          answer4Complete={medicalInfo ? medicalInfo.answer4 : "loading"}
          answer5Complete={medicalInfo ? medicalInfo.answer5 : "loading"}
          answer6Complete={medicalInfo ? medicalInfo.answer6 : "loading"}
          answer7Complete={medicalInfo ? medicalInfo.answer7 : "loading"}
          answer8Complete={medicalInfo ? medicalInfo.answer8 : "loading"}
          answer9Complete={medicalInfo ? medicalInfo.answer9 : "loading"}
        />
      ) : null}
      {section === "occupational" ? (
        <OccupationalInfo
          setOccupationalDetails={setOccupationalDetails}
          setSelectedSection={setSelectedSection}
          occupationaleInfo={occupationaleInfo}
          answer1Complete={
            occupationaleInfo ? occupationaleInfo.answer1 : "loading"
          }
          answer2Complete={
            occupationaleInfo ? occupationaleInfo.answer2 : "loading"
          }
        />
      ) : null}
      {section === "consent" ? (
        <Consent
          setSelectedSection={setSelectedSection}
          setConsent={setConsent}
        />
      ) : null}
      {section !== "none" ? (
        <div style={{ position: "fixed", top: 60, left: 20 }}>
          <CloseButton label={"X"} onClick={() => setSelectedSection("none")} />
        </div>
      ) : null}
    </div>
  );
};

export default MedicalHistory;
