import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import DisplayButton from "../display-button/DisplayButton";

const InventoryDashboard = () => {
  return (
    <div
      style={{
        width: "100%",
        flex: 1,

        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%", marginBottom: 10 }}>
        <div
          style={{
            marginLeft: 20,
            marginTop: 20,
            marginBottom: 10,
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          DASHBOARD
        </div>
      </div>
      <div
        style={{
          height: 300,
          width: "97%",
          //
          borderRadius: 4,
          backgroundColor: BlackAndWhite.secondary,
          WebkitBoxShadow: "0px 0px 5px 2px #00000050",
          display: "flex",
        }}
      >
        {/* left */}
        <div
          style={{
            flex: 0.5,

            height: "100%",
            flexDirection: "column",
            display: "flex",
          }}
        >
          {/* topleft */}

          <div
            style={{
              flex: 0.5,

              width: "100%",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 0.5,

                width: "100%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DisplayButton
                title={"title"}
                value={"10000"}
                image={require("../../../../../../images/material-icons/productBlack.png")}
                imageHover={require("../../../../../../images/material-icons/productWhite.png")}
              />
            </div>
            <div
              style={{
                flex: 0.5,
                width: "100%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DisplayButton
                title={"title"}
                value={"10000"}
                image={require("../../../../../../images/material-icons/productBlack.png")}
                imageHover={require("../../../../../../images/material-icons/productWhite.png")}
              />
            </div>
          </div>
          {/* bottom left */}
          <div
            style={{
              flex: 0.5,

              width: "100%",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 0.5,

                width: "100%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DisplayButton
                title={"title"}
                value={"10000"}
                image={require("../../../../../../images/material-icons/productBlack.png")}
                imageHover={require("../../../../../../images/material-icons/productWhite.png")}
              />
            </div>
            <div
              style={{
                flex: 0.5,
                width: "100%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DisplayButton
                title={"title"}
                value={"10000"}
                image={require("../../../../../../images/material-icons/productBlack.png")}
                imageHover={require("../../../../../../images/material-icons/productWhite.png")}
              />
            </div>
          </div>
        </div>
        {/* right */}
        <div
          style={{
            flex: 0.5,

            height: "100%",
            flexDirection: "column",
            display: "flex",
          }}
        >
          {/* topleft */}

          <div
            style={{
              flex: 0.5,

              width: "100%",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 0.5,
                width: "100%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DisplayButton
                title={"title"}
                value={"10000"}
                image={require("../../../../../../images/material-icons/productBlack.png")}
                imageHover={require("../../../../../../images/material-icons/productWhite.png")}
              />
            </div>
            <div
              style={{
                flex: 0.5,
                width: "100%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DisplayButton
                title={"title"}
                value={"10000"}
                image={require("../../../../../../images/material-icons/productBlack.png")}
                imageHover={require("../../../../../../images/material-icons/productWhite.png")}
              />
            </div>
          </div>
          {/* bottom left */}
          <div
            style={{
              flex: 0.5,

              width: "100%",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 0.5,
                width: "100%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DisplayButton
                title={"title"}
                value={"10000"}
                image={require("../../../../../../images/material-icons/productBlack.png")}
                imageHover={require("../../../../../../images/material-icons/productWhite.png")}
              />
            </div>
            <div
              style={{
                flex: 0.5,
                width: "100%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DisplayButton
                title={"title"}
                value={"10000"}
                image={require("../../../../../../images/material-icons/productBlack.png")}
                imageHover={require("../../../../../../images/material-icons/productWhite.png")}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 60 }}>
        <div>
          Here we can place a product search or more stats depending on tyour
          requirements
        </div>
      </div>
    </div>
  );
};

export default InventoryDashboard;
