import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../../themes/Colors";

const MessageCard = ({ message, userId, name, dateSent, isUser }) => {
  return (
    <div
      style={{
        width: "70%",
        paddingTop: 15,
        paddingBottom: 15,
        textAlign: "left",
        backgroundColor:
          message.sentBy === userId
            ? BlackAndWhite.secondary
            : BlackAndWhite.blue50,
        borderRadius: 4,
        WebkitBoxShadow: "0px 0px 5px 1px #00000073",
        marginLeft: message.sentBy !== userId ? 20 : null,
        marginRight: message.sentBy === userId ? 20 : null,
        position: "relative",
      }}
    >
      <div style={{ marginLeft: 10 }}> {message.message}</div>
      <div
        style={{
          width: "90%",
          fontSize: 12,
          marginTop: 5,
          fontWeight: "bold",
          display: "flex",
        }}
      >
        <div style={{ marginLeft: 10 }}> {name}</div>
      </div>
      <div
        style={{
          width: "100%",
          fontSize: 10,
          marginTop: 7,
          // fontWeight: "bold",
          display: "flex",
          position: "absolute",
          top: isUser ? -23 : null,
          bottom: !isUser ? -15 : null,
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {dateSent}
      </div>
    </div>
  );
};

export default MessageCard;
