import React, { useState, useContext } from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../components/atoms";
import QuestionaireDropDown from "../questionaire-drop-down/QuestionareDropDown";
import QuestionButton from "../button/QuestionButton";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { AuthContext } from "../../../../../../context/auth-context";
import LoadingPopUp from "../../../../../../components/loading-pop-up/LoadingPopUp";

const Lifestyle = ({
  setSelectedSection,
  setLifeStyle,
  lifeStyleInfo,
  weight,
  height,
  answer3Complete,
  answer4Complete,
  answer5Complete,
  answer6Complete,
  answer7Complete,
  answer8Complete,
  answer9Complete,
  answer10Complete,
  answer11Complete,
}) => {
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [answer5, setAnswer5] = useState("");
  const [answer6, setAnswer6] = useState("");
  const [answer7, setAnswer7] = useState("");
  const [answer8, setAnswer8] = useState("");
  const [answer9, setAnswer9] = useState("");
  const [answer10, setAnswer10] = useState("");
  const [answer11, setAnswer11] = useState("");
  const answer12 = "no data";

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("no data");
  const auth = useContext(AuthContext);

  const lifeStyleHandler = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/medicalHistory/createAnswerSheet",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `bearer ${token}`,
          },
          body: JSON.stringify({
            id: auth.customerUserData.idDoc,
            section: "lifestyle",
            answer1: answer1,
            answer2: answer2,
            answer3: answer3,
            answer4: answer4,
            answer5: answer5,
            answer6: answer6,
            answer7: answer7,
            answer8: answer8,
            answer9: answer9,
            answer10: answer10,
            answer11: answer11,
            answer12: answer12,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.data) {
        setLoading(false);
        setSelectedSection("none");
        setLifeStyle(true);
      }
    } catch (err) {
      setErrorMessage("Unable to upload answer sheet");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      console.log("error", err);
    }
  };

  return (
    <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
      {loading ? (
        <LoadingPopUp display={loading} />
      ) : errorMessage !== "no data" ? (
        <div
          onClick={() => setErrorMessage("no data")}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {errorMessage}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 30 }}>LIFESTYLE INFO</div>
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            1. Mind sharing your weight, don't worry, we're not launching any
            rockets, just updating our earthly files.
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 10,
                fontWeight: "bold",
              }}
            >
              If you are in-store hop on the scale, if not you can complete this
              section later. Your weight is required to submit this section, so
              fill it out when you have your weight on hand.
            </div>
          </div>
          {lifeStyleInfo ? (
            <>
              <div style={{ height: 20 }} />
              <QuestionButton
                borderOn={true}
                title={weight}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : (
            <TextInputField
              labelTitle={"WEIGHT IN KG"}
              invert={true}
              onChange={(event) => setAnswer1(event.target.value)}
            />
          )}

          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              fontWeight: "bold",
              marginBottom: 20,
            }}
          >
            2. How about your altitude? If you are in-store just ask Merry-Jane,
            she will size you up, if not, your height is required so fill out
            this section when you have your height on hand.
          </div>
          {lifeStyleInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={height}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : (
            <TextInputField
              labelTitle={"HEIGHT IN CM"}
              invert={true}
              onChange={(event) => setAnswer2(event.target.value)}
            />
          )}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            3. Spill the beans on the relationship with those smoking habits of
            yours!
          </div>
          <div style={{ height: 20 }} />
          {lifeStyleInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer3Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer3 === "" ? (
            <QuestionaireDropDown
              setItem={setAnswer3}
              data={[
                { text: "Non-smoker." },
                {
                  text: "Tabacco products.",
                },
                { text: "Cannabis and Tabacco products." },
                { text: "Cannabis only." },
                { text: "E-cigarettes or Vapes." },
                { text: "I smoke Everthing." },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer3("")}
              title={answer3}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"80%"}
              height={60}
              borderRadius={200}
            />
          )}

          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            4. What's your go-to party potion or favourite after work alcoholic
            drink?
          </div>
          {lifeStyleInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer4Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer4 === "" ? (
            <QuestionaireDropDown
              setItem={setAnswer4}
              data={[
                { text: "I don't consume Alcohol." },
                { text: "Spirits neat or with mix." },
                { text: "Beer or Wine is my thing." },
                { text: "I drink everything." },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer4("")}
              title={answer4}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"80%"}
              height={60}
              borderRadius={200}
            />
          )}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            5. How often do you find yourself gracefully raising a glass in
            celebration or relaxation?
          </div>
          {lifeStyleInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer5Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer5 === "" ? (
            <QuestionaireDropDown
              setItem={setAnswer5}
              data={[
                { text: "I don't consume Alcohol." },
                { text: "Once a week." },
                { text: "Mostly only socially." },
                { text: "Two to three times a week." },
                { text: "A couple of drinks daily." },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer5("")}
              title={answer5}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"80%"}
              height={60}
              borderRadius={200}
            />
          )}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            6. What's your exercise style? How often do you get your body moving
            and grooving?
          </div>
          {lifeStyleInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer6Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer6 === "" ? (
            <QuestionaireDropDown
              setItem={setAnswer6}
              data={[
                { text: "Not a big priority." },
                { text: "Just enough exercise." },
                {
                  text: "I do more than most.",
                },
                { text: "I’m an exercise junkie." },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer6("")}
              title={answer6}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"80%"}
              height={60}
              borderRadius={200}
            />
          )}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            7. How are you doing at night time, do you get enough shut eye?
          </div>
          {lifeStyleInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer7Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer7 === "" ? (
            <QuestionaireDropDown
              setItem={setAnswer7}
              data={[
                { text: "I generally get a good nights rest." },
                { text: "I struggle to fall asleep." },
                { text: "I have trouble staying asleep." },
                { text: "I have nightmares." },
                { text: "Pain keeps me awake." },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer7("")}
              title={answer7}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"80%"}
              height={60}
              borderRadius={200}
            />
          )}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            8. How's your eating routine these days?
          </div>
          {lifeStyleInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer8Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer8 === "" ? (
            <QuestionaireDropDown
              setItem={setAnswer8}
              data={[
                { text: "I have healthy appetite." },
                { text: "I am eating too much." },
                { text: "I have eating issues." },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer8("")}
              title={answer8}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"80%"}
              height={60}
              borderRadius={200}
            />
          )}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            9. How are your Nerves holding up?
          </div>
          {lifeStyleInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer9Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer9 === "" ? (
            <QuestionaireDropDown
              setItem={setAnswer9}
              data={[
                {
                  text: "I dont generally stress.",
                },
                { text: "I tend to stress occasionally." },
                { text: "I generally stress." },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer9("")}
              title={answer9}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"80%"}
              height={60}
              borderRadius={200}
            />
          )}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            10. How are you doing on the social front?
          </div>
          {lifeStyleInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer10Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer10 === "" ? (
            <QuestionaireDropDown
              setItem={setAnswer10}
              data={[
                {
                  text: "I am a bit of a loaner.",
                },
                {
                  text: "I am big on socialising.",
                },
                { text: "My family keeps me occupied." },
                { text: "I socialise occasionally." },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer10("")}
              title={answer10}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"80%"}
              height={60}
              borderRadius={200}
            />
          )}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            11. How much screen time do you get (TV, PC, Smartphone)?
          </div>
          {lifeStyleInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer11Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer11 === "" ? (
            <QuestionaireDropDown
              setItem={setAnswer11}
              data={[
                {
                  text: "Restricted to as needed only.",
                },
                { text: "A lot of screen time for work." },
                {
                  text: "Way too much.",
                },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer11("")}
              title={answer11}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"80%"}
              height={60}
              borderRadius={200}
            />
          )}
          <div
            style={{
              marginTop: 20,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {lifeStyleInfo ? null : (
              <BaseButtons
                label={"SUBMIT"}
                size={"80%"}
                onClick={() => lifeStyleHandler()}
                disable={
                  answer1.length >= 2 &&
                  answer2.length >= 2 &&
                  answer3 !== "" &&
                  answer4 !== "" &&
                  answer5 !== "" &&
                  answer6 !== "" &&
                  answer7 !== "" &&
                  answer8 !== "" &&
                  answer9 !== "" &&
                  answer10 !== "" &&
                  answer11 !== ""
                    ? false
                    : true
                }
              />
            )}
          </div>
          <div style={{ height: 80 }} />
        </div>
      )}
    </div>
  );
};
export default Lifestyle;
