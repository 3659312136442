import React, { useState } from "react";
import StatCard from "./stat-card/StatCard";
import { BlackAndWhite } from "../../../themes/Colors";
import { BaseButtons } from "../../../components/atoms";
import { LoadingPopUp } from "../../../components";
import StatPopUp from "./stat-pop-up/StatPopUp";

const DashBoard = ({
  patientStats,
  onRefreshClick,
  loading,
  prescriptionStats,
  readOnly,
}) => {
  const [statType, setStatType] = useState(null);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "scroll",
        position: "relative",
      }}
    >
      {/* <div
        style={{
          color: BlackAndWhite.secondary,
          fontSize: 26,
          marginLeft: 40,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        STATS
      </div> */}
      <div style={{ display: "flex" }}>
        <StatCard
          onClick={() => setStatType("patient")}
          title={"PATIENTS"}
          section1Image={require("../../../images/material-icons/profileBlack.png")}
          Section1Title={"ALL PATIENTS"}
          section1Amount={
            patientStats ? patientStats.stats.allPatinetsCount : "loading"
          }
          Section2Title={"YESTERDAY'S REGISTRATIONS"}
          section2Amount={
            patientStats ? patientStats.stats.yesterday : "loading"
          }
          Section3Title={"TODAY'S REGISTRATIONS"}
          section3Amount={patientStats ? patientStats.stats.today : "loading"}
          section3Image={require("../../../images/material-icons/Toady.png")}
          section2Image={require("../../../images/material-icons/yesterday.png")}
        />
        <StatCard
          // onClick={() => setStatType("prescription")}
          title={"PRESCRIPTION"}
          section1Image={require("../../../images/material-icons/prescriptionBlack.png")}
          Section1Title={"ALL"}
          section1Amount={
            prescriptionStats
              ? prescriptionStats.stats.allPrescriptions
              : "loading"
          }
          Section2Title={"PENDING"}
          section2Amount={
            prescriptionStats ? prescriptionStats.stats.pending : "loading"
          }
          Section3Title={"APPROVED"}
          section3Amount={
            prescriptionStats ? prescriptionStats.stats.approved : "loading"
          }
          section3Image={require("../../../images/material-icons/prescriptionBlack.png")}
          section2Image={require("../../../images/material-icons/prescriptionBlack.png")}
        />
        <div
          style={{
            width: 280,
            height: 270,
            borderRadius: 25,
            backgroundColor: BlackAndWhite.secondary,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            marginTop: 14,
            marginLeft: 10,
          }}
        >
          more stats
        </div>
      </div>

      {/* <StatCard /> */}
      <div
        style={{
          position: "fixed",
          top: -10,
          left: readOnly ? 500 : 350,
          zIndex: 900000000000,
        }}
      >
        <BaseButtons
          label={"REFRESH"}
          onClick={onRefreshClick}
          height={40}
          invert={true}
        />
      </div>
      <StatPopUp
        dataPoint={patientStats ? patientStats.stats : null}
        statType={statType ? statType : "loading"}
        display={statType}
        onCloseClick={() => setStatType(null)}
      />
    </div>
  );
};

export default DashBoard;
