import React, { useEffect, useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import PrescriptionCard from "../../../customer-portal/components/prescription/components/PrescriptionCard";
import TabSearchOption from "../tab-search-option/TabSearchOption";
import { BaseButtons } from "../../../../components/atoms";
import SearchBar from "../../../canna-pos/components/patient-display/SearchBar";
import FilterPopUp from "../../../canna-pos/components/patient-display/FilterPopUp";
import { DocPrescriptionCard } from "./components";

const PrescriptionDisplay = ({
  setSelectedPatient,
  setLoading,
  loading,
  setPatientPop,
  width,
  admin,
}) => {
  const [prescriptArray, setPrescriptArray] = useState([]);
  let prescriptionArraySortedByDate = prescriptArray.sort(
    (a, b) =>
      new Date(...a.validUntil.split("/").reverse()) -
      new Date(...b.validUntil.split("/").reverse())
  );

  const [practiceInformationData, setPracticeInformationData] = useState(null);

  const [displayType, setDisplayType] = useState("pending");

  const [filterOpen, setFliterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("NONE");
  const [search, setSearch] = useState("");

  const NameAssemble = (prescription) => {
    if (prescription) {
      let name =
        prescription.patientId +
        " | " +
        prescription.productSubType +
        " | " +
        prescription.thcType +
        " | " +
        prescription.thcContent;
      return name;
    }
  };

  const updateHandler = async (status, id) => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/prescription/updateprescriptionbyId",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            status: status,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.data.approved) {
        UpdateRefeshlist();
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  const UpdateRefeshlist = async () => {
    setLoading(true);
    await fetch(
      "https://merry-jane-api.onrender.com/prescription/pendingPrescriptions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          doc: practiceInformationData ? practiceInformationData.fullTitle : "",
          type: displayType,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setLoading(false);
        setPrescriptArray(resData.data);
      });
  };

  const GetPracticeInformation = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/prescription/getPracticeInformation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: admin.userId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setPracticeInformationData(responseData.practiceInformation);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (admin) {
      GetPracticeInformation();
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(
      "https://merry-jane-api.onrender.com/prescription/pendingPrescriptions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          doc: practiceInformationData ? practiceInformationData.fullTitle : "",
          type: displayType,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setPrescriptArray(resData.data);
        setLoading(false);
      });
  }, [displayType, practiceInformationData]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        color: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative",
        paddingTop: width > 899 ? 50 : 0,
        backgroundColor: BlackAndWhite.secondary,
      }}
    >
      <TabSearchOption
        width={width}
        selected={displayType}
        onPendingClick={() => setDisplayType("pending")}
        onApprovedClick={() => setDisplayType("approved")}
        onDeclinedClick={() => setDisplayType("declined")}
        onExpiredClick={() => setDisplayType("expired")}
        onFlaggedClick={() => setDisplayType("flagged")}
        amountPending={prescriptArray.length}
        search={search}
        setSearch={setSearch}
      />

      {/* <div
        style={{
          position: "fixed",
          top: width > 899 ? 187 : 5,
          right: width > 899 ? 15 : 5,
          zIndex: 4000000000000,
        }}
      >
        <SearchBar
          width={width}
          onFilterClick={() => {
            setFliterOpen(filterOpen ? false : true);
          }}
          filterOption={selectedFilter}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div style={{ position: "absolute", right: 40, marginTop: 10 }}>
          <FilterPopUp
            width={width}
            display={filterOpen}
            setFliterOpen={setFliterOpen}
            setSelectedFilter={setSelectedFilter}
            data={["ID", "NONE"]}
          />
        </div>
      </div> */}
      <div style={{ padding: 3 }} />
      {prescriptArray.length >= 1 ? (
        prescriptionArraySortedByDate.map((prescription, index) => {
          return prescription.patientId.includes(search) ? (
            <div
              key={index}
              style={{
                width: "100%",
                backgroundColor: BlackAndWhite.secondary,
              }}
            >
              <div
                style={{
                  zIndex: 2000000,
                  color: BlackAndWhite.primary,
                  minHeight: 25,
                  maxHeight: 25,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 13,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 50,

                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: BlackAndWhite.primary,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: 10,
                      paddingRight: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      color: BlackAndWhite.secondary,
                    }}
                  >
                    <div>Patient ID:</div>
                    <div style={{ marginLeft: 10 }}>
                      {prescription.patientId}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}></div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: 150,
                      color: BlackAndWhite.secondary,
                      marginRight: 10,
                      // marginTop: -10,
                    }}
                  >
                    <BaseButtons
                      label={"Patient Info"}
                      invert={true}
                      onClick={() => {
                        setSelectedPatient(prescription);
                      }}
                      mini={true}
                      marginTopFalse={true}
                      height={35}
                      borderRadius={4}
                    />
                  </div>
                </div>
              </div>
              <DocPrescriptionCard
                widthNew={width}
                invert={true}
                //   heightCheck={400}
                healerName={
                  practiceInformationData
                    ? practiceInformationData.fullTitle
                    : "laading"
                }
                isDoc={true}
                prescriptionText={prescription.prescription}
                reasonText={prescription.diagnosis}
                sizeWidth={"90%"}
                prescriptionNumber={prescription.prescriptionNum}
                statusCheck={loading ? "loading" : prescription.approved}
                onApproveClick={() => {
                  updateHandler("approved", prescription.id);
                  UpdateRefeshlist();
                }}
                onDeclineClick={() => {
                  updateHandler("declined", prescription.id);
                  UpdateRefeshlist();
                }}
                onExipreClick={() => {
                  updateHandler("expired", prescription.id);
                  UpdateRefeshlist();
                }}
                idCheckMark={prescription.id}
                UpdateRefeshlist={UpdateRefeshlist}
                validDate={prescription.validUntil}
                startedDate={prescription.date}
              />

              <div style={{ height: 10 }} />
            </div>
          ) : null;
        })
      ) : (
        <div
          style={{
            // height: "60%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: BlackAndWhite.primary,
            marginTop: 200,
          }}
        >
          No Prescriptions available
        </div>
      )}
      <div
        style={{
          width: "100%",
          //   borderStyle: "solid",
          marginBottom: 280,
          color: BlackAndWhite.secondary,
          marginTop: 60,
          // height: 200,
        }}
      >
        space
      </div>
    </div>
  );
};

export default PrescriptionDisplay;
