import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const ListItem = ({
  image,
  productName,
  category,
  subcategory,
  sku,
  productfeature,
  productPref,
  saleSize,
  unitMeasure,
  retail,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        height: 55,
        width: "100%",
        borderBottomStyle: "solid",
        borderWidth: 0.8,
        borderColor: "#00000040",
        display: "flex",
        alignItems: "center",
        fontSize: 12,
        flexDirection: "row",
        cursor: "pointer",
        backgroundColor: hover
          ? BlackAndWhite.purple50
          : BlackAndWhite.secondary,
      }}
    >
      <div
        style={{
          marginLeft: 20,
          height: "100%",
          width: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 0.05,
        }}
      >
        <img alt="icon" src={image} style={{ height: 30, width: 30 }} />
      </div>
      <div style={{ marginLeft: 20, flex: 0.3, textAlign: "left" }}>
        {productName}
      </div>
      <div style={{ flex: 0.2, textAlign: "center" }}>{category}</div>
      <div style={{ flex: 0.2 }}>{subcategory}</div>
      <div style={{ flex: 0.15 }}>{sku}</div>

      <div style={{ flex: 0.1, textAlign: "center", marginRight: 5 }}>
        {productPref}
      </div>
      <div style={{ flex: 0.1, textAlign: "center" }}>{productfeature}</div>
      <div style={{ flex: 0.1, textAlign: "center" }}>{saleSize}</div>
      <div style={{ flex: 0.1, textAlign: "center" }}>{unitMeasure}</div>
      <div style={{ flex: 0.15, textAlign: "center" }}>{retail}</div>
    </div>
  );
};
export default ListItem;
