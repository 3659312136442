import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import ProductListItem from "../product-list-item/ProductListItem";
import SubCatItemCard from "./SubCatItemCard";

const SubcategoryListItem = ({
  display,
  subCategories,
  setToastPop,
  setMessage,
  selectedProduct,
  setSelectedProd,
  setSubcategoryEditPop,
  refresh,
  setRefresh,
}) => {
  const [hover, setHover] = useState(null);
  const [dropDown, setDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productLines, setProductLines] = useState(null);
  const [subCat, setSubCat] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <div
      style={{
        height: display ? null : 0,
        overflow: "hidden",
        width: "100%",
        transition: "all 0.3s",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {display ? (
        <div
          style={{
            width: "100%",
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {subCategories ? (
            subCategories.length >= 1 ? (
              subCategories.map((sub, index) => {
                return (
                  <div
                    key={index}
                    style={{ width: "100%", overflowX: "hidden" }}
                  >
                    <SubCatItemCard
                      refresh={refresh}
                      setRefresh={setRefresh}
                      sub={sub}
                      index={index}
                      setLoading={setLoading}
                      selectedProduct={selectedProduct}
                      setMessage={setMessage}
                      setToastPop={setToastPop}
                      setSelectedProd={setSelectedProd}
                      onEditClick={() => setSubcategoryEditPop(sub)}
                    />
                  </div>
                );
              })
            ) : (
              <div style={{ marginLeft: 20 }}>no sub-categories</div>
            )
          ) : (
            "LOADING"
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SubcategoryListItem;
