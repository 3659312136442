import React from "react";

import { SectionTitle } from "../../components/atoms";
import SocialIconDisplay from "../social-icon-display/SocailIconDisplay";
import { BlackAndWhite } from "../../themes/Colors";

const Footer = ({ width }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        backgroundColor: BlackAndWhite.primary,
        height: 400,
      }}
    >
      <div
        style={{
          color: "#FFFFFF",
          backgroundColor: "#000000",
          // marginTop: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          fontFamily: "Montserrat",
          height: 500,
          width: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {width > 899 ? (
          <SectionTitle
            title={"QUICK INFO"}
            width={width > 899 ? width : "100%"}
            colorSwap={true}
            marker={true}
          />
        ) : (
          <div
            style={{
              fontWeight: "bold",
              fontSize: 22,
              color: BlackAndWhite.secondary,
              width: "100%",
              zIndex: 10,
              marginLeft: 40,
              marginTop: 30,
              marginBottom: 20,
            }}
          >
            QUICK INFO
          </div>
        )}

        <div
          style={{
            width: width > 899 ? "50%" : "80%",
            fontFamily: "Montserrat",
            marginLeft: 40,
            zIndex: 10,
            fontSize: 14,
          }}
        >
          <div>
            A Holistic Enterprise Solution for the South African Cannabis
            Industry.
          </div>
          <div style={{ marginTop: 10 }}>
            KZN | Dube Agrizone – Block C , 1 Harvest Way, La Mercy 4439,
            KwazuluNatal
          </div>
          <div style={{ marginTop: 10 }}>
            WESTERN CAPE | 124 Spes Bona Road Farm 168/14, Cape Farms, Cape
            Town, Western Cape
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: width > 899 ? "50%" : "80%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            color: BlackAndWhite.secondary,
            marginLeft: 40,
            zIndex: 10,
            marginTop: 10,
          }}
        >
          <div style={{ flex: 0.5, fontSize: 12 }}>
            <h3 style={{ width: "90%" }}>Get in touch</h3>
            <div style={{ width: "90%" }}>Contact Us +27 63 277 3636 </div>
            <div style={{ width: "90%", marginTop: 5 }}>
              info@merry-jane.com
            </div>
          </div>
          <div style={{ flex: 0.5, fontSize: 12 }}>
            <h3 style={{ width: "90%" }}>Legal</h3>
            <div style={{ width: "90%", marginTop: 5 }}>Terms of Service </div>
            <div style={{ width: "90%", marginTop: 5 }}>Privacy Policy </div>
          </div>
        </div>
        {width > 899 ? (
          <h3
            style={{
              width: "50%",
              marginLeft: 40,
              marginTop: 30,
              fontSize: 20,
              fontWeight: "bold",
              color: BlackAndWhite.secondary,
            }}
          >
            Follow us:
          </h3>
        ) : (
          <div
            style={{
              fontSize: 22,
              fontWeight: "bold",
              color: BlackAndWhite.secondary,
              zIndex: 10,
              marginLeft: 40,
              marginTop: 20,
            }}
          >
            Follow us:
          </div>
        )}

        <div
          style={{
            display: "flex",
            width: width > 899 ? "35%" : "70%",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
            color: BlackAndWhite.secondary,
            // marginLeft: 40,
            zIndex: 10,
            marginTop: 30,
          }}
        >
          <div style={{ marginTop: 5 }}>
            <SocialIconDisplay
              image={require("../../images/material-icons/instagramWhite.png")}
              size={40}
            />{" "}
          </div>
          <div style={{ marginTop: 5 }}>
            {" "}
            <SocialIconDisplay
              image={require("../../images/material-icons/linkedinWhite.png")}
              size={40}
            />{" "}
          </div>
          <div style={{ marginTop: 5 }}>
            {" "}
            <SocialIconDisplay
              image={require("../../images/material-icons/facebookWhite.png")}
              size={40}
            />{" "}
          </div>
          {/* <div style={{ marginTop: 5 }}>
            {" "}
            <SocialIconDisplay
              image={require("../../images/material-icons/instagramWhite.png")}
              size={40}
            />{" "}
          </div> */}
        </div>

        <div style={{ height: 20 }} />
      </div>
    </div>
  );
};

export default Footer;
