import React, { useState } from "react";
import useWindowDimensions from "../../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../../themes/Colors";
import CloseButton from "../close-button/CloseButton";
import BaseButtons from "../base-buttons/BaseButtons";
import {
  GeneralEnquiry,
  OpportunityCard,
  OpportunityEnquiry,
  WholeSalerEnquiry,
} from "./components";

const EnquireTab = ({
  display,
  onCloseClick,
  selected,
  setSelected,
  setInfoBlock,
  infoBlock,
  setEnquireTab,
}) => {
  const { width, height } = useWindowDimensions();

  return (
    <div
      style={{
        height: display ? height : 0,
        width: width,
        backgroundColor: "#00000033",
        overflow: "hidden",
        transition: "all 0.5s",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
        top: 0,
      }}
    >
      <div style={{ color: BlackAndWhite.secondary, flex: 0.15 }} />
      <div
        style={{
          color: BlackAndWhite.primary,
          flex: 0.85,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          //   borderStyle: "solid",
          backgroundColor: "#000000cc",
          width: width > 899 ? "60%" : "95%",
          position: "relative",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
        }}
      >
        <div style={{ position: "absolute", top: 20, left: 20 }}>
          <CloseButton
            onClick={onCloseClick}
            label={"X"}
            width={50}
            invert={true}
          />
        </div>
        <h2
          style={{
            marginTop: 35,
            color: BlackAndWhite.secondary,
            fontFamily: "Montserrat",
          }}
        >
          CONTACT US
        </h2>

        <GeneralEnquiry
          setSelected={setSelected}
          width={width}
          height={height}
          setEnquireTab={setEnquireTab}
        />
      </div>
    </div>
  );
};

export default EnquireTab;
