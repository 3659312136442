import React from "react";
import useWindowDimensions from "../../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../../themes/Colors";
import CloseButton from "../close-button/CloseButton";
import BaseButtons from "../base-buttons/BaseButtons";

const TeamTab = ({ display, onCloseClick, onSignUpClick }) => {
  const { width, height } = useWindowDimensions();
  return (
    <div
      style={{
        height: display ? height : 0,
        width: width,
        backgroundColor: "#00000033",
        overflow: "hidden",
        transition: "all 0.5s",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        zIndex: 10,
        top: 0,
        // left: "30%",
      }}
    >
      <div
        style={{
          height: width > 899 ? 600 : height > 680 ? 530 : 500,
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          overflow: "hidden",
          position:"absolute",
          bottom:0
        }}
      >
        <div
          style={{
            height: width > 899 ? "90%" : height > 680 ? 530 : 530,
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            overflowY: "auto",
            overflowX:"hidden",
            scrollbarWidth:"none",
            position:"absolute",
          bottom:0
            // paddingLeft: width > 899 ? 15 : 15,
          }}
        >
          <div style={{ color: BlackAndWhite.secondary, flex: 0.15 }} />
          <div
            style={{
              color: BlackAndWhite.primary,
              flex: 0.85,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              //   borderStyle: "solid",
              backgroundColor: "#000000cc",
              width: width > 899 ? "40%" : "95%",
              position: "relative",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          >
            <div style={{ position: "absolute", top: 20, left: 20 }}>
              <CloseButton
                onClick={onCloseClick}
                label={"X"}
                width={50}
                invert
              />
            </div>
            <img
              alt="logo"
              src={require("../../../images/newLogos/2. Merry-Jane Logo White for Dark Background.png")}
              style={{ height: 80, marginTop: 20 }}
            />
            <h2 style={{ marginTop: 35, color: BlackAndWhite.secondary }}>
              PATIENT REGISTRY
            </h2>

            <p
              style={{
                color: BlackAndWhite.secondary,
                width: width > 899 ? "80%" : "80%",
                fontFamily: "Montserrat",
                lineHeight: 1.6,
                marginBottom: 5,
              }}
            >
              An exclusive Client Registry in the Indigenous Knowledge System
              (IKS) for a holistic wellness experience. Access premium herbal
              and cannabis products through Merry-Jane facilities nationwide in
              partnership with the Interim Traditional Health Practitioners
              Council of South Africa.
            </p>
            <p
              style={{
                color: BlackAndWhite.secondary,
                width: width > 899 ? "80%" : "80%",
                fontFamily: "Montserrat",
                lineHeight: 1.6,
                marginBottom: 5,
                marginTop: 8,
              }}
            >
              Our Traditional Healers manage prescriptions, facilitate
              collections, and ensure seamless delivery, channelling traditional
              healing into mainstream complementary remedies.
            </p>
            <p
              style={{
                color: BlackAndWhite.secondary,
                width: width > 899 ? "80%" : "80%",
                fontFamily: "Montserrat",
                lineHeight: 1.6,
                marginBottom: 5,
                marginTop: 8,
              }}
            >
              Elevate your wellness journey by registering with our private
              Client Registry. Stay informed and compliant – registration is
              free!
            </p>

            <BaseButtons
              invert={true}
              label={"REGISTER NOW"}
              onClick={onSignUpClick}
            />
            <div style={{ height: 40 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamTab;
