import React from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";

const Transferheader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: 50,
        display: "flex",
        borderTopColor: BlackAndWhite.primary,
        backgroundColor: BlackAndWhite.primary,
        color: BlackAndWhite.secondary,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }}
    >
      <div
        style={{
          flex: 0.25,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
          width: "100%",
        }}
      >
        <div style={{ marginLeft: 5, width: "100%" }}> Transfer ID:</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "95%" }}> Date Added:</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "95%" }}>From</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "95%" }}>To:</div>
      </div>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "fle-start",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "80%" }}>Line items</div>
      </div>
      <div
        style={{
          flex: 0.2,
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "60%",
            width: "100%",
            paddingRight: 10,
          }}
        >
          Created By:
        </div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
            width: "100%",
            paddingRight: 10,
          }}
        >
          Actions
        </div>
      </div>
    </div>
  );
};

export default Transferheader;
