import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";
import { BaseButtons } from "../../../../../components/atoms";

const PractionerListItem = ({
  type,
  firstName,
  lastName,
  email,
  contactNumber,
  status,
  onManageClick,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: "100%",
        height: 50,
        // borderStyle: "solid",
        display: "flex",
        fontSize: 12,
        backgroundColor: hover ? "#9E3AC350" : BlackAndWhite.secondary,
        cursor: "pointer",
        position: "relative",
        transition: "all 0.3s",
      }}
    >
      <div
        style={{
          flex: 0.1,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 25, width: "100%", textAlign: "left" }}>
          {type}
        </div>
      </div>
      <div
        style={{
          flex: 0.2,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
          {firstName}
        </div>
      </div>
      <div
        style={{
          flex: 0.2,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
          {lastName}
        </div>
      </div>
      <div
        style={{
          flex: 0.2,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ width: "100%", textAlign: "left" }}>{email}</div>
      </div>
      <div
        style={{
          flex: 0.2,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ width: "100%", textAlign: "left" }}>{contactNumber}</div>
      </div>
      <div
        style={{
          flex: 0.1,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ width: "100%", textAlign: "left" }}>{status}</div>
      </div>
      <div
        style={{
          flex: 0.1,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <BaseButtons
          label={"MANAGE"}
          size={80}
          mini={true}
          marginTopFalse={true}
          height={30}
          borderRadius={4}
          onClick={onManageClick}
        />

        {/* <div> Edit users</div> */}
        <div style={{ width: 20 }} />
      </div>
    </div>
  );
};

export default PractionerListItem;
