import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import { BaseButtons } from "../../../../components/atoms";

const PosPatientCard = ({
  firstName,
  lastName,
  idNum,
  email,
  cell,
  status,
  onClick,
  quickSelect,
  isSelector,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        height: quickSelect ? 50 : 50,
        width: quickSelect ? "100%" : "100%",
        // marginBottom: 65,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        // marginTop: 2,
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: hover
            ? BlackAndWhite.purple50
            : BlackAndWhite.secondary,
          // height: 240,
          // borderRadius: 10,
          // WebkitBoxShadow: "0px 0px 6px 0.5px #00000033",
          // position: "absolute",
          // bottom: 20,
          zIndex: 1,
          display: "flex",
          flexDirection: "row",
          borderBottomStyle: "solid",
          borderColor: "#00000050",
          borderWidth: 0.5,
          height: "100%",
        }}
      >
        <div
          style={{
            flex: 0.05,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          <img
            alt="gender"
            src={require("../../../../images/material-icons/profileBlack.png")}
            style={{ height: 25, opacity: 0.6 }}
          />
        </div>
        <div
          style={{
            flex: quickSelect ? 0.2 : 0.15,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: quickSelect ? 11 : 14,
          }}
        >
          <div style={{ marginLeft: 20, width: "100%", textAlign: "left" }}>
            {quickSelect ? firstName + " " + lastName : firstName}
          </div>
        </div>
        {quickSelect ? null : (
          <div
            style={{
              flex: 0.15,

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 14,
            }}
          >
            <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
              {lastName}
            </div>
          </div>
        )}
        <div
          style={{
            flex: quickSelect ? 0.35 : 0.15,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: quickSelect ? 11 : 14,
          }}
        >
          <div style={{ marginLeft: 40, width: "100%", textAlign: "left" }}>
            {idNum}
          </div>
        </div>

        <div
          style={{
            flex: quickSelect ? 0.45 : 0.2,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: quickSelect ? 11 : 14,
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              marginLeft: 0,
              width: "100%",
              textAlign: "left",
            }}
          >
            {email}
          </div>
        </div>
        {quickSelect ? null : (
          <div
            style={{
              flex: quickSelect ? 0.25 : 0.15,

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 14,
            }}
          >
            <div style={{ marginLeft: 50, width: "90%", textAlign: "left" }}>
              {cell}
            </div>
          </div>
        )}
        {/* <div
          style={{
            flex: 0.1,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
            {status}
          </div>
        </div> */}
        {quickSelect ? null : (
          <div
            style={{
              flex: 0.2,

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 14,
            }}
          >
            <BaseButtons
              onClick={onClick}
              mini={true}
              marginTopFalse={true}
              height={30}
              label={isSelector ? "SELECT" : "VIEW"}
              borderRadius={8}
              size={90}
              fontSize={12}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PosPatientCard;
