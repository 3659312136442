import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  ArtDisplay,
  Banner,
  Description,
  Footer,
  Header,
} from "../../../../webiste-components";

const WebsiteDisplayEdit = () => {
  const [websiteData, setWebsiteData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [descriptionArray, setDescriptionArray] = useState(null);
  const [editSelection, setEditSelection] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const descriptionsFetch = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/website/getDescriptionParagraphs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            descriptionId: websiteData.data.landingPage.descriptionId,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.data) {
        setDescriptionArray(responseData.data);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const AddNewParagraph = async ({ idValue, paragraphValue }) => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/website/createDescriptionLinkParagraph",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            descriptionId: descriptionArray[0].descriptionId,
            paragraph: "Add new text here",
            paragraphIndex: "0",
            fontsize: "18",
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.data) {
        setRefresh(refresh ? false : true);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (websiteData) {
      descriptionsFetch();
    }
  }, [websiteData, refresh]);
  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/website/getWebsiteInfo")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setWebsiteData(resData);
      });
  }, [edit, refresh]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Header />
      <div
        style={{
          flex: 1,
          width: "100%",
          overflow: "auto",
        }}
      >
        <Banner
          websiteData={websiteData}
          setEdit={setEdit}
          edit={edit}
          editSelection={editSelection}
          refresh={refresh}
          setRefresh={setRefresh}
          setLoading={setLoading}
          loading={loading}
        />
        <Description
          title={"WELCOME TO MERRY-JANE"}
          descriptionArray={descriptionArray}
          setEditSelection={setEditSelection}
          setRefresh={setRefresh}
          refresh={refresh}
          AddNewParagraph={AddNewParagraph}
        />
        <ArtDisplay
          title={"MANY FACES OF MERRY-JANE"}
          websiteData={websiteData}
        />
        <Footer websiteData={websiteData} />
      </div>
    </div>
  );
};

export default WebsiteDisplayEdit;
