import React, { useEffect, useState } from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import { toHaveDescription } from "@testing-library/jest-dom/dist/matchers";

const ItemCard = ({
  timer,
  refresh,
  label,
  strain,
  price,
  type,
  thcPercent,
  description,
  notIn,
}) => {
  const [displayCard, setDisplayCard] = useState(false);

  const getColor = () => {
    if (strain === "INDICA HYBRID") {
      return "#965857";
    } else if (strain === "SATIVA HYBRID") {
      return "#36635A";
    } else if (strain === "BALANCED") {
      return " #0A86B3";
    } else if (strain === "PURE SATIVA") {
      return "#36635A";
    } else if (strain === "PURE INDICA") {
      return "#965857";
    } else if (strain === "COMING SOON") {
      return "#BB6C32";
    }
  };

  useEffect(() => {
    timer
      ? setTimeout(() => {
          setDisplayCard(true);
        }, timer)
      : setDisplayCard(false);
  }, [refresh]);
  useEffect(() => {
    timer
      ? setTimeout(() => {
          setDisplayCard(false);
        }, timer + 13000)
      : setDisplayCard(false);
  }, [refresh]);
  return (
    <div
      style={{
        flex: 0.5,
        height: displayCard ? "100%" : 0,
        width: "100%",
        overflow: "hidden",
        // borderStyle: displayCard ? "solid" : "none",
        color: BlackAndWhite.secondary,
        transition: "all 1s",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        opacity: displayCard ? 1 : 0,
        flexDirection: "column",
        fontFamily: "Montserrat",
        position: "relative",
      }}
    >
      <div style={{ width: "95%" }}>
        <div
          style={{
            fontSize: 25,
            marginBottom: 5,
            fontWeight: "bold",
            lineHeight: 1.6,
            letterSpacing: 1,
            // wordSpacing: 5,
          }}
        >
          {label}
        </div>
        <div
          style={{
            display: "flex",
            fontSize: 18,
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <div
            style={{
              marginRight: 5,
              backgroundColor: getColor(),
              padding: 2,
              borderRadius: 4,
              fontWeight: "bold",
            }}
          >
            {strain}
          </div>
          <div
            style={{ fontWeight: "bold", fontSize: 24, position: "relative" }}
          >
            {price}
            <div
              style={{
                position: "absolute",
                right: -40,
                bottom: -2,
                opacity: 0.3,
              }}
            >
              {notIn ? null : (
                <img
                  alt="in store"
                  src={require("../../../images/material-icons/shopWhite.png")}
                  style={{ height: 25 }}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", fontSize: 16 }}>
          <div style={{ fontWeight: "bold", marginRight: 5 }}>{type}</div>
          <div>THC {thcPercent}</div>
        </div>
        <div style={{ fontSize: 12, width: "90%", marginTop: 5 }}>
          {description}
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
