import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const HeadersPatients = ({ onClick, marginTop }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        minHeight: 45,
        maxHeight: 45,
        display: "flex",
        borderTopColor: BlackAndWhite.primary,
        backgroundColor: BlackAndWhite.primary,
        color: BlackAndWhite.secondary,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginTop: marginTop,
      }}
    >
      <div
        style={{
          flex: 0.05,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      ></div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 20, width: "100%", textAlign: "left" }}>
          FIRST NAME
        </div>
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
          SURNAME
        </div>
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
          ID
        </div>
      </div>

      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div
          style={{
            marginLeft: 0,
            width: "100%",
            textAlign: "left",
          }}
        >
          EMAIL
        </div>
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 50, width: "90%", textAlign: "left" }}>
          CELL
        </div>
      </div>
      {/* <div
        style={{
          flex: 0.1,
          
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
          STATUS
        </div>
      </div> */}
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        ACTIONS
        {/* <div> Edit users</div>
        <div style={{ width: 20 }} /> */}
      </div>
    </div>
  );
};

export default HeadersPatients;
