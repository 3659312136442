import React, { useState } from "react";
import {
  BaseButtons,
  CheckInput,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { ImageCheckMark, LoadingPopUp } from "../../../../../../components";

const AddProduct = ({
  setMessage,
  setToastPop,
  setSelectedAdd,
  categorySelected,
  selectedSubCategory,
}) => {
  const [loading, setLoading] = useState(false);

  const [productName, setProductName] = useState(null);
  const [description, setDescription] = useState(null);
  const [menuDescription, setMenuDescription] = useState(null);
  const [useDescription, setUseDescription] = useState(null);
  const [moreInfo, setMoreInfo] = useState(null);
  const [strain, setStrain] = useState(null);
  const [growType, setGrowType] = useState(null);
  const [thcContent, setThcContent] = useState(null);
  const [productNo, setProductNo] = useState(null);
  const [productType, setProductType] = useState(null);
  const [retailPrice, setRetailPrice] = useState(null);
  const [costPrice, setCostPrice] = useState(null);

  // productType,
  // categoryId,
  // subCategoryId,
  // addedBy,
  // retailPrice,
  // costPrice,

  // subCategoryName,
  // categoryId,
  // description,
  // moreInfo,
  // subCategoryType,

  const uploadProduct = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    if (
      productName &&
      description &&
      menuDescription &&
      useDescription &&
      strain &&
      growType &&
      thcContent &&
      productNo &&
      productType &&
      retailPrice &&
      costPrice
    ) {
      try {
        const response = await fetch(
          "https://merry-jane-api.onrender.com/productUpload/createProduct",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              productName: productName,
              description: description,
              menuDescription: menuDescription,
              useDescription: useDescription,
              moreInfo: "none",
              strain: strain,
              growType: growType,
              thcContent: thcContent,
              productNo: productNo,
              productType: productType,
              categoryId: categorySelected.categoryId,
              subCategoryId: selectedSubCategory.subCategoryId,
              addedBy: "Admin",
              retailPrice: retailPrice,
              costPrice: costPrice,
            }),
          }
        );

        const respnseData = await response.json();
        if (respnseData.newProduct) {
          // const wait = await updateListFetch();
          setToastPop(true);
          setMessage("Product Uploaded");
          setLoading(false);
          setSelectedAdd(null);
        } else if (respnseData.message) {
          setToastPop(true);
          setMessage(respnseData.message);
          setLoading(false);
        }
      } catch (err) {
        setToastPop(true);
        setMessage("Error occured please try again in a few minutes");
        setLoading(false);
      }
    } else {
      setToastPop(true);
      setMessage(
        "You are missing data fields please review inforation and try again"
      );
      setLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          flex: 0.5,
          position: "relative",
          height: "100%",
        }}
      >
        <div style={{ overflow: "scroll", height: 600 }}>
          <div style={{ height: 40 }} />
          <TextInputField
            labelTitle={" Product name:"}
            invert
            size={"70%"}
            onChange={(e) => setProductName(e.target.value)}
          />
          <TextAreaInput
            labelTitle={"Description :"}
            invert
            size={"70%"}
            color={BlackAndWhite.primary}
            onChange={(e) => setDescription(e.target.value)}
            height={100}
          />
          <TextAreaInput
            labelTitle={"Menu Description :"}
            invert
            size={"70%"}
            color={BlackAndWhite.primary}
            onChange={(e) => setMenuDescription(e.target.value)}
            height={100}
          />
          <TextAreaInput
            labelTitle={"Use Description :"}
            invert
            size={"70%"}
            color={BlackAndWhite.primary}
            onChange={(e) => setUseDescription(e.target.value)}
            height={100}
          />
          <TextInputField
            labelTitle={"Strain:"}
            invert
            size={"70%"}
            onChange={(e) => setStrain(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
              //   textAlign: "left",
              //   width: "100%",
              //   borderStyle: "solid",
              fontSize: 20,
            }}
          >
            Grow Condition:
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <CheckInput
              check={growType === "Indoor" ? true : false}
              question={"INDOOR"}
              size={230}
              invert={true}
              onClick={() => setGrowType("Indoor")}
            />
            <CheckInput
              check={growType === "Greenhouse" ? true : false}
              question={"GREENHOUSE"}
              size={230}
              invert={true}
              onClick={() => setGrowType("Greenhouse")}
            />
          </div>
          <div style={{ height: 40 }} />
        </div>
      </div>
      <div style={{ flex: 0.5 }}>
        <div style={{ overflow: "scroll", height: 600 }}>
          <div style={{ height: 40 }} />
          <TextInputField
            labelTitle={"THC content:"}
            invert
            size={"70%"}
            onChange={(e) => setThcContent(e.target.value)}
          />
          <TextInputField
            labelTitle={"Product No:"}
            invert
            size={"70%"}
            onChange={(e) => setProductNo(e.target.value)}
          />
          <TextInputField
            labelTitle={"Product type:"}
            invert
            size={"70%"}
            onChange={(e) => setProductType(e.target.value)}
          />
          <TextInputField
            labelTitle={"Cost price:"}
            invert
            size={"70%"}
            onChange={(e) => setCostPrice(e.target.value)}
          />
          <TextInputField
            labelTitle={"Retail price:"}
            invert
            size={"70%"}
            onChange={(e) => setRetailPrice(e.target.value)}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <BaseButtons label={"SUBMIT"} onClick={() => uploadProduct()} />
            <BaseButtons
              label={"CANCEL"}
              onClick={() => setSelectedAdd(null)}
            />
          </div>
        </div>
      </div>
      <LoadingPopUp display={loading} />
    </>
  );
};

export default AddProduct;
