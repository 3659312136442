import React, { useState, useEffect } from "react";

import {
  BaseButtons,
  TextInputFieldSearch,
} from "../../../../components/atoms";
import { BlackAndWhite } from "../../../../themes/Colors";
import { CreatePrescriptionPopUp, RequestCard } from "./components";
import { ToastNotification } from "../../../../components";

const RequestDisplay = ({ setLoading, admin, setSelectedPatient, loading }) => {
  const [search, setSearch] = useState("");
  const [customers, setCustomers] = useState(null);
  const [requestedPrescriptionData, setRequestedPrescriptionData] =
    useState(null);
  // const [selectedUser, setSelectedUser] = useState(null);
  const [displayScreen, setDisplayScreen] = useState("requested");

  const [messageErr, setMessageErr] = useState(null);
  const [toastPop, setToastPop] = useState(null);

  const [requestArray, setREquestArray] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const [selectedPatientRequest, setSelectedPatientrequest] = useState(null);

  const GetRequests = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/requestPrescriptionUser/getRequests",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            docId: admin.userId,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.requestList) {
        setREquestArray(responseData.requestList);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const UpdateRequestStatus = async (requestId) => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/requestPrescriptionUser/updateRequestStatus",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: "DECLINED",
            requestId: requestId,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        setRefresh(refresh ? false : true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (admin) {
      GetRequests();
    }
  }, [refresh]);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 3000);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        height: "93%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#00000010",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* search and actions */}
      <div style={{ minHeight: 60, display: "flex", alignItems: "center" }}>
        <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 5 }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              // width: 300,

              borderWidth: 0.5,
            }}
          >
            <TextInputFieldSearch
              placeholder={"Type here to search"}
              height={30}
              borderRadius={4}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <div
              style={{
                height: 35,
                width: 70,
                backgroundColor: BlackAndWhite.primary,
                borderRadius: 4,
                display: "flex",
                color: BlackAndWhite.secondary,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: 5,
              }}
            >
              <div
                onClick={() => {
                  setSearch("");
                  setCustomers(null);
                }}
                style={{ fontSize: 12, fontWeight: "bold" }}
              >
                CLEAR
              </div>
            </div>
            <div
              style={{
                height: 35,
                width: 70,
                backgroundColor: BlackAndWhite.primary,
                borderRadius: 4,
                display: "flex",
                color: BlackAndWhite.secondary,
                justifyContent: "center",
                alignItems: "center",
                cursor: search === "" ? "not-allowed" : "pointer",
                marginLeft: 10,
              }}
            >
              <div
                onClick={search === "" ? null : null}
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  opacity: search === "" ? 0.5 : 1,
                }}
              >
                SEARCH
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}></div>
        <div style={{ marginRight: 10, marginBottom: 5, display: "flex" }}>
          <BaseButtons
            mini={true}
            label={"REQUESTED"}
            marginTopFalse={true}
            size={120}
            height={35}
            borderRadius={4}
            // selected={ }
            invert={displayScreen === "requested" ? false : true}
            onClick={() => setDisplayScreen("requested")}
          />
          <div style={{ minWidth: 10 }} />
          <BaseButtons
            mini={true}
            label={"DECLINED"}
            marginTopFalse={true}
            size={120}
            height={35}
            borderRadius={4}
            invert={displayScreen === "declined" ? false : true}
            onClick={() => setDisplayScreen("declined")}
          />
          <div style={{ minWidth: 10 }} />
          <BaseButtons
            mini={true}
            label={"APPROVED"}
            marginTopFalse={true}
            size={120}
            height={35}
            borderRadius={4}
            invert={displayScreen === "approved" ? false : true}
            onClick={() => setDisplayScreen("approved")}
          />
        </div>
      </div>

      {displayScreen === "requested" ? (
        <div
          style={{
            flex: 1,
            overflowY: "scroll",
            overflowX: "hidden",
            scrollbarWidth: "none",
          }}
        >
          {requestArray.length >= 1 ? (
            requestArray.map((request, index) => {
              return request.status === "REQUESTED" ? (
                <div key={index} style={{ width: "100%" }}>
                  <RequestCard
                    onCreatePreClick={() => {
                      setRequestedPrescriptionData(true);
                      setSelectedPatientrequest(request);
                    }}
                    onViewProfileClick={() =>
                      setSelectedPatient(request.patientData)
                    }
                    setSelectedUser={setSelectedPatient}
                    setRequestedPrescriptionData={setRequestedPrescriptionData}
                    request={request}
                    admin={admin}
                    onStatusClickChange={() =>
                      UpdateRequestStatus(request.requestId)
                    }
                  />
                </div>
              ) : null;
            })
          ) : (
            <div>NO REQUEST AVALIBLE</div>
          )}
        </div>
      ) : null}
      {displayScreen === "declined" ? (
        <div
          style={{
            flex: 1,
            overflowY: "scroll",
            overflowX: "hidden",
            scrollbarWidth: "none",
          }}
        >
          {requestArray.length >= 1 ? (
            requestArray.map((request, index) => {
              return request.status === "DECLINED" ? (
                <div key={index} style={{ width: "100%" }}>
                  <RequestCard
                    onCreatePreClick={() => {
                      setRequestedPrescriptionData(true);
                      setSelectedPatientrequest(request);
                    }}
                    onViewProfileClick={() =>
                      setSelectedPatient(request.patientData)
                    }
                    setSelectedUser={setSelectedPatient}
                    setRequestedPrescriptionData={setRequestedPrescriptionData}
                    request={request}
                    admin={admin}
                    onStatusClickChange={() =>
                      UpdateRequestStatus(request.requestId)
                    }
                  />
                </div>
              ) : null;
            })
          ) : (
            <div>NO REQUEST AVALIBLE</div>
          )}
        </div>
      ) : null}
      {displayScreen === "approved" ? (
        <div
          style={{
            flex: 1,
            overflowY: "scroll",
            overflowX: "hidden",
            scrollbarWidth: "none",
          }}
        >
          {requestArray.length >= 1 ? (
            requestArray.map((request, index) => {
              return request.status === "APPROVED" ? (
                <div key={index} style={{ width: "100%" }}>
                  <RequestCard
                    onCreatePreClick={() => {
                      setRequestedPrescriptionData(true);
                      setSelectedPatientrequest(request);
                    }}
                    onViewProfileClick={() =>
                      setSelectedPatient(request.patientData)
                    }
                    setSelectedUser={setSelectedPatient}
                    setRequestedPrescriptionData={setRequestedPrescriptionData}
                    request={request}
                    admin={admin}
                    onStatusClickChange={() =>
                      UpdateRequestStatus(request.requestId)
                    }
                  />
                </div>
              ) : null;
            })
          ) : (
            <div>NO REQUEST AVALIBLE</div>
          )}
        </div>
      ) : null}
      <CreatePrescriptionPopUp
        setMessage={setMessageErr}
        setToastPop={setToastPop}
        display={requestedPrescriptionData}
        setRequestedPrescriptionData={setRequestedPrescriptionData}
        selectedPatientRequest={selectedPatientRequest}
        setSelectedPatientrequest={setSelectedPatientrequest}
        setLoading={setLoading}
        admin={admin}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <ToastNotification
        display={toastPop}
        message={messageErr ? messageErr.toUpperCase() : "loading"}
      />
    </div>
  );
};
export default RequestDisplay;
