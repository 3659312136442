import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const StatusSelectDropDown = ({ data, setProvince, province }) => {
  const [open, setOpen] = useState(false);
  const [selectedType, setType] = useState("Click here to select Status");
  const [hover, setHover] = useState(null);
  return (
    <div
      style={{
        width: "100%",
        height: 80,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: 200,
      }}
    >
      <div
        style={{
          marginBottom: 5,
          width: "78%",
          textAlign: "left",
          marginTop: 7,
        }}
      >
        Status
      </div>
      <div
        onClick={open ? null : () => setOpen(true)}
        style={{
          borderStyle: "solid",
          width: "78%",
          height: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderWidth: 0.5,
          textAlign: "left",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <div style={{ width: "100%", textAlign: "left", marginLeft: 10 }}>
          {province !== "none" ? province : selectedType}
        </div>
        <div
          style={{
            position: "absolute",
            height: open ? 280 : 0,
            overflow: "hidden",
            width: "100%",
            transition: "all 0.3s",
            top: 0,
            WebkitBoxShadow: open ? "0px 0px 10px 5px #00000033" : null,
            zIndex: 100,
          }}
        >
          {data.map((type, index) => {
            return (
              <div
                onMouseOver={() => setHover(index)}
                onMouseOut={() => setHover(null)}
                onClick={() => {
                  setOpen(false);
                  setType(type);
                  setProvince(type);
                }}
                style={{
                  backgroundColor:
                    hover === index
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                  width: "100%",
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 200,
                  // borderStyle: "solid",
                  // borderWidth: 0.5,
                }}
                key={index}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                    marginLeft: 20,
                    color:
                      hover === index
                        ? BlackAndWhite.secondary
                        : BlackAndWhite.primary,
                  }}
                >
                  {type}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StatusSelectDropDown;
