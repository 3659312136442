import React from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import { SocialMemberTerms } from "./terms-options";
import { CloseButton } from "../../../../../../../../components/atoms";

const TermsDisplay = ({
  display,
  terms,
  onCloseClick,
  isSigned,
  dateCreated,
  signedAtAlready,
  userName,
  signedAt,
  setSignedAt,
  onAcceptClick,
  isActive,
}) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        overflowY: display ? "scroll" : "hidden",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        transition: "all 0.3s",
        opacity: display ? 1 : 0,
        zIndex: 30000000000,
      }}
    >
      {isActive ? (
        <div style={{ position: "fixed", left: 10, top: 10 }}>
          <CloseButton label={"X"} invert={false} onClick={onCloseClick} />
        </div>
      ) : null}
      {terms === "social" && (
        <SocialMemberTerms
          isSigned={isSigned}
          dateCreated={dateCreated}
          signedAtAlready={signedAtAlready}
          userName={userName}
          signedAt={signedAt}
          setSignedAt={setSignedAt}
          onAcceptClick={onAcceptClick}
        />
      )}
    </div>
  );
};

export default TermsDisplay;
