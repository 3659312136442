import React, { useState } from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../../../components/atoms";

const InventoryLinkCard = ({ store, NameAssemble, selectedProduct }) => {
  const [areSure, setAreSure] = useState(false);
  return (
    <div
      style={{
        height: 70,
        width: "100%",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#00000030",
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        <div
          style={{
            marginLeft: 25,
            width: "100%",
            textAlign: "left",
          }}
        >
          {store ? store.storeName.toLocaleUpperCase() : null}
        </div>
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div
          style={{
            marginLeft: 0,
            width: "100%",
            textAlign: "left",
          }}
        >
          {NameAssemble(selectedProduct)}
        </div>
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div
          style={{
            marginLeft: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          {store.status}
        </div>
      </div>
      <div
        style={{
          flex: 0.3,

          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        {/* <div
            style={{
              fontSize: 14,
              marginTop: 15,
              marginRight: 5,
            }}
          >
            UNIT
          </div> */}
        <div>
          <TextInputField
            invert={true}
            mini={true}
            height={10}
            marginTop={-5}
            size={70}
            labelTitle={"In Store"}
            type={"Number"}
            readOnly={true}
          />
        </div>
        <div>
          <TextInputField
            invert={true}
            mini={true}
            height={10}
            marginTop={-5}
            size={70}
            labelTitle={"Available"}
            type={"Number"}
            readOnly={true}
          />
        </div>
        <div>
          <TextInputField
            invert={true}
            mini={true}
            height={10}
            marginTop={-5}
            size={70}
            labelTitle={"Quantity"}
            type={"Number"}
          />
        </div>
      </div>

      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        {areSure ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ marginBottom: 5 }}>ARE YOU SURE ?</div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <BaseButtons
                label={"YES"}
                mini={true}
                borderRadius={8}
                marginTopFalse={true}
                height={35}
                size={70}
              />
              <BaseButtons
                label={"NO"}
                mini={true}
                borderRadius={8}
                marginTopFalse={true}
                height={35}
                size={70}
                onClick={() => setAreSure(false)}
              />
            </div>
          </div>
        ) : (
          <BaseButtons
            label={"SAVE"}
            mini={true}
            borderRadius={8}
            marginTopFalse={true}
            height={35}
            size={120}
            onClick={() => setAreSure(true)}
          />
        )}
      </div>
    </div>
  );
};

export default InventoryLinkCard;
