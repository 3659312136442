import React from "react";
import { BlackAndWhite } from "../../../themes/Colors";

const PinTextInputField = ({
  labelTitle,
  onChange,
  width,
  size,
  invert,
  rule,
  reqiredCheck,
  showPassword,
  autoFocus,
  value,
  placeholder,
  onClick,
  mini,
  height,
  marginTop,
  enter,
  type,
  min,
  readOnly,
  labelWidth,
  fontsize,
  onKeyUp,
  tabIndex,
  maxLength,
  max,
  ref,
}) => {
  const OnKeyDown = (e) => {
    if (e.key === "Tab") {
      enter();
    }
  };
  return (
    <form
      onKeyDown={enter ? (e) => OnKeyDown(e) : null}
      onSubmit={
        enter
          ? (e) => {
              e.preventDefault();
              enter();
            }
          : (e) => e.preventDefault()
      }
      style={{
        width: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: mini ? (marginTop ? marginTop : 10) : 20,
      }}
    >
      {/* <label
        style={{
          width: labelWidth ? labelWidth : size ? size : 240,
          fontWeight: "500",
          fontSize: mini ? 14 : 16,
          color: invert ? BlackAndWhite.primary : BlackAndWhite.secondary,
          fontFamily: "Montserrat",
          position: "relative",
        }}
      >
        {labelTitle ? labelTitle : null}
        {reqiredCheck ? (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              color: rule ? "#5EEB5B" : "#FFFFFF",
            }}
          >
            {rule ? "complete" : "required"}
          </div>
        ) : null}
      </label> */}

      <input
        ref={ref}
        tabIndex={tabIndex}
        onKeyUp={onKeyUp}
        autoFocus={autoFocus}
        maxLength={4}
        max={4}
        min={4}
        style={{
          height: height ? height : 50,
          width: size ? size : 380,
          marginTop: mini ? 5 : 10,
          borderRadius: 8,
          paddingRight: 0,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 30,
          fontSize: fontsize ? fontsize : mini ? 12 : 40,
          fontFamily: "Montserrat",
          textAlign: "center",
          borderWidth: 0,
          borderBottomStyle: "dashed",
          // lineHeight: 100,
          letterSpacing: value.length >= 4 ? 50 : 30,
          marginRight: 20,
        }}
        type={type ? type : showPassword ? "password" : "text"}
        name={labelTitle}
        onChange={onChange}
        value={value}
        placeholder={placeholder ? placeholder : "type here"}
        readOnly={readOnly ? true : false}
      />
      {/* <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 50,
            height: 5,
            backgroundColor: BlackAndWhite.primary,
          }}
        />
        <div
          style={{
            width: 50,
            height: 5,
            backgroundColor: BlackAndWhite.primary,
          }}
        />
        <div
          style={{
            width: 50,
            height: 5,
            backgroundColor: BlackAndWhite.primary,
          }}
        />
        <div
          style={{
            width: 50,
            height: 5,
            backgroundColor: BlackAndWhite.primary,
            marginRight: 30,
          }}
        />
      </div> */}
    </form>
  );
};

export default PinTextInputField;
