import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";

const CategoryCard = ({
  title,
  description,
  hasSubCategory,
  imageIcon,
  imageIcon2,
  onClick,
  width,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        height: 200,
        width: width ? width : 300,
        backgroundColor: hover ? "#FFFFFF50" : BlackAndWhite.secondary,
        margin: 10,
        borderRadius: 8,
        display: "flex",
        color: BlackAndWhite.secondary,
        transition: "all 0.3s",
        cursor: "pointer",
        borderStyle: "solid",
        borderWidth: hover ? 5 : 0.5,
        borderColor: "#FAA919",
        WebkitBoxShadow: "0px 0px 10px 1px #00000066",
      }}
    >
      <div
        style={{
          flex: 0.5,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            fontSize: 22,
            textAlign: "center",
            height: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            position: "absolute",
          }}
        >
          {title}
        </div>

        <img
          alt="Logo"
          src={require("../../../../../images/marijuana-buds-with-marijuana-joints-cannabis-oil.jpg")}
          style={{
            height: "100%",
            // position: "absolute",
            bottom: hover ? 10 : -50,
            left: hover ? 10 : -70,
            transition: "all 0.3s",
            borderRadius: 8,
            // transform: hover ? null : `rotate(180deg)`,
          }}
        />
      </div>
      <div
        style={{
          flex: 0.5,

          color: hover ? BlackAndWhite.secondary : BlackAndWhite.primary,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginTop: 10,
            // marginRight: 10,
            fontWeight: "bold",
            marginBottom: 6,
            width: "90%",
            textAlign: "left",
            fontSize: 15,
          }}
        >
          Description:
        </div>
        <div style={{ width: "90%", textAlign: "left", fontSize: 14 }}>
          {description}
        </div>
        <div
          style={{
            marginTop: 10,
            // marginRight: 10,
            fontWeight: "bold",
            marginBottom: 6,
            width: "90%",
            textAlign: "left",
            fontSize: 15,
          }}
        >
          Sub-categories
        </div>
        <div style={{ width: "90%", textAlign: "left", fontSize: 14 }}>
          {hasSubCategory}
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
