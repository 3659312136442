import React, { useState } from "react";
import useWindowDimensions from "../../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../../themes/Colors";
import CloseButton from "../close-button/CloseButton";
import BaseButtons from "../base-buttons/BaseButtons";
import NavArrow from "../location-tab/components/NavArrow";

const MobileShopDisplay = ({
  display,
  onCloseClick,
  desTitle,
  description,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
  infoDisplay,
  setInfoDisplay,
  contact,
  email,
  address,
  type,
  openInfo,
  paragraph1,
  paragraph2,
  onLeftClick,
  onRightClick,
  shopImage,
  selectedLocation,
}) => {
  const { width, height } = useWindowDimensions();
  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: width,
        backgroundColor:
          width > 899 ? BlackAndWhite.primary : BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        overflow: "hidden",
        top: 0,
        transition: "all 0.4s",
      }}
    >
      <div style={{ position: "absolute", top: 90, left: 15, zIndex: 20 }}>
        <CloseButton onClick={onCloseClick} />
      </div>
      {width > 899 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
            overflow: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // borderStyle: "solid",
              // flex: 0.5,
              height: "100%",
            }}
          >
            <img
              alt="shop image"
              src={selectedLocation ? selectedLocation.image : null}
              style={{
                height: 400,
                // marginLeft: 20,
                width: "100%",
                marginTop: infoDisplay ? 120 : 250,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // borderStyle: "solid",
              // flex: 0.5,
              height: "100%",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                width: "100%",
                height: 30,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                backgroundColor: "#00000033",
              }}
            >
              <BaseButtons
                label={"DESCRIPTION"}
                size={150}
                height={30}
                onClick={() => setInfoDisplay(true)}
                invert={infoDisplay ? false : true}
              />
              <BaseButtons
                label={"SHOP INFO"}
                size={150}
                height={30}
                onClick={() => setInfoDisplay(false)}
                invert={!infoDisplay ? false : true}
              />
            </div>
            {infoDisplay ? (
              <div
                style={{
                  height: "100%",
                  width: 700,
                  overflow: "scroll",
                  marginTop: 20,
                  backgroundColor: BlackAndWhite.secondary,
                }}
              >
                <div
                  style={{
                    marginTop: 40,
                    marginLeft: 20,
                    fontSize: 20,
                    fontFamily: "Montserrat",
                    width: "90%",
                    lineHeight: 1.6,
                  }}
                >
                  {selectedLocation ? selectedLocation.storeName : "loading"}
                </div>

                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontFamily: "Montserrat",
                    lineHeight: 1.6,
                    width: "90%",
                  }}
                >
                  {selectedLocation
                    ? selectedLocation.descriptionP1
                    : "loading"}
                </div>
                <div
                  style={{
                    marginTop: 14,
                    marginLeft: 20,
                    fontSize: 16,
                    fontFamily: "Montserrat",
                    lineHeight: 1.6,
                    width: "90%",
                  }}
                >
                  {selectedLocation
                    ? selectedLocation.descriptionp2
                    : "loading"}
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "100%",
                  width: 700,
                  overflow: "scroll",
                  marginTop: 20,
                  backgroundColor: BlackAndWhite.secondary,
                }}
              >
                <div
                  style={{
                    marginTop: 20,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Address:
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    width: "90%",
                  }}
                >
                  {selectedLocation
                    ? selectedLocation.unitNumber +
                      "  " +
                      selectedLocation.streetAddress +
                      ", " +
                      selectedLocation.suburb +
                      ", " +
                      selectedLocation.province
                    : null}
                </div>

                <div style={{ marginTop: 10, marginLeft: 20, fontSize: 16 }}>
                  {selectedLocation ? selectedLocation.email : null}
                </div>
                <div style={{ marginTop: 10, marginLeft: 20, fontSize: 16 }}>
                  {selectedLocation ? selectedLocation.cellNumber : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  {selectedLocation ? selectedLocation.status : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  {selectedLocation ? selectedLocation.type : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Operating hours:
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Monday: {selectedLocation ? selectedLocation.monday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Tuesday: {selectedLocation ? selectedLocation.tuesday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Wednesday:{" "}
                  {selectedLocation ? selectedLocation.wednesday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Thursday:{" "}
                  {selectedLocation ? selectedLocation.thursday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Friday: {selectedLocation ? selectedLocation.friday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Saturday:{" "}
                  {selectedLocation ? selectedLocation.saturday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Sunday: {selectedLocation ? selectedLocation.sunday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Public holidays:{" "}
                  {selectedLocation ? selectedLocation.publicHoliday : null}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              flex: 0.11,
              width: "100%",
              backgroundColor: BlackAndWhite.secondary,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
          <div
            style={{
              flex: 0.3,
              width: "100%",
              backgroundColor: BlackAndWhite.primary,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                color: BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {/* <div
            style={{
              height: 40,
              width: 40,

              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              position: "absolute",
              left: 10,
              backgroundColor: "#000000cc",
              borderRadius: 100,
            }}
          >
            <NavArrow
              icon={require("../../../images/standard-icons/leftArrowWhite.png")}
              onClick={onLeftClick}
            />
          </div> */}

              <img
                alt="shop image"
                src={selectedLocation ? selectedLocation.image : null}
                style={{
                  // height: width > 899 ? "50%" : "100%",
                  height: 270,
                  width: "100%",
                  marginTop: 160,
                }}
              />

              {/* <div
            style={{
              height: 40,
              width: 40,
              marginLeft: 20,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              position: "absolute",
              right: 10,
              backgroundColor: "#000000cc",
              borderRadius: 100,
            }}
          >
            <NavArrow
              icon={require("../../../images/standard-icons/rightArrowWhite.png")}
              onClick={onRightClick}
            />
          </div> */}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: BlackAndWhite.secondary,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: 30,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
              }}
            >
              <BaseButtons
                label={"DESCRIPTION"}
                size={150}
                height={30}
                onClick={() => setInfoDisplay(true)}
                invert={infoDisplay ? false : true}
              />
              <BaseButtons
                label={"SHOP INFO"}
                size={150}
                height={30}
                onClick={() => setInfoDisplay(false)}
                invert={!infoDisplay ? false : true}
              />
            </div>

            {infoDisplay ? (
              <div
                style={{
                  height: height > 650 ? 350 : 300,
                  width: "100%",
                  overflow: "scroll",
                  marginTop: 20,
                  backgroundColor: BlackAndWhite.secondary,
                }}
              >
                <div
                  style={{
                    marginTop: 40,
                    marginLeft: 20,
                    fontSize: 20,
                    fontFamily: "Montserrat",

                    lineHeight: 1.6,
                  }}
                >
                  {selectedLocation ? selectedLocation.storeName : null}
                </div>

                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontFamily: "Montserrat",
                    lineHeight: 1.6,
                    width: "90%",
                  }}
                >
                  {selectedLocation ? selectedLocation.descriptionP1 : null}
                </div>
                <div
                  style={{
                    marginTop: 14,
                    marginLeft: 20,
                    fontSize: 16,
                    fontFamily: "Montserrat",
                    lineHeight: 1.6,
                    width: "90%",
                  }}
                >
                  {selectedLocation ? selectedLocation.descriptionp2 : null}
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: height > 650 ? 350 : 300,
                  width: "100%",
                  overflow: "scroll",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    marginTop: 20,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Address:
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    width: "90%",
                  }}
                >
                  {selectedLocation
                    ? selectedLocation.unitNumber +
                      "  " +
                      selectedLocation.streetAddress +
                      ", " +
                      selectedLocation.suburb +
                      ", " +
                      selectedLocation.province
                    : null}
                </div>

                <div style={{ marginTop: 10, marginLeft: 20, fontSize: 16 }}>
                  {selectedLocation ? selectedLocation.email : null}
                </div>
                <div style={{ marginTop: 10, marginLeft: 20, fontSize: 16 }}>
                  {selectedLocation ? selectedLocation.cellNumber : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  {selectedLocation ? selectedLocation.status : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  {selectedLocation ? selectedLocation.type : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Operating hours:
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Monday: {selectedLocation ? selectedLocation.monday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Tuesday: {selectedLocation ? selectedLocation.tuesday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Wednesday:{" "}
                  {selectedLocation ? selectedLocation.wednesday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Thursday:{" "}
                  {selectedLocation ? selectedLocation.thursday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Friday: {selectedLocation ? selectedLocation.friday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Saturday:{" "}
                  {selectedLocation ? selectedLocation.saturday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Sunday: {selectedLocation ? selectedLocation.sunday : null}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 20,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Public Holidays:{" "}
                  {selectedLocation ? selectedLocation.publicHoliday : null}
                </div>
              </div>
            )}
            <div style={{ marginBottom: 220 }}></div>
          </div>
        </>
      )}
    </div>
  );
};

export default MobileShopDisplay;
