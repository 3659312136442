import React from "react";
import { TextAreaInput, TextInputField } from "../../../components/atoms";
import { BlackAndWhite } from "../../../themes/Colors";

const CreateFacility = ({
  onStoreChange,
  onEmailChange,
  onCelNumberChange,
  onLandlineChange,
  onTypeChange,
  onStreetChange,
  onSuburbChange,
  onCityChange,
  onProvinceChange,
  onPostalChange,
  onChangeParagraph1,
  onChangeParagraph2,
  onDayToChange,
  onDayfromChange,
  onEndToChange,
  onEndfromChange,
  onPublicfromChange,
  onPublicToChange,
}) => {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <div style={{ flex: 0.33, height: "100%" }}>
        <TextInputField
          labelTitle={"Store name:"}
          invert={true}
          onChange={onStoreChange}
        />
        <TextInputField
          labelTitle={"Email:"}
          invert={true}
          onChange={onEmailChange}
        />
        <TextInputField
          labelTitle={"Cell-Number:"}
          invert={true}
          onChange={onCelNumberChange}
        />
        <TextInputField
          labelTitle={"Land-line:"}
          invert={true}
          onChange={onLandlineChange}
        />
        <TextInputField
          labelTitle={"Type:"}
          invert={true}
          onChange={onTypeChange}
        />
      </div>
      <div style={{ flex: 0.34, height: "100%" }}>
        <TextInputField
          labelTitle={"Street address:"}
          invert={true}
          onChange={onStreetChange}
        />
        <TextInputField
          labelTitle={"Suburb:"}
          invert={true}
          onChange={onSuburbChange}
        />
        <TextInputField
          labelTitle={"City:"}
          invert={true}
          onChange={onCityChange}
        />
        <TextInputField
          labelTitle={"Postal code:"}
          invert={true}
          onChange={onPostalChange}
        />
        <TextInputField
          labelTitle={"Province:"}
          invert={true}
          onChange={onProvinceChange}
        />
      </div>
      <div
        style={{
          flex: 0.33,
          height: "100%",
          backgroundColor: BlackAndWhite.primary,
          borderTopLeftRadius: 20,
          color: BlackAndWhite.secondary,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              //   marginLeft: 20,
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              marginBottom: 8,
              fontSize: 20,
            }}
          >
            Operating hours:
          </div>
          <div
            style={{
              width: "80%",
              height: 30,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>weekdays</div>
            <form style={{ display: "flex" }}>
              <div style={{ marginLeft: 5, marginRight: 5 }}>from</div>
              <label>
                <input style={{ width: 50 }} onChange={onDayfromChange} />
              </label>
              <div style={{ marginLeft: 5, marginRight: 5 }}>to</div>
              <label>
                <input style={{ width: 50 }} onChange={onDayToChange} />
              </label>
            </form>
          </div>
          <div
            style={{
              width: "80%",
              height: 30,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>weekends</div>
            <form style={{ display: "flex" }}>
              <div style={{ marginLeft: 5, marginRight: 5 }}>from</div>
              <label>
                <input style={{ width: 50 }} onChange={onEndfromChange} />
              </label>
              <div style={{ marginLeft: 5, marginRight: 5 }}>to</div>
              <label>
                <input style={{ width: 50 }} onChange={onEndToChange} />
              </label>
            </form>
          </div>
          <div
            style={{
              width: "80%",
              height: 30,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>public holidays</div>
            <form style={{ display: "flex" }}>
              <div style={{ marginLeft: 5, marginRight: 5 }}>from</div>
              <label>
                <input style={{ width: 50 }} onChange={onPublicfromChange} />
              </label>
              <div style={{ marginLeft: 5, marginRight: 5 }}>to</div>
              <label>
                <input style={{ width: 50 }} onChange={onPublicToChange} />
              </label>
            </form>
          </div>
        </div>

        <TextAreaInput
          labelTitle={"Paragraph 1"}
          size={300}
          height={100}
          onChange={onChangeParagraph1}
        />
        <TextAreaInput
          labelTitle={"Paragraph 2"}
          size={300}
          height={100}
          onChange={onChangeParagraph2}
        />
        <div style={{ height: 60 }} />
      </div>
    </div>
  );
};

export default CreateFacility;
