import React from "react";
import PicsDisplay from "./pic-display/PicsDisplay";

const ArtDisplay = ({ title, fontSize, websiteData }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          marginTop: 40,
          width: "100%",
          justifyContent: "ceneter",
          alignItems: "center",
          textAlign: "center",
          fontFamily: "Montserrat",
        }}
      >
        <div style={{ fontSize: fontSize ? fontSize : 30, fontWeight: "bold" }}>
          {title}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: 40,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: 0.25 }}></div>
        <div
          style={{
            flex: 0.5,
            textAlign: "center",
            fontSize: 20,
            minWidth: 500,
          }}
        >
          <div
            style={{
              marginBottom: 1,
              width: "100%",
              height: 150,
              minWidth: 500,
            }}
          >
            {websiteData
              ? websiteData.data.landingPage.merryJaneArt.description
              : null}
          </div>

          <PicsDisplay websiteData={websiteData} />
        </div>
        <div style={{ flex: 0.25 }}></div>
      </div>
    </div>
  );
};
export default ArtDisplay;
