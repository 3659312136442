import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import TabMobileButton from "../../../customer-portal/components/mobile-layout/components/tab-mobile-button/TabMobileButton";
import {
  BaseButtons,
  CloseButton,
  TextInputFieldSearch,
} from "../../../../components/atoms";

const TabSearchOption = ({
  selected,
  onApprovedClick,
  onDeclinedClick,
  onExpiredClick,
  onPendingClick,
  amountPending,
  onFlaggedClick,
  width,
  search,
  setSearch,
}) => {
  const [open, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  return (
    <div
      onClick={width > 899 ? null : () => setOpen(open ? false : true)}
      style={{
        minHeight: 60,
        width: width > 899 ? width - 220 : open ? "100%" : 100,
        // backgroundColor: BlackAndWhite.primary,
        color: BlackAndWhite.primary,
        position: "fixed",
        top: 50,
        right: width > 899 ? null : 5,
        borderRadius: 8,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
        transition: "all 0.2s",
        zIndex: 100,
      }}
    >
      {width > 899 ? (
        <>
          <div style={{ width: 10 }} />

          <BaseButtons
            mini={true}
            marginTopFalse={true}
            label={"PENDING"}
            onClick={onPendingClick}
            invert={selected === "pending" ? false : true}
            height={40}
            size={120}
          />

          <BaseButtons
            mini={true}
            marginTopFalse={true}
            label={"APPROVED"}
            onClick={onApprovedClick}
            invert={selected === "approved" ? false : true}
            height={40}
            size={120}
          />

          <BaseButtons
            mini={true}
            marginTopFalse={true}
            label={"DECLINED"}
            onClick={onDeclinedClick}
            invert={selected === "declined" ? false : true}
            height={40}
            size={120}
          />

          <BaseButtons
            mini={true}
            marginTopFalse={true}
            label={"EXPIRED"}
            onClick={onExpiredClick}
            invert={selected === "expired" ? false : true}
            height={40}
            size={120}
          />

          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFFFFF40",
              height: 40,
              position: "relative",
            }}
          >
            {selected === "pending" ? (
              <div>you have {amountPending} pending prescriptions</div>
            ) : null}

            {selected === "flagged" ? (
              <div>you have {amountPending} flagged prescriptions</div>
            ) : null}
            {selected === "approved" ? (
              <div>you have {amountPending} approved prescriptions</div>
            ) : null}
            {selected === "declined" ? (
              <div>you have {amountPending} declined prescriptions</div>
            ) : null}
            {selected === "expired" ? (
              <div>you have {amountPending} declined prescriptions</div>
            ) : null}

            <div
              style={{
                height: "100%",
                width: searchOpen ? "100%" : 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                backgroundColor: BlackAndWhite.secondary,
                // width: 300,
                overflow: "hidden",
                borderWidth: 0.5,
                transition: "all 0.3s",
              }}
            >
              <TextInputFieldSearch
                placeholder={"SEARCH BY ID NUMBER"}
                height={30}
                borderRadius={4}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <div
                style={{
                  height: 35,
                  width: 70,
                  backgroundColor: BlackAndWhite.primary,
                  borderRadius: 4,
                  display: "flex",
                  color: BlackAndWhite.secondary,
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div
                  onClick={() => setSearch("")}
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  CLEAR
                </div>
              </div>
              <div
                style={{
                  height: 35,
                  width: 70,
                  backgroundColor: BlackAndWhite.primary,
                  borderRadius: 4,
                  display: "flex",
                  color: BlackAndWhite.secondary,
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  marginLeft: 10,
                }}
              >
                <div
                  onClick={() => setSearchOpen(false)}
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  CLOSE
                </div>
              </div>
            </div>
            <div style={{ marginLeft: 10 }}>
              {" "}
              <BaseButtons
                marginTopFalse={true}
                mini={true}
                label={"SEARCH"}
                borderRadius={4}
                height={35}
                size={100}
                onClick={() => setSearchOpen(true)}
              />{" "}
            </div>
          </div>
          <BaseButtons
            mini={true}
            marginTopFalse={true}
            label={"FLAGGED"}
            onClick={onFlaggedClick}
            invert={selected === "flagged" ? false : true}
            height={40}
            size={120}
          />
          <div style={{ width: 10 }} />
        </>
      ) : null}

      {width > 899 ? null : (
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "100%",
            zIndex: 20000000000000,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              height: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {open ? (
              <div
                onClick={onPendingClick}
                style={{
                  flex: 0.3,
                  height: "90%",
                  color:
                    selected === "pending"
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    selected === "pending" ? BlackAndWhite.secondary : null,
                  borderRadius: selected === "pending" ? 8 : null,
                }}
              >
                Pending
              </div>
            ) : null}
            {open ? (
              <div
                onClick={onApprovedClick}
                style={{
                  flex: 0.3,
                  height: "90%",
                  color:
                    selected === "approved"
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    selected === "approved" ? BlackAndWhite.secondary : null,
                  borderRadius: selected === "approved" ? 8 : null,
                }}
              >
                Approved
              </div>
            ) : null}
            {open ? (
              <div
                onClick={onFlaggedClick}
                style={{
                  flex: 0.3,
                  height: "90%",
                  color:
                    selected === "flagged"
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    selected === "flagged" ? BlackAndWhite.secondary : null,
                  borderRadius: selected === "flagged" ? 8 : null,
                }}
              >
                Flagged
              </div>
            ) : null}
          </div>
          <div
            style={{
              height: "95%",
              width: open ? 50 : 100,
              // textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              backgroundColor: open ? BlackAndWhite.secondary : null,
              marginRight: 2,
              color: open ? BlackAndWhite.primary : BlackAndWhite.secondary,
              borderRadius: 8,
              fontSize: open ? 12 : 16,
            }}
          >
            {open ? "CLOSE" : selected ? selected : "FILTER"}
          </div>
        </div>
      )}
    </div>
  );
};

export default TabSearchOption;
