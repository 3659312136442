import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import { BaseButtons } from "../../../../../../../../components/atoms";
import AWS from "aws-sdk";

const SupportMessageView = ({
  messageTitle,
  type,
  date,
  fullDescription,
  quickMessage,
  signOff,
  tags,
  createdBy,
  imageId,
  messageId,
  setRefresh,
  refresh,
  setLoading,
}) => {
  const [image, setImage] = useState(null);
  const [areYouSure, setAreYouSure] = useState(false);

  const getAdminMessages = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/adminMessageRoutes/deleteMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messageId: messageId,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        setLoading(false);
        setAreYouSure(false);
        setRefresh(refresh ? false : true);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const getUplaodImage = async () => {
    const S3_BUCKET = process.env.REACT_APP_BUCKET;
    const apiKey = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secrectapiKey = process.env.REACT_APP_AWS_SECRET_KEY_ID;
    AWS.config.update({
      accessKeyId: apiKey,
      secretAccessKey: secrectapiKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: process.env.REACT_APP_REGION,
      signatureVersion: "v4",
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: imageId,
    };

    var promise = s3.getSignedUrlPromise("getObject", params);
    // return promise;
    promise.then(
      function (url) {
        if (url) {
          setImage(url);
        }
        return url;
      },
      function (err) {
        console.log(err);
      }
    );
  };
  useEffect(() => {
    if (imageId) {
      getUplaodImage();
    }
  }, [imageId]);
  return (
    <div
      style={{
        height: 300,
        width: "100%",
        display: "flex",
        backgroundColor: BlackAndWhite.secondary,
        borderBottomWidth: 2,
        borderBottomStyle: "solid",
        borderBottomColor: "#00000030",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          flex: 0.75,
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div style={{ height: "100%", width: 300, position: "relative" }}>
          <img
            alt="preview"
            src={image}
            style={{
              backgroundColor: "#00000030",
              height: "100%",
              width: "100%",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              color: BlackAndWhite.secondary,
              fontWeight: "bold",
            }}
          >
            {date}
          </div>
        </div>
        <div
          style={{
            height: "100%",
            position: "relative",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", display: "flex", marginTop: 10 }}>
            <div style={{ fontWeight: "bold", fontSize: 18, marginLeft: 20 }}>
              {messageTitle}
            </div>{" "}
            <div
              style={{
                marginLeft: 20,
                fontSize: 12,
                marginTop: 5,
              }}
            >
              {type}
            </div>
            <div style={{ flex: 1 }} />
          </div>
          <div
            style={{
              width: "100%",
              flex: 1,
              marginTop: 10,
              display: "flex",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 10,
                left: 12,
                fontWeight: "bold",
                fontSize: 14,
                borderRadius: 20,
                backgroundColor: "#FFFFFF50",
              }}
            >
              FULL DESCRIPTION
            </div>

            <div
              style={{
                flex: 0.5,
                height: 250,
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
                overflowX: "hidden",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginTop: 35,
                  width: "90%",
                  textAlign: "left",
                  fontSize: 14,
                }}
              >
                {fullDescription}
              </div>
            </div>
            <div
              style={{
                flex: 0.5,
                height: 250,

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowY: "scroll",
                overflowX: "hidden",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  borderRadius: 20,
                  backgroundColor: "#FFFFFF50",
                  marginTop: 5,
                  width: "90%",
                }}
              >
                QUICK DESCRIPTION
              </div>
              <div
                style={{
                  fontSize: 14,
                  borderRadius: 20,

                  marginTop: 10,
                  width: "90%",
                }}
              >
                {quickMessage}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  borderRadius: 20,
                  backgroundColor: "#FFFFFF50",
                  marginTop: 5,
                  width: "90%",
                }}
              >
                SIGN OFF
              </div>
              <div
                style={{
                  fontSize: 14,
                  borderRadius: 20,

                  marginTop: 10,
                  width: "90%",
                }}
              >
                {signOff}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 0.25,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "flex-start",
          position: "relative",
        }}
      >
        <div style={{ width: "90%", marginTop: 10, fontWeight: "bold" }}>
          TAGS
        </div>
        <div style={{ width: "90%", display: "flex", marginTop: 10 }}>
          {tags}
        </div>
        <div style={{ flex: 1 }}></div>
        <div
          style={{
            width: "90%",
            fontWeight: "bold",
            marginBottom: 5,
            fontSize: 14,
          }}
        >
          CREATED BY
        </div>
        <div
          style={{
            width: "90%",
            // fontWeight: "bold",
            marginBottom: 15,
            fontSize: 14,
          }}
        >
          {createdBy}
        </div>
        <BaseButtons
          label={"DELETE"}
          mini={true}
          marginTopFalse={true}
          width={"90%"}
          size={"99%"}
          onClick={() => setAreYouSure(true)}
        />
        <div
          style={{
            position: "absolute",
            height: areYouSure ? "100%" : 0,
            width: "100%",
            transition: "all 0.3s",
            overflow: "hidden",
            backgroundColor: BlackAndWhite.secondary,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", width: "80%" }}>
            ARE YOU SYRE YOU WANT TO DELETE THIS NOTOCE MESSAGE
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <BaseButtons
              label={"YES"}
              mini={true}
              onClick={() => getAdminMessages()}
            />
            <BaseButtons
              label={"NO"}
              mini={true}
              onClick={() => setAreYouSure(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportMessageView;
