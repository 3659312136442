import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import { SupportModal, SupportTicketItem } from "../dashboard-pos/components";
import {
  BaseButtons,
  TextInputFieldSearch,
} from "../../../../components/atoms";
import { SupportHeader, ViewTicketModal } from "./components";

const SupportDisplay = ({
  user,
  refresh,
  setLoading,

  OnUserSearch,
  customers,
  setCustomers,
  setToastPop,
  setMessage,
  setRefresh,
}) => {
  const [supportModalPopUp, setSupportModalPopUp] = useState(false);

  const [infoPopUp, setInfoPopUp] = useState(false);

  const [selectedTickect, setSelectedTicket] = useState(null);

  const [search, setSearch] = useState("");

  const [supportTicketsArray, setSupportTickectsArray] = useState(null);
  const GetSupportTickets = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/support/getStoreSupportTicket",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
            userId: user.adminUserData.userId,
          }),
        }
      );

      const respnseData = await response.json();

      if (!respnseData.error) {
        // console.log("response dtat", respnseData);
        setSupportTickectsArray(respnseData.data);
        setLoading(false);
        // setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (user) {
      GetSupportTickets();
    }
  }, [refresh]);
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {selectedTickect ? null : (
        <div
          onClick={() => setInfoPopUp(infoPopUp ? false : true)}
          style={{
            cursor: "pointer",
            height: infoPopUp ? 150 : 50,
            width: infoPopUp ? "100%" : 50,
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: infoPopUp ? "relative" : "absolute",
            right: infoPopUp ? null : supportModalPopUp ? 10 : 50,
            top: infoPopUp ? null : -10,
            transition: "all 0.3s",
            zIndex: 200,
            // overflow: "hidden",
          }}
        >
          <div
            style={{
              maxWidth: 800,
              width: "85%",
              backgroundColor: infoPopUp
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary,
              WebkitBoxShadow: "0px 0px 15px 2px #00000050 ",
              height: "90%",
              borderRadius: 8,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: "90%",
                opacity: infoPopUp ? 1 : 0,
                transition: "all 0.3s",
                height: infoPopUp ? "100%" : 0,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                Need some help? Depending on what issue your having a support
                ticket created and sent to the correct department to help you or
                one of our customers... You will need to let us know whuch
                customer it's related to or what part of the system but dont
                worry you will find everything in the customer search and drop
                downs . . .
              </div>
            </div>
            <div
              style={{
                opacity: infoPopUp ? 0 : 1,
                transition: "all 0.5s",
                height: infoPopUp ? 0 : null,
                width: infoPopUp ? 0 : null,
                overflow: "hidden",
                fontSize: 30,
                fontWeight: "bold",
                color: BlackAndWhite.secondary,
              }}
            >
              ?
            </div>
          </div>
        </div>
      )}
      {/* need help */}
      <div
        style={{
          height: 100,
          width: "100%",
          marginTop: infoPopUp ? 20 : 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.3s",
        }}
      >
        {" "}
        <div
          style={{
            width: "85%",
            backgroundColor: BlackAndWhite.secondary,
            WebkitBoxShadow: "0px 0px 15px 2px #00000050 ",
            height: "90%",
            borderRadius: 8,
            justifyContent: "space-evenly",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            textAlign: "center",
          }}
        >
          <img
            alt="icon"
            src={require("../../../../images/material-icons/qrCodeBlack.png")}
            style={{ height: 40, opacity: 0.5 }}
          />
          <div style={{ display: "flex", fontSize: 14 }}>
            Contact us on whatsapp or call
            <div style={{ fontWeight: "bold", marginLeft: 5, fontSize: 14 }}>
              072 226 9694
            </div>{" "}
          </div>
          <div style={{ fontWeight: "bold", fontSize: 14 }}>OR</div>
          <BaseButtons
            label={"CREATE SUPPORT TICKET"}
            marginTopFalse={true}
            mini={true}
            borderRadius={8}
            size={210}
            height={40}
            onClick={() => setSupportModalPopUp(true)}
          />
        </div>
      </div>
      <div
        style={{
          cursor: "pointer",
          height: 500,
          width: "100%",
          marginTop: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          transition: "all 0.3s",
          // zIndex: 200,
          // overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "85%",
            backgroundColor: BlackAndWhite.secondary,
            WebkitBoxShadow: "0px 0px 15px 2px #00000050 ",
            height: "90%",
            borderRadius: 8,
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            overflow: "hidden",
            position: "relative",
            // flexDirection: "column",
            // maxWidth: 800,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              opacity: 0.6,
              fontSize: 18,
              width: "95%",
              textAlign: "left",
              marginTop: 10,
              marginBottom: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            SUPPORT TICKETS STATUS
            <div style={{ flex: 1 }} />
            <div style={{ marginLeft: 10, marginRight: -5 }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  // width: 300,

                  borderWidth: 0.5,
                }}
              >
                <TextInputFieldSearch
                  placeholder={"Type here to search"}
                  height={30}
                  borderRadius={4}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <div
                  style={{
                    height: 35,
                    width: 70,
                    backgroundColor: BlackAndWhite.primary,
                    borderRadius: 4,
                    display: "flex",
                    color: BlackAndWhite.secondary,
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginLeft: 5,
                  }}
                >
                  <div
                    onClick={() => setSearch("")}
                    style={{ fontSize: 12, fontWeight: "bold" }}
                  >
                    CLEAR
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SupportHeader />
          <div
            style={{
              width: "99%",

              // flex: 1,
              display: "flex",
              overflowY: "auto",
              overflowX: "hidden",
              justifyContent: supportTicketsArray
                ? supportTicketsArray.length >= 1
                  ? "flex-start"
                  : "center"
                : "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {supportTicketsArray ? (
              supportTicketsArray.length >= 1 ? (
                supportTicketsArray.map((ticket, index) => {
                  return (
                    <div key={index} style={{ width: "100%" }}>
                      <SupportTicketItem
                        ticket={ticket}
                        onViewClick={() => setSelectedTicket(ticket)}
                      />
                    </div>
                  );
                })
              ) : (
                <div style={{ marginTop: 70 }}>NO SUPPORT TICKETS</div>
              )
            ) : null}
          </div>
        </div>
      </div>
      <SupportModal
        display={supportModalPopUp}
        setSupportModalPopUp={setSupportModalPopUp}
        user={user ? user.adminUserData : null}
        search={search}
        setSearch={setSearch}
        OnUserSearch={OnUserSearch}
        customers={customers}
        setCustomers={setCustomers}
        setMessage={setMessage}
        setToastPop={setToastPop}
        setLoading={setLoading}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <ViewTicketModal
        display={selectedTickect}
        onCloseClick={() => setSelectedTicket(null)}
      />
    </div>
  );
};

export default SupportDisplay;
