import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputField,
} from "../../../../components/atoms";
import {
  ProvinceSelectDropDown,
  StoreDropDown,
  TypeSelectDropDown,
} from "./components";

const StoreSelectDropDown = ({
  display,
  onCloseClick,
  setSelectedType,
  selectedType,
  setFirstName,
  firstName,
  setLastName,
  lastName,
  setCellNumber,
  cellNumber,
  setEmail,
  email,
  setIdNumber,
  idNumber,
  setProvince,
  province,
  setAccountNumber,
  accountNumber,
  setPassword,
  password,
  setConfirmPassword,
  confirmPassword,
  setSelectedStore,
  selectedStore,
  onSubmitClick,
  setPin,
  pin,
}) => {
  const [storeArray, setStoreArray] = useState(null);

  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/facilities/getFacilities")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setStoreArray(resData);
      });
  }, [display]);
  return (
    <div
      style={{
        width: "100%",
        height: display ? "100%" : 0,
        position: "absolute",
        top: 0,
        transition: "all 0.5s",
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        display: "flex",
      }}
    >
      <div style={{ position: "absolute", top: 10, left: 10 }}>
        <CloseButton label={"X"} onClick={onCloseClick} />
      </div>
      <div style={{ position: "absolute", top: 10, right: 10 }}>
        <BaseButtons
          label={"SUBMIT"}
          onClick={onSubmitClick}
          marginTopFalse={true}
          height={40}
          disable={
            selectedType !== "none" &&
            firstName.length >= 2 &&
            lastName.length >= 2 &&
            cellNumber.length === 10 &&
            email.length >= 7 &&
            idNumber.length === 13 &&
            province &&
            accountNumber.length >= 3
              ? false
              : true
          }
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          overflowY: "scroll",
          overflowX: "hidden",
          height: "100%",
          width: "100%",
          marginTop: 40,
        }}
      >
        <div
          style={{
            flex: 0.7,
          }}
        >
          <div
            style={{
              height: 160,
              width: "90%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 30,
              display: "flex",
              marginLeft: 40,
              flexDirection: "column",
              //   justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginBottom: 20,
                }}
              >
                ACCOUNT TYPE
              </div>
            </div>
            <TypeSelectDropDown
              data={[
                "Cashier",
                "Admin",
                "Sub-Admin",
                "Owner",
                "Manager",
                "Floater",
                "Support",
                "Doctor",
              ]}
              setSelectedType={setSelectedType}
              selectedType={selectedType}
            />
          </div>
          <div
            style={{
              height: 400,
              width: "90%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 30,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 40,
            }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginBottom: 30,
                }}
              >
                PRIMARY INFORMATION
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextInputField
                labelTitle={"First Name"}
                mini={true}
                invert={true}
                width={"50%"}
                size={"70%"}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextInputField
                labelTitle={"Last Name"}
                mini={true}
                invert={true}
                width={"50%"}
                size={"70%"}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <TextInputField
                labelTitle={"Cell Number"}
                mini={true}
                invert={true}
                width={"50%"}
                size={"70%"}
                value={cellNumber}
                onChange={(e) => setCellNumber(e.target.value)}
              />
              <TextInputField
                labelTitle={"Email"}
                mini={true}
                invert={true}
                width={"50%"}
                size={"70%"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <TextInputField
                labelTitle={"Id-Number"}
                mini={true}
                invert={true}
                width={"50%"}
                size={"70%"}
                value={idNumber}
                onChange={(e) => setIdNumber(e.target.value)}
              />
              <div style={{ width: "50%" }}>
                <ProvinceSelectDropDown
                  province={province}
                  setProvince={setProvince}
                  data={[
                    "Gauteng",
                    "Free State",
                    "Western Cape",
                    "Eastern Cape",
                    "KwaZulu-Natal",
                    "Limpopo",
                    "Mpumalanga",
                    "Northern Cape",
                    "North West",
                  ]}
                />
              </div>
            </div>
          </div>
          {selectedType === "Owner" || selectedType === "Admin" ? null : (
            <div
              style={{
                height: 200,
                width: "90%",
                WebkitBoxShadow: "0px 0px 10px 5px #00000030",
                marginTop: 30,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 40,
              }}
            >
              <div style={{ width: "100%", display: "flex" }}>
                <div
                  style={{
                    marginTop: 20,
                    marginLeft: 20,
                    fontSize: 18,
                    fontWeight: "bold",
                    color: BlackAndWhite.primary,
                    marginBottom: 30,
                  }}
                >
                  STORE INFORMATION
                </div>
              </div>
              <StoreDropDown
                data={storeArray ? storeArray.store : []}
                setSelectedStore={setSelectedStore}
                selectedStore={selectedStore}
              />
            </div>
          )}
        </div>
        {/* right sub sections */}
        <div
          style={{
            flex: 0.3,

            // display: "flex",
            // flexDirection: "column",
            // height: "100%",
            // justifyContent: "flex-start",
            // alignItems: "center",
          }}
        >
          <div
            style={{
              height:
                selectedType === "Owner" || selectedType === "Admin"
                  ? 585
                  : 920,
              width: "90%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 30,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              transition: "all 0.4s",
            }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginBottom: 30,
                }}
              >
                TERTIARY INFORMATION
              </div>
            </div>
            <TextInputField
              invert={true}
              labelTitle={"Status" + " " + "(Read only field)"}
              mini={true}
              width={"100%"}
              size={"70%"}
              labelWidth={200}
              value={"Pending"}
              readOnly={true}
            />
            <div
              style={{
                width: "80%",
                textAlign: "left",
                fontSize: 12,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              All Accounts will be pending untill first login by new user which
              activates account ...
            </div>
            <TextInputField
              invert={true}
              labelTitle={"Username" + " " + "(Read only field)"}
              readOnly={true}
              mini={true}
              value={email}
              width={"100%"}
              size={"70%"}
              labelWidth={200}
            />
            <TextInputField
              invert={true}
              labelTitle={"Account number"}
              mini={true}
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              width={"100%"}
              size={"70%"}
              labelWidth={200}
            />
            <TextInputField
              invert={true}
              labelTitle={"Pin"}
              mini={true}
              width={"100%"}
              size={"70%"}
              labelWidth={200}
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
            <TextInputField
              invert={true}
              labelTitle={"Password"}
              mini={true}
              width={"100%"}
              size={"70%"}
              labelWidth={200}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextInputField
              invert={true}
              labelTitle={"confirm Password"}
              mini={true}
              width={"100%"}
              size={"70%"}
              labelWidth={200}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div style={{ padding: 50 }} />
        </div>
      </div>
    </div>
  );
};

export default StoreSelectDropDown;
