import React, { useState } from "react";
import { BlackAndWhite } from "../../../themes/Colors";

const MenuButton = ({ width, label, onClick, marginTop }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={onClick}
      style={{
        height: 50,
        width: width,
        backgroundColor: hover ? BlackAndWhite.secondary : null,
        marginTop: marginTop,
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all .3s",
        borderStyle: hover ? "solid" : null,
        flexDirection: "column",
      }}
    >
      <div
        style={{
          backgroundColor: hover
            ? BlackAndWhite.primary
            : BlackAndWhite.secondary,
          height: 4,
          width: 30,
          borderRadius: 10,
        }}
      />
      <div
        style={{
          backgroundColor: hover
            ? BlackAndWhite.primary
            : BlackAndWhite.secondary,
          height: 4,
          width: 30,
          borderRadius: 10,
          marginTop: 5,
        }}
      />
      <div
        style={{
          backgroundColor: hover
            ? BlackAndWhite.primary
            : BlackAndWhite.secondary,
          height: 4,
          width: 30,
          borderRadius: 10,
          marginTop: 5,
        }}
      />
    </div>
  );
};

export default MenuButton;
