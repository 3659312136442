import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const FacilityCard = ({ location, onClick }) => {
  const [hover, setHover] = useState(false);
  const [locationHover, setLocationHover] = useState(false);
  return (
    <div
      onClick={locationHover ? null : onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        backgroundColor: BlackAndWhite.secondary,
        height: 200,
        width: 350,
        borderRadius: 10,
        margin: 10,
        display: "flex",
        flexDirection: "column",
        transition: "all 0.3s",
        borderStyle: "solid",
        borderWidth: hover ? 3 : 0,
        borderColor: "#FAA919",
      }}
    >
      <div
        style={{
          fontSize: 16,
          paddingTop: 10,
          marginLeft: 15,
          fontWeight: "bold",
        }}
      >
        {location.storeName}
      </div>
      <div style={{ display: "flex", flex: 1 }}>
        <div
          style={{
            display: "flex",
            flex: 0.5,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <a href={location.locationLink} target="_blank">
            <img
              onMouseOver={() => setLocationHover(true)}
              onMouseOut={() => setLocationHover(false)}
              alt="location link"
              src={require("../../../../images/paarlLocation.png")}
              style={{
                height: locationHover ? 150 : 140,
                width: locationHover ? 150 : 140,
                borderRadius: 8,
                transition: "all 0.3s",
              }}
            />
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flex: 0.5,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "left",
              fontSize: 13,
              color: "#00000090",
              marginBottom: 5,
            }}
          >
            ADDRESS:{" "}
          </div>
          <div
            style={{
              fontSize: 13,
              width: "100%",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            {location.type}
          </div>
          <div
            style={{
              fontSize: 12,
              width: "100%",
              textAlign: "left",
            }}
          >
            {location.unitNumber + " " + location.streetAddress}
          </div>
          <div
            style={{
              fontSize: 12,
              width: "100%",
              textAlign: "left",
            }}
          >
            {location.suburb}
          </div>
          <div
            style={{
              width: "100%",
              textAlign: "left",
              fontSize: 13,
              color: "#00000090",
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            CONTACT:{" "}
          </div>
          <div
            style={{
              fontSize: 12,
              width: "100%",
              textAlign: "left",
            }}
          >
            {location.cellNumber}
          </div>
          <div
            style={{
              fontSize: 12,
              width: "100%",
              textAlign: "left",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                width: "80%",
                textAlign: "left",
              }}
            >
              {location.email}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: -15,
              right: 10,
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            {location.status}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilityCard;
