import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { BaseButtons } from "../../../../../../components/atoms";

const ImageSavePopUp = ({ display, image, onSubmitClick, onCancelClick }) => {
  return (
    <div
      style={{
        position: "absolute",
        height: display ? 300 : 0,
        width: 300,
        backgroundColor: BlackAndWhite.secondary,
        // borderStyle: display ? "solid" : "none",
        bottom: 0,
        overflow: "hidden",
        transition: "all 0.3s",
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img alt="Preview" src={image} style={{ height: "100%" }} />
      <div
        style={{
          position: "absolute",
          bottom: 0,
          display: "flex",
          width: "100%",
        }}
      >
        <BaseButtons
          mini={true}
          marginTop={-5}
          label={"CANCEL"}
          onClick={onCancelClick}
        />
        <BaseButtons
          mini={true}
          marginTop={-5}
          label={"SAVE"}
          onClick={onSubmitClick}
        />
      </div>
    </div>
  );
};

export default ImageSavePopUp;
