import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import TabCategoryOptions from "../../../../../admin-portal/components/product-uploader/components/tab-category-options/TabCategoryOptions";
import { LoadingPopUp } from "../../../../../../components";
import ProductCard from "../../../../../admin-portal/components/product-uploader/components/ProductCard";
import SubCategoryCard from "../../../../../admin-portal/components/product-uploader/components/SubCategoryCard";
import { BaseButtons } from "../../../../../../components/atoms";
import ProductInfoPopUp from "../../../../../admin-portal/components/product-uploader/components/product-info-pop-up/ProductInfoPopUp";
import AddProductPopUp from "../add-product-pop-up/AddProductPopUp";

const Store = () => {
  const [refresh, setRefresh] = useState(false);

  const [displayEditPopUp, setDisplayEditPopUp] = useState(false);

  const [allProducts, setAllProducts] = useState(null);

  const [sectionDisplay, setSectionDisplay] = useState("cat");
  const [category, setCategory] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [subCategoryArray, setSubCategoryArray] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [subCategoryProductsArray, setSubCategoryProductsArray] =
    useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [message, setMessage] = useState(null);
  const [toastPop, setToastPop] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedAdd, setSelectedAdd] = useState(null);
  const [addProductPop, setAddProductPop] = useState(null);

  const [cartList, setCartList] = useState([]);

  const cart = [];

  const updateSubCategorydisplay = async ({ info }) => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getSubCatergoriesId",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId: info.categoryId,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.subCategory) {
        // const wait = await updateListFetch();
        setCategorySelected(info.categoryId);
        setSubCategoryArray(respnseData.subCategory);
        setLoading(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  const updateProductDisplay = async ({ info }) => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsBySubCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subCategoryId: info.subCategoryId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setSubCategoryProductsArray(respnseData.product);
        setLoading(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/productUpload/getCatergories")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setCategory(resData);
      });
  }, [refresh]);
  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/productUpload/getAllProducts")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setAllProducts(resData.product);
      });
  }, [refresh]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#FFFFFF50",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        //   justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          height: 55,
          width: "100%",
          backgroundColor: BlackAndWhite.primary,
          marginTop: 2,
          display: "flex",
          overflow: "scroll",
        }}
      >
        {subCategoryProductsArray ? (
          <div
            style={{
              marginLeft: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BaseButtons
              label={"BACK"}
              onClick={() => setSubCategoryProductsArray(null)}
              height={35}
              size={100}
              invert={true}
              marginTopFalse={true}
            />
          </div>
        ) : null}
        {subCategoryProductsArray ? null : (
          <div
            style={{
              marginLeft: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TabCategoryOptions
              title={"All products"}
              onClick={() => {
                setSubCategoryArray(null);
                setCategorySelected(null);
              }}
              selected={categorySelected ? false : true}
            />
          </div>
        )}
        {subCategoryProductsArray ? null : category ? (
          category.categories.map((info, index) => {
            return (
              <div
                key={index}
                style={{
                  marginLeft: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TabCategoryOptions
                  title={info.categoryName}
                  onClick={() => updateSubCategorydisplay({ info })}
                  selected={
                    categorySelected
                      ? categorySelected === info.categoryId
                        ? true
                        : false
                      : null
                  }
                />
              </div>
            );
          })
        ) : (
          <div>Loading . . .</div>
        )}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          backgroundColor: "#00000033",
          position: "relative",
          overflow: "scroll",
        }}
      >
        {subCategoryProductsArray ? (
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {subCategoryProductsArray
              ? subCategoryProductsArray.map((info, index) => (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ProductCard
                      title={info.productName}
                      // onViewProductClick={() => setSelectedProduct(info)}
                      retail={info.retailPrice}
                      thcContent={info.thcContent}
                      onMoreClick={() => setSelectedProduct(info)}
                      onClick={() => setAddProductPop(true)}
                      // width={"80%"}
                    />
                  </div>
                ))
              : null}
          </div>
        ) : (
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {subCategoryArray
              ? subCategoryArray.map((info, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <SubCategoryCard
                      title={info.subCategoryName}
                      onClick={() => {
                        updateProductDisplay({ info });
                        setSelectedSubCategory(info);
                      }}
                      description={info.description}
                      width={"100%"}
                    />
                  </div>
                ))
              : allProducts
              ? allProducts.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ProductCard
                      title={item.productName}
                      retail={item.retailPrice}
                      // onViewProductClick={() => setSelectedProduct(item)}
                      thcContent={item.thcContent}
                      onMoreClick={() => setSelectedProduct(item)}
                      onClick={() => setAddProductPop(true)}
                      // width={"80%"}
                    />
                  </div>
                ))
              : null}
          </div>
        )}
      </div>
      <LoadingPopUp display={loading} />
      <ProductInfoPopUp
        display={selectedProduct ? true : false}
        onCloseClick={() => setSelectedProduct(null)}
        selectedProduct={selectedProduct}
        mobile={true}
        onAddClick={() => setAddProductPop(true)}
      />
      <AddProductPopUp
        display={addProductPop}
        onCloseClick={() => setAddProductPop(false)}
      />
    </div>
  );
};

export default Store;
