import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const BaseStatBlock = ({ value, label }) => {
  return (
    <div
      style={{
        height: "80%",
        width: 120,
        display: "flex",
        flexDirection: "column",
        borderRadius: 8,
        borderWidth: 0.5,
        // marginRight: 5,
      }}
    >
      <div
        style={{
          fontSize: 24,
          fontWeight: "bold",
          height: 60,
          width: "80%",
          borderRadius: 8,
          backgroundColor: BlackAndWhite.primary,
          color: BlackAndWhite.secondary,
          marginBottom: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {value ? value : null}
      </div>
      <div
        style={{
          fontSize: 12,
          //   marginTop: 5,
          textAlign: "center",
          width: "80%",
          height: 40,
        }}
      >
        {label ? label : null}
      </div>
    </div>
  );
};

export default BaseStatBlock;
