import React, { useEffect, useState } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";
import CategoryCard from "./CategoryCard";
import { BaseButtons } from "../../../../../components/atoms";
import SubCategoryCard from "./SubCategoryCard";
import AddPopUp from "./add-pop-ups/AddPopUp";
import { LoadingPopUp, ToastNotification } from "../../../../../components";
import ProductCard from "./ProductCard";
import EditPopUp from "./edit-pop-up/EditPopUp";

const Categories = () => {
  const checkIcon = (check) => {
    if (check === "flowers1") {
      return {
        imageIcon: require("../../../../../images/product-icons/flowerBlack.png"),
        imageIcon2: require("../../../../../images/product-icons/flowerWhite2.png"),
      };
    } else {
      return {
        imageIcon: require("../../../../../images/product-icons/otherProdyctsBlack2.png"),
        imageIcon2: require("../../../../../images/product-icons/otherProductsWhite2.png"),
      };
    }
  };

  const [refresh, setRefresh] = useState(false);

  const [displayEditPopUp, setDisplayEditPopUp] = useState(false);

  const [sectionDisplay, setSectionDisplay] = useState("cat");
  const [category, setCategory] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [subCategoryArray, setSubCategoryArray] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [subCategoryProductsArray, setSubCategoryProductsArray] =
    useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [message, setMessage] = useState(null);
  const [toastPop, setToastPop] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedAdd, setSelectedAdd] = useState(null);

  const updateSubCategorydisplay = async ({ info }) => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getSubCatergoriesId",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId: info.categoryId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.subCategory) {
        // const wait = await updateListFetch();
        setSubCategoryArray(respnseData.subCategory);
        setSectionDisplay("catDisplay");
        setLoading(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  const updateProductDisplay = async ({ info }) => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsBySubCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subCategoryId: info.subCategoryId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setSubCategoryProductsArray(respnseData.product);
        setSectionDisplay("product");
        setLoading(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/productUpload/getCatergories")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setCategory(resData);
      });
  }, [refresh]);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  return (
    <>
      {/* category display */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          height: sectionDisplay === "cat" ? "100%" : 0,
          overflow: "hidden",
          transition: "all 0.3s",
          position: "absolute",
        }}
      >
        <div
          style={{
            height: 80,
            width: "100%",
            backgroundColor: BlackAndWhite.secondary,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginLeft: 20,
              fontSize: 28,
              fontWeight: "bold",
              color: BlackAndWhite.primary,
            }}
          >
            Product Categories
          </div>
          <div
            style={{
              flex: 1,
            }}
          ></div>
          <div
            style={{
              marginRight: 20,
              margintop: 0,
              marginBottom: 17,
            }}
          >
            <BaseButtons
              label={"+ ADD CATEGORY"}
              onClick={() => setSelectedAdd("category")}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {category
            ? category.categories.map((info, index) => {
                return (
                  <div key={index}>
                    <CategoryCard
                      title={info ? info.categoryName : null}
                      description={info ? info.description : null}
                      imageIcon={checkIcon(info.imageIcon).imageIcon}
                      imageIcon2={checkIcon(info.imageIcon).imageIcon2}
                      onClick={() => {
                        setCategorySelected(info);
                        updateSubCategorydisplay({ info });
                      }}
                      hasSubCategory={
                        info.hasSubCategory === "true" ? "YES" : "NOT YET"
                      }
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {/* sub category display */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: sectionDisplay === "catDisplay" ? "100%" : 0,
          overflow: "scroll",
          transition: "all 0.3s",
          position: "absolute",
          backgroundColor: "#FFFFFF66",
        }}
      >
        <div
          style={{
            height: 80,
            width: "100%",
            backgroundColor: BlackAndWhite.secondary,
            display: "flex",
            alignItems: "center",
            position:
              sectionDisplay === "catDisplay"
                ? toastPop
                  ? "absolute"
                  : "absolute"
                : "absolute",
            top: 0,
            right: 0,
            zIndex:
              selectedAdd === "subCategory" ? (toastPop ? null : 0) : 200000,
          }}
        >
          <div
            style={{
              marginLeft: 20,
              margintop: 0,
              marginBottom: 17,
            }}
          >
            <BaseButtons
              label={"BACK"}
              onClick={() => {
                setSectionDisplay("cat");
                setSelectedSubCategory(null);
              }}
              size={100}
            />
          </div>
          <div
            style={{
              marginLeft: 20,
              fontSize: 28,
              fontWeight: "bold",
              color: BlackAndWhite.primary,
            }}
          >
            {categorySelected ? categorySelected.categoryName : "loading"}{" "}
            Sub-Categories
          </div>
          <div
            style={{
              flex: 1,
            }}
          ></div>
          <div
            style={{
              marginRight: 20,
              margintop: 0,
              marginBottom: 17,
            }}
          >
            <BaseButtons
              label={"+ ADD SUB-CATEGORY"}
              size={250}
              onClick={() => setSelectedAdd("subCategory")}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            // backgroundColor: "#FFFFFF66",
            // flexDirection: "column",
            height: "100%",
            marginTop: category
              ? category.categories.length > 8
                ? 140
                : 80
              : 80,
          }}
        >
          {subCategoryArray
            ? subCategoryArray.map((info, index) => {
                return (
                  <div onClick={() => setSectionDisplay("product")} key={index}>
                    <SubCategoryCard
                      title={info ? info.subCategoryName : "loading"}
                      onClick={() => {
                        setSelectedSubCategory(info);
                        updateProductDisplay({ info });
                      }}
                      description={info ? info.description : "loading"}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {/* prudcts for sub-sections */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          height: sectionDisplay === "product" ? "100%" : 0,
          overflow: "scroll",
          transition: "all 0.3s",
          position: "absolute",
        }}
      >
        <div
          style={{
            height: 80,
            width: "100%",
            backgroundColor: BlackAndWhite.secondary,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginLeft: 20,
              margintop: 0,
              marginBottom: 17,
            }}
          >
            <BaseButtons
              label={"BACK"}
              onClick={() => {
                setSectionDisplay("catDisplay");
              }}
              size={100}
            />
          </div>
          <div
            style={{
              marginLeft: 20,
              fontSize: 28,
              fontWeight: "bold",
              color: BlackAndWhite.primary,
            }}
          >
            {selectedSubCategory
              ? selectedSubCategory.subCategoryName
              : "loading"}{" "}
            PRODUCTS
          </div>
          <div
            style={{
              flex: 1,
            }}
          ></div>
          <div
            style={{
              marginRight: 20,
              margintop: 0,
              marginBottom: 17,
            }}
          >
            <BaseButtons
              label={"+ ADD PRODUCT"}
              size={250}
              onClick={() => setSelectedAdd("product")}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            backgroundColor: "#FFFFFF66",
            // flexDirection: "column",
            height: "100%",
          }}
        >
          {subCategoryProductsArray
            ? subCategoryProductsArray.map((info, index) => {
                return (
                  <div key={index}>
                    <ProductCard
                      title={info ? info.productName : "loading"}
                      strain={info ? info.strain : "loading"}
                      thcContent={info ? info.thcContent : "loading"}
                      productType={info ? info.productType : "loading"}
                      growType={info ? info.growType : "loading"}
                      cost={info ? info.costPrice : "loading"}
                      retail={info ? info.retailPrice : "loading"}
                      description={info ? info.createdOn : "loading"}
                      onClick={() => {
                        setSectionDisplay("productSingle");
                        setSelectedProduct(info);
                      }}
                      isAdmin={true}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {/* single productDisplay */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          height: sectionDisplay === "productSingle" ? "100%" : 0,
          overflow: "hidden",
          transition: "all 0.3s",
          position: "absolute",
          backgroundColor: BlackAndWhite.secondary,
        }}
      >
        <div
          style={{
            height: 80,
            width: "100%",
            backgroundColor: BlackAndWhite.secondary,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginLeft: 20,
              margintop: 0,
              marginBottom: 17,
            }}
          >
            <BaseButtons
              label={"BACK"}
              onClick={() => {
                setSectionDisplay("product");
                setSelectedProduct(null);
              }}
              size={100}
            />
          </div>
          <div
            style={{
              marginLeft: 20,
              fontSize: 28,
              fontWeight: "bold",
              color: BlackAndWhite.primary,
            }}
          >
            {selectedProduct ? selectedProduct.productName : "loading"}
          </div>
          <div
            style={{
              flex: 1,
            }}
          ></div>
          <div
            style={{
              marginRight: 20,
              margintop: 0,
              marginBottom: 17,
            }}
          >
            <BaseButtons
              label={"Edit Product"}
              size={250}
              onClick={() => setDisplayEditPopUp(true)}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            // flexWrap: "wrap",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFFFFF66",
            flexDirection: "row",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              flex: 0.5,
              height: "100%",
            }}
          >
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 20,
                fontSize: 16,
              }}
            >
              Product Name:
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 5,
                fontSize: 20,
              }}
            >
              {selectedProduct ? selectedProduct.productName : "loading"}
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 20,
                fontSize: 16,
              }}
            >
              Strain:
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 5,
                fontSize: 20,
              }}
            >
              {selectedProduct ? selectedProduct.strain : "loading"}
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 20,
                fontSize: 16,
              }}
            >
              Grow type:
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 5,
                fontSize: 20,
              }}
            >
              {selectedProduct ? selectedProduct.growType : "loading"}
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 20,
                fontSize: 16,
              }}
            >
              THC Content:
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 5,
                fontSize: 20,
              }}
            >
              {selectedProduct ? selectedProduct.thcContent : "loading"}
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 20,
                fontSize: 16,
              }}
            >
              Product type:
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 5,
                fontSize: 20,
              }}
            >
              {selectedProduct ? selectedProduct.productType : "loading"}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              flex: 0.5,

              height: "100%",
            }}
          >
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 20,
                fontSize: 16,
              }}
            >
              description
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 5,
                fontSize: 20,
              }}
            >
              {selectedProduct ? selectedProduct.description : "loading"}
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 20,
                fontSize: 16,
              }}
            >
              Menu description
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 5,
                fontSize: 20,
              }}
            >
              {selectedProduct ? selectedProduct.menuDescription : "loading"}
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 20,
                fontSize: 16,
              }}
            >
              use description
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 5,
                fontSize: 20,
              }}
            >
              {selectedProduct ? selectedProduct.useDescription : "loading"}
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 20,
                fontSize: 16,
              }}
            >
              cost price:
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 5,
                fontSize: 20,
              }}
            >
              {selectedProduct ? selectedProduct.costPrice : "loading"}
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 20,
                fontSize: 16,
              }}
            >
              Retail price
            </div>
            <div
              style={{
                width: "80%",
                textAlign: "left",
                marginTop: 5,
                fontSize: 20,
              }}
            >
              {selectedProduct ? selectedProduct.retailPrice : "loading"}
            </div>
          </div>
        </div>
      </div>
      <AddPopUp
        setSectionDisplay={setSectionDisplay}
        display={selectedAdd}
        onCloseClick={() => setSelectedAdd(null)}
        setToastPop={setToastPop}
        setSelectedAdd={setSelectedAdd}
        setMessage={setMessage}
        categorySelected={categorySelected}
        setCategorySelected={setCategorySelected}
        selectedSubCategory={selectedSubCategory}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <ToastNotification message={message} display={toastPop} />
      <LoadingPopUp display={loading} />
      <EditPopUp
        display={displayEditPopUp}
        onCloseClick={() => setDisplayEditPopUp(false)}
        setMessage={setMessage}
        setToastPop={setToastPop}
        selectedProduct={selectedProduct}
        setLoading={setLoading}
        setDisplayEditPopUp={setDisplayEditPopUp}
        setSectionDisplay={setSectionDisplay}
      />
    </>
  );
};
export default Categories;
