import React from "react";

const DisplayImage = ({ display, image }) => {
  return (
    <div
      style={{
        width: display ? "100%" : 0,
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.35s",
        height: "100%",
        opacity: display ? 1 : 0,
        minHeight: 350,
      }}
    >
      <img
        alt="icon"
        src={image}
        style={{ height: display ? 350 : 0, transition: "all 0.3s" }}
      />
    </div>
  );
};

export default DisplayImage;
