import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { BaseButtons, CloseButton } from "../../../../../../components/atoms";
import { ProductDisplayItem, RefundOrExcahnge } from "./components";

const dummyData = [{ item: "1" }, { item: "2" }, { item: "3" }, { item: "4" }];

const TransactionDisplay = ({
  display,
  setViewTransactionDisplay,
  selectedTransactionDisplay,
  user,
  setLoading,
  setMessage,
  setToastPop,
  storeInfo,
  receiptOptionView,
}) => {
  const [type, setType] = useState("TILL");
  const [productsArray, setProductsArray] = useState(null);

  const [title, setTitle] = useState(null);
  const [refundPopUp, setRefundPopUp] = useState(null);
  const [exchangePopUp, setExchangePopUp] = useState(null);

  const [exchangeRefundArray, setExchangeRefundArray] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;
      return name;
    }
  };
  useEffect(() => {
    if (selectedTransactionDisplay) {
      setProductsArray(selectedTransactionDisplay.productsArray);
      setExchangeRefundArray(selectedTransactionDisplay.productsArray);
    }
  }, [display, selectedTransactionDisplay, refresh]);
  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        position: "absolute",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 20,
          left: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        <CloseButton
          label={"X"}
          onClick={() => setViewTransactionDisplay(false)}
        />
        <div
          style={{
            fontWeight: "bold",
            fontSize: 20,
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {type} RECIEPT
        </div>
        <div>
          SLIP NO:{" "}
          {selectedTransactionDisplay
            ? receiptOptionView === "refunds" ||
              receiptOptionView === "exchanges"
              ? selectedTransactionDisplay.slipNoLinkedTo
              : selectedTransactionDisplay.slipNo
            : "loading"}
        </div>
      </div>
      {receiptOptionView === "refunds" || receiptOptionView === "exchanges" ? (
        <div
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            display: "flex",
            alignItems: "center",
          }}
        >
          <BaseButtons
            mini={true}
            marginTopFalse={true}
            label={"DOWNLOAD"}
            borderRadius={8}
            height={40}
            size={120}
            marginRight={10}
          />
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            display: "flex",
            alignItems: "center",
          }}
        >
          <BaseButtons
            mini={true}
            marginTopFalse={true}
            label={"DOWNLOAD"}
            borderRadius={8}
            height={40}
            size={120}
            marginRight={10}
          />
          <BaseButtons
            mini={true}
            marginTopFalse={true}
            label={"EXCHANGE"}
            borderRadius={8}
            height={40}
            size={120}
            onClick={() => setExchangePopUp(true)}
          />
          <BaseButtons
            mini={true}
            marginTopFalse={true}
            label={"REFUND"}
            borderRadius={8}
            height={40}
            marginLeft={10}
            size={120}
            onClick={() => setRefundPopUp(true)}
          />
        </div>
      )}
      <div
        style={{
          flex: 1,
          height: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            height: 150,
            width: "99%",
            marginTop: 80,
            display: "flex",
          }}
        >
          <div
            style={{
              flex: 0.5,

              height: "100%",
              display: "flex",
              flexDirection: "column",

              justifyContent: "flex-start",
              alignItems: "flex-start",
              position: "relative",
            }}
          >
            {/* <div style={{ marginLeft: 20, fontSize: 16, fontWeight: "bold" }}>
              CASHIER INFO:
            </div> */}
            <div
              style={{
                marginLeft: 20,
                fontSize: 12,

                marginTop: 4,
                marginBottom: 1,
                fontWeight: "bold",
                opacity: 0.6,
              }}
            >
              STORE
            </div>
            <div
              style={{
                marginLeft: 20,
                fontSize: 16,
                // fontWeight: "bold",
              }}
            >
              {selectedTransactionDisplay
                ? selectedTransactionDisplay.storeName
                : "loading"}
            </div>
            <div
              style={{
                marginLeft: 20,
                fontSize: 12,

                marginTop: 5,
                marginBottom: 1,
                fontWeight: "bold",
                opacity: 0.6,
              }}
            >
              CASHIER
            </div>
            <div
              style={{
                marginLeft: 20,
                fontSize: 16,
                // fontWeight: "bold",
              }}
            >
              {selectedTransactionDisplay
                ? selectedTransactionDisplay.name
                : "loading"}
            </div>

            {/* <div
              style={{
                marginLeft: 20,
                fontSize: 14,
                fontWeight: "normal",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              Address
            </div> */}
            <div>
              <div
                style={{
                  marginLeft: 20,
                  fontSize: 12,

                  marginTop: 5,
                  marginBottom: 1,
                  fontWeight: "bold",
                  opacity: 0.6,
                }}
              >
                CONTACT NO.
              </div>
              <div
                style={{
                  marginLeft: 20,
                  fontSize: 16,
                  // fontWeight: "bold",
                }}
              >
                {selectedTransactionDisplay
                  ? selectedTransactionDisplay.contactNumber
                  : "loading"}
              </div>
              {/* <div>Vat no.</div> */}
            </div>
          </div>
          <div
            style={{
              flex: 0.5,

              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                // marginLeft: 20,
                fontSize: 12,
                marginTop: 5,
                marginBottom: 1,
                fontWeight: "bold",
                opacity: 0.6,
              }}
            >
              CUSTOMER NAME
            </div>
            <div
              style={{
                // marginLeft: 20,
                fontSize: 16,
                // fontWeight: "bold",
              }}
            >
              {selectedTransactionDisplay
                ? selectedTransactionDisplay.customerName
                : "loading"}
            </div>
            <div
              style={{
                // marginLeft: 20,
                fontSize: 12,
                marginTop: 5,
                marginBottom: 1,
                fontWeight: "bold",
                opacity: 0.6,
              }}
            >
              CUSTOMER ID
            </div>
            <div
              style={{
                // marginLeft: 20,
                fontSize: 16,
                // fontWeight: "bold",
              }}
            >
              {selectedTransactionDisplay
                ? selectedTransactionDisplay.customerUserId
                : "loading"}
            </div>
            <div
              style={{
                // marginLeft: 20,
                fontSize: 12,
                marginTop: 5,
                marginBottom: 1,
                fontWeight: "bold",
                opacity: 0.6,
              }}
            >
              DATE & TIME CREATED
            </div>
            <div
              style={{
                // marginLeft: 20,
                fontSize: 16,
                // fontWeight: "bold",
              }}
            >
              {selectedTransactionDisplay
                ? selectedTransactionDisplay.dateTime
                : "loading"}
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              minHeight: 5,
              width: "95%",
              backgroundColor: BlackAndWhite.primary,
              borderRadius: 7,
              marginRight: 25,
              marginLeft: 25,
              marginTop: 20,
            }}
          />
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <div style={{ marginLeft: 25 }}>
              PRODUCT QTY : {productsArray ? productsArray.length : 0}
            </div>{" "}
            <div style={{ flex: 1 }} />{" "}
            {receiptOptionView === "refunds" ? null : (
              <div
                style={{ marginRight: 25, fontWeight: "bold", marginLeft: 20 }}
              >
                DISCOUNT: R{" "}
                {selectedTransactionDisplay
                  ? Number(selectedTransactionDisplay.discount).toFixed(2)
                  : "loading"}
              </div>
            )}
            <div style={{ marginRight: 25, fontWeight: "bold" }}>
              TOTAL: R{" "}
              {selectedTransactionDisplay
                ? receiptOptionView === "refunds"
                  ? Number(selectedTransactionDisplay.refundedTotal).toFixed(2)
                  : Number(selectedTransactionDisplay.total).toFixed(2)
                : "loading"}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              width: "99%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            {productsArray
              ? productsArray.map((product, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom:
                          index === productsArray.length - 1 ? 80 : 20,
                        marginTop: index === 0 ? 20 : null,
                      }}
                    >
                      <ProductDisplayItem
                        product={product}
                        NameAssemble={NameAssemble}
                      />
                    </div>
                  );
                })
              : "loading"}
          </div>
        </div>
      </div>
      <RefundOrExcahnge
        user={user}
        selectedTransactionDisplay={selectedTransactionDisplay}
        title={"REFUND"}
        exchangeRefundArray={exchangeRefundArray}
        setExchangeRefundArray={setExchangeRefundArray}
        display={refundPopUp}
        onCloseClick={() => {
          setRefundPopUp(false);
        }}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
        setCloseUp={setRefundPopUp}
        storeInfo={storeInfo}
      />
      <RefundOrExcahnge
        user={user}
        selectedTransactionDisplay={selectedTransactionDisplay}
        setExchangeRefundArray={setExchangeRefundArray}
        exchangeRefundArray={exchangeRefundArray}
        title={"EXCHANGE"}
        display={exchangePopUp}
        onCloseClick={() => {
          setExchangePopUp(false);
        }}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
        setCloseUp={setExchangePopUp}
        storeInfo={storeInfo}
      />
    </div>
  );
};

export default TransactionDisplay;
