import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const OwnerDisplayCard = ({ firstName, width, height, lastName, onClick }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={onClick}
      style={{
        borderStyle: "solid",
        width: width,
        height: height,
        borderRadius: 4,
        marginTop: 10,
        backgroundColor: hover ? "#faa91966" : BlackAndWhite.secondary,
        color: hover ? BlackAndWhite.secondary : BlackAndWhite.primary,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        fontSize: 20,
      }}
    >
      <div style={{ marginLeft: 10 }}>{firstName}</div>
      <div style={{ width: 5 }} />
      <div>{lastName}</div>
    </div>
  );
};

export default OwnerDisplayCard;
