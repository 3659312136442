import React, { useState, useEffect, useContext } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import {
  BaseButtons,
  SectionTitle,
  TextAreaInput,
  TextInputField,
  TextInputFieldSearch,
} from "../../components/atoms";
import { AdminContext } from "../../context/admin-context";
import { useNavigate } from "react-router-dom";
import AdminCustomerTab from "../admin-portal/components/AdminCustomerTab";
import QuestionButton from "../customer-portal/components/medical-history/components/button/QuestionButton";
import {
  CannaMenuButton,
  CashRegister,
  DashBoardPos,
  InventoryPos,
  KnowledgeBasePanel,
  PatientDisplay,
  ProcessSale,
  ProfileMenu,
  RegisterPanel,
  SalesHistory,
  SupportDisplay,
} from "./components";
import PopUp from "./components/pop-up/PopUp";
import { LoadingPopUp, ToastNotification } from "../../components";
import PatientProfilePopUp from "./components/patient-display/PatientProfilePopUp";
import { HeadersPatients } from "./components/patient-display/components";
import SearchBar from "./components/patient-display/SearchBar";
import { ProductKnowledgeDisplayModal } from "./components/knowledge-base-panel/components";

// import { useNavigate } from "react-router-dom";

const CannaPosAccess = () => {
  const [patientProfilePop, setPatientProfilePop] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const { width, height } = useWindowDimensions();
  const [passwordPop, setPasswordPop] = useState(true);
  const [tab, setTab] = useState("dashboard");
  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [storeInfo, setStoreInfo] = useState(null);

  const [refreshHover, setRefreshHover] = useState(false);

  const [loading, setLoading] = useState(false);

  const [shrink, setShrink] = useState(false);

  const [onMenu, setOnMenu] = useState(false);

  const [searchBy, setSearchBy] = useState("ID NUMBER");

  //add product info
  // const [urlPath, setUrlPath] = useState("");
  const admin = useContext(AdminContext);
  const navigate = useNavigate();

  const [selectedProductDisplay, setSelectedProductDisplay] = useState(null);

  const [search, setSearch] = useState("");

  const [customers, setCustomers] = useState(null);

  const [cashRegisterOpen, setCashRegisterOpen] = useState(false);

  const LogOut = () => {
    admin.logout();
    admin.adminUserData(null);
    navigate("/adminPortal");
  };

  const OnUserSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/searchForUserPos",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchParameter: search,
            userId: admin.adminUserData.userId,
          }),
        }
      );

      const responseData = await response.json();

      setLoading(false);
      if (responseData.success) {
        setCustomers(responseData.data);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  const GetStoreInfo = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/fetchStoreInfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: admin.adminUserData.storeName,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setStoreInfo(responseData.store);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  useEffect(() => {
    console.log("cash register check");
  }, [tab]);
  useEffect(() => {
    if (!admin.isloggedIn || admin.adminUserData.type === "Doc") {
      // console.log("path check", admin.adminUserData.type);
      navigate("/adminPortal");
    } else if (!admin.isloggedIn || admin.adminUserData.type === "Customer") {
      navigate("/");
    } else if (!admin.isloggedIn || admin.adminUserData.type === "Support") {
      navigate("/adminPortal");
    }
  }, []);
  useEffect(() => {
    GetStoreInfo();
  }, []);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: BlackAndWhite.secondary,
        position: "relative",
      }}
    >
      <div
        style={{
          fontSize: 30,
          fontWeight: "700",
          color: BlackAndWhite.secondary,
          position: "fixed",
          width: "100%",
          backgroundColor: BlackAndWhite.primary,
          top: 0,
          height: 60,
          display: "flex",
          alignItems: "center",
          zIndex: 2000,
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
        }}
      >
        <div
          onClick={() => setShrink(shrink ? false : true)}
          style={{
            marginLeft: shrink ? 15 : 5,
            backgroundColor: shrink ? "#FFFFFFcc" : BlackAndWhite.secondary,
            height: shrink ? 95 : 135,
            width: shrink ? 95 : 202.5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: shrink ? 200 : 20,
            position: "absolute",
            top: shrink ? 10 : 5,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            transition: "all 0.3s",
            // borderStyle: "solid",
            // borderColor: BlackAndWhite.primary,
            // borderWidth: 5,
          }}
        >
          <div
            onClick={() => setShrink(shrink ? false : true)}
            style={{
              // marginLeft: shrink ? 5 : 5,
              backgroundColor: shrink
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary,
              height: shrink ? 95 : 135,
              width: shrink ? 95 : 202.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: shrink ? 200 : 8,
              // position: "absolute",
              // top: shrink ? 10 : 5,
              WebkitBoxShadow: "0px 0px 10px 1px #00000033",
              transition: "all 0.3s",
              // borderStyle: "solid",
              // borderColor: BlackAndWhite.primary,
              // borderWidth: 5,
            }}
          >
            <img
              alt="load"
              src={
                shrink
                  ? require("../../images/newLogos/2. Merry-Jane Logo White for Dark Background.png")
                  : require("../../images/newLogos/1. MJ Logo Black with White Surround.png")
              }
              style={{
                height: shrink ? 65 : 120,
                transition: "all 0.3s",
              }}
            />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 16,
              marginLeft: shrink ? 125 : 235,
              fontWeight: "bolder",
              transition: "all 0.3s",
            }}
          >
            {tab
              ? tab === "Knowledge Base"
                ? "PRODUCT INFO"
                : tab.toLocaleUpperCase()
              : "loading"}
          </div>
          <div style={{ marginLeft: 20 }}>
            <img
              onMouseOver={() => setRefreshHover(true)}
              onMouseOut={() => setRefreshHover(false)}
              onClick={() => setRefresh(refresh ? false : true)}
              alt="icon"
              src={require("../../images/material-icons/refreshwhite.png")}
              style={{
                height: refreshHover ? 40 : 40,
                // backgroundColor: BlackAndWhite.primary,
                borderRadius: 50,
                marginTop: 10,
                transform: loading
                  ? `rotate(360deg)`
                  : refreshHover
                  ? `rotate(0deg)`
                  : `rotate(360deg)`,
                transition: loading ? "transform 3s" : "all 0.7s",
              }}
            />
          </div>
        </div>

        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
            borderRadius: 8,
            borderStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 20,
            borderWidth: 0.5,
          }}
        >
          <div style={{ fontSize: 14 }}>
            Store:{" "}
            {admin.adminUserData ? admin.adminUserData.storeName : "loading"}
          </div>
        </div>
        <div
          style={{
            marginRight: 20,
            fontSize: 14,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: 7 }}>
            {admin.adminUserData ? admin.adminUserData.firstName : "loading"}
          </div>
          <div style={{ marginRight: 12 }}>
            {admin.adminUserData ? admin.adminUserData.lastName : "loading"}
          </div>

          <div
            onClick={() => setOnMenu(onMenu ? false : true)}
            style={{
              height: 25,
              width: 25,
              backgroundColor: onMenu
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary,
              borderRadius: 100,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              transition: "0.3s",
            }}
          >
            <div
              style={{
                height: 5,
                width: 5,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
            <div
              style={{
                height: 5,
                width: 5,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
            <div
              style={{
                height: 5,
                width: 5,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
          </div>
        </div>
      </div>
      {/* menu */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: shrink ? 90 : 213,
          minWidth: shrink ? 90 : 213,
          backgroundColor: "#FFFFFF",
          height: "100%",
          marginTop: 10,
          alignItems: "center",
          transition: "all 0.3s",
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          zIndex: 1000,
          marginLeft: shrink ? 20 : 0,
        }}
      >
        <div style={{ height: shrink ? 110 : 150, transition: "all 0.3s" }} />
        {shrink ? (
          <CannaMenuButton
            // width={"90%"}
            selected={tab === "dashboard" ? true : false}
            iconImage={
              tab === "dashboard"
                ? require("../../images/material-icons/dashboardWhite.png")
                : require("../../images/material-icons/dashboardBlack.png")
            }
            onClick={() => setTab("dashboard")}
            height={30}
          />
        ) : (
          <QuestionButton
            borderOn={tab === "dashboard" ? true : false}
            title={"DASHBOARD"}
            height={50}
            onClick={() => setTab("dashboard")}
            backgroundColor={
              tab === "dashboard"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "dashboard"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "dashboard"
                ? require("../../images/material-icons/dashboardWhite.png")
                : require("../../images/material-icons/dashboardBlack.png")
            }
            // selected={tab === "knowledge" ? true : false}
            questionAmount={true}
            borderRadius={8}
          />
        )}
        <div style={{ height: 5 }} />
        {shrink ? (
          <CannaMenuButton
            // disable={cashRegisterOpen ? false : true}
            // width={"90%"}
            iconImage={
              tab === "CUSTOMERS"
                ? require("../../images/material-icons/profileWhite.png")
                : require("../../images/material-icons/profileBlack.png")
            }
            height={30}
            onClick={() => setTab("CUSTOMERS")}
            selected={tab === "CUSTOMERS" ? true : false}
          />
        ) : (
          <QuestionButton
            // disable={cashRegisterOpen ? false : true}
            title={"CUSTOMERS"}
            height={50}
            onClick={() => setTab("CUSTOMERS")}
            backgroundColor={
              tab === "CUSTOMERS"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "CUSTOMERS"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "CUSTOMERS"
                ? require("../../images/material-icons/profileWhite.png")
                : require("../../images/material-icons/profileBlack.png")
            }
            questionAmount={true}
            borderOn={tab === "CUSTOMERS" ? true : false}
            borderRadius={8}
          />
        )}
        <div style={{ height: 5 }} />
        {shrink ? (
          <CannaMenuButton
            disable={true}
            // width={"90%"}
            // disable={cashRegisterOpen ? false : true}
            height={30}
            onClick={() => setTab("PROCESS SALE")}
            selected={tab === "PROCESS SALE" ? true : false}
            iconImage={
              tab === "PROCESS SALE"
                ? require("../../images/material-icons/posWhite.png")
                : require("../../images/material-icons/posBlack.png")
            }
          />
        ) : (
          <QuestionButton
            disable={true}
            // disable={cashRegisterOpen ? false : true}
            borderOn={tab === "PROCESS SALE" ? true : false}
            title={"PROCESS SALE"}
            height={50}
            onClick={() => setTab("PROCESS SALE")}
            backgroundColor={
              tab === "PROCESS SALE"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "PROCESS SALE"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "PROCESS SALE"
                ? require("../../images/material-icons/posWhite.png")
                : require("../../images/material-icons/posBlack.png")
            }
            // selected={tab === "knowledge" ? true : false}
            questionAmount={true}
            borderRadius={8}
          />
        )}
        <div style={{ height: 5 }} />
        {shrink ? (
          <CannaMenuButton
            disable={true}
            // width={"90%"}
            selected={tab === "PROCESS ORDERS" ? true : false}
            iconImage={
              tab === "PROCESS ORDERS"
                ? require("../../images/material-icons/productWhite.png")
                : require("../../images/material-icons/productBlack.png")
            }
            onClick={() => setTab("PROCESS ORDERS")}
            height={30}
          />
        ) : (
          <QuestionButton
            disable={true}
            borderOn={tab === "PROCESS ORDERS" ? true : false}
            title={"ONLINE SALES"}
            height={50}
            onClick={() => setTab("PROCESS ORDERS")}
            backgroundColor={
              tab === "PROCESS ORDERS"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "PROCESS ORDERS"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "PROCESS ORDERS"
                ? require("../../images/material-icons/productWhite.png")
                : require("../../images/material-icons/productBlack.png")
            }
            // selected={tab === "knowledge" ? true : false}
            questionAmount={true}
            borderRadius={8}
          />
        )}

        {/* <div style={{ height: 5 }} />
        {shrink ? (
          <CannaMenuButton
            width={"90%"}
            height={30}
            onClick={() => setTab("register")}
            selected={tab === "register" ? true : false}
            iconImage={
              tab === "register"
                ? require("../../images/material-icons/usersWhite.png")
                : require("../../images/material-icons/userBlack.png")
            }
          />
        ) : (
          <QuestionButton
            borderOn={tab === "register" ? true : false}
            title={"REGISTER USER"}
            height={50}
            onClick={() => setTab("register")}
            backgroundColor={
              tab === "register"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "register"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "register"
                ? require("../../images/material-icons/usersWhite.png")
                : require("../../images/material-icons/userBlack.png")
            }
            // selected={tab === "register" ? true : false}
            questionAmount={true}
            borderRadius={8}
          />
        )} */}
        <div style={{ height: 5 }} />
        {shrink ? (
          <CannaMenuButton
            disable={true}
            // width={"90%"}
            // disable={cashRegisterOpen ? false : true}
            height={30}
            onClick={() => setTab("SALES HISTORY")}
            selected={tab === "SALES HISTORY" ? true : false}
            iconImage={
              tab === "SALES HISTORY"
                ? require("../../images/material-icons/menuWhite.png")
                : require("../../images/material-icons/menuBlack.png")
            }
          />
        ) : (
          <QuestionButton
            disable={true}
            // disable={cashRegisterOpen ? false : true}
            borderOn={tab === "SALES HISTORY" ? true : false}
            title={"SALES HISTORY"}
            height={50}
            onClick={() => setTab("SALES HISTORY")}
            backgroundColor={
              tab === "SALES HISTORY"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "SALES HISTORY"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "SALES HISTORY"
                ? require("../../images/material-icons/menuWhite.png")
                : require("../../images/material-icons/menuBlack.png")
            }
            // selected={tab === "register" ? true : false}
            questionAmount={true}
            borderRadius={8}
          />
        )}
        <div style={{ height: 5 }} />
        {shrink ? (
          <CannaMenuButton
            disable={true}
            // width={"90%"}
            height={30}
            onClick={() => setTab("CASH REGISTER")}
            selected={tab === "CASH REGISTER" ? true : false}
            iconImage={
              tab === "CASH REGISTER"
                ? require("../../images/material-icons/prescriptionWhite.png")
                : require("../../images/material-icons/prescriptionBlack.png")
            }
          />
        ) : (
          <QuestionButton
            disable={true}
            borderOn={tab === "CASH REGISTER" ? true : false}
            title={"CASH REGISTER"}
            height={50}
            onClick={() => setTab("CASH REGISTER")}
            backgroundColor={
              tab === "CASH REGISTER"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "CASH REGISTER"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "CASH REGISTER"
                ? require("../../images/material-icons/prescriptionWhite.png")
                : require("../../images/material-icons/prescriptionBlack.png")
            }
            // selected={tab === "register" ? true : false}
            questionAmount={true}
            borderRadius={8}
          />
        )}
        <div style={{ height: 5 }} />
        {shrink ? (
          <CannaMenuButton
            disable={true}
            // width={"90%"}
            // disable={cashRegisterOpen ? false : true}
            height={30}
            onClick={() => setTab("INVENTORY")}
            selected={tab === "INVENTORY" ? true : false}
            iconImage={
              tab === "INVENTORY"
                ? require("../../images/material-icons/setupAndMangeWhite.png")
                : require("../../images/material-icons/setupAndMangeBlack.png")
            }
          />
        ) : (
          <QuestionButton
            disable={true}
            // disable={cashRegisterOpen ? false : true}
            borderOn={tab === "INVENTORY" ? true : false}
            title={"INVENTORY"}
            height={50}
            onClick={() => setTab("INVENTORY")}
            backgroundColor={
              tab === "INVENTORY"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "INVENTORY"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "INVENTORY"
                ? require("../../images/material-icons/setupAndMangeWhite.png")
                : require("../../images/material-icons/setupAndMangeBlack.png")
            }
            // selected={tab === "register" ? true : false}
            questionAmount={true}
            borderRadius={8}
          />
        )}
        <div style={{ height: 5 }} />
        {shrink ? (
          <CannaMenuButton
            // width={"90%"}
            height={30}
            onClick={() => setTab("Knowledge Base")}
            selected={tab === "Knowledge Base" ? true : false}
            iconImage={
              tab === "Knowledge Base"
                ? require("../../images/product-icons/categoryWhite.png")
                : require("../../images/product-icons/categoryBlack.png")
            }
          />
        ) : (
          <QuestionButton
            borderOn={tab === "Knowledge Base" ? true : false}
            title={"PRODUCT INFO"}
            height={50}
            onClick={() => setTab("Knowledge Base")}
            backgroundColor={
              tab === "Knowledge Base"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "Knowledge Base"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "Knowledge Base"
                ? require("../../images/product-icons/categoryWhite.png")
                : require("../../images/product-icons/categoryBlack.png")
            }
            // selected={tab === "register" ? true : false}
            questionAmount={true}
            borderRadius={8}
          />
        )}
        <div style={{ height: 5 }} />
        {shrink ? (
          <CannaMenuButton
            // width={"90%"}
            height={30}
            onClick={() => setTab("support")}
            selected={tab === "support" ? true : false}
            iconImage={
              tab === "support"
                ? require("../../images/material-icons/qrcodeWhite.png")
                : require("../../images/material-icons/qrCodeBlack.png")
            }
          />
        ) : (
          <QuestionButton
            borderOn={tab === "support" ? true : false}
            title={"SUPPORT"}
            height={50}
            onClick={() => setTab("support")}
            backgroundColor={
              tab === "support"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "support"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "support"
                ? require("../../images/material-icons/qrcodeWhite.png")
                : require("../../images/material-icons/qrCodeBlack.png")
            }
            // selected={tab === "register" ? true : false}
            questionAmount={true}
            borderRadius={8}
          />
        )}
      </div>

      <div
        style={{
          height: "95%",
          width: "100%",

          marginTop: 90,
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {tab === "CUSTOMERS" ? (
          <div
            style={{
              height: 55,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* search section */}
            <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 5 }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  // width: 300,

                  borderWidth: 0.5,
                }}
              >
                <TextInputFieldSearch
                  placeholder={"Type here to search"}
                  height={30}
                  borderRadius={4}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <div
                  style={{
                    height: 35,
                    width: 70,
                    backgroundColor: BlackAndWhite.primary,
                    borderRadius: 4,
                    display: "flex",
                    color: BlackAndWhite.secondary,
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginLeft: 5,
                  }}
                >
                  <div
                    onClick={() => {
                      setSearch("");
                      setCustomers(null);
                    }}
                    style={{ fontSize: 12, fontWeight: "bold" }}
                  >
                    CLEAR
                  </div>
                </div>
                <div
                  style={{
                    height: 35,
                    width: 70,
                    backgroundColor: BlackAndWhite.primary,
                    borderRadius: 4,
                    display: "flex",
                    color: BlackAndWhite.secondary,
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: search === "" ? "not-allowed" : "pointer",
                    marginLeft: 10,
                  }}
                >
                  <div
                    onClick={search === "" ? null : () => OnUserSearch()}
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      opacity: search === "" ? 0.5 : 1,
                    }}
                  >
                    SEARCH
                  </div>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }}></div>
          </div>
        ) : null}

        {tab === "CUSTOMERS" ? <HeadersPatients /> : null}
        {tab === "CUSTOMERS" ? (
          <PatientDisplay
            setToastPop={setToastPop}
            setMessage={setMessage}
            setPatientProfilePop={setPatientProfilePop}
            setSelectedPatient={setSelectedPatient}
            setLoading={setLoading}
            width={width}
            search={search}
            setRefresh={setRefresh}
            refresh={refresh}
            loading={loading}
            customers={customers}
          />
        ) : null}

        {tab === "dashboard" ? (
          <DashBoardPos
            user={admin}
            refresh={refresh}
            setLoading={setLoading}
            search={search}
            setSearch={setSearch}
            customers={customers}
            OnUserSearch={OnUserSearch}
            setCustomers={setCustomers}
            setToastPop={setToastPop}
            setMessage={setMessage}
            setRefresh={setRefresh}
          />
        ) : null}

        {tab === "Knowledge Base" ? (
          <KnowledgeBasePanel
            setToastPop={setToastPop}
            setMessage={setMessage}
            setLoading={setLoading}
            setSelectedProductDisplay={setSelectedProductDisplay}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        ) : null}
        {tab === "support" ? (
          <SupportDisplay
            user={admin}
            refresh={refresh}
            setLoading={setLoading}
            search={search}
            setSearch={setSearch}
            customers={customers}
            OnUserSearch={OnUserSearch}
            setCustomers={setCustomers}
            setToastPop={setToastPop}
            setMessage={setMessage}
            setRefresh={setRefresh}
          />
        ) : null}

        {tab === "INVENTORY" ? (
          <InventoryPos
            user={admin}
            refresh={refresh}
            setLoading={setLoading}
            setToastPop={setToastPop}
            setMessage={setMessage}
            setRefresh={setRefresh}
          />
        ) : null}
        {tab === "CASH REGISTER" ? (
          <CashRegister
            user={admin}
            refresh={refresh}
            setLoading={setLoading}
            setToastPop={setToastPop}
            setMessage={setMessage}
            setRefresh={setRefresh}
          />
        ) : null}
        {tab === "PROCESS SALE" ? (
          <ProcessSale
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            user={admin}
            refresh={refresh}
            setLoading={setLoading}
            setToastPop={setToastPop}
            setMessage={setMessage}
            setRefresh={setRefresh}
            storeInfo={storeInfo}
            setStoreInfo={setStoreInfo}
          />
        ) : null}
        {tab === "SALES HISTORY" ? (
          <SalesHistory
            user={admin}
            refresh={refresh}
            setLoading={setLoading}
            setToastPop={setToastPop}
            setMessage={setMessage}
            setRefresh={setRefresh}
            storeInfo={storeInfo}
            setStoreInfo={setStoreInfo}
          />
        ) : null}
      </div>
      <ToastNotification display={toastPop} message={message.toUpperCase()} />
      <ProfileMenu
        display={onMenu}
        username={
          admin.adminUserData ? admin.adminUserData.userName : "loading"
        }
        idNum={admin.adminUserData ? admin.adminUserData.idDoc : "loading"}
        store={admin.adminUserData ? admin.adminUserData.storeName : "loading"}
        province={
          admin.adminUserData ? admin.adminUserData.province : "loading"
        }
        onCancelClick={() => setOnMenu(false)}
        onLogOutClick={() => LogOut()}
      />
      <PatientProfilePopUp
        display={patientProfilePop}
        selectedPatient={selectedPatient}
        onCloseClick={() => {
          setPatientProfilePop(false);
          setSelectedPatient(null);
        }}
        loading={loading}
        setLoading={setLoading}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        setTab={setTab}
        setPatientProfilePop={setPatientProfilePop}
        setSelectedPatient={setSelectedPatient}
      />
      <ProductKnowledgeDisplayModal
        display={selectedProductDisplay}
        onCloseClick={() => setSelectedProductDisplay(null)}
        selectedProductDisplay={selectedProductDisplay}
      />
      <LoadingPopUp display={loading} />
    </div>
  );
};

export default CannaPosAccess;
