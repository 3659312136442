import React from "react";
import { BlackAndWhite } from "../../../themes/Colors";

const EdibleCard = ({ size, rows }) => {
  return (
    <div
      style={{
        flex: size === "small" ? 0.25 : null || size === "large" ? 0.5 : null,
        borderStyle: "solid",
        color: BlackAndWhite.secondary,
        height: "100%",
      }}
    >
      Card
    </div>
  );
};

export default EdibleCard;
