import React from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import { CloseButton } from "../../../../components/atoms";

const UserPopUp = ({ display, onCloseClick, userSelected }) => {
  console.log("user", userSelected);
  return (
    <div
      style={{
        height: display ? "100%" : 0,
        overflow: "hidden",
        width: "100%",
        position: "absolute",
        top: 0,
        backgroundColor: "#00000066",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.3s",
      }}
    >
      <div
        style={{
          height: display ? "90%" : 0,
          width: display ? "90%" : 0,
          opacity: display ? 1 : 0,
          backgroundColor: BlackAndWhite.secondary,
          borderRadius: 20,
          overflow: "hidden",
          position: "relative",
          transition: "all 0.5s",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ position: "absolute", top: 15, left: 15 }}>
          <CloseButton label={"X"} onClick={onCloseClick} />
        </div>
        <div
          style={{
            flex: 0.5,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* name */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
              marginTop: 40,
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              Name :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected
                ? userSelected.firstName + " " + userSelected.lastName
                : "Loading"}
            </div>
          </div>
          {/* date of birth */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              Date of birth :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected ? userSelected.dateOfBirth : "Loading"}
            </div>
          </div>
          {/* Id-number */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              ID Number :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected ? userSelected.idDoc : "Loading"}
            </div>
          </div>
          {/* Cell number */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              Cell number :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected ? userSelected.cellNumber : "Loading"}
            </div>
          </div>
          {/* email */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              Email address :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected ? userSelected.email : "Loading"}
            </div>
          </div>
          {/* username */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              Username :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected ? userSelected.userName : "Loading"}
            </div>
          </div>
          {/* username */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              User No :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected ? userSelected.id : "Loading"}
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 0.5,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Account number */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
              marginTop: 40,
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              Account no :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected ? userSelected.accountNumber : "Loading"}
            </div>
          </div>
          {/* Account type */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              Account type :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected ? userSelected.type : "Loading"}
            </div>
          </div>
          {/* store */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              Store :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected ? userSelected.storeName : "Loading"}
            </div>
          </div>
          {/* province */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              Province :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected ? userSelected.province : "Loading"}
            </div>
          </div>
          {/* knowledgeRank */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              knowledge Rank :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected ? userSelected.knowledgeRank : "Loading"}
            </div>
          </div>
          {/* image */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: 60,
              fontSize: 18,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: 60,
                fontSize: 13,
                fontWeight: "lighter",
                marginBottom: 5,
              }}
            >
              ProfileImage :
            </div>
            <div style={{ marginLeft: 60 }}>
              {userSelected
                ? userSelected.profileImage
                  ? userSelected.profileImage
                  : "N/a"
                : "Loading"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPopUp;
