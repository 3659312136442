import React from "react";
import ItemCard from "../../item-card/ItemCard";

const SecondPageGroup2 = ({ selectedStore, refresh, page2, page4 }) => {
  return (
    <>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[4].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[4].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[4].type
                  : "TBA"
                : page2.length >= 1
                ? page2[4].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[4].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[4].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[4].description
                  : "TBA"
                : page2.length >= 1
                ? page2[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[4].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[4].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[4].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[4].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[4].type
                  : "TBA"
                : page2.length >= 1
                ? page2[4].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[4].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[4].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[4].description
                  : "TBA"
                : page2.length >= 1
                ? page2[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[4].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[4].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[4].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[4].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[4].type
                  : "TBA"
                : page2.length >= 1
                ? page2[4].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[4].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[4].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[4].description
                  : "TBA"
                : page2.length >= 1
                ? page2[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[4].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[4].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[4].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[4].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[4].type
                  : "TBA"
                : page2.length >= 1
                ? page2[4].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[4].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[4].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[4].description
                  : "TBA"
                : page2.length >= 1
                ? page2[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[4].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[4].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[4].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[4].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[4].type
                  : "TBA"
                : page2.length >= 1
                ? page2[4].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[4].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[4].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[4].description
                  : "TBA"
                : page2.length >= 1
                ? page2[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[4].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[4].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[4].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[4].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[4].type
                  : "TBA"
                : page2.length >= 1
                ? page2[4].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[4].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[4].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[4].description
                  : "TBA"
                : page2.length >= 1
                ? page2[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[4].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[4].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[4].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[4].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[4].type
                  : "TBA"
                : page2.length >= 1
                ? page2[4].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[4].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[4].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[4].description
                  : "TBA"
                : page2.length >= 1
                ? page2[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[4].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[4].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[4].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[4].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[4].type
                  : "TBA"
                : page2.length >= 1
                ? page2[4].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[4].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[4].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[4].description
                  : "TBA"
                : page2.length >= 1
                ? page2[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[4].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[4].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[5].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[5].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[5].type
                  : "TBA"
                : page2.length >= 1
                ? page2[5].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[5].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[5].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[5].description
                  : "TBA"
                : page2.length >= 1
                ? page2[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[5].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[5].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[5].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[5].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[5].type
                  : "TBA"
                : page2.length >= 1
                ? page2[5].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[5].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[5].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[5].description
                  : "TBA"
                : page2.length >= 1
                ? page2[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[5].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[5].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[5].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[5].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[5].type
                  : "TBA"
                : page2.length >= 1
                ? page2[5].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[5].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[5].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[5].description
                  : "TBA"
                : page2.length >= 1
                ? page2[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[5].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[5].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[5].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[5].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[5].type
                  : "TBA"
                : page2.length >= 1
                ? page2[5].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[5].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[5].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[5].description
                  : "TBA"
                : page2.length >= 1
                ? page2[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[5].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[5].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[5].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[5].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[5].type
                  : "TBA"
                : page2.length >= 1
                ? page2[5].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[5].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[5].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[5].description
                  : "TBA"
                : page2.length >= 1
                ? page2[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[5].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[5].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[5].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[5].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[5].type
                  : "TBA"
                : page2.length >= 1
                ? page2[5].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[5].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[5].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[5].description
                  : "TBA"
                : page2.length >= 1
                ? page2[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[5].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[5].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[5].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[5].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[5].type
                  : "TBA"
                : page2.length >= 1
                ? page2[5].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[5].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[5].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[5].description
                  : "TBA"
                : page2.length >= 1
                ? page2[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[5].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[5].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[5].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[5].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[5].type
                  : "TBA"
                : page2.length >= 1
                ? page2[5].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[5].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[5].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[5].description
                  : "TBA"
                : page2.length >= 1
                ? page2[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[5].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[5].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[6].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[6].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[6].type
                  : "TBA"
                : page2.length >= 1
                ? page2[6].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[6].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[6].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[6].description
                  : "TBA"
                : page2.length >= 1
                ? page2[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[6].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[6].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[6].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[6].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[6].type
                  : "TBA"
                : page2.length >= 1
                ? page2[6].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[6].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[6].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[6].description
                  : "TBA"
                : page2.length >= 1
                ? page2[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[6].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[6].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[6].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[6].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[6].type
                  : "TBA"
                : page2.length >= 1
                ? page2[6].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[6].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[6].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[6].description
                  : "TBA"
                : page2.length >= 1
                ? page2[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[6].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[6].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[6].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[6].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[6].type
                  : "TBA"
                : page2.length >= 1
                ? page2[6].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[6].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[6].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[6].description
                  : "TBA"
                : page2.length >= 1
                ? page2[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[6].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[6].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[6].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[6].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[6].type
                  : "TBA"
                : page2.length >= 1
                ? page2[6].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[6].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[6].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[6].description
                  : "TBA"
                : page2.length >= 1
                ? page2[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[6].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[6].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[6].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[6].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[6].type
                  : "TBA"
                : page2.length >= 1
                ? page2[6].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[6].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[6].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[6].description
                  : "TBA"
                : page2.length >= 1
                ? page2[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[6].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[6].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[6].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[6].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[6].type
                  : "TBA"
                : page2.length >= 1
                ? page2[6].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[6].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[6].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[6].description
                  : "TBA"
                : page2.length >= 1
                ? page2[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[6].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[6].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[6].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[6].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[6].type
                  : "TBA"
                : page2.length >= 1
                ? page2[6].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[6].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[6].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[6].description
                  : "TBA"
                : page2.length >= 1
                ? page2[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[6].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[6].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[7].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[7].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[7].type
                  : "TBA"
                : page2.length >= 1
                ? page2[7].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[7].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[7].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[7].description
                  : "TBA"
                : page2.length >= 1
                ? page2[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[7].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[7].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[7].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[7].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[7].type
                  : "TBA"
                : page2.length >= 1
                ? page2[7].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[7].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[7].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[7].description
                  : "TBA"
                : page2.length >= 1
                ? page2[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[7].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[7].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[7].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[7].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[7].type
                  : "TBA"
                : page2.length >= 1
                ? page2[7].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[7].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[7].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[7].description
                  : "TBA"
                : page2.length >= 1
                ? page2[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[7].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[7].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[7].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[7].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[7].type
                  : "TBA"
                : page2.length >= 1
                ? page2[7].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[7].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[7].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[7].description
                  : "TBA"
                : page2.length >= 1
                ? page2[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[7].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[7].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[7].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[7].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[7].type
                  : "TBA"
                : page2.length >= 1
                ? page2[7].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[7].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[7].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[7].description
                  : "TBA"
                : page2.length >= 1
                ? page2[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[7].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[7].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[7].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[7].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[7].type
                  : "TBA"
                : page2.length >= 1
                ? page2[7].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[7].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[7].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[7].description
                  : "TBA"
                : page2.length >= 1
                ? page2[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[7].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[7].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[7].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[7].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[7].type
                  : "TBA"
                : page2.length >= 1
                ? page2[7].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[7].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[7].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[7].description
                  : "TBA"
                : page2.length >= 1
                ? page2[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[7].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[7].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[7].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[7].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[7].type
                  : "TBA"
                : page2.length >= 1
                ? page2[7].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[7].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[7].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[7].description
                  : "TBA"
                : page2.length >= 1
                ? page2[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[7].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[7].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
    </>
  );
};

export default SecondPageGroup2;
