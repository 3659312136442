import {
  AboutUsLanding,
  AreYou21,
  ForgotPasswordPopUp,
  LoginPopUp,
  OptionLandingPage,
  ToastNotification,
} from "../../components";
import React, { useState, useEffect } from "react";
import {
  HeaderButton,
  RegistrationTab,
  OpportunitiesTab,
  EnquireTab,
  LocationTab,
  OnlineStore,
  TeamTab,
} from "../../components/atoms";
import MenuPopUp from "../../components/atoms/menu-pop-up/MenuPopUp";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import { useNavigate } from "react-router-dom";

const Website = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  const [aboutUs, setAboutUs] = useState(false);
  const [menuPopUp, setMenuPopUp] = useState(false);
  const [areYou, setAreYou] = useState(false);
  const [regsitration, setRegistration] = useState(false);
  const [opsTab, setOpsTab] = useState(false);
  const [enquiretab, setEnquireTab] = useState(false);
  const [locationTab, setLocationTab] = useState(false);
  const [onlineStore, setOnlineStore] = useState(false);
  const [teamTab, setTeamTab] = useState(false);
  const [displayButtons, setDisplayButtons] = useState(true);
  const [selected, setSelected] = useState("nothing");
  const [infoBlock, setInfoBlock] = useState(false);
  const [option, setOption] = useState("nothing");
  const [helpPopUp, setHelpPopUp] = useState(true);
  const { width, height } = useWindowDimensions();
  const [error, setError] = useState("");
  const [websiteData, setWebsiteData] = useState(null);
  const [websiteDataTwo, setWebsiteDataTwo] = useState(null);
  const [forgotPasswordPop, setForgotPasswordPop] = useState(false);
  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");
  const [descriptionArray, setDescriptionArray] = useState(null);

  const descriptionsFetch = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/website/getDescriptionParagraphs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            descriptionId: websiteData.data.landingPage.descriptionId,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.data) {
        setDescriptionArray(responseData.data);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (websiteData) {
      descriptionsFetch();
    }
  }, [websiteData]);
  const GetWebsiteDataTwo = async () => {
    try {
      const run = await fetch(
        "https://merry-jane-api.onrender.com/website/adminFetch/65784e47833b40e2a6f54c47"
      )
        .then((response) => {
          return response.json();
        })
        .then((resData) => {
          setWebsiteDataTwo(resData);
        });
    } catch (err) {
      console.log("err", err);
      setWebsiteDataTwo(null);
    }
  };
  useEffect(() => {
    GetWebsiteDataTwo();
  }, []);
  const GwtWebsitesData = async () => {
    try {
      const run = await fetch(
        "https://merry-jane-api.onrender.com/website/getWebsiteInfo"
      )
        .then((response) => {
          return response.json();
        })
        .then((resData) => {
          setWebsiteData(resData);
        });
    } catch (err) {
      console.log("err", err);
      setWebsiteData(null);
    }
  };
  useEffect(() => {
    GwtWebsitesData();
  }, []);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <HeaderButton
        width={"100%"}
        display={aboutUs}
        onHomeClick={() => {
          setMenuPopUp(false);
          setAboutUs(false);
          setDisplayButtons(true);
          setEnquireTab(false);
          setOpsTab(false);
          setLocationTab(false);
          setOnlineStore(false);
          setTeamTab(false);
          setMenuPopUp(false);
          setInfoBlock(false);
          setOption("nothing");
        }}
        onMenuClick={() => {
          setMenuPopUp(menuPopUp ? false : true);
          setEnquireTab(false);
          setOpsTab(false);
          setLocationTab(false);
          setOnlineStore(false);
          setTeamTab(false);
          // setSelected("nothing");
          setInfoBlock(false);
          setOption("nothing");
        }}
      />
      <OptionLandingPage
        onLoginClick={() => {
          setLogin(true);
          setDisplayButtons(false);
        }}
        display={aboutUs}
        onABoutClick={() => {
          setAreYou(true);
          setDisplayButtons(false);
        }}
        displayButtons={displayButtons}
      />

      <AboutUsLanding
        display={aboutUs}
        websiteData={websiteData}
        descriptionArray={descriptionArray}
      />
      <LoginPopUp
        onBackClick={() => {
          setLogin(false);
          setDisplayButtons(true);
        }}
        login={login}
        onRegClick={() => {
          setRegistration(true);
          setLogin(false);
        }}
        error={error}
        setError={setError}
        onForgotClick={() => {
          setForgotPasswordPop(true);
          setLogin(false);
        }}
      />
      <MenuPopUp
        display={menuPopUp}
        onLoginClick={() => {
          setMenuPopUp(false);
          setAboutUs(false);
          setLogin(true);
          setDisplayButtons(false);
        }}
        onHomeClick={() => {
          setMenuPopUp(false);
          setDisplayButtons(true);
        }}
        // onOpportunitiesUs={() => {
        //   setOpsTab(true);
        //   setMenuPopUp(false);
        // }}
        onEnquireClick={() => {
          setEnquireTab(true);
          setMenuPopUp(false);
        }}
        onLocationClick={() => {
          navigate("/facilities");
        }}
        onStoreClick={() => {
          setOnlineStore(true);
          setMenuPopUp(false);
        }}
        onMemberClick={() => {
          setTeamTab(true);
          setMenuPopUp(false);
        }}
      />
      <AreYou21
        display={areYou}
        onBackClick={() => {
          setAreYou(false);
          setDisplayButtons(true);
        }}
        onNoClick={() => {
          setAreYou(false);
          setDisplayButtons(true);
        }}
        onYesClick={() => {
          setAreYou(false);
          setAboutUs(true);
        }}
      />
      <RegistrationTab
        setError={setError}
        setDisplayButtons={setDisplayButtons}
        setLogin={setLogin}
        display={regsitration}
        helpPopUp={helpPopUp}
        onQuestionClick={() => setHelpPopUp(true)}
        onCloseClick={() => {
          setRegistration(false);
          setDisplayButtons(true);
        }}
        onGotItClick={() => setHelpPopUp(false)}
        onNotClick={() => {
          setRegistration(false);
          setDisplayButtons(true);
        }}
        setRegistration={setRegistration}
      />
      {/* <OpportunitiesTab
        display={opsTab}
        onCloseClick={() => setOpsTab(false)}
        option={option}
        setOption={setOption}
        onEnquireClick={() => {
          setOption("opportunity");
        }}
        data={websiteDataTwo ? websiteDataTwo.oppurtunities : []}
      /> */}
      <EnquireTab
        display={enquiretab}
        onCloseClick={
          selected !== "nothing"
            ? () => {
                setSelected("nothing");
              }
            : () => {
                setEnquireTab(false);
              }
        }
        selected={selected}
        setSelected={setSelected}
        infoBlock={infoBlock}
        setInfoBlock={setInfoBlock}
        setEnquireTab={setEnquireTab}
      />

      <OnlineStore
        display={onlineStore}
        onCloseClick={() => setOnlineStore(false)}
      />
      <TeamTab
        data={websiteData}
        display={teamTab}
        onCloseClick={() => setTeamTab(false)}
        onSignUpClick={() => {
          setMenuPopUp(false);
          setAboutUs(false);
          // setLogin(true);
          setDisplayButtons(false);
          setRegistration(true);
          setTeamTab(false);
        }}
      />
      <ForgotPasswordPopUp
        display={forgotPasswordPop}
        setForgotPasswordPop={setForgotPasswordPop}
        onCloseClick={() => {
          setForgotPasswordPop(false);
          setDisplayButtons(true);
        }}
        setMessage={setMessage}
        setToastPop={setToastPop}
      />
      <ToastNotification
        display={toastPop}
        message={
          message
            ? message.toLocaleUpperCase()
            : "Contact support an error has occured"
        }
      />
    </div>
  );
};

export default Website;
