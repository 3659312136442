import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const HeadersTransfers = ({ onClick, marginTop }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        minHeight: 50,
        display: "flex",
        borderTopColor: BlackAndWhite.primary,
        backgroundColor: BlackAndWhite.primary,
        color: BlackAndWhite.secondary,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginTop: marginTop,
      }}
    >
      <div
        style={{
          flex: 0.25,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
          width: "100%",
        }}
      >
        <div style={{ marginLeft: 5, width: "100%", fontSize: 12 }}>DOC ID</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%", fontSize: 12 }}> DATE ADDED</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%" }}>FROM</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
          overflow: "hidden",
        }}
      >
        <div style={{ width: "100%" }}>TO</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "100%", overflowX: "hidden" }}>ITEMS/QTY</div>
      </div>
      <div
        style={{
          flex: 0.24,
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            width: "100%",
            marginLeft: 40,
          }}
        >
          CREATED BY
        </div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        ACTIONS
      </div>
    </div>
  );
};

export default HeadersTransfers;
