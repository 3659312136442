import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const CompletedStepNotice = ({ complete, width, task }) => {
  return (
    <div
      style={{
        width: width,
        borderStyle: "solid",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: BlackAndWhite.secondary,
        marginTop: 10,
        height: 35,
        borderRadius: 20,
        borderWidth: 0.5,
      }}
    >
      <div style={{ marginLeft: 10 }}>{task}</div>
      <div
        style={{
          fontWeight: "bold",
          marginRight: 10,
          color: complete ? BlackAndWhite.green : "#ED1C24",
        }}
      >
        {complete ? "Complete" : "Required"}
      </div>
    </div>
  );
};

export default CompletedStepNotice;
