import React, { useState, useEffect } from "react";
import "../../App.css";
import { BaseButtons, FallingLeaf } from "../atoms";
import useWindowDimensions from "../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../themes/Colors";
import { useSpring, animated } from "react-spring";
import ItemCard from "./item-card/ItemCard";
import { useNavigate } from "react-router-dom";
import EdibleCard from "./edible-card/EdibleCard";
import Page3Display from "./page3display/Page3Display";
import FirstGroup from "./first-group/FirstGroup";
import SecondGroup from "./second-group/SecondGroup";
import ThirdGroupPageOne from "./third-group-page-one/ThirdGroupPageOne";
import FourthGroupPageOne from "./fourth-group-page-one/FourthGroupPageOne";
import SecondPageGroupOne from "./secondPage/second-page-group-1/SecondPageGroupOne";
import SecondPageGroup2 from "./secondPage/secound-page-group-2/SecondPageGroup2";
import SecondPageGroup3 from "./secondPage/second-page-group-3/SecondPageGroup3";
import SecondPageGroup4 from "./secondPage/second-page-group-4/SecondPageGroup4";

const MenuLoadingPage1 = ({
  onLoginClick,
  display,
  onABoutClick,
  displayButtons,
}) => {
  const { width, height } = useWindowDimensions();
  const [fall, setFall] = useState(false);
  const [fallinGroup, setFallinGroup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [startAnationOne, setStartAnimationOne] = useState(false);
  const [startAnationTwo, setStartAnimationTwo] = useState(false);
  const [startAnationThree, setStartAnimationThree] = useState(false);

  const [selectedStore, setSelectedStore] = useState("");

  const [page1, setPage1] = useState([]);
  const [page2, setPage2] = useState([]);
  const [page3, setPage3] = useState([]);
  const [page4, setPage4] = useState([]);
  const [page5, setPage5] = useState([]);
  const [page6, setPage6] = useState([]);

  const [tv, setTv] = useState(0);

  const [error, setError] = useState(null);

  const getMenuForStore = async () => {
    setLoading(true);
    //this
    // "https://merry-jane-api.onrender.com/user/login"
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/menu/getPageMenu",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tv: tv,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.tv) {
        setLoading(false);
        setPage1(responseData.page1.items);
        setPage2(responseData.page2.items);
        setPage3(responseData.page3.items);
        setPage4(responseData.page4.items);
        setPage5(responseData.page5.items);
        setPage6(responseData.page6.items);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setError("invalid credentials");
    }
  };
  const styles = useSpring({
    // loop: startAnationOne ? false : true,
    from: { rotateZ: 0 },
    to: { rotateZ: 360 },
    delay: 1000,
    config: {
      tension: 50,
      friction: 8,
    },
  });

  useEffect(() => {
    if (tv !== 0) {
      setTimeout(() => {
        setStartAnimationOne(true);
      }, 1500);
    }
  }, [refresh, tv]);
  useEffect(() => {
    if (tv !== 0) {
      setTimeout(() => {
        setStartAnimationOne(false);
      }, 16500);
    }
  }, [refresh, tv]);
  useEffect(() => {
    if (tv !== 0) {
      setTimeout(() => {
        setStartAnimationTwo(true);
      }, 17500);
    }
  }, [refresh, tv]);
  useEffect(() => {
    if (tv !== 0) {
      setTimeout(() => {
        setStartAnimationTwo(false);
      }, 34000);
    }
  }, [refresh, tv]);
  useEffect(() => {
    if (tv !== 0) {
      setTimeout(() => {
        setStartAnimationThree(true);
      }, 35000);
    }
  }, [refresh, tv]);

  useEffect(() => {
    if (tv !== 0) {
      setTimeout(() => {
        setStartAnimationThree(false);
      }, 50000);
    }
  }, [refresh, tv]);
  useEffect(() => {
    if (tv !== 0) {
      setTimeout(() => {
        setRefresh(refresh ? false : true);
      }, 50000);
    }
  }, [startAnationOne, tv]);
  useEffect(() => {
    if (tv === 0) {
      return;
    } else {
      getMenuForStore();
    }
  }, [tv]);
  if (tv === 1) {
    return (
      <div
        style={{
          height: display ? 0 : height <= 600 ? height + 80 : height + 20,
          width: width,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.5s",
          overflow: "hidden",
          position: "relative",
          // backgroundColor: BlackAndWhite.primary,
        }}
      >
        <img
          src={require("../../images/menus/MJ Digital Menu Background.png")}
          alt="background"
          style={{
            transition: "all 1.3s",
            width: "100%",
            height: "100%",
            zIndex: -20,
            position: "absolute",
          }}
        />
        <div
          style={{
            height:
              startAnationOne || startAnationTwo || startAnationThree
                ? 150
                : 400,
            width:
              startAnationOne || startAnationTwo || startAnationThree
                ? 150
                : 400,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            // top: startAnationOne ? 5 : "20%",
            transition: "all 1s",
          }}
        >
          <animated.div
            style={{
              ...styles,
            }}
          >
            <img
              src={require("../../images/menus/3c. Merry-Jane Logo White with White Transparent Background White Lettering Stoned Version.png")}
              className="App-logo"
              alt="logo"
              style={{
                transition: "all 1.3s",
                height:
                  startAnationOne || startAnationTwo || startAnationThree
                    ? 400
                    : width > 899
                    ? 450
                    : 340,
                opacity:
                  startAnationOne || startAnationTwo || startAnationThree
                    ? 0.2
                    : 1,
              }}
            />
          </animated.div>
        </div>
        {/* //page 1 */}
        <div
          style={{
            // backgroundColor: "#00000070",
            width: "99%",
            height: startAnationOne ? "99%" : 0,
            overflow: "hidden",
            // borderRadius: 8,
            transition: "all 1.4s",
            display: "flex",
            flexDirection: "row",
            zIndex: 100,
            opacity: startAnationOne ? 1 : 0,
          }}
        >
          {/* left half page */}
          <div
            style={{
              flex: 0.5,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              // borderStyle: "solid",
            }}
          >
            {/* first 4 menu items page 1 and 3 */}
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <FirstGroup
                selectedStore={selectedStore}
                page1={page1}
                page3={page3}
                refresh={refresh}
              />
            </div>
            {/* second 4 menu items page 1 and 3 */}
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <SecondGroup
                selectedStore={selectedStore}
                page1={page1}
                page3={page3}
                refresh={refresh}
              />
            </div>
          </div>
          {/* //next set */}
          <div
            style={{
              flex: 0.5,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              // borderStyle: "solid",
            }}
          >
            {/* third 4 menu items page 1 and 3 */}
            <ThirdGroupPageOne
              selectedStore={selectedStore}
              page1={page1}
              page3={page3}
              refresh={refresh}
            />
            {/* last 4 menu items page 1 and 3 */}
            <FourthGroupPageOne
              selectedStore={selectedStore}
              page1={page1}
              page3={page3}
              refresh={refresh}
            />
          </div>
        </div>
        {/* //page 2 */}
        <div
          style={{
            // backgroundColor: "#00000070",
            width: "99%",
            height: startAnationTwo ? "99%" : 0,
            overflow: "hidden",
            // borderRadius: 8,
            transition: "all 1.4s",
            display: "flex",
            flexDirection: "row",
            zIndex: 100,
            opacity: startAnationTwo ? 1 : 0,
          }}
        >
          <div
            style={{
              flex: 0.5,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              // borderStyle: "solid",
            }}
          >
            {/* left side */}
            {/* frst 4 menu items page 2 and 4 */}
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <SecondPageGroupOne
                selectedStore={selectedStore}
                page2={page2}
                page4={page4}
                refresh={refresh}
              />
            </div>
            {/* right side */}
            {/* second 4 menu items page 2 and 4 */}
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <SecondPageGroup2
                selectedStore={selectedStore}
                page2={page2}
                page4={page4}
                refresh={refresh}
              />
            </div>
          </div>
          <div
            style={{
              flex: 0.5,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              // borderStyle: "solid",
            }}
          >
            {/* third 4 on page 4 and 2 menu items last 4 */}
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <SecondPageGroup3
                selectedStore={selectedStore}
                page2={page2}
                page4={page4}
                refresh={refresh}
              />
            </div>
            {/* last 4 on page 4 and 2 menu items last 4 */}
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <SecondPageGroup4
                selectedStore={selectedStore}
                page2={page2}
                page4={page4}
                refresh={refresh}
              />
            </div>
          </div>
        </div>
        {/* //page 3 */}
        <Page3Display
          refresh={refresh}
          page5={page5}
          page6={page6}
          startAnationThree={startAnationThree}
          selectedStore={selectedStore}
        />
      </div>
    );
  } else if (tv === 2) {
    return (
      <div
        style={{
          color: BlackAndWhite.primary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: width,
          height: height,
        }}
      >
        <img
          src={require("../../images/menus/MJ Digital Menu Background.png")}
          alt="background"
          style={{
            transition: "all 1.3s",
            width: "100%",
            height: "100%",
            zIndex: -20,
            position: "absolute",
          }}
        />
        <div
          style={{
            flex: 0.5,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", flex: 0.5 }}>
            <EdibleCard size={"small"} />
            <EdibleCard size={"small"} />
            <EdibleCard size={"large"} />
          </div>
          <div style={{ display: "flex", flex: 0.5 }}>
            <EdibleCard size={"large"} />
            <EdibleCard size={"small"} />
            <EdibleCard size={"small"} />
          </div>
        </div>
        <div
          style={{
            flex: 0.5,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <EdibleCard size={"small"} />
          <EdibleCard size={"small"} />
          <EdibleCard size={"large"} />
        </div>
      </div>
    );
  } else if (tv === 3) {
    return (
      <div
        style={{
          color: BlackAndWhite.primary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: width,
          height: height,
        }}
      >
        <img
          src={require("../../images/menus/MJ Digital Menu Background.png")}
          alt="background"
          style={{
            transition: "all 1.3s",
            width: "100%",
            height: "100%",
            zIndex: -20,
            position: "absolute",
          }}
        />
        menu 3
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: display ? 0 : height <= 600 ? height + 80 : height + 20,
          width: width,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.5s",
          overflow: "hidden",
          position: "relative",
          // backgroundColor: BlackAndWhite.primary,
        }}
      >
        <img
          src={require("../../images/menus/MJ Digital Menu Background.png")}
          alt="background"
          style={{
            transition: "all 1.3s",
            width: "100%",
            height: "100%",
            zIndex: -20,
            position: "absolute",
          }}
        />
        <img
          src={require("../../images/menus/3c. Merry-Jane Logo White with White Transparent Background White Lettering Stoned Version.png")}
          className="App-logo"
          alt="logo"
          style={{
            transition: "all 1.3s",
            height: 200,
          }}
        />
        <div
          style={{
            height: selectedStore === "" ? 350 : 0,
            overflow: "hidden",
            transition: "all 0.4s",
            display: "flex",
            width: 400,
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BaseButtons
            label={"Langebaan"}
            onClick={() => setSelectedStore("langabaan")}
          />
          {/* <div style={{ width: 20 }} /> */}
          <BaseButtons
            label={"Woodstock"}
            onClick={() => setSelectedStore("woodstock")}
          />
          <BaseButtons
            label={"Malmesbury"}
            onClick={() => setSelectedStore("malmesbury")}
          />
          {/* <div style={{ width: 20 }} /> */}
          <BaseButtons
            label={"Mossel Bay"}
            onClick={() => setSelectedStore("mosselBay")}
          />

          <BaseButtons
            label={"Worcester"}
            onClick={() => setSelectedStore("woester")}
          />
          {/* <div style={{ width: 20 }} /> */}
          <BaseButtons
            label={"Panorama"}
            onClick={() => setSelectedStore("panorama")}
          />
          {/* <div style={{ width: 20 }} /> */}
          <BaseButtons
            label={"Hermanus"}
            onClick={() => setSelectedStore("hermanus")}
          />
          <BaseButtons
            label={"Wellington"}
            onClick={() => setSelectedStore("wellington")}
          />
        </div>
        <div
          style={{
            height: selectedStore !== "" ? 300 : 0,
            overflow: "hidden",
            transition: "all 0.4s",
          }}
        >
          <BaseButtons label={"MENU 1"} onClick={() => setTv(1)} />
          <BaseButtons label={"MENU 2"} onClick={() => setTv(2)} />
          <BaseButtons label={"MENU 3"} onClick={() => setTv(3)} />
          <BaseButtons label={"BACK"} onClick={() => setSelectedStore("")} />
        </div>
      </div>
    );
  }
};

export default MenuLoadingPage1;
