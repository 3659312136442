import React, { useState } from "react";

const QuestionButton = ({
  height,
  width,
  backgroundColor,
  color,
  title,
  questionAmount,
  borderRadius,
  onClick,
  backgroundColorAlt,
  colorAlt,
  borderOn,
  complete,
  fontSize,
  icon,
  description,
  fontWeight,
  selected,
  checkLeft,
  inProgress,
  notAvailable,
  icon2,
  disable,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onClick={disable ? null : onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        height: height,
        width: width,
        backgroundColor: notAvailable
          ? "rgb(255, 99, 132)"
          : inProgress
          ? "#FAA919"
          : hover
          ? backgroundColorAlt
          : backgroundColor,
        color: hover ? colorAlt : color,
        display: "flex",
        flexDirection: "row",
        borderRadius: borderRadius,
        justifyContent: "center",
        alignItems: "center",

        transition: "all 0.4s",

        cursor: "pointer",
        opacity: disable ? 0.5 : 1,
      }}
    >
      {title ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginLeft: checkLeft ? 15 : 25,
              marginRight: 5,
              fontSize: fontSize ? fontSize : 14,
              fontWeight: fontWeight,
              marginRight: description ? null : 15,
            }}
          >
            {title}
          </div>
          <div
            style={{
              marginLeft: 15,
              marginRight: 20,
              fontSize: 12,
              marginTop: 4,
            }}
          >
            {description}
          </div>
        </div>
      ) : null}
      {title ? <div style={{ flex: 1 }} /> : null}
      {/* {complete ? (
        <div
          style={{
            marginRight: 20,
            fontSize: 12,
            fontWeight: "bold",
            marginLeft: 10,
          }}
        >
          {" "}
          COMPLETE
        </div>
      ) : null} */}
      {questionAmount ? (
        <div style={{ marginRight: title ? 20 : null }}>
          {hover && icon2 ? (
            <img
              alt="logo"
              style={{ transition: "all 0.3s", height: hover ? 35 : 30 }}
              src={icon2}
            />
          ) : (
            <img
              alt="logo"
              style={{ transition: "all 0.3s", height: hover ? 35 : 30 }}
              src={icon}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default QuestionButton;
