import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import PatientDisplay from "../../../patient-display/PatientDisplay";
import { HeadersPatients } from "../../../patient-display/components";
import { TextInputFieldSearch } from "../../../../../../components/atoms";
import { LoadingPopUp } from "../../../../../../components";

const SelectedCustomerPopUp = ({
  display,
  setSelectedUser,
  admin,
  customers,
  setCustomers,
}) => {
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);
  const OnUserSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/searchForUserPos",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchParameter: search,
            userId: admin.adminUserData.userId,
          }),
        }
      );

      const responseData = await response.json();

      setLoading(false);
      if (responseData.success) {
        setLoading(false);
        setCustomers(responseData.data);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: "#00000050",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        top: 0,
        left: 0,
        zIndex: 30000000000,
      }}
    >
      <div
        style={{
          height: "70%",
          width: "90%",
          backgroundColor: BlackAndWhite.secondary,
          borderRadius: 8,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: 55,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* search section */}
          <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 0 }}>
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                // width: 300,

                borderWidth: 0.5,
              }}
            >
              <TextInputFieldSearch
                placeholder={"Type here to search"}
                height={30}
                borderRadius={4}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <div
                style={{
                  height: 35,
                  width: 70,
                  backgroundColor: BlackAndWhite.primary,
                  borderRadius: 4,
                  display: "flex",
                  color: BlackAndWhite.secondary,
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  marginLeft: 5,
                }}
              >
                <div
                  onClick={() => {
                    setSearch("");
                    setCustomers(null);
                  }}
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  CLEAR
                </div>
              </div>
              <div
                style={{
                  height: 35,
                  width: 70,
                  backgroundColor: BlackAndWhite.primary,
                  borderRadius: 4,
                  display: "flex",
                  color: BlackAndWhite.secondary,
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: search === "" ? "not-allowed" : "pointer",
                  marginLeft: 10,
                }}
              >
                <div
                  onClick={search === "" ? null : () => OnUserSearch()}
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    opacity: search === "" ? 0.5 : 1,
                  }}
                >
                  SEARCH
                </div>
              </div>
            </div>
          </div>
          <div style={{ flex: 1 }}></div>
        </div>

        <HeadersPatients />

        <PatientDisplay
          isSelector={true}
          setSelectedPatient={setSelectedUser}
          setLoading={setLoading}
          search={search}
          customers={customers}
        />
      </div>
      <LoadingPopUp display={loading} />
    </div>
  );
};

export default SelectedCustomerPopUp;
