import React from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
} from "../../../../../../../../components/atoms";

const ProductDisplayItem = ({
  NameAssemble,
  product,
  isRefund,
  onRemoveClick,
}) => {
  return (
    <div
      style={{
        width: "90%",
        backgroundColor: BlackAndWhite.secondary,
        height: 80,
        borderRadius: 10,
        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 80,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img alt="icon" style={{ height: 70 }} src={product.product.imageUrl} />
      </div>
      <div>{NameAssemble({ product: product.product })}</div>
      <div style={{ flex: 1 }} />
      <div
        style={{
          marginLeft: 20,
          marginRight: 30,
          fontWeight: "bold",
        }}
      >
        X {product.qtyCard.qty}
      </div>
      <div
        style={{
          marginRight: 20,
          fontWeight: "bold",
          fontSize: 18,
        }}
      >
        R {product.product.retailPriceInc}
      </div>
      {isRefund ? (
        <div style={{ marginRight: 15 }}>
          <BaseButtons
            mini={true}
            label={"X"}
            onClick={onRemoveClick}
            borderRadius={8}
            marginTop={-1}
            size={50}
            height={50}
            fontSize={20}
          />
        </div>
      ) : null}
    </div>
  );
};
export default ProductDisplayItem;
