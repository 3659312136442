import React from "react";
import ItemCard from "../../item-card/ItemCard";

const SecondPageGroupOne = ({ selectedStore, refresh, page2, page4 }) => {
  return (
    <>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[0].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[0].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[0].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[0].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[0].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[0].description
                  : "TBA"
                : page2.length >= 1
                ? page2[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[0].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[0].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[0].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[0].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[0].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[0].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[0].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[0].description
                  : "TBA"
                : page2.length >= 1
                ? page2[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[0].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[0].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[0].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[0].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[0].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[0].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[0].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[0].description
                  : "TBA"
                : page2.length >= 1
                ? page2[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[0].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[0].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[0].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[0].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[0].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[0].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[0].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[0].description
                  : "TBA"
                : page2.length >= 1
                ? page2[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[0].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[0].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[0].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[0].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[0].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[0].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[0].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[0].description
                  : "TBA"
                : page2.length >= 1
                ? page2[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[0].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[0].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[0].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[0].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[0].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[0].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[0].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[0].description
                  : "TBA"
                : page2.length >= 1
                ? page2[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[0].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[0].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[0].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[0].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[0].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[0].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[0].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[0].description
                  : "TBA"
                : page2.length >= 1
                ? page2[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[0].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[0].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[0].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[0].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[0].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[0].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[0].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[0].description
                  : "TBA"
                : page2.length >= 1
                ? page2[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[0].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[0].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}

        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[1].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[1].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[1].type
                  : "TBA"
                : page2.length >= 1
                ? page2[1].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[1].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[1].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[1].description
                  : "TBA"
                : page2.length >= 1
                ? page2[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[1].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[1].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[1].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[1].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[1].type
                  : "TBA"
                : page2.length >= 1
                ? page2[1].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[1].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[1].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[1].description
                  : "TBA"
                : page2.length >= 1
                ? page2[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[1].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[1].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[1].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[1].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[1].type
                  : "TBA"
                : page2.length >= 1
                ? page2[1].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[1].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[1].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[1].description
                  : "TBA"
                : page2.length >= 1
                ? page2[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[1].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[1].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[1].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[1].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[1].type
                  : "TBA"
                : page2.length >= 1
                ? page2[1].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[1].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[1].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[1].description
                  : "TBA"
                : page2.length >= 1
                ? page2[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[1].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[1].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[1].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[1].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[1].type
                  : "TBA"
                : page2.length >= 1
                ? page2[1].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[1].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[1].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[1].description
                  : "TBA"
                : page2.length >= 1
                ? page2[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[1].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[1].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[1].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[1].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[1].type
                  : "TBA"
                : page2.length >= 1
                ? page2[1].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[1].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[1].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[1].description
                  : "TBA"
                : page2.length >= 1
                ? page2[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[1].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[1].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[1].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[1].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[1].type
                  : "TBA"
                : page2.length >= 1
                ? page2[1].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[1].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[1].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[1].description
                  : "TBA"
                : page2.length >= 1
                ? page2[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[1].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[1].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[1].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[1].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[1].type
                  : "TBA"
                : page2.length >= 1
                ? page2[1].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[1].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[1].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[1].description
                  : "TBA"
                : page2.length >= 1
                ? page2[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[1].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[1].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[2].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[2].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[2].type
                  : "TBA"
                : page2.length >= 1
                ? page2[2].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[2].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[2].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[2].description
                  : "TBA"
                : page2.length >= 1
                ? page2[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[2].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[2].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[2].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[2].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[2].type
                  : "TBA"
                : page2.length >= 1
                ? page2[2].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[2].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[2].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[2].description
                  : "TBA"
                : page2.length >= 1
                ? page2[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[2].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[2].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[2].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[2].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[2].type
                  : "TBA"
                : page2.length >= 1
                ? page2[2].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[2].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[2].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[2].description
                  : "TBA"
                : page2.length >= 1
                ? page2[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[2].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[2].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[2].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[2].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[2].type
                  : "TBA"
                : page2.length >= 1
                ? page2[2].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[2].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[2].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[2].description
                  : "TBA"
                : page2.length >= 1
                ? page2[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[2].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[2].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[2].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[2].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[2].type
                  : "TBA"
                : page2.length >= 1
                ? page2[2].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[2].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[2].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[2].description
                  : "TBA"
                : page2.length >= 1
                ? page2[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[2].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[2].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[2].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[2].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[2].type
                  : "TBA"
                : page2.length >= 1
                ? page2[2].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[2].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[2].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[2].description
                  : "TBA"
                : page2.length >= 1
                ? page2[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[2].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[2].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[2].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[2].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[2].type
                  : "TBA"
                : page2.length >= 1
                ? page2[2].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[2].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[2].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[2].description
                  : "TBA"
                : page2.length >= 1
                ? page2[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[2].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[2].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[2].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[2].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[2].type
                  : "TBA"
                : page2.length >= 1
                ? page2[2].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[2].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[2].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[2].description
                  : "TBA"
                : page2.length >= 1
                ? page2[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[2].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[2].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[3].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[3].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[3].type
                  : "TBA"
                : page2.length >= 1
                ? page2[3].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[3].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[3].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[3].description
                  : "TBA"
                : page2.length >= 1
                ? page2[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[3].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[3].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[3].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[3].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[3].type
                  : "TBA"
                : page2.length >= 1
                ? page2[3].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[3].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[3].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[3].description
                  : "TBA"
                : page2.length >= 1
                ? page2[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[3].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[3].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[3].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[3].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[3].type
                  : "TBA"
                : page2.length >= 1
                ? page2[3].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[3].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[3].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[3].description
                  : "TBA"
                : page2.length >= 1
                ? page2[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[3].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[3].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[3].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[3].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[3].type
                  : "TBA"
                : page2.length >= 1
                ? page2[3].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[3].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[3].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[3].description
                  : "TBA"
                : page2.length >= 1
                ? page2[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[3].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[3].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[3].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[3].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[3].type
                  : "TBA"
                : page2.length >= 1
                ? page2[3].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[3].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[3].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[3].description
                  : "TBA"
                : page2.length >= 1
                ? page2[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[3].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[3].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[3].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[3].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[3].type
                  : "TBA"
                : page2.length >= 1
                ? page2[3].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[3].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[3].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[3].description
                  : "TBA"
                : page2.length >= 1
                ? page2[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[3].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[3].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[3].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[3].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[3].type
                  : "TBA"
                : page2.length >= 1
                ? page2[3].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[3].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[3].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[3].description
                  : "TBA"
                : page2.length >= 1
                ? page2[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[3].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[3].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[3].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[3].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[3].type
                  : "TBA"
                : page2.length >= 1
                ? page2[3].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[3].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[3].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[3].description
                  : "TBA"
                : page2.length >= 1
                ? page2[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[3].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[3].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
    </>
  );
};

export default SecondPageGroupOne;
