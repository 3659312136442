import React, { useState } from "react";
import { BlackAndWhite } from "../../themes/Colors";

const DropDownButton = ({ width, title, height, onClick, borderRadius }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={onClick}
      style={{
        height: height ? height : 60,
        width: width,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: hover
          ? BlackAndWhite.primary
          : BlackAndWhite.secondary,
        cursor: "pointer",
        color: hover ? BlackAndWhite.secondary : BlackAndWhite.primary,
        borderBottomStyle: hover ? "solid" : null,
        transition: "all 0.3s",
        borderRadius: borderRadius,
        marginBottom: hover ? 5 : 0,
      }}
    >
      <div
        style={{
          width: "100%",
          textAlign: "left",
          marginLeft: 20,
          fontSize: 12,
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default DropDownButton;
