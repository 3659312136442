import React, { useState, useEffect } from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../components/atoms";

const InventoryManageCard = ({
  productName,
  NameAssemble,
  selectedProduct,
  product,
  sku,
  supplier,
  image,
}) => {
  const [areSure, setAreSure] = useState(false);
  const [info, setInfo] = useState(null);
  const [stockValue, setStockValue] = useState(null);
  const GetWareHouseStockInfo = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getLineItemWarehouseStockLevel",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId: product._id,
          }),
        }
      );

      const responseData = await response.json();
      // console.log("response", responseData);
      if (responseData.success) {
        setInfo(responseData.stockAmount);
        setStockValue(responseData.stockAmount.currentStock);
        // setMessage(responseData.success);
        // setToastPop(true);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    GetWareHouseStockInfo();
  }, []);
  return (
    <div
      style={{
        height: 70,
        width: "100%",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#00000030",
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 0.05,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          overflow: "hidden",
        }}
      >
        <img alt="product" src={image} style={{ height: 50 }} />
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 15, width: "100%", textAlign: "left" }}>
          {productName}
        </div>
      </div>

      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 35, width: "100%", textAlign: "left" }}>
          {sku}
        </div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 25, width: "100%", textAlign: "left" }}>
          {supplier}
        </div>
      </div>
      <div
        style={{
          flex: 0.3,

          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        {/* <div
            style={{
              fontSize: 14,
              marginTop: 15,
              marginRight: 5,
            }}
          >
            UNIT
          </div> */}
        <div>
          <TextInputField
            invert={true}
            mini={true}
            height={10}
            marginTop={-5}
            size={200}
            labelTitle={"Total"}
            type={"Number"}
            readOnly={stockValue ? false : true}
            value={
              info ? (stockValue ? stockValue : info.currentStock) : "loading"
            }
            onChange={(e) => setStockValue(e.target.value)}
          />
        </div>
      </div>

      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <BaseButtons
          label={"SAVE"}
          mini={true}
          marginTopFalse={true}
          borderRadius={8}
          height={35}
          size={100}
          disable={
            info ? (info.currentStock === stockValue ? true : false) : true
          }
        />
      </div>
    </div>
  );
};

export default InventoryManageCard;
