import React from "react";

const Title = ({ number, title }) => {
  return (
    <div style={{ display: "flex", width: "80%", marginTop: 20 }}>
      <div style={{ marginRight: 5 }}>{number}</div>
      <div style={{ fontWeight: "bold" }}> {title}</div>
    </div>
  );
};

export default Title;
