import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import {
  AdminForm,
  CashierForm,
  CategoryButton,
  DoctorForm,
  InvestorForm,
  LoadingPopUp,
  ManagerForm,
  SubmitPopUp,
  SupportForm,
  ToastNotification,
} from "../../../components";
import HeadersTitle from "./table-blocks/HeadersTitle";
import UserItem from "./table-blocks/UserItem";
import HeadersTitleFacilities from "./table-blocks/HeadersTitleFacilities";
import FacilityItem from "./table-blocks/FacilityItem";
import FacilityPopUp from "./facility-pop-up/FacilityPopUp";
import CreateFacility from "./CreateFacility";
import SelectOwnerPopUp from "./SelectOwnerPopUp";
import Page1 from "./pages/Page1";
import EditPopUp from "./pages/EditPopUp";
import Page2 from "./pages/Page2";
import Page4 from "./pages/Page4";
import Page3 from "./pages/Page3";

const Menus = ({
  subSelection,
  userDisplay,
  setUserdisplay,
  setSubSelection,
}) => {
  const [facilitiesArray, setFacilitiesArray] = useState([]);
  const [tvSelected, setTvSelected] = useState("menu 1");
  const [tvSelectedHover, setTvSetSelectedHover] = useState("none");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");

  const [menuData, setMenuData] = useState("");

  const updateItem = async () => {
    setLoading(true);
    const response = await fetch(
      "https://merry-jane-api.onrender.com/menu/getPageMenu",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tv: "1",
        }),
      }
    );
    const respnseData = await response.json();
    if (respnseData.tv) {
      setMenuData(respnseData);
      setLoading(false);
    } else {
      setLoading(false);
      setToastPop(true);
      setMessage("could not load menus please trya gain later");
    }
  };

  useEffect(() => {
    updateItem();
  }, [refresh]);
  // useEffect(() => {}, []);
  useEffect(() => {
    if (toastPop) {
      updateItem();
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {subSelection === "MENU 1" ? (
        <div
          style={{
            height: "100%",
            backgroundColor: BlackAndWhite.secondary,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginBottom: 10,
              fontSize: 24,
              fontWeight: "bold",
              textAlign: "left",
              width: "85%",
              marginTop: 10,
            }}
          >
            MENU 1
          </div>

          <div
            style={{
              height: 60,
              width: "100%",
              // borderStyle: "solid",
              color: BlackAndWhite.primary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 0,
            }}
          >
            <div
              onClick={() => setTvSelected("menu 1")}
              onMouseOver={() => setTvSetSelectedHover("menu 1")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                cursor: "pointer",
                flex: 0.25,
                backgroundColor:
                  tvSelected === "menu 1"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 1"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 1"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 1"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 1
            </div>
            <div
              onClick={() => setTvSelected("menu 2")}
              onMouseOver={() => setTvSetSelectedHover("menu 2")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                flex: 0.25,
                cursor: "pointer",
                backgroundColor:
                  tvSelected === "menu 2"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 2"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 2"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 2"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 2
            </div>
            <div
              onClick={() => setTvSelected("menu 3")}
              onMouseOver={() => setTvSetSelectedHover("menu 3")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                flex: 0.25,
                cursor: "pointer",
                backgroundColor:
                  tvSelected === "menu 3"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 3"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 3"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 3"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 3
            </div>
            <div
              onClick={() => setTvSelected("menu 4")}
              onMouseOver={() => setTvSetSelectedHover("menu 4")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                flex: 0.25,
                cursor: "pointer",
                backgroundColor:
                  tvSelected === "menu 4"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 4"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 4"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 4"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 4
            </div>
            <div
              onClick={() => setTvSelected("menu 5")}
              onMouseOver={() => setTvSetSelectedHover("menu 5")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                flex: 0.25,
                cursor: "pointer",
                backgroundColor:
                  tvSelected === "menu 5"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 5"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 5"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 5"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 5
            </div>
            <div
              onClick={() => setTvSelected("menu 6")}
              onMouseOver={() => setTvSetSelectedHover("menu 6")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                flex: 0.25,
                cursor: "pointer",
                backgroundColor:
                  tvSelected === "menu 6"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 6"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 6"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 6"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 6
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {tvSelected === "menu 1" ? (
              <Page1
                setSelectedMenuItem={setSelectedMenuItem}
                menuData={menuData === "" ? [] : menuData.page1.items}
              />
            ) : null}
            {tvSelected === "menu 2" ? (
              <Page2
                setSelectedMenuItem={setSelectedMenuItem}
                menuData={menuData === "" ? [] : menuData.page2.items}
              />
            ) : null}
            {tvSelected === "menu 3" ? (
              <Page3
                setSelectedMenuItem={setSelectedMenuItem}
                menuData={menuData === "" ? [] : menuData.page3.items}
              />
            ) : null}
            {tvSelected === "menu 4" ? (
              <Page4
                setSelectedMenuItem={setSelectedMenuItem}
                menuData={menuData === "" ? [] : menuData.page4.items}
              />
            ) : null}
            {tvSelected === "menu 5" ? (
              <Page4
                setSelectedMenuItem={setSelectedMenuItem}
                menuData={menuData === "" ? [] : menuData.page5.items}
              />
            ) : null}
            {tvSelected === "menu 6" ? (
              <Page4
                setSelectedMenuItem={setSelectedMenuItem}
                menuData={menuData === "" ? [] : menuData.page6.items}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {subSelection === "MENU 2" ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            borderStyle: "solid",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: 10,
              fontSize: 24,
              fontWeight: "bold",
              textAlign: "left",
              width: "85%",
              marginTop: 10,
            }}
          >
            MENU 2
          </div>
          <div
            style={{
              height: 60,
              width: "100%",
              // borderStyle: "solid",
              color: BlackAndWhite.primary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 0,
            }}
          >
            <div
              onClick={() => setTvSelected("menu 1")}
              onMouseOver={() => setTvSetSelectedHover("menu 1")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                cursor: "pointer",
                flex: 0.25,
                backgroundColor:
                  tvSelected === "menu 1"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 1"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 1"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 1"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 1
            </div>
            <div
              onClick={() => setTvSelected("menu 2")}
              onMouseOver={() => setTvSetSelectedHover("menu 2")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                flex: 0.25,
                cursor: "pointer",
                backgroundColor:
                  tvSelected === "menu 2"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 2"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 2"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 2"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 2
            </div>
            <div
              onClick={() => setTvSelected("menu 3")}
              onMouseOver={() => setTvSetSelectedHover("menu 3")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                flex: 0.25,
                cursor: "pointer",
                backgroundColor:
                  tvSelected === "menu 3"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 3"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 3"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 3"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 3
            </div>
            <div
              onClick={() => setTvSelected("menu 4")}
              onMouseOver={() => setTvSetSelectedHover("menu 4")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                flex: 0.25,
                cursor: "pointer",
                backgroundColor:
                  tvSelected === "menu 4"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 4"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 4"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 4"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 4
            </div>
          </div>
          {tvSelected === "menu 1" ? <div>page 1</div> : null}
          {tvSelected === "menu 2" ? <div>page 2</div> : null}
          {tvSelected === "menu 3" ? <div>page 3</div> : null}
          {tvSelected === "menu 4" ? <div>page 4</div> : null}
        </div>
      ) : null}
      {subSelection === "MENU 3" ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            borderStyle: "solid",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: 10,
              fontSize: 24,
              fontWeight: "bold",
              textAlign: "left",
              width: "85%",
              marginTop: 10,
            }}
          >
            MENU 3
          </div>
          <div
            style={{
              height: 60,
              width: "100%",
              // borderStyle: "solid",
              color: BlackAndWhite.primary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 0,
            }}
          >
            <div
              onClick={() => setTvSelected("menu 1")}
              onMouseOver={() => setTvSetSelectedHover("menu 1")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                cursor: "pointer",
                flex: 0.25,
                backgroundColor:
                  tvSelected === "menu 1"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 1"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 1"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 1"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 1
            </div>
            <div
              onClick={() => setTvSelected("menu 2")}
              onMouseOver={() => setTvSetSelectedHover("menu 2")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                flex: 0.25,
                cursor: "pointer",
                backgroundColor:
                  tvSelected === "menu 2"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 2"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 2"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 2"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 2
            </div>
            <div
              onClick={() => setTvSelected("menu 3")}
              onMouseOver={() => setTvSetSelectedHover("menu 3")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                flex: 0.25,
                cursor: "pointer",
                backgroundColor:
                  tvSelected === "menu 3"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 3"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 3"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 3"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 3
            </div>
            <div
              onClick={() => setTvSelected("menu 4")}
              onMouseOver={() => setTvSetSelectedHover("menu 4")}
              onMouseOut={() => setTvSetSelectedHover("none")}
              style={{
                flex: 0.25,
                cursor: "pointer",
                backgroundColor:
                  tvSelected === "menu 4"
                    ? BlackAndWhite.primary
                    : tvSelectedHover === "menu 4"
                    ? "#00000040"
                    : BlackAndWhite.secondary,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color:
                  tvSelected === "menu 4"
                    ? BlackAndWhite.secondary
                    : tvSelectedHover === "menu 4"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                height: "100%",
              }}
            >
              PAGE 4
            </div>
          </div>
          {tvSelected === "menu 1" ? <div>page 1</div> : null}
          {tvSelected === "menu 2" ? <div>page 2</div> : null}
          {tvSelected === "menu 3" ? <div>page 3</div> : null}
          {tvSelected === "menu 4" ? <div>page 4</div> : null}
        </div>
      ) : null}
      {selectedMenuItem ? (
        <EditPopUp
          display={selectedMenuItem}
          onCloseClick={() => setSelectedMenuItem(null)}
          setLoading={setLoading}
          setMessage={setMessage}
          setToastPop={setToastPop}
          setSelectedMenuItem={setSelectedMenuItem}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      ) : null}

      <LoadingPopUp display={loading} />
      <ToastNotification display={toastPop} message={message.toUpperCase()} />
    </div>
  );
};

export default Menus;
