import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";
import FilterButtonSmall from "../filter-button-small/FilterButtonSmall";

const FilterPractioner = ({ filterName, setScreen }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("PRACTIONERS");
  const [selectedHover, setSelectedHover] = useState(false);

  return (
    <div
      onClick={open ? null : () => setOpen(true)}
      style={{
        cursor: "pointer",
        height: 50,
        width: open ? 500 : 40,
        // borderStyle: "solid",
        // borderWidth: open ? 1 : 0,
        transition: "all 0.4s",
        marginRight: open ? 10 : 20,
        borderRadius: 100,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: open ? BlackAndWhite.primary : BlackAndWhite.secondary,
      }}
    >
      {open ? null : (
        <div
          onClick={open ? null : () => setOpen(true)}
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? BlackAndWhite.primary
                : BlackAndWhite.primary,
              color: open ? BlackAndWhite.secondary : BlackAndWhite.secondary,
              height: 35,
              width: 120,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: 14,
            }}
          >
            <div>{selected}</div>
          </div>
        </div>
      )}

      <div
        style={{
          flex: open ? 1 : 0,
          display: "flex",
          overflow: open ? null : "hidden",
        }}
      >
        {/* // invite button */}
        <div
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: open ? 5 : null,
          }}
        >
          <div
            onMouseOver={() => setSelectedHover("invites")}
            onMouseOut={() => setSelectedHover(null)}
            onClick={() => {
              setSelected("INVITES");
              setOpen(false);
              setScreen("invites");
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? selected === "INVITES"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
                : null,
              color: open
                ? selected === "INVITES"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
                : null,
              height: selectedHover === "invites" ? 40 : 35,
              width: selectedHover === "invites" ? 135 : 120,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: selectedHover === "invites" ? 14 : 12,
              transition: "all 0.3s",
              WebkitBoxShadow:
                selectedHover === "invites"
                  ? "0px 0px 5px 5px #FFFFFF90"
                  : "0px 0px 0px 0px #00000040",
            }}
          >
            <div>INVITES</div>
          </div>
        </div>
        {/* Practiner Buttin */}
        <div
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: open ? 5 : null,
          }}
        >
          <div
            onMouseOver={() => setSelectedHover("practioners")}
            onMouseOut={() => setSelectedHover(null)}
            onClick={() => {
              setSelected("PRACTIONERS");
              setOpen(false);
              setScreen("practioners");
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? selected === "PRACTIONERS"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
                : null,
              color: open
                ? selected === "PRACTIONERS"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
                : null,
              height: selectedHover === "practioners" ? 40 : 35,
              width: selectedHover === "practioners" ? 135 : 120,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              // marginLeft: open ? 7 : 0,
              fontSize: selectedHover === "practioners" ? 14 : 12,
              transition: "all 0.3s",
              WebkitBoxShadow:
                selectedHover === "practioners"
                  ? "0px 0px 5px 5px #FFFFFF90"
                  : "0px 0px 0px 0px #00000040",
            }}
          >
            <div>PRACTIONERS</div>
          </div>
        </div>
        <div
          style={{
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onMouseOver={() => setSelectedHover("pending")}
            onMouseOut={() => setSelectedHover(null)}
            onClick={() => {
              setSelected("PENDING");
              setOpen(false);
              setScreen("pending");
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "solid",
              backgroundColor: open
                ? selected === "PENDING"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
                : null,
              color: open
                ? selected === "PENDING"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
                : null,
              height: selectedHover === "pending" ? 40 : 35,
              width: selectedHover === "pending" ? 135 : 120,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 80,
              borderWidth: open ? 0 : 0.5,
              marginLeft: open ? 7 : 0,
              fontSize: selectedHover === "pending" ? 14 : 12,
              transition: "all 0.3s",
              WebkitBoxShadow:
                selectedHover === "pending"
                  ? "0px 0px 5px 5px #FFFFFF90"
                  : "0px 0px 0px 0px #00000040",
            }}
          >
            <div>PENDING</div>
          </div>
        </div>
      </div>
      <FilterButtonSmall
        icon1={
          open
            ? require("../../../../../images/material-icons/setupAndMangeWhite.png")
            : require("../../../../../images/material-icons/setupAndMangeBlack.png")
        }
        icon2={
          open
            ? require("../../../../../images/material-icons/setupAndMangeWhite.png")
            : require("../../../../../images/material-icons/setupAndMangeBlack.png")
        }
        label={open ? "CLOSE" : filterName}
        iconHeight={20}
        iconWidth={20}
        fontSize={12}
        fontColor={open ? BlackAndWhite.secondary : BlackAndWhite.primary}
        fontColor1={open ? BlackAndWhite.secondary : BlackAndWhite.primary}
        onClick={() => setOpen(false)}
      />
      <div style={{ width: 10 }} />
    </div>
  );
};

export default FilterPractioner;
