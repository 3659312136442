import React from "react";
import { BlackAndWhite } from "../../themes/Colors";

const ImageUploadAWS = ({
  labelTitle,
  onChange,
  width,
  size,
  invert,
  rule,
  reqiredCheck,
  showPassword,
  autoFocus,
  value,
  placeholder,
  onClick,
  mini,
  height,
  marginTop,
  enter,
  type,
  min,
  count,
  readOnly,
  errorDisplay,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        onSubmit={
          enter
            ? (e) => {
                e.preventDefault();
                enter();
              }
            : (e) => e.preventDefault()
        }
        style={{
          width: width,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: mini ? (marginTop ? marginTop : 10) : 20,
        }}
      >
        <label
          style={{
            width: size ? size : 240,
            fontWeight: "500",
            fontSize: mini ? 14 : 16,
            color: invert ? BlackAndWhite.secondary : BlackAndWhite.primary,

            position: "relative",
          }}
        >
          {labelTitle ? labelTitle : null}
          {reqiredCheck ? (
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                color: rule === count ? "green" : "red",
              }}
            >
              {rule + " / " + count}
            </div>
          ) : null}
        </label>

        <input
          autoFocus={autoFocus}
          min={min}
          //   hidden={true}
          style={{
            height: height ? height : 30,
            width: size ? size : 220,
            marginTop: mini ? 10 : 10,
            borderRadius: mini ? 4 : 50,
            paddingRight: 10,
            paddingTop: 20,
            paddingBottom: 8,
            paddingLeft: 20,
            fontSize: mini ? 12 : 18,
            borderStyle: "none",
            backgroundColor: invert ? "#FFFFFF50" : "#00000030",
            color: invert ? BlackAndWhite.secondary : BlackAndWhite.primary,
            fontWeight: "bold",
            // borderWidth: 0.2,
          }}
          type={"file"}
          name={labelTitle}
          onChange={onChange}
          //   value={value}
          placeholder={placeholder ? placeholder : "type here"}
        />
      </form>
      {errorDisplay && (
        <div style={{ marginTop: 10, color: "red", fontSize: 14 }}>
          {errorDisplay}
        </div>
      )}
    </div>
  );
};

export default ImageUploadAWS;
