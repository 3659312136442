import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import SubcategoryListItem from "../subcategory-list-item/SubcategoryListItem";

const CategoryListItem = ({
  category,
  setToastPop,
  setMessage,
  onEditClick,
  onDeleteClick,
  selectedProduct,
  setSelectedProd,
  setSubcategoryEditPop,
  refresh,
  setRefresh,
  productTotal,
}) => {
  const [dropDown, setDropDown] = useState(false);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subCategories, setSubCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [deleteHover, setDeleteHover] = useState(false);
  const [manageHover, setManageHover] = useState(false);
  const [total, setTotal] = useState(0);

  const updateSubCategorydisplay = async (info) => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getSubCatergoriesId",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId: info.categoryId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.subCategory) {
        // const wait = await updateListFetch();
        setSubCategories(respnseData.subCategory);
        setLoading(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };
  const getProductAmount = async (searchId) => {
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/productLine/getProductsAmount",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchType: "cat",
            searchId: searchId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.category) {
        // const wait = await updateListFetch();
        setTotal(respnseData.category);
      } else {
        setTotal("error");
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductAmount(category.categoryId);
    if (dropDown) {
      updateSubCategorydisplay(selectedCategory);
    }
  }, [selectedCategory, refresh]);
  return (
    <div style={{ width: "100%" }}>
      <div
        onClick={
          deleteHover || manageHover
            ? null
            : dropDown
            ? () => {
                setDropDown(false);
                setSelectedCategory(null);
              }
            : () => {
                setSelectedCategory(category);
                setDropDown(true);
              }
        }
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          height: 50,
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: 3,
          backgroundColor: selectedCategory
            ? selectedCategory.categoryName === category.categoryName
              ? "#Faa91980"
              : null
            : hover
            ? "#00000075"
            : "#00000040",
          marginBottom: dropDown ? 2 : 0,
          transition: "all 0.2s",
        }}
      >
        <div
          style={{
            transition: "all 0.2",
            fontWeight: selectedCategory
              ? selectedCategory.categoryName === category.categoryName
                ? "bold"
                : null
              : hover
              ? "bold"
              : "normal",
            marginLeft: 20,
            fontSize: 14,
            color: selectedCategory
              ? selectedCategory.categoryName === category.categoryName
                ? "#000000"
                : null
              : hover
              ? "#FFFFFF"
              : "#000000",
          }}
        >
          {category.categoryName}
        </div>
        <div style={{ flex: 1 }}></div>
        <div style={{ fontSize: 14, marginRight: 10, transition: "all 0.3s" }}>
          Total Products : {total}
        </div>
        {selectedCategory ? null : (
          <>
            <button
              onClick={onEditClick}
              onMouseOver={() => setManageHover(true)}
              onMouseOut={() => setManageHover(false)}
              style={{
                height: "100%",
                width: 80,
                backgroundColor: manageHover
                  ? BlackAndWhite.primary
                  : "#ffffff70",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: 0,
              }}
            >
              <img
                alt="icon"
                src={
                  manageHover
                    ? require("../../../../../../../../images/material-icons/editWhite.png")
                    : require("../../../../../../../../images/material-icons/editBlack.png")
                }
                style={{
                  height: 15,

                  transition: "all 0.3s",
                }}
              />
            </button>
            {/* dlete butto */}
            {/* <button
              onClick={onDeleteClick}
              disabled={true}
              onMouseOver={() => setDeleteHover(true)}
              onMouseOut={() => setDeleteHover(false)}
              style={{
                height: "100%",
                width: 80,
                backgroundColor: deleteHover
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: 0,
              }}
            >
              <img
                alt="icon"
                src={
                  deleteHover
                    ? require("../../../../../../../../images/material-icons/deleteWhite.png")
                    : require("../../../../../../../../images/material-icons/deleteBlack.png")
                }
                style={{
                  height: 20,

                  transition: "all 0.3s",
                }}
              />
            </button> */}
          </>
        )}
        {/* edit button */}
      </div>
      <SubcategoryListItem
        refresh={refresh}
        setRefresh={setRefresh}
        setSubcategoryEditPop={setSubcategoryEditPop}
        setSelectedProd={setSelectedProd}
        display={dropDown}
        loading={loading}
        subCategories={subCategories}
        selectedProduct={selectedProduct}
      />
    </div>
  );
};

export default CategoryListItem;
