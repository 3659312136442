import React, { useState, useContext } from "react";
import {
  BaseButtons,
  CheckInput,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";
import QuestionaireDropDown from "../questionaire-drop-down/QuestionareDropDown";
import QuestionButton from "../button/QuestionButton";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { AuthContext } from "../../../../../../context/auth-context";
import LoadingPopUp from "../../../../../../components/loading-pop-up/LoadingPopUp";

const MedicalQuestions = ({
  setSelectedSection,
  setMedicalHistory,
  medicalInfo,
  answer1Complete,
  answer2Complete,
  answer3Complete,
  answer4Complete,
  answer5Complete,
  answer6Complete,
  answer7Complete,
  answer8Complete,
  answer9Complete,
}) => {
  const [answer1, setAnswer1] = useState("");
  const [what1, setWhat1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [what2, setWhat2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [what3, setWhat3] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [what4, setWhat4] = useState("");
  const [answer5, setAnswer5] = useState("");
  const [answer6, setAnswer6] = useState("nothing");
  const [answer7, setAnswer7] = useState("");
  const [answer8, setAnswer8] = useState("nothing");
  const [answer9, setAnswer9] = useState("");
  const [condition1, setCodition1] = useState("");
  const [condition3, setCodition3] = useState("");
  const [condition5, setCodition5] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("no data");
  const auth = useContext(AuthContext);
  const medicalHandler = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/medicalHistory/createAnswerSheet",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `bearer ${token}`,
          },
          body: JSON.stringify({
            id: auth.customerUserData.idDoc,
            section: "medical history",
            answer1: answer1 + " ," + what1,
            answer2: answer2 + " ," + what2,
            answer3: answer3 + " ," + what3,
            answer4: answer4 + " ," + what4,
            answer5: answer5.text + " " + condition1,
            answer6: answer6,
            answer7: answer7.text + " " + condition3,
            answer8: answer8,
            answer9: answer9.text + " " + condition5,
            answer10: "no data",
            answer11: "no data",
            answer12: "no data",
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.data) {
        setLoading(false);
        setSelectedSection("none");
        setMedicalHistory(true);
      }
    } catch (err) {
      setErrorMessage("Unable to upload answer sheet");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      console.log("error", err);
    }
  };

  return (
    <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
      {loading ? (
        <LoadingPopUp display={loading} />
      ) : errorMessage !== "no data" ? (
        <div
          onClick={() => setErrorMessage("no data")}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {errorMessage}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 30 }}>MEDICAL INFO</div>
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            1. Let's play Doctor-Doctor! If you've got health curiosities or
            concerns, toss them our way you will feel better already!
          </div>
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            A. Any past surgeries or medical procedures?
          </div>
          {medicalInfo ? null : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                height: 60,
                overflow: "hidden",
              }}
            >
              <div style={{ position: "absolute", left: 20 }}>
                <CheckInput
                  question={"YES"}
                  check={answer1 === "yes" ? true : false}
                  onClick={() => setAnswer1("yes")}
                  size={220}
                  invert={true}
                />
              </div>
              <div style={{ position: "absolute", right: -50 }}>
                <CheckInput
                  question={"NO"}
                  check={answer1 === "no" ? true : false}
                  onClick={() => setAnswer1("no")}
                  size={220}
                  invert={true}
                />
              </div>
            </div>
          )}

          {medicalInfo ? (
            <>
              <div style={{ height: 20 }} />
              <QuestionButton
                borderOn={true}
                title={answer1Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer1 === "yes" ? (
            <div>
              <TextInputField
                labelTitle={"Please provide details"}
                onChange={(event) => setWhat1(event.target.value)}
                invert={true}
                autoFocus={
                  answer1 === "yes" && what1.length === "" ? true : false
                }
              />
            </div>
          ) : null}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            B. Are you recovering from surgery or treatment?
          </div>
          {medicalInfo ? null : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                height: 60,
                overflow: "hidden",
              }}
            >
              <div style={{ position: "absolute", left: 20 }}>
                <CheckInput
                  question={"YES"}
                  check={answer2 === "yes" ? true : false}
                  onClick={() => setAnswer2("yes")}
                  size={220}
                  invert={true}
                />
              </div>
              <div style={{ position: "absolute", right: -50 }}>
                <CheckInput
                  question={"NO"}
                  check={answer2 === "no" ? true : false}
                  onClick={() => setAnswer2("no")}
                  size={220}
                  invert={true}
                />
              </div>
            </div>
          )}
          {medicalInfo ? (
            <>
              <div style={{ height: 20 }} />
              <QuestionButton
                borderOn={true}
                title={answer2Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer2 === "yes" ? (
            <div>
              <TextInputField
                labelTitle={"Please provide details"}
                onChange={(event) => setWhat2(event.target.value)}
                invert={true}
              />
            </div>
          ) : null}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            C. Do you currently take any prescribed medications?
          </div>
          {medicalInfo ? null : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                height: 60,
                overflow: "hidden",
              }}
            >
              <div style={{ position: "absolute", left: 20 }}>
                <CheckInput
                  question={"YES"}
                  check={answer3 === "yes" ? true : false}
                  onClick={() => setAnswer3("yes")}
                  size={220}
                  invert={true}
                />
              </div>
              <div style={{ position: "absolute", right: -50 }}>
                <CheckInput
                  question={"NO"}
                  check={answer3 === "no" ? true : false}
                  onClick={() => setAnswer3("no")}
                  size={220}
                  invert={true}
                />
              </div>
            </div>
          )}

          {medicalInfo ? (
            <>
              <div style={{ height: 20 }} />
              <QuestionButton
                borderOn={true}
                title={answer3Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer3 === "yes" ? (
            <div>
              <TextInputField
                labelTitle={"Please provide details"}
                onChange={(event) => setWhat3(event.target.value)}
                invert={true}
              />
            </div>
          ) : null}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            D. Any prominent medical conditions in your family’s history?
          </div>
          {medicalInfo ? null : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                height: 60,
                overflow: "hidden",
              }}
            >
              <div style={{ position: "absolute", left: 20 }}>
                <CheckInput
                  question={"YES"}
                  check={answer4 === "yes" ? true : false}
                  onClick={() => setAnswer4("yes")}
                  size={220}
                  invert={true}
                />
              </div>
              <div style={{ position: "absolute", right: -50 }}>
                <CheckInput
                  question={"NO"}
                  check={answer4 === "no" ? true : false}
                  onClick={() => setAnswer4("no")}
                  size={220}
                  invert={true}
                />
              </div>
            </div>
          )}
          {medicalInfo ? (
            <>
              <div style={{ height: 20 }} />
              <QuestionButton
                borderOn={true}
                title={answer4Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer4 === "yes" ? (
            <div>
              <TextInputField
                labelTitle={"Please provide details"}
                onChange={(event) => setWhat4(event.target.value)}
                invert={true}
              />
            </div>
          ) : null}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            2. Let's navigate the wild world of well-being! Health hiccups come
            in all shapes and sizes, and they play hide-and-seek based on
            factors like age, genes, lifestyle, and even where you hang your
            hat.
          </div>
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            Take a look at the following list and select the condition of
            concern:
          </div>
          {medicalInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer5Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer5 === "" ? (
            <QuestionaireDropDown
              meidcalCheck={true}
              setItem={setAnswer5}
              data={[
                {
                  text: "Heart and Blood Vessel problems ",
                  description:
                    "i.e (Cardiovascular Diseases) blood pressure, heart failure, stroke, coronary artery issues or related.",
                },
                {
                  text: "Breathing-related issues or lung conditions ",
                  description:
                    "i.e ((Respiratory Conditions) like asthma, chronic obstructive pulmonary disease (COPD), bronchitis. pneumonia or related.",
                },
                {
                  text: "Issues related to muscles and bones ",
                  description:
                    "i.e (Musculoskeletal Conditions) like osteoarthritis, rheumatoid arthritis, back pain, osteoporosis or related.",
                },
                {
                  text: "How your body processes food ",
                  description:
                    "i.e (Metabolic Conditions) like, diabetes, obesity, metabolic syndrome, hyperlipidaemia (high cholesterol)or related.",
                },
                {
                  text: "Nervous system and brain concerns",
                  description:
                    "i.e (Neurological Conditions) like migraines, alzheimer's, parkinson's, multiple sclerosis or related.",
                },
                {
                  text: "Mental health Conditions",
                  description:
                    "i.e  (Mental health issues), like depression, anxiety disorders, bipolar disorder, schizophrenia, obsessive-compulsive disorder (OCD), post-traumatic stress disorder (PTSD) or related.",
                },
                {
                  text: "Digestive system concerns ",
                  description:
                    "i.e (Gastrointestinal Conditions) like gastroesophageal reflux disease (GERD), irritable bowel syndrome (IBS), crohn's disease, ulcerative colitis or related.",
                },
                {
                  text: "Cancer ",
                  description:
                    "i.e like breast cancer, lung cancer, colorectal cancer, prostate cancer or related.",
                },
                {
                  text: "Skin Conditions",
                  description: "i.e like eczema, psoriasis, acne",
                },
                {
                  text: "No Known Issues",
                  description:
                    "I have looked through the list and dont recognise any coditions that are of concern to me",
                },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer5("")}
              title={answer5.text}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"85%"}
              height={120}
              borderRadius={20}
              fontSize={14}
              description={answer5.description}
              fontWeight={"bold"}
              borderOn={true}
              checkLeft={true}
            />
          )}
          {medicalInfo ? null : answer5 !== "" &&
            answer5.text !== "No Known Issues" ? (
            <TextInputField
              labelTitle={"Please provide details"}
              invert={true}
              onChange={(event) => setCodition1(event.target.value)}
              autoFocus={answer5 !== "" ? true : false}
            />
          ) : null}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            If you have more than one health concern please provide detail
            below.
          </div>

          {medicalInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer6Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : (
            <TextInputField
              labelTitle={"Please provide details"}
              invert={true}
              onChange={(event) => setAnswer6(event.target.value)}
            />
          )}

          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            3. Here is another lineup of everyday health pals that might be of
            concern.
          </div>
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            Take a look at the next list and select the condition of concern:
          </div>
          {medicalInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer7Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer7 === "" ? (
            <QuestionaireDropDown
              meidcalCheck={true}
              setItem={setAnswer7}
              data={[
                {
                  text: "Immune system Condtions ",
                  description:
                    "i.e (Autoimmune Conditions) like Rheumatoid arthritis, Lupus (systemic lupus erythematosus), Hashimoto's thyroiditis, Multiple sclerosis or related.",
                },
                {
                  text: "Allergic Conditions .",
                  description:
                    "i.e allergic rhinitis (hay fever), asthma,eczema, food allergies or related",
                },
                {
                  text: "Hormonal Conditions ",
                  description:
                    "i.e Polycystic ovary syndrome (PCOS), endometriosis, testosterone deficiency, growth hormone deficiency or related.",
                },
                {
                  text: "Sleep Disorders ",
                  description:
                    "i.e Insomnia, sleep apnoea, narcolepsy, restless legs syndrome or related.",
                },
                {
                  text: "Kidney Conditions ",
                  description:
                    "i.e Chronic kidney disease, kidney stones, urinary tract infections, glomerulonephritis or related",
                },
                {
                  text: "Blood Disorders ",
                  description:
                    "i.e Anemia, hemophilia, thrombophilia, leukemia or related.",
                },
                {
                  text: "Ear, Nose, Throat issues ",
                  description:
                    "i.e Sinusitis, tinnitus, hearing loss, vertigo or related.",
                },
                {
                  text: "Reproductive Health Issues ",
                  description:
                    "i.e Infertility, polycystic ovary syndrome (PCOS), erectile dysfunction, premenstrual syndrome (PMS).",
                },
                {
                  text: "Chronic Pain Conditions ",
                  description:
                    "i.e Fibromyalgia, chronic fatigue syndrome, complex regional pain syndrome (CRPS), migraines and headaches or related.",
                },
                {
                  text: "Gynecological Conditions",
                  description:
                    "i.e Endometriosis, uterine fibroids, ovarian cysts, pelvic inflammatory disease (PID)",
                },
                {
                  text: "Other concerns",
                  description:
                    "if you have any other medical or health concerns that we havent listed above, please provide details",
                },
                {
                  text: "No Known Issues",
                  description:
                    "I have looked through the list and dont recognise any coditions that are of concern to me",
                },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer7("")}
              title={answer7.text}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"85%"}
              height={120}
              borderRadius={20}
              fontSize={14}
              description={answer7.description}
              fontWeight={"bold"}
              borderOn={true}
              checkLeft={true}
            />
          )}
          {medicalInfo ? null : answer7 !== "" &&
            answer7.text !== "No Known Issues" ? (
            <TextInputField
              color={BlackAndWhite.primary}
              labelTitle={"Please provide details"}
              onChange={(event) => setCodition3(event.target.value)}
              autoFocus={answer7 !== "" ? true : false}
              invert={true}
            />
          ) : null}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            If you have more than one health concern please provide detail
            below.
          </div>
          {medicalInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer8Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : (
            <TextInputField
              color={BlackAndWhite.primary}
              labelTitle={"Please provide details"}
              onChange={(event) => setAnswer8(event.target.value)}
              invert={true}
            />
          )}

          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            5. Caught in the clutches of any current vices? Spill the beans on
            any habits that might be holding you hostage, if you feel like
            sharing! Of course, no pressure, your secrets are safe!
          </div>
          {medicalInfo ? (
            <>
              <QuestionButton
                borderOn={true}
                title={answer9Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer9 === "" ? (
            <QuestionaireDropDown
              meidcalCheck={true}
              setItem={setAnswer9}
              data={[
                {
                  text: "No Addiction",
                  description: "you have no addiction's",
                },
                {
                  text: "Substance Addictions ",
                  description: "i.e (cocaine, tobacco, alcohol)",
                },
                {
                  text: "Behavioural Addictions ",
                  description: "i.e (gaming, gambling, work)",
                },
                {
                  text: "Food-related Addictions ",
                  description: "i.e (Sugar, Binge eating)",
                },
                {
                  text: "Prescription Medication Addictions ",
                  description: "i.e( pain killers, opiods or sleeping pills )",
                },
                {
                  text: "Other ",
                  description:
                    "A addiction we havent listed above, please type what your addiction is below",
                },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer9("")}
              title={answer9.text}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"85%"}
              height={120}
              borderRadius={20}
              fontSize={14}
              description={answer9.description}
              fontWeight={"bold"}
              borderOn={true}
              checkLeft={true}
            />
          )}
          {medicalInfo ? null : answer9 !== "" &&
            answer9.text !== "No Addiction" ? (
            <TextInputField
              labelTitle={"Please provide details"}
              invert={true}
              onChange={(event) => setCodition5(event.target.value)}
              autoFocus={answer9 !== "" ? true : false}
            />
          ) : null}
          <div
            style={{
              marginTop: 20,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {medicalInfo ? null : (
              <BaseButtons
                label={"SUBMIT"}
                size={"80%"}
                onClick={() => medicalHandler()}
                disable={
                  answer1.length >= 2 &&
                  answer2.length >= 2 &&
                  answer3 !== "" &&
                  answer4 !== "" &&
                  answer5 !== "" &&
                  answer6 !== "" &&
                  answer7 !== "" &&
                  answer8 !== "" &&
                  answer9 !== ""
                    ? false
                    : true
                }
              />
            )}
          </div>
          <div style={{ height: 80 }} />
        </div>
      )}
    </div>
  );
};
export default MedicalQuestions;
