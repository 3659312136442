import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import { TextInputField } from "../../../components/atoms";
import { LoadingPopUp, PatientCard } from "../../../components";
import TabMobileButton from "../../customer-portal/components/mobile-layout/components/tab-mobile-button/TabMobileButton";
import QuestionButton from "../../customer-portal/components/medical-history/components/button/QuestionButton";
import SearchBar from "../../canna-pos/components/patient-display/SearchBar";
import FilterPopUp from "../../canna-pos/components/patient-display/FilterPopUp";
import PosPatientCard from "../../canna-pos/components/patient-display/PosPatientCard";

const AdminCustomerTab = ({
  isDoc,
  isCustomer,
  updateHandler,
  setSelectedPatient,
  docCheck,
  setData,
  csvExport,
  width,
  selectedPatient,
  refresh,
  setRefresh,
  customers,
  setPatientProfilePop,
}) => {
  const [arrayDisplay, setArrayDisplay] = useState(false);
  const [filterOpen, setFliterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("NONE");
  const [search, setSearch] = useState("");
  // const [displayMore, setDisplayMore] = useState(false);

  const [patientSearch, setPatientSearch] = useState("");

  return (
    <div style={{ flex: 1, overflowY: "scroll", height: "90%" }}>
      {/* <div
        style={{
          height: 80,
          width: "83%",
          backgroundColor: BlackAndWhite.secondary,
          position: "fixed",
          display: "flex",
          flexDirection: "row",
          zIndex: 0,
          borderBottomStyle: "solid",
          borderBottomColor: "#00000066",
          marginTop: 1,
        }}
      >
        <div style={{ width: 400, heght: "100%" }}>
          <TextInputField
            labelTitle={"SEARCH PATIENT BY ID"}
            invert={true}
            size={320}
            onChange={(event) => setPatientSearch(event.target.value)}
          />
        </div>
        
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            position: "relative",
            width: "100%",
          }}
        >
          <div style={{ width: 20 }} />
          You have {customers ? customers.users.length : 0} active patients.
         
          <div
            style={{
              height: 60,
              width: 60,
              borderRadius: 200,
              backgroundColor: BlackAndWhite.primary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              right: 20,
              // top: 10,
            }}
          >
            <img
              alt="icon"
              src={require("../../../images/material-icons/refreshwhite.png")}
              style={{ height: 40 }}
            />
          </div>
        </div>
      </div> */}

      {/* {selectedFilter === "NONE"
        ? customers
          ? customers.map((customer, index) => {
              return (
                <div
                  key={index}
                  style={{
                    marginBottom: index === customers.length - 1 ? 100 : 0,
                  }}
                >
                  <PatientCard
                    onClickClient={() => setSelectedPatient(customer)}
                    updateHandler={updateHandler}
                    isCustomer={isCustomer}
                    // isDoc={isDoc}
                    firstName={customer.firstName}
                    lastName={customer.lastName}
                    cellNumber={customer.cellNumber}
                    email={customer.email}
                    patientNo={customer.id}
                    idDoc={customer.idDoc}
                    dateOfBirth={customer.dateOfBirth}
                    gender={customer.gender}
                    town={customer.town}
                    suburb={customer.suburb}
                    type={customer.type}
                    dataId={customer.id}
                    docCheck={docCheck}
                  />
                </div>
              );
            })
          : null
        : null} */}
      {selectedFilter === "NONE" ? (
        customers ? (
          customers.map((patient, index) => {
            return (
              <div
                key={index}
                style={{
                  marginBottom: index === customers.length - 1 ? 100 : 0,
                }}
              >
                <PosPatientCard
                  firstName={patient ? patient.firstName : "loading"}
                  lastName={patient ? patient.lastName : "loading"}
                  idNum={patient ? patient.idDoc : "loading"}
                  email={patient ? patient.email : "loading"}
                  status={"in progress"}
                  cell={patient ? patient.cellNumber : "loading"}
                  onClick={() => {
                    setSelectedPatient(patient);
                    setPatientProfilePop(true);
                  }}
                />
              </div>
            );
          })
        ) : (
          <div style={{ width: "100%", textAlign: "center", marginTop: 40 }}>
            SEARCH FOR USER ABOVE ... WHEN YOUR READY
          </div>
        )
      ) : null}
    </div>
  );
};

export default AdminCustomerTab;
