import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { DropDownWithButton } from "../../../../../../components";
import {
  BaseButtons,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";

const SupportTicketCard = ({
  tickets,
  isSystem,
  admin,
  setMessage,
  setToastPop,
  setLoading,
  setRefresh,
  refresh,
}) => {
  const [statusDisplay, setStatusDisplay] = useState("");
  const [descriptionSwitch, setDescriptionSwitch] = useState(false);
  const [statusTitle, setStatusTitle] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [customers, setCustomers] = useState(null);

  const [updateComment, setupdateComment] = useState(false);
  const OnUserSearch = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/searchForUserPos",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchParameter: tickets.selectedUserId,
            userId: admin.adminUserData.userId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setCustomers(responseData.data);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const UpdateSupportTicket = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/support/updateSupportTicket",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            statusTitle: statusTitle,
            statusMessage: statusMessage,
            status: statusDisplay,
            supportId: tickets._id,
          }),
        }
      );

      const responseData = await response.json();
      console.log("response check", responseData);
      if (responseData.success) {
        setLoading(false);
        setMessage(responseData.success);
        setToastPop(true);
        setupdateComment(false);
        setRefresh(refresh ? false : true);
      } else {
        setLoading(false);
        setMessage(responseData.error);
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (tickets && admin) {
      setStatusDisplay(tickets.status);
      setStatusTitle(tickets.statusComments[0].title);
      setStatusMessage(tickets.statusComments[0].message);
      OnUserSearch();
    }
  }, []);
  return (
    <div
      style={{
        width: "100%",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: BlackAndWhite.secondary,
        marginTop: 2,
        overflow: "hidden",
        position: "relative",
      }}
    >
      {/* section one */}
      <div
        style={{
          flex: 0.35,

          height: "99%",
          borderWidth: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginTop: 30,
            position: "relative",
            width: "90%",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: -15,
              opacity: 0.6,
              fontSize: 12,
            }}
          >
            STORE NAME
          </div>
          {tickets.storeName}
        </div>
        <div
          style={{
            marginTop: 20,
            position: "relative",
            width: "90%",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: -15,
              opacity: 0.6,
              fontSize: 12,
            }}
          >
            TICKET CREATOR
          </div>
          {tickets.systemUser}
        </div>

        <div
          style={{
            marginTop: 20,
            position: "relative",
            width: "90%",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: -15,
              opacity: 0.6,
              fontSize: 12,
            }}
          >
            TICKET ID
          </div>
          {tickets.id}
        </div>
        <div
          style={{
            marginTop: 20,
            position: "relative",
            width: "90%",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: -15,
              opacity: 0.6,
              fontSize: 12,
            }}
          >
            CREATED ON
          </div>
          {tickets.createdOn}
        </div>
        <div style={{ marginTop: 20 }} />
        <DropDownWithButton
          support={true}
          title={statusDisplay}
          width={"100%"}
          data={["PENDING", "IN PROGRESS", "COMPLETE"]}
          setSelected={setStatusDisplay}
          dropdownHeight={120}
        />
      </div>
      {/* section 2 */}
      <div
        style={{
          flex: isSystem ? 0.7 : 0.4,

          borderWidth: 1,
          height: "99%",
          display: "flex",
          flexDirection: "column",
          //   justifyContent: "flex-start",
          alignItems: "flex-start",
          position: "relative",
        }}
      >
        {descriptionSwitch ? (
          <div
            style={{
              marginTop: 30,
              position: "relative",
              width: "90%",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: -15,
                opacity: 0.6,
                fontSize: 12,
              }}
            >
              SECTION
            </div>
            {tickets.section}
          </div>
        ) : (
          <div
            style={{
              marginTop: 30,
              position: "relative",
              width: "90%",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: -15,
                opacity: 0.6,
                fontSize: 12,
              }}
            >
              ISSUE TYPE
            </div>
            {tickets.issueType}
          </div>
        )}
        {!descriptionSwitch ? (
          <div
            style={{
              marginTop: 35,
              position: "relative",
              width: "90%",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: -20,
                opacity: 0.6,
                fontSize: 12,
              }}
            >
              ISSUE DESCRIPTION
            </div>
            {tickets.issueDescription}
          </div>
        ) : (
          <div
            style={{
              marginTop: 35,
              position: "relative",
              width: "90%",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: -20,
                opacity: 0.6,
                fontSize: 12,
              }}
            >
              SECTION ERROR DESCRIPTION
            </div>
            {tickets.sectionErrorDescription}
          </div>
        )}
        {isSystem ? (
          <div
            style={{
              display: "flex",
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <BaseButtons
              label={"TYPE"}
              mini={true}
              marginTopFalse={true}
              height={30}
              size={80}
              borderRadius={4}
              onClick={() => setDescriptionSwitch(false)}
              invert={descriptionSwitch ? true : false}
            />
            <div style={{ width: 5 }} />
            <BaseButtons
              label={"SECTION"}
              mini={true}
              marginTopFalse={true}
              height={30}
              size={80}
              borderRadius={4}
              onClick={() => setDescriptionSwitch(true)}
              invert={descriptionSwitch ? false : true}
            />
          </div>
        ) : null}
      </div>
      {/* section 3 */}
      {isSystem ? null : (
        <div
          style={{
            flex: 0.3,
            borderWidth: 1,
            height: "99%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: 30,
              position: "relative",
              width: "90%",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: -15,
                opacity: 0.6,
                fontSize: 12,
              }}
            >
              PATIENT NAME
            </div>
            {customers
              ? customers.length >= 1
                ? customers[0].firstName + " " + customers[0].lastName
                : "loading"
              : "loading"}
          </div>
          <div
            style={{
              marginTop: 20,
              position: "relative",
              width: "90%",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: -15,
                opacity: 0.6,
                fontSize: 12,
              }}
            >
              GENDER
            </div>
            {customers
              ? customers.length >= 1
                ? customers[0].gender
                : "loading"
              : "loading"}
          </div>
          <div
            style={{
              marginTop: 25,
              position: "relative",
              width: "90%",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: -15,
                opacity: 0.6,
                fontSize: 12,
              }}
            >
              EMAIL
            </div>
            {customers
              ? customers.length >= 1
                ? customers[0].email
                : "loading"
              : "loading"}
          </div>
          <div
            style={{
              marginTop: 25,
              position: "relative",
              width: "90%",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: -15,
                opacity: 0.6,
                fontSize: 12,
              }}
            >
              CELL NUMBER
            </div>
            {customers
              ? customers.length >= 1
                ? customers[0].cellNumber
                : "loading"
              : "loading"}
          </div>
          <div
            style={{
              display: "flex",
              position: "absolute",
              right: 20,
              bottom: 20,
            }}
          >
            <BaseButtons
              label={"COMMENTS"}
              mini={true}
              marginTopFalse={true}
              height={35}
              size={120}
              borderRadius={4}
              onClick={() => setupdateComment(true)}
            />
          </div>
        </div>
      )}
      {isSystem ? (
        <div
          style={{
            display: "flex",
            position: "absolute",
            right: 20,
            bottom: 20,
          }}
        >
          <BaseButtons
            label={"COMMENTS"}
            mini={true}
            marginTopFalse={true}
            height={35}
            size={120}
            borderRadius={4}
            onClick={() => setupdateComment(true)}
          />
        </div>
      ) : null}
      <div
        style={{
          position: "absolute",
          width: updateComment ? "67%" : 0,
          backgroundColor: BlackAndWhite.secondary,
          right: 0,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          transition: "all 0.3s",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: BlackAndWhite.primary,

            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            overflow: "hidden",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <TextInputField
            mini={true}
            labelTitle={"COMMENT TITLE"}
            height={20}
            width={"100%"}
            size={"85%"}
            value={statusTitle}
            onChange={(e) => setStatusTitle(e.target.value)}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <TextAreaInput
              mini={true}
              labelTitle={"DESCRIPTION"}
              height={100}
              width={"70%"}
              size={"80%"}
              value={statusMessage}
              onChange={(e) => setStatusMessage(e.target.value)}
            />
            <div
              style={{
                width: "30%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ marginRight: 20, marginTop: 30 }}>
                <BaseButtons
                  label={"UPDATE"}
                  mini={true}
                  marginTopFalse={true}
                  height={40}
                  invert={true}
                  borderRadius={8}
                  onClick={() => UpdateSupportTicket()}
                  disable={
                    statusMessage !== tickets.statusComments[0].message ||
                    statusTitle !== tickets.statusComments[0].title ||
                    statusDisplay !== tickets.status
                      ? false
                      : true
                  }
                />
                <div style={{ height: 30 }} />
                <BaseButtons
                  label={"CANCEL"}
                  mini={true}
                  marginTopFalse={true}
                  height={40}
                  invert={true}
                  borderRadius={8}
                  onClick={() => setupdateComment(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTicketCard;
