import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  CheckInput,
  CloseButton,
  TextAreaInput,
  TextInputField,
} from "../../../../components/atoms";

const EditPopUp = ({
  display,
  onCloseClick,
  setLoading,
  setMessage,
  setToastPop,
  setSelectedMenuItem,
  setRefresh,
  refresh,
}) => {
  const [productName, setProductName] = useState("");
  const [type, setType] = useState("");
  const [strain, setStrain] = useState("");
  const [thcContent, setThcContent] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [menuPage, setMenuPage] = useState("");
  const [tvPosition, setTvPostion] = useState("");
  const [langabaan, setLangabaan] = useState("");
  const [woodstock, setWoodstock] = useState("");
  const [malmesbury, setMalmesbury] = useState("");
  const [mosselBay, setMosselBay] = useState("");
  const [woester, setWoester] = useState("");
  const [panorama, setPanorama] = useState("");
  const [hermanus, setHermanus] = useState("");
  const [wellington, setWellington] = useState("");
  const [open, setOpen] = useState(false);

  const updateHandler = async () => {
    setLoading(true);
    // https://merry-jane-api.onrender.com
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/menu/editMenuItem",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productName: productName,
            type: type,
            strain: strain,
            thcContent: thcContent,
            description: description,
            productNo: display.productNo,
            imageUrl: "coming soon",
            addedOn: new Date().toDateString(),
            price: price,
            menuPage: menuPage,
            tvPosition: tvPosition,
            langabaan: langabaan,
            woodstock: woodstock,
            malmesbury: malmesbury,
            mosselBay: mosselBay,
            woester: woester,
            panorama: panorama,
            hermanus: hermanus,
            wellington: wellington,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.message === "Menu item was updated") {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
        setSelectedMenuItem(null);
        setRefresh(refresh ? false : true);
      }
    } catch (err) {
      setLoading(false);
      setMessage("could not update menu item please conatct support");
      setToastPop(true);
      console.log("error", err);
    }
  };
  useEffect(() => {
    setOpen(true);
    if (display) {
      setProductName(display.productName);
      setStrain(display.strain);
      setType(display.type);
      setPrice(display.price);
      setThcContent(display.thcContent);
      setDescription(display.description);
      setMenuPage(display.menuPage);
      setTvPostion(display.tvPosition);
      setLangabaan(display.langabaan);
      setWoodstock(display.woodstock);
      setMalmesbury(display.storefield1);
      setMosselBay(display.storefield2);
      setWoester(display.storefield3);
      setPanorama(display.storefield4);
      setHermanus(display.storefield5);
      setWellington(display.storefield6);
    }
  }, [display]);

  return (
    <div
      style={{
        height: open ? "100%" : 0,
        width: "100%",
        position: "absolute",
        top: 0,
        backgroundColor: "#00000050",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.5s",
        opacity: open ? 1 : 0,
      }}
    >
      <div
        style={{
          height: "90%",
          width: "90%",
          backgroundColor: BlackAndWhite.secondary,
          borderRadius: 8,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          //   justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div
            style={{
              flex: 0.34,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TextInputField
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              labelTitle={"ProductName"}
              invert={true}
            />
            <div style={{ marginTop: 20 }}>
              <div style={{ marginLeft: 20 }}>TYPE</div>

              {display ? (
                <div
                  style={{
                    display: "flex",
                    width: 300,
                    flexDirection: "column",
                    marginLeft: 20,
                  }}
                >
                  <CheckInput
                    check={type === "Indoor" ? true : false}
                    onClick={() => setType("Indoor")}
                    question={"Indoor"}
                    invert={true}
                    size={"100%"}
                  />
                  <CheckInput
                    check={type === "GreenHouse" ? true : false}
                    onClick={() => setType("Greenhouse")}
                    question={"Greenhouse"}
                    invert={true}
                    size={"100%"}
                  />
                </div>
              ) : (
                "loading"
              )}
            </div>
            <TextInputField
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              labelTitle={"Price"}
              invert={true}
            />
            <TextInputField
              value={thcContent}
              onChange={(e) => setThcContent(e.target.value)}
              labelTitle={"THC content"}
              invert={true}
            />
          </div>
          <div
            style={{
              flex: 0.33,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextAreaInput
              labelTitle={"Description"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              color={BlackAndWhite.primary}
              height={150}
            />
            <div style={{ display: "flex" }}>
              <TextInputField
                value={menuPage}
                onChange={(e) => setMenuPage(e.target.value)}
                labelTitle={"MENU"}
                invert={true}
                size={80}
              />
              <div style={{ width: 20 }} />
              <TextInputField
                value={tvPosition}
                onChange={(e) => setTvPostion(e.target.value)}
                labelTitle={"TV"}
                invert={true}
                size={80}
              />
            </div>
            <div style={{ marginTop: 20 }}>Available at:</div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                // height: 200,
              }}
            >
              <CheckInput
                check={langabaan === "true" ? true : false}
                onClick={() =>
                  setLangabaan(langabaan === "true" ? "false" : "true")
                }
                question={"Langebaan"}
                invert={true}
                size={"40%"}
              />
              <CheckInput
                check={woodstock === "true" ? true : false}
                onClick={() =>
                  setWoodstock(woodstock === "true" ? "false" : "true")
                }
                question={"Woodstock"}
                invert={true}
                size={"40%"}
              />
              <CheckInput
                check={malmesbury === "malmesbury" ? true : false}
                onClick={() =>
                  setMalmesbury(
                    malmesbury === "malmesbury" ? "none" : "malmesbury"
                  )
                }
                question={"Malmesbury"}
                invert={true}
                size={"40%"}
              />
              <CheckInput
                check={mosselBay === "mosselBay" ? true : false}
                onClick={() =>
                  setMosselBay(mosselBay === "mosselBay" ? "none" : "mosselBay")
                }
                question={"mosselBay"}
                invert={true}
                size={"40%"}
              />
              <CheckInput
                check={woester === "woester" ? true : false}
                onClick={() =>
                  setWoester(woester === "woester" ? "none" : "woester")
                }
                question={"Worcester"}
                invert={true}
                size={"40%"}
              />
              <CheckInput
                check={panorama === "panorama" ? true : false}
                onClick={() =>
                  setPanorama(panorama === "panorama" ? "none" : "panorama")
                }
                question={"Panorama"}
                invert={true}
                size={"40%"}
              />
              <CheckInput
                check={hermanus === "hermanus" ? true : false}
                onClick={() =>
                  setHermanus(hermanus === "hermanus" ? "none" : "hermanus")
                }
                question={"Hermanus"}
                invert={true}
                size={"40%"}
              />
              <CheckInput
                check={wellington === "wellington" ? true : false}
                onClick={() =>
                  setWellington(
                    wellington === "wellington" ? "none" : "wellington"
                  )
                }
                question={"Wellington"}
                invert={true}
                size={"40%"}
              />
            </div>
          </div>
          <div
            style={{
              flex: 0.33,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              Strains
              <div
                style={{
                  display: "flex",
                  width: 300,
                  flexDirection: "column",
                  marginLeft: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CheckInput
                  check={strain === "INDICA HYBRID" ? true : false}
                  onClick={() => setStrain("INDICA HYBRID")}
                  question={"INDICA HYBRID"}
                  invert={true}
                  size={"100%"}
                />
                <CheckInput
                  check={strain === "PURE INDICA" ? true : false}
                  onClick={() => setStrain("PURE INDICA")}
                  question={"PURE INDICA"}
                  invert={true}
                  size={"100%"}
                />
                <CheckInput
                  check={strain === "BALANCED" ? true : false}
                  onClick={() => setStrain("BALANCED")}
                  question={"BALANCED"}
                  invert={true}
                  size={"100%"}
                />
                <CheckInput
                  check={strain === "SATIVA HYBRID" ? true : false}
                  onClick={() => setStrain("SATIVA HYBRID")}
                  question={"SATIVA HYBRID"}
                  invert={true}
                  size={"100%"}
                />
                <CheckInput
                  check={strain === "PURE SATIVA" ? true : false}
                  onClick={() => setStrain("PURE SATIVA")}
                  question={"PURE SATIVA"}
                  invert={true}
                  size={"100%"}
                />
                <CheckInput
                  check={strain === "COMING SOON" ? true : false}
                  onClick={() => setStrain("COMING SOON")}
                  question={"COMING SOON"}
                  invert={true}
                  size={"100%"}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", marginBottom: 10 }}>
          <BaseButtons label={"CANCEL"} onClick={onCloseClick} />
          <div style={{ width: 50 }} />
          <BaseButtons label={"SAVE CHANGES"} onClick={() => updateHandler()} />
        </div>
        <div style={{ position: "absolute", left: 10, top: 10 }}>
          <CloseButton label={"X"} onClick={onCloseClick} />
        </div>
      </div>
    </div>
  );
};

export default EditPopUp;
