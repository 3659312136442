import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  CheckInput,
  TextInputField,
} from "../../../../components/atoms";
import StatusDisplay from "../status-display/StatusDisplay";

const RefistrationPopUp = ({
  display,
  loading,
  setLoading,
  onCloseClick,
  setRegPopUp,
  setFoundInvite,
  foundInvite,
  setMessage,
  setToastPop,
}) => {
  const [count, setCount] = useState(1);

  // Practiner information
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [email, setEmail] = useState("");
  const [idDoc, setIdDoc] = useState("");
  const [province, setProvince] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [acceptedTandC, setAcceptedTandC] = useState(false);

  //practice information
  const [practiceName, setPracticeName] = useState("");
  const [association, setAssociation] = useState("");
  const [practiceCellNumber, setPracticeCellNumber] = useState("");
  const [landLine, setLandLine] = useState("");
  const [practiceEmail, setPracticeEmail] = useState("");
  const [regNo, setRegNo] = useState("");
  const [practiceNo, setPracticeNo] = useState("");
  const [website, setWebsite] = useState("");
  const [locationLink, setLocationLink] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [suburb, setSuburb] = useState("");
  const [city, setCity] = useState("");
  const [provincePractic, setProvincePractice] = useState("");
  const [practionerTitle, setPracticionerTitle] = useState("");
  const [docImageLink, setDocImageLink] = useState("");
  const [practiceLogoLink, setPracticeLogoLink] = useState("");

  const ClearPracticeSelection = () => {
    setPracticeName("");
    setAssociation("");
    setPracticeCellNumber("");
    setLandLine("");
    setPracticeEmail("");
    setRegNo("");
    setPracticeNo("");
    setWebsite("");
    setLocationLink("");
    setStreetNumber("");
    setStreetName("");
    setProvincePractice("");
    setPracticionerTitle("");
    setDocImageLink("");
    setPracticeLogoLink("");
  };

  const ClearPrimarySelection = () => {
    setFirstName("");
    setLastName("");
    setCellNumber("");
    setEmail("");
    setIdDoc("");
    setProvince("");
    setPassword("");
    setConfirmPassword("");
    setPin("");
    setConfirmPin("");
    setAcceptedTandC(false);
    setFoundInvite(null);
  };
  const SubmitForApproval = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/practioner/createPractionerUsers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            cellNumber: cellNumber,
            email: email,
            idDoc: idDoc,
            province: province,
            storeName: "N/A",
            storeId: "N/A",
            userName: email,
            dateOfBirth: idDoc.substring(0, 6),
            password: password,
            acceptedTandC: acceptedTandC,
            type: "Practitioner",
            profileImage: "coming soon",
            pin: pin,
            practiceType: foundInvite.type,
            invitedBy: foundInvite.createdBy,
            PracticeName: practiceName,
            Association: association,
            regNo: regNo,
            practiceNo: practiceName,
            practiceEmail: practiceEmail,
            practiceCellNumber: practiceCellNumber,
            landLineNo: landLine,
            website: website,
            residentialAddress:
              streetNumber + ", " + streetName + ", " + suburb + ", " + city,
            practionerImage: docImageLink,
            practiceLogo: practiceLogoLink,
            docSigniture: "coming soon",
            practiceStatus: "Pending",
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        setMessage(responseData.success);
        setToastPop(true);
        setLoading(false);
        setRegPopUp(false);
        setFoundInvite(null);
      } else {
        setMessage(responseData.error);
        setToastPop(true);
        setLoading(false);
      }
    } catch (err) {
      setMessage("Please check your connection then try again");
      setToastPop(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (foundInvite) {
      setFirstName(foundInvite.firstName);
      setLastName(foundInvite.lastName);
      setCellNumber(foundInvite.cellNumber);
      setEmail(foundInvite.email);
    }
  }, [display]);

  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: display ? "100%" : 0,
        overflow: "hidden",
        position: "absolute",
        transition: "all 0.3s",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "90%",
          width: "85%",
          backgroundColor: BlackAndWhite.primary,
          borderRadius: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", top: -30 }}>
          <StatusDisplay step={1} count={count} />
        </div>
        <div
          style={{
            height: "98%",
            width: "99%",
            backgroundColor: BlackAndWhite.secondary,
            borderRadius: 18,
            display: "flex",
          }}
        >
          {/* section 1 */}
          <div
            style={{
              flex: count === 2 ? 0 : 0.5,
              height: count === 2 ? 0 : "100%",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              overflow: "hidden",
              transition: "all 0.4s",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  marginLeft: 25,
                  marginTop: 30,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                BASIC PERSONAL INFORAMTION
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  marginLeft: 25,
                  marginTop: 20,
                  fontSize: 16,
                  marginBottom: 20,
                }}
              >
                We use this to link you to your practice and patients
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextInputField
                invert={true}
                labelTitle={"First Name"}
                mini={true}
                width={"50%"}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextInputField
                invert={true}
                labelTitle={"Last Name"}
                mini={true}
                width={"50%"}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <TextInputField
                invert={true}
                labelTitle={"Cell Number"}
                mini={true}
                width={"50%"}
                value={cellNumber}
                onChange={(e) => setCellNumber(e.target.value)}
              />
              <TextInputField
                invert={true}
                labelTitle={"Username (Auto generated)"}
                mini={true}
                width={"50%"}
                readOnly={true}
                value={email}
              />
            </div>
            <TextInputField
              invert={true}
              labelTitle={"Email"}
              mini={true}
              width={"100%"}
              size={"87%"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <TextInputField
                invert={true}
                labelTitle={"Id Number"}
                mini={true}
                width={"50%"}
                value={idDoc}
                onChange={(e) => setIdDoc(e.target.value)}
              />
              <TextInputField
                invert={true}
                labelTitle={"Province"}
                mini={true}
                width={"50%"}
                value={province}
                onChange={(e) => setProvince(e.target.value)}
              />
            </div>
            <TextInputField
              invert={true}
              labelTitle={"Account Number ( Auto genearted )"}
              mini={true}
              width={"100%"}
              size={"87%"}
              readOnly={true}
            />
          </div>
          {/* section 2 */}
          <div
            style={{
              flex: count === 2 ? 0 : 0.5,
              height: count === 2 ? 0 : "100%",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              alignItems: "center",
              overflow: "hidden",
              transition: "all 0.4s",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  //   marginLeft: 25,
                  marginTop: 30,
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Lets add your Passwords and pin
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  //   marginLeft: 25,
                  marginTop: 20,
                  fontSize: 16,
                  marginBottom: 20,
                  textAlign: "center",
                }}
              >
                Remember to make sure you use a password you wont forget...
              </div>
            </div>
            <div
              style={{
                flex: 1,
                width: "80%",
                backgroundColor: BlackAndWhite.primary,
                marginBottom: 20,
                borderRadius: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextInputField
                labelTitle={"Password"}
                mini={true}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextInputField
                labelTitle={"Confirm Password"}
                mini={true}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <TextInputField
                labelTitle={"Pin"}
                mini={true}
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
              <TextInputField
                labelTitle={"Confirm Pin"}
                mini={true}
                value={confirmPin}
                onChange={(e) => setConfirmPin(e.target.value)}
              />
              <div style={{ height: 20 }} />
              <CheckInput
                question={"Accept T&C's"}
                mini={true}
                check={acceptedTandC}
                onClick={() => setAcceptedTandC(acceptedTandC ? false : true)}
              />
              <div style={{ display: "flex" }}>
                <BaseButtons
                  label={"CANCEL"}
                  invert={true}
                  size={180}
                  onClick={() => {
                    setRegPopUp(false);
                    ClearPrimarySelection();
                  }}
                  mini={true}
                />

                <div style={{ width: 20 }} />
                <BaseButtons
                  label={"NEXT"}
                  invert={true}
                  size={180}
                  onClick={() => setCount(2)}
                  mini={true}
                />
              </div>
            </div>
          </div>
          {/* section 1 practice information */}
          <div
            style={{
              flex: count === 2 ? 0.5 : 0,
              height: count === 2 ? "100%" : 0,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              overflow: "hidden",
              transition: "all 0.4s",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  marginLeft: 25,
                  marginTop: 30,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                BASIC PRACTICE INFORMATION
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  marginLeft: 25,
                  marginTop: 20,
                  fontSize: 16,
                  marginBottom: 20,
                }}
              >
                WE NEED YOUR PRACTICE INFRMATION ....
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextInputField
                invert={true}
                labelTitle={"Practice Name"}
                mini={true}
                width={"50%"}
                value={practiceName}
                onChange={(e) => setPracticeName(e.target.value)}
              />
              <TextInputField
                invert={true}
                labelTitle={"Association"}
                mini={true}
                width={"50%"}
                value={association}
                onChange={(e) => setAssociation(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <TextInputField
                invert={true}
                labelTitle={"Cell Number"}
                mini={true}
                width={"50%"}
                value={practiceCellNumber}
                onChange={(e) => setPracticeCellNumber(e.target.value)}
              />
              <TextInputField
                invert={true}
                labelTitle={"Landline"}
                mini={true}
                width={"50%"}
                value={landLine}
                onChange={(e) => setLandLine(e.target.value)}
              />
            </div>
            <TextInputField
              invert={true}
              labelTitle={"Email"}
              mini={true}
              width={"100%"}
              size={"87%"}
              value={practiceEmail}
              onChange={(e) => setPracticeEmail(e.target.value)}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <TextInputField
                invert={true}
                labelTitle={"Reg no."}
                mini={true}
                width={"50%"}
                value={regNo}
                onChange={(e) => setRegNo(e.target.value)}
              />
              <TextInputField
                invert={true}
                labelTitle={"Practice No."}
                mini={true}
                width={"50%"}
                value={practiceNo}
                onChange={(e) => setPracticeNo(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 0,
                marginBottom: 20,
              }}
            >
              <TextInputField
                invert={true}
                labelTitle={"Website"}
                mini={true}
                width={"50%"}
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
              <TextInputField
                invert={true}
                labelTitle={"Location link"}
                mini={true}
                width={"50%"}
                value={locationLink}
                onChange={(e) => setLocationLink(e.target.value)}
              />
            </div>
          </div>
          {/* section 2 */}
          <div
            style={{
              flex: count === 2 ? 0.5 : 0,
              height: count === 2 ? "100%" : 0,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              alignItems: "center",
              overflow: "hidden",
              transition: "all 0.4s",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  //   marginLeft: 25,
                  marginTop: 30,
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Secondary Information
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  //   marginLeft: 25,
                  marginTop: 20,
                  fontSize: 16,
                  marginBottom: 20,
                  textAlign: "center",
                }}
              >
                Dont worry we are almost done...
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextInputField
                invert={true}
                labelTitle={"Unit Number"}
                mini={true}
                width={"50%"}
                value={streetNumber}
                onChange={(e) => setStreetNumber(e.target.value)}
              />
              <TextInputField
                invert={true}
                labelTitle={"Street Name"}
                mini={true}
                width={"50%"}
                value={streetName}
                onChange={(e) => setStreetName(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <TextInputField
                invert={true}
                labelTitle={"Suburb"}
                mini={true}
                width={"50%"}
                value={suburb}
                onChange={(e) => setSuburb(e.target.value)}
              />
              <TextInputField
                invert={true}
                labelTitle={"City"}
                mini={true}
                width={"50%"}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <TextInputField
                invert={true}
                labelTitle={"Province"}
                mini={true}
                width={"50%"}
                value={provincePractic}
                onChange={(e) => setProvincePractice(e.target.value)}
              />
              <TextInputField
                invert={true}
                labelTitle={"Practioner Title"}
                mini={true}
                width={"50%"}
                value={practionerTitle}
                onChange={(e) => setPracticionerTitle(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <TextInputField
                invert={true}
                labelTitle={"Dr image link"}
                mini={true}
                width={"50%"}
                value={docImageLink}
                onChange={(e) => setDocImageLink(e.target.value)}
              />
              <TextInputField
                invert={true}
                labelTitle={"Practice image link"}
                mini={true}
                width={"50%"}
                value={practiceLogoLink}
                onChange={(e) => setPracticeLogoLink(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <BaseButtons
                label={"BACK"}
                onClick={() => {
                  setCount(1);
                  ClearPracticeSelection();
                }}
                mini={true}
                size={240}
              />
              <BaseButtons
                label={"SUBMIT"}
                mini={true}
                size={240}
                onClick={() => SubmitForApproval()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefistrationPopUp;
