import React, { useState } from "react";
import { BlackAndWhite } from "../../themes/Colors";

const SocialIconDisplay = ({ image, size, marginBottom, marginTop }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: 52,
        height: 52,
        borderRadius: 50,
        backgroundColor: BlackAndWhite.mattGreen,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        // borderStyle: "solid",
        // borderColor: BlackAndWhite.mattGreen,
        // borderWidth: 3,
        WebkitBoxShadow: "0px 0px 5px 1px #00000050",
        marginTop: marginTop ? 5 : 0,
        marginBottom: marginBottom ? 5 : 0,
      }}
    >
      <img
        alt="icon"
        src={image}
        style={{ height: hover ? size + 5 : size, transition: "all .3s" }}
      />
    </div>
  );
};
export default SocialIconDisplay;
