import React, { useState } from "react";
import { BaseButtons, CloseButton, TextInputField } from "../atoms";
import { BlackAndWhite } from "../../themes/Colors";
import SelectStorePopUp from "../selected-store-pop-up/SelectStorePopUp";

const ManagerForm = ({
  onCloseClick,
  firstName,
  lastName,
  idNumber,
  email,
  password,
  onFirstNameChange,
  onLastNameChange,
  onIdNumChange,
  onEmailChange,
  isBussinesClick,
  onSubmitClick,
  onPasswordChange,
  onAccountNameChange,
  setProvince,
  province,
  onCellChange,
  selectedStore,
  setSelectedStore,
}) => {
  const [confirmPassword, setConfirmPassword] = useState("none");
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            flex: 0.7,
            height: "100%",
            position: "relative",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              height: "100%",
            }}
          >
            <TextInputField
              labelTitle={"First name:"}
              invert={true}
              value={firstName}
              onChange={onFirstNameChange}
              mini={true}
            />
            <TextInputField
              labelTitle={"Last name:"}
              invert={true}
              value={lastName}
              onChange={onLastNameChange}
              mini={true}
            />
            <TextInputField
              labelTitle={"Id-Num / Passport:"}
              invert={true}
              value={idNumber}
              onChange={onIdNumChange}
              mini={true}
            />

            <TextInputField
              labelTitle={"Email:"}
              invert={true}
              value={email}
              onChange={onEmailChange}
              mini={true}
            />
            <TextInputField
              labelTitle={"Cell number:"}
              invert={true}
              onChange={onCellChange}
              mini={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              height: "100%",
            }}
          >
            <div
              style={{
                height: 100,
                width: 250,
                marginTop: 20,
                textAlign: "center",
              }}
            >
              Click below to select store
              <BaseButtons
                size={250}
                borderRadius={1}
                label={
                  selectedStore && selectedStore !== "open"
                    ? selectedStore.storeName
                    : "SELECT STORE"
                }
                onClick={() => setSelectedStore("open")}
              />
            </div>
            {/* // add a on store selectect slect from list of stores need a search as well */}
            {/* <TextInputField labelTitle={"Store :"} invert={true} /> */}
            <TextInputField
              labelTitle={"Account Num:"}
              invert={true}
              onChange={onAccountNameChange}
              mini={true}
            />
            <TextInputField
              labelTitle={"Password:"}
              invert={true}
              onChange={onPasswordChange}
              value={password}
              mini={true}
            />
            <TextInputField
              labelTitle={"Confirm password:"}
              invert={true}
              onChange={(e) => setConfirmPassword(e.target.value)}
              mini={true}
            />
          </div>
          <div style={{ position: "absolute", top: 20, left: 20 }}>
            <CloseButton label={"X"} onClick={onCloseClick} />
          </div>
        </div>
        <div
          style={{
            flex: 0.3,
            backgroundColor: BlackAndWhite.primary,
            borderTopLeftRadius: 20,
            height: "99%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 60,
              display: "flex",
              fontSize: 22,
              alignItems: "center",
              color: BlackAndWhite.secondary,
            }}
          >
            <div style={{ marginLeft: 20 }}>Create a new Manager</div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              color: BlackAndWhite.secondary,
            }}
          >
            <div style={{ width: "80%", textAlign: "left", marginTop: 20 }}>
              1. Please fill out all the information on the left starting with
              first name.
            </div>
            <div style={{ width: "80%", textAlign: "left", marginTop: 20 }}>
              2. Make sure the email address is correct as the invite for the
              User will be sent to them.
            </div>
            <div style={{ width: "80%", textAlign: "left", marginTop: 20 }}>
              3. A store is needed in order for the Manager to be created, dont
              forget to create a store first. If the store you are looking for
              is in the list, just select it.
            </div>
            <BaseButtons
              label={"SUBMIT"}
              disable={
                firstName.length >= 2 &&
                lastName.length >= 2 &&
                email.length >= 6 &&
                confirmPassword === password
                  ? false
                  : true
              }
              invert={true}
              onClick={onSubmitClick}
            />
          </div>
        </div>
      </div>
      <SelectStorePopUp
        display={selectedStore === "open" ? true : false}
        onCloseClick={() => setSelectedStore(null)}
        setSelectedStore={setSelectedStore}
      />
    </div>
  );
};

export default ManagerForm;
