import React from "react";
import { BlackAndWhite } from "../../../themes/Colors";

const CheckInput = ({
  width,
  check,
  question,
  onClick,
  size,
  invert,
  mini,
  marginRight,
}) => {
  return (
    <div
      style={{
        minHeight: mini ? 40 : 60,
        width: size,
        display: "flex",
        flexDirection: "row",
        justifyContent: width > 899 ? "flex-start" : "center",
        alignItems: "center",
        marginTop: 0,
        // marginLeft: 20,
        color: invert ? BlackAndWhite.primary : BlackAndWhite.secondary,
        marginRight: marginRight ? marginRight : null,
      }}
    >
      <div
        onClick={onClick}
        style={{
          height: 20,
          minWidth: width > 899 ? (mini ? 40 : 20) : 20,
          borderRadius: 100,
          borderStyle: "solid",
          marginLeft: mini ? null : 20,
          borderWidth: 0.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderColor: invert ? BlackAndWhite.primary : BlackAndWhite.secondary,
          // marginLeft: width > 899 ? 120 : null,
        }}
      >
        {check ? (
          <div
            style={{
              backgroundColor: invert
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary,
              height: mini ? "70%" : "70%",
              width: mini ? "70%" : "70%",
              borderRadius: 100,
            }}
          />
        ) : null}
      </div>
      <div
        style={{
          color: invert ? BlackAndWhite.primary : BlackAndWhite.secondary,
          marginLeft: 20,
          fontSize: width > 899 ? (mini ? 12 : 14) : 14,
          // width: width > 899 ? (mini ? 100 : 200) : 200,
        }}
      >
        {question}
      </div>
    </div>
  );
};

export default CheckInput;
