import React, { useState } from "react";
import useWindowDimensions from "../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../themes/Colors";
import { BaseButtons, CloseButton, TextInputField } from "../atoms";

const AreYou21 = ({ onBackClick, display, onYesClick, onNoClick }) => {
  const { width, height } = useWindowDimensions();

  //user login info

  return (
    <div
      style={{
        height: display ? height : 0,
        width: width,
        // backgroundColor: "#00000066",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.5s",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          height: width > 899 ? "85%" : "90%",
          width: width > 899 ? "30%" : "80%",
          backgroundColor: "#000000cc",
          borderRadius: 10,
          // borderStyle: "solid",
          // borderWidth: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div style={{ position: "absolute", left: 20, top: 20 }}>
          <CloseButton
            width={80}
            onClick={onBackClick}
            height={30}
            invert={true}
          />
        </div>

        <div
          style={{
            flex: 0.9,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="logo"
              src={require("../../images/merryjanelogooptions/Merry-Jane Logo Black on White Alt.png")}
              style={{ width: width > 899 ? 80 : 50, marginTop: 20 }}
            />
            <div
              style={{
                marginTop: 40,
                fontSize: 20,
                color: BlackAndWhite.secondary,
                // fontFamily: "calibri",
              }}
            >
              "Merry Jane says..."
            </div>
          </div>

          {/* <div
            style={{
              marginTop: 10,
              fontSize: 28,
              color: BlackAndWhite.secondary,
            }}
          >
           
          </div> */}
          <div
            style={{
              marginTop: 40,
              fontSize: 20,
              color: BlackAndWhite.secondary,
              width: "70%",
              textAlign: "center",
              marginBottom:10
              // fontFamily: "Montserrat",
            }}
          >
            By clicking 'YES' you verify that you are 18 years of age or older
            and by entering this site, you are agreeing to the{" "}
            <a
              style={{ color: BlackAndWhite.secondary }}
              href="https://docs.google.com/document/d/e/2PACX-1vTXiPb6kXz9MZ5F3Ek7YntCTlzBDBPSQt-1sH5c1jzpbmGoQ5kIqVx-wNikvr83RA/pub"
            >
              Terms of Use and Privacy Policy.
            </a>
          </div>
          {/* <div
            style={{
              marginTop: 2,
              fontSize: 26,
              color: BlackAndWhite.secondary,
              width: "90%",
              textAlign: "center",
              fontFamily: "Montserrat",
            }}
          >
            
          </div> */}
        </div>
        <BaseButtons
          width={"80%"}
          label={"YES"}
          onClick={onYesClick}
          invert={true}
        />
        <BaseButtons
          width={"80%"}
          label={"NO"}
          onClick={onNoClick}
          invert={true}
        />
      </div>
    </div>
  );
};

export default AreYou21;
