import React from "react";
import { BlackAndWhite } from "../../themes/Colors";

const ToastNotification = ({ display, message }) => {
  return (
    <div
      style={{
        height: display ? 150 : 0,
        width: "100%",
        backgroundColor: "#000000cc",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "absolute",
        top: 0,
        transition: "height 0.5s",
        color: "#FFFFFF",
        fontWeight: "bold",
        zIndex: 9000000000000,
      }}
    >
      <div style={{ width: "80%", textAlign: "left" }}>{message}</div>
    </div>
  );
};

export default ToastNotification;
