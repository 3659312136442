import React from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import BaseButtons from "../../base-buttons/BaseButtons";
import "./HelpPopUp.css";

const HelpPopUp = ({ width, onNotClick, onGotItClick, display }) => {
  return (
    <div
      className="help-container"
      style={{
        position: "absolute",
        height: display ? "95%" : 0,
        width: width > 899 ? "45%" : "95%",
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        bottom: 0,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        overflow: display ? (width > 1299 ? null : "scroll") : "hidden",
        alignItems: "center",
        transition: "height 0.6s",
      }}
    >
      <div
        style={{
          // position: "absolute",
          width: "100%",
          // top: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // borderStyle: "solid",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: width > 899 ? "row" : "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="logo"
            src={require("../../../../images/merryjanelogooptions/mainLogo.png")}
            style={{
              width: width > 899 ? 80 : 80,
              marginTop: 20,
              marginBottom: 20,
            }}
          />
          <div
            style={{
              marginTop: 15,
              marginBottom: 30,
              fontWeight: "bold",
              fontSize: width > 899 ? 22 : 22,
              fontFamily: "Montserrat",
              marginLeft: 20,
            }}
          >
            "Merry-Jane says ...."
          </div>
        </div>
      </div>
      <div
        style={{
          fontFamily: "Montserrat",
          textAlign: "left",
          fontSize: width > 899 ? 16 : 16,
          lineHeight: 1.6,
          width: "90%",
          marginBottom: 5,
          marginTop: 8,
          display: "flex",
        }}
      >
        <div
          style={{
            height: 25,
            width: width > 899 ? 50 : 100,
            borderRadius: "100%",
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFFFFF",
            backgroundColor: BlackAndWhite.primary,
            // padding: 5,
          }}
        >
          1
        </div>
        Buckle up for an express sign-up registration! In under 10 minutes,
        you'll be cruising through the process, and the best part , it’s on the
        house.
      </div>
      <div
        style={{
          fontFamily: "Montserrat",
          textAlign: "left",
          fontSize: width > 899 ? 16 : 16,
          lineHeight: 1.6,
          width: "90%",
          marginBottom: 5,
          marginTop: 8,
          display: "flex",
        }}
      >
        <div
          style={{
            height: 25,
            width: width > 899 ? 50 : 100,
            borderRadius: "100%",
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFFFFF",
            backgroundColor: BlackAndWhite.primary,
            // padding: 5,
          }}
        >
          2
        </div>
        We're on a quest for the deets! We'll ask nicely for a bit of your
        personal magic and dive into the specifics of your unique story.
      </div>
      <div
        style={{
          fontFamily: "Montserrat",
          textAlign: "left",
          fontSize: width > 899 ? 16 : 16,
          lineHeight: 1.6,
          width: "90%",
          marginBottom: 5,
          marginTop: 8,
          display: "flex",
        }}
      >
        <div
          style={{
            height: 25,
            width: width > 899 ? 75 : 140,
            borderRadius: "100%",
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFFFFF",
            backgroundColor: BlackAndWhite.primary,
            // padding: 5,
          }}
        >
          3
        </div>
        Fear not! Your info is like Fort Knox around here. We guard it with the
        secrecy of a top - secret agent, following the hush - hush rules for
        keeping things as private as a ninja in the shadows.
      </div>
      <div
        style={{
          fontFamily: "Montserrat",
          textAlign: "left",
          fontSize: width > 899 ? 16 : 16,
          lineHeight: 1.6,
          width: "90%",
          marginBottom: 5,
          marginTop: 8,
          display: "flex",
        }}
      >
        <div
          style={{
            height: 25,
            width: width > 899 ? 85 : 170,
            borderRadius: "100%",
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFFFFF",
            backgroundColor: BlackAndWhite.primary,
            // padding: 5,
          }}
        >
          4
        </div>
        Expect a sprinkle of updates! Every now and then, we'll shower you with
        some cool product and industry news via email or your cellular number,
        plus a prescription drop from your designated traditional wellness
        wizard!
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: width > 899 ? 16 : 16,
          lineHeight: 1.6,
          width: "90%",
          fontFamily: "Montserrat",
          marginBottom: 5,
          marginTop: 8,
          display: "flex",
        }}
      >
        <div
          style={{
            height: 25,
            width: width > 899 ? 75 : 150,
            borderRadius: "100%",
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFFFFF",
            backgroundColor: BlackAndWhite.primary,
            // padding: 5,
          }}
        >
          5
        </div>
        When you have crossed the finish line, you can dive into our spots,
        partner joints, and access our online forest , your well - being and the
        lush world of greenery are now at your fingertips.
      </div>
      <div style={{ display: "flex", marginBottom: 20 }}>
        <BaseButtons label={"Not now"} size={150} onClick={onNotClick} />
        <div style={{ width: 20 }} />
        <BaseButtons label={"Got it"} size={150} onClick={onGotItClick} />
      </div>
    </div>
  );
};

export default HelpPopUp;
