import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../../themes/Colors";
import {
  BaseButtons,
  TextAreaInput,
} from "../../../../../../../components/atoms";
import { LoadingPopUp } from "../../../../../../../components";

const ReplyPopUp = ({
  display,
  lastMessage,
  setReplyPopUp,
  lastMessageData,
}) => {
  const [reply, setReply] = useState("");

  const [loading, setLoading] = useState(false);
  const sendMessage = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/requestPrescriptionUser/sendMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messageId: lastMessageData ? lastMessageData.messageId : null,
            patientUserId: lastMessageData
              ? lastMessageData.patientUserId
              : null,
            docId: lastMessageData ? lastMessageData.docID : null,
            message: reply,
            sentBy: lastMessageData ? lastMessageData.patientUserId : null,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        setLoading(false);
        setReplyPopUp(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        position: "fixed",
        overflow: "hidden",
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        transition: "all 0.3s",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: 80,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 22,
        }}
      >
        REPLY TO DOCTOR...
      </div>
      <div
        style={{
          fontSize: 14,
          marginBottom: 10,
          width: "80%",
          textAlign: "left",
          fontWeight: "bold",
          opacity: 0.6,
        }}
      >
        LAST MESSAGE FROM DOC :
      </div>
      <div
        style={{
          fontSize: 14,
          marginBottom: 20,
          width: "80%",
          textAlign: "left",
        }}
      >
        {lastMessage}
      </div>
      <TextAreaInput
        mini={true}
        labelTitle={"TYPE YOUR REPLY BELOW"}
        color={BlackAndWhite.primary}
        width={"100%"}
        size={"80%"}
        height={200}
        value={reply}
        onChange={(e) => setReply(e.target.value)}
      />
      <div style={{ display: "flex" }}>
        <BaseButtons
          label={"CANCEL"}
          onClick={() => setReplyPopUp(false)}
          mini={true}
        />
        <div style={{ minWidth: 10 }} />
        <BaseButtons
          label={"SUBMIT"}
          mini={true}
          disable={lastMessage && reply !== "" ? false : true}
          onClick={() => sendMessage()}
        />
      </div>
      <LoadingPopUp display={loading} />
    </div>
  );
};

export default ReplyPopUp;
