import React, { useState, useEffect } from "react";
import useWindowDimensions from "../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../themes/Colors";
import { FallingLeaf, SectionTitle } from "../atoms";

import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import AdBanner from "./components/AdBanner";
import { Footer } from "../../webiste-components";

const AboutUsLanding = ({
  onLoginClick,
  display,
  onOpsClick,
  websiteData,
  descriptionArray,
}) => {
  const { width, height } = useWindowDimensions();
  const [fall, setFall] = useState(false);
  const [fallinGroup, setFallinGroup] = useState(false);

  useEffect(() => {
    if (fall) {
      setTimeout(() => {
        setFall(false);
      }, 10000);
    } else {
      setFall(true);
      setFallinGroup(true);
    }
  }, [fall]);
  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: width + 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        justifyContent: "flex-start",
        marginTop: display ? 80 : 0,
      }}
    >
      <div
        style={{
          width: width,
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: BlackAndWhite.secondary,
          // overflow: "scroll",
        }}
      >
        <div
          style={{
            width: width,
            backgroundColor: BlackAndWhite.secondary,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <div
            style={{
              height: width > 899 ? 300 : 250,
              width: "width",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "column",
              position: "relative",
              // overflow: "hidden",
              // backgroundColor: BlackAndWhite.primary,
              zIndex: 0,
            }}
          >
            {width > 899 ? (
              <img
                alt="loading"
                src={
                  websiteData
                    ? websiteData.data.landingPage.bannerItem.bannerImageLink
                    : "loading"
                }
                style={{
                  position: "absolute",
                  zIndex: 0,
                  width: width > 899 ? "100%" : "100%",
                  height: 300,
                  // opacity: 0.4,
                  // left: 0,
                }}
              />
            ) : (
              <img
                alt="loading"
                src={require("../../images/header-banners/Merry-Jane Banner Logo Signature 400x280.png")}
                style={{
                  position: "absolute",
                  zIndex: 0,
                  width: width > 899 ? "100%" : "100%",
                  height: 300,
                  // opacity: 0.4,
                  // left: 0,
                }}
              />
            )}

            {width > 899 ? (
              <img
                alt="loading"
                src={
                  websiteData
                    ? websiteData.data.landingPage.bannerItem.displayIcon
                    : "loading"
                }
                style={{
                  position: "absolute",
                  zIndex: 0,
                  // width: 100,
                  height: 375,
                  // opacity: 0.4,
                  // left: 0,
                  bottom: -100,
                }}
              />
            ) : (
              <img
                alt="loading"
                src={require("../../images/newLogos/1. MJ Logo Black with White Surround.png")}
                style={{
                  position: "absolute",
                  zIndex: 0,
                  // width: 100,
                  height: 250,
                  // opacity: 0.4,
                  // left: 0,
                  bottom: -80,
                }}
              />
            )}
          </div>
        </div>

        <div
          style={{
            width: width > 899 ? "100%" : "90%",
            textAlign: "center",
            marginBottom: 20,
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            position: "relative",
            minHeight: 320,
            overflow: "hidden",
            flexDirection: "row",
          }}
        >
          {width > 899 ? (
            <div
              style={{
                width: 250,
                height: 450,
                // backgroundColor: BlackAndWhite.primary,
                borderBottomRightRadius: 10,
                position: "relative",
                zIndex: 0,
                overflow: "hidden",
              }}
            >
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"15%"}
                delay={0}
                friction={25}
                tension={15}
                from={-20}
                to={600}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"25%"}
                delay={0}
                friction={22}
                tension={15}
                from={-80}
                to={600}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"45%"}
                delay={0}
                friction={25}
                tension={15}
                from={-20}
                to={500}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"65%"}
                delay={0}
                friction={22}
                tension={12}
                from={-20}
                to={500}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"75%"}
                delay={0}
                friction={28}
                tension={12}
                from={-20}
                to={700}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"85%"}
                delay={0}
                friction={23}
                tension={17}
                from={-20}
                to={500}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"90%"}
                delay={0}
                friction={20}
                tension={15}
                from={-20}
                to={700}
                changeSize={40}
                timer={1000}
              />
            </div>
          ) : null}
          <>
            <div
              style={{
                position: "absolute",
                width: "100%",
                top: width > 899 ? 150 : 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SectionTitle
                title={"WELCOME TO MERRY-JANE"}
                width={width}
                // colorSwap={true}
                // noHighlight
              />
            </div>

            <div
              style={{
                width: width > 899 ? "50%" : "100%",
                // height: 200,
                fontSize: width > 899 ? 20 : 16,
                display: "flex",
                flexDirection: "column",
                fontFamily: "Montserrat",
                textAlign: "center",
                lineHeight: 1.6,
                marginTop: width > 899 ? 280 : 180,
                marginBottom: 0,
              }}
            >
              {descriptionArray
                ? descriptionArray.map((para, index) => {
                    return para.paragraph !== "Add new text here" ? (
                      <div
                        key={index}
                        style={{ marginBottom: 15, fontSize: para.fontSize }}
                      >
                        {para.paragraph}
                      </div>
                    ) : null;
                  })
                : null}
            </div>
          </>

          {width > 899 ? (
            <div
              style={{
                width: 250,
                height: 450,
                // backgroundColor: BlackAndWhite.primary,
                borderBottomRightRadius: 10,
                position: "relative",
                zIndex: 0,
                overflow: "hidden",
              }}
            >
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"15%"}
                delay={0}
                friction={27}
                tension={17}
                from={-20}
                to={650}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"25%"}
                delay={0}
                friction={22}
                tension={15}
                from={-80}
                to={600}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"45%"}
                delay={0}
                friction={24}
                tension={15}
                from={-20}
                to={550}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"65%"}
                delay={0}
                friction={20}
                tension={12}
                from={-20}
                to={600}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"75%"}
                delay={0}
                friction={28}
                tension={12}
                from={-20}
                to={700}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"85%"}
                delay={0}
                friction={23}
                tension={17}
                from={-20}
                to={550}
                changeSize={40}
                timer={1000}
              />
              <FallingLeaf
                fall={fall}
                setFall={setFall}
                setFallinGroup={setFallinGroup}
                postion={"90%"}
                delay={0}
                friction={20}
                tension={15}
                from={-20}
                to={600}
                changeSize={40}
                timer={1000}
              />
            </div>
          ) : null}
        </div>
        <div
          style={{
            // position: "absolute",
            width: "100%",
            // top: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: -30,
            marginTop: -20,
          }}
        >
          <SectionTitle title={"MANY FACES OF MERRY-JANE"} width={width} />
        </div>
        <p
          style={{
            width: width > 899 ? "50%" : "90%",
            // height: 200,
            fontSize: width > 899 ? 20 : 16,
            display: "flex",
            flexDirection: "column",
            fontFamily: "Montserrat",
            textAlign: "center",
            lineHeight: 1.6,
            // marginTop: width > 899 ? 280 : 180,
          }}
        >
          {websiteData
            ? websiteData.data.landingPage.merryJaneArt.description
            : "loading"}
        </p>
        {/* <div
          style={{
            // position: "absolute",
            width: "100%",
            // top: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SectionTitle title={"MERRY-JANE ART"} width={width} />
        </div> */}

        <div
          style={{
            width: width,
            height: width > 899 ? 400 : 300,
          }}
        >
          <CarouselProvider
            naturalSlideWidth={50}
            naturalSlideHeight={40}
            totalSlides={width > 899 ? 5 : 5}
            interval={5000}
            isPlaying={true}
            visibleSlides={width > 899 ? 1 : 1}
            infinite={true}
          >
            <Slider style={{ width: "100%" }}>
              <Slide index={0}>
                <AdBanner
                  width={width}
                  img={require("../../images/merry-jane-web-images/Picture1.png")}
                />
              </Slide>

              <Slide index={1}>
                <AdBanner
                  width={width}
                  img={require("../../images/merry-jane-web-images/Picture2.png")}
                />
              </Slide>
              <Slide index={2}>
                <AdBanner
                  width={width}
                  img={require("../../images/merry-jane-web-images/Picture3.png")}
                />
              </Slide>
              <Slide index={3}>
                <AdBanner
                  width={width}
                  img={require("../../images/merry-jane-web-images/Picture5.png")}
                />
              </Slide>
              <Slide index={4}>
                <AdBanner
                  width={width}
                  img={require("../../images/merry-jane-web-images/Picture7.png")}
                />
              </Slide>
            </Slider>
            {/* <ButtonBack style={{ left: 0 }}>Back</ButtonBack>
            <ButtonNext style={{ right: 0, position: "absolute" }}>
              Next
            </ButtonNext> */}
          </CarouselProvider>
        </div>
        {/* <div
          style={{
            // position: "absolute",
            width: "100%",
            // top: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // borderStyle: "solid",
          }}
        >
          <SectionTitle title={"ONLINE STORE"} width={width} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            height: 300,

            flexDirection: "column",
            // overflow: "scroll",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: 20,
              // marginLeft: 20,
              marginBottom: 80,
            }}
          >
            <img
              alt="loading"
              src={require("../../images/merryjanelogooptions/Merry-Jane Online Sotre.png")}
              style={{
                // position: "absolute",
                zIndex: 0,
                // width: 200,
                height: 200,
                // opacity: 0.4,
                // left: 0,
                // bottom: -100,
              }}
            />
            <h3>COMING SOON !</h3>
          </div>
        </div> */}

        <Footer />
      </div>
    </div>
  );
};

export default AboutUsLanding;
