import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";
import { BaseButtons } from "../../../../../components/atoms";

const ProductCard = ({
  title,
  description,
  imageIcon,
  imageIcon2,
  onClick,
  strain,
  thcContent,
  productType,
  growType,
  cost,
  retail,
  isAdmin,
  onViewProductClick,
  width,
  onMoreClick,
}) => {
  const [hover, setHover] = useState(false);
  const imageBackGround = require("../../../../../images/marijuana-buds-with-marijuana-joints-cannabis-oil.jpg");
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        // backgroundColor: hover ? "#FAA919" : BlackAndWhite.secondary,
        margin: 15,
        borderRadius: 8,
        display: "flex",
        // color: hover ? BlackAndWhite.secondary : BlackAndWhite.primary,
        transition: "all 0.3s",
        cursor: "pointer",
        // overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <div
        onClick={isAdmin ? null : onMoreClick}
        style={{
          height: 240,
          width: width ? width : 260,
          overflow: "hidden",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 8,
          marginBottom: 3,
          backgroundColor: BlackAndWhite.secondary,
          WebkitBoxShadow: "0px 0px 10px 1px #00000066",
        }}
      >
        <img
          src={require("../../../../../images/marijuana-buds-with-marijuana-joints-cannabis-oil.jpg")}
          alt="load"
          style={{ height: "100%" }}
        />
        {/* <div style={{ width: "80%", fontSize: 14 }}>Strain:</div> */}
        <div style={{ width: "100%", display: "flex" }}>
          <div
            style={{
              width: 180,
              position: "absolute",
              bottom: 10,
              backgroundColor: "#FFFFFF",
              borderRadius: 80,
              height: 50,
              display: "flex",

              marginLeft: 10,

              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ fontSize: 16, marginLeft: 14 }}>{title}</div>
            <div
              style={{
                borderRadius: 20,

                display: "flex",
                // alignItems: "center",

                fontSize: 11,
                color: BlackAndWhite.primary,
                marginLeft: 14,
              }}
            >
              <div style={{ width: "80%", textAlign: "left" }}>
                THC: {thcContent}
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              right: 5,
              bottom: -13,
              height: 85,
              width: 85,
              borderRadius: "100%",
              backgroundColor: "#FAA919",
              color: BlackAndWhite.secondary,
              fontWeight: "bold",
              borderStyle: "solid",
              borderColor: BlackAndWhite.secondary,
              borderWidth: 3,
              fontSize: 20,
              flexDirection: "column",
            }}
          >
            {isAdmin ? <div style={{ fontSize: 12 }}>Retail</div> : null}R{" "}
            {retail}
          </div>
        </div>

        {/* <div style={{ width: "80%", fontSize: 14 }}>THC content:</div>
        <div style={{ width: "80%" }}>{thcContent}</div>
        <div style={{ width: "80%", fontSize: 14 }}>Product type:</div>
        <div style={{ width: "80%" }}>{productType}</div> */}
      </div>
      {isAdmin ? (
        <div
          style={{
            height: 60,
            overflow: "hidden",
            color: hover ? BlackAndWhite.primary : BlackAndWhite.primary,
            backgroundColor: BlackAndWhite.secondary,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              width: "80%",
              marginTop: 10,
              textAlign: "left",
              fontSize: 14,
              // marginBottom: 10,
              marginLeft: 5,
            }}
          >
            Pricing:
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginLeft: 5 }}>cost: </div>
            <div style={{ width: "50%", marginLeft: 5, textAlign: "center" }}>
              {cost}
            </div>
          </div>

          <div
            style={{
              marginTop: 10,
              marginRight: 10,
              marginBottom: 6,
              fontSize: 14,
            }}
          >
            Created on:
          </div>
          <div>{description}</div>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 3,
          //  WebkitBoxShadow: "0px 0px 10px 1px #00000066",
        }}
      >
        {isAdmin ? (
          <BaseButtons
            label={"SELECT"}
            size={"100%"}
            onClick={onClick}
            marginTopFalse={true}
          />
        ) : onViewProductClick ? (
          <BaseButtons
            label={"MORE INFO"}
            size={"100%"}
            onClick={onViewProductClick}
            marginTopFalse={true}
          />
        ) : (
          <BaseButtons
            label={"ADD TO CART"}
            size={"100%"}
            onClick={onClick}
            marginTopFalse={true}
          />
        )}
      </div>
    </div>
  );
};

export default ProductCard;

// productName: productName,
//               description: description,
//               menuDescription: menuDescription,
//               useDescription: useDescription,
//               moreInfo: "none",
//               strain: strain,
//               growType: growType,
//               thcContent: thcContent,
//               productNo: productNo,
//               productType: productType,
//               categoryId: categorySelected.categoryId,
//               subCategoryId: selectedSubCategory.subCategoryId,
//               addedBy: "Admin",
//               retailPrice: retailPrice,
//               costPrice: costPrice,
