import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const types = [
  "grams (g)",
  "miligrams (mg)",
  "mililitres (ml) ",
  "unit",
  "each",
];

const MeasurementSelector = ({
  type,
  setType,
  open,
  onTypeClick,
  setTypeOpen,
  mini,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          marginTop: mini ? 10 : 20,
          marginBottom: open ? 0 : 6,
          width: "80%",
          textAlign: "left",
          fontSize: 14,
        }}
      >
        {open ? "" : "Unit Measure (Required)"}
      </div>
      <div
        onClick={onTypeClick}
        style={{
          height: mini ? (open ? 0 : 47) : open ? 0 : 45,
          width: "85%",
          borderStyle: "solid",

          borderRadius: mini ? 0 : 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderWidth: open ? 0 : 0.5,
          transition: "all 0.3s",
          overflow: "hidden",
          cursor: "pointer",
          fontSize: mini ? 12 : null,
          // marginBottom: 5,
        }}
      >
        {type ? type : "Click here"}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: open ? null : 0,
          overflow: "hidden",

          flexWrap: "wrap",
          marginTop: 5,
          position: "absolute",
          // flexDirection: "column",
        }}
      >
        {types.map((type, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                setType(type);
                setTypeOpen(false);
              }}
              style={{
                height: mini ? 30 : 45,
                width: "70%",
                borderStyle: "solid",
                margin: mini ? 0 : 5,
                borderRadius: mini ? null : 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: 0.5,
                transition: "all 0.3s",
                overflow: "hidden",
                fontSize: type ? 14 : 14,
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: BlackAndWhite.secondary,
                zIndex: 20000000,
              }}
            >
              {type ? type : "loading"}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MeasurementSelector;
