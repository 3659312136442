import React, { useState, useEffect } from "react";
import {
  HeaderButton,
  EnquireTab,
  OnlineStore,
  TeamTab,
} from "../../components/atoms";
import MenuPopUp from "../../components/atoms/menu-pop-up/MenuPopUp";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import { useNavigate } from "react-router-dom";
import { BlackAndWhite } from "../../themes/Colors";
import MobileShopDisplay from "../../components/atoms/mobile-shop-display/MobileShopDisplay";
import { FacilityCard } from "./components";

const DirectLink = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const [login, setLogin] = useState(false);
  const [aboutUs, setAboutUs] = useState(false);
  const [menuPopUp, setMenuPopUp] = useState(false);
  const [areYou, setAreYou] = useState(false);
  const [regsitration, setRegistration] = useState(false);
  const [opsTab, setOpsTab] = useState(false);
  const [enquiretab, setEnquireTab] = useState(false);
  const [locationTab, setLocationTab] = useState(false);
  const [onlineStore, setOnlineStore] = useState(false);
  const [teamTab, setTeamTab] = useState(false);
  const [displayButtons, setDisplayButtons] = useState(true);
  const [selected, setSelected] = useState("nothing");
  const [infoBlock, setInfoBlock] = useState(false);
  const [option, setOption] = useState("nothing");
  const [helpPopUp, setHelpPopUp] = useState(true);
  // const { width, height } = useWindowDimensions();
  const [error, setError] = useState("");
  const [forgotPasswordPop, setForgotPasswordPop] = useState(false);
  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("loading");

  const [shopBreakDown, setShopBreakDown] = useState(false);
  const [infoDisplay, setInfoDisplay] = useState(false);
  const [shopInfoDisplay, setShopInfoDisplay] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [imageSelected, setImageSelected] = useState(0);

  const [websiteData, setWebsiteData] = useState(null);

  const [facilitiesArray, setFacilitiesArray] = useState(null);

  const updateListFetch = async () => {
    setMessage("loading");
    try {
      const runCall = await fetch(
        "https://merry-jane-api.onrender.com/facilities/getFacilities"
      )
        .then((response) => {
          return response.json();
        })
        .then((resData) => {
          setFacilitiesArray(resData);
        });
    } catch (err) {
      setMessage("You have no connection unable to get facilities");
    }
  };

  useEffect(() => {
    updateListFetch();
  }, []);

  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
        overflowX: "hidden",
        backgroundColor: BlackAndWhite.primary,
        // flexWrap: width > 899 ? "wrap" : null,
        // paddingTop: width > 899 ? 100 : null,
        position: "relative",
      }}
    >
      <HeaderButton
        facilities={true}
        width={"100%"}
        display={true}
        onHomeClick={() => {
          navigate("/");
        }}
        onMenuClick={() => {
          setMenuPopUp(menuPopUp ? false : true);
          setEnquireTab(false);
          setOpsTab(false);
          setLocationTab(false);
          setOnlineStore(false);
          setTeamTab(false);
          // setSelected("nothing");
          setInfoBlock(false);
          setOption("nothing");
        }}
      />

      <div style={{ paddingTop: width > 899 ? 100 : 80 }}></div>
      <div
        style={{
          display: width > 899 ? "flex" : null,
          flexWrap: "wrap",
          overflowY: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {facilitiesArray ? (
          facilitiesArray.store.map((location, index) => {
            return location.status !== "Pending" &&
              location.status !== "PENDING" &&
              location.status !== "Pipeline" ? (
              <div key={index}>
                <FacilityCard
                  location={location}
                  onClick={() => {
                    setSelectedLocation(location);
                    setShopBreakDown(true);
                  }}
                />
              </div>
            ) : null;
          })
        ) : (
          <div
            style={{
              fontSize: 18,
              color: BlackAndWhite.secondary,
              fontWeight: "bold",
            }}
          >
            {message}
          </div>
        )}
      </div>

      <MenuPopUp
        facilities={true}
        display={menuPopUp}
        onHomeClick={() => {
          navigate("/");
        }}
        onLoginClick={() => {
          navigate("/");
        }}
        onOpportunitiesUs={() => {
          setOpsTab(true);
          setMenuPopUp(false);
        }}
        onEnquireClick={() => {
          setEnquireTab(true);
          setMenuPopUp(false);
        }}
        // onLocationClick={() => {
        //   setLocationTab(true);
        //   setMenuPopUp(false);
        // }}
        onStoreClick={() => {
          setOnlineStore(true);
          setMenuPopUp(false);
        }}
        onMemberClick={() => {
          setTeamTab(true);
          setMenuPopUp(false);
        }}
      />
      {/* <OpportunitiesTab
        display={opsTab}
        onCloseClick={() => setOpsTab(false)}
        option={option}
        setOption={setOption}
        onEnquireClick={() => {
          setOption("opportunity");
        }}
        data={websiteData ? websiteData.oppurtunities : []}
      /> */}
      <EnquireTab
        display={enquiretab}
        onCloseClick={
          selected !== "nothing"
            ? () => {
                setSelected("nothing");
              }
            : () => {
                setEnquireTab(false);
              }
        }
        selected={selected}
        setSelected={setSelected}
        infoBlock={infoBlock}
        setInfoBlock={setInfoBlock}
        setEnquireTab={setEnquireTab}
      />

      <OnlineStore
        display={onlineStore}
        onCloseClick={() => setOnlineStore(false)}
      />
      <TeamTab
        // data={websiteData}
        display={teamTab}
        onCloseClick={() => setTeamTab(false)}
        onSignUpClick={() => {
          setMenuPopUp(false);
          setAboutUs(false);
          // setLogin(true);
          setDisplayButtons(false);
          setRegistration(true);
          setTeamTab(false);
        }}
      />

      <MobileShopDisplay
        selectedLocation={selectedLocation}
        onLeftClick={() =>
          setImageSelected(imageSelected === 0 ? 0 : imageSelected - 1)
        }
        // onRightClick={() =>
        //   setImageSelected(
        //     locationData[selectedLocation].images.length - 1 === imageSelected
        //       ? locationData[selectedLocation].images.length - 1
        //       : imageSelected + 1
        //   )
        // }
        // shopImage={locationData[selectedLocation].images[imageSelected].image}
        display={shopBreakDown}
        onCloseClick={() => setShopBreakDown(false)}
        // desTitle={locationData[selectedLocation].name}
        // paragraph1={locationData[selectedLocation].paragraph1}
        // paragraph2={locationData[selectedLocation].paragraph2}
        // monday={locationData[selectedLocation].operatingHours[0].time}
        // tuesday={locationData[selectedLocation].operatingHours[1].time}
        // wednesday={locationData[selectedLocation].operatingHours[2].time}
        // thursday={locationData[selectedLocation].operatingHours[3].time}
        // friday={locationData[selectedLocation].operatingHours[4].time}
        // saturday={locationData[selectedLocation].operatingHours[5].time}
        // sunday={locationData[selectedLocation].operatingHours[6].time}
        infoDisplay={shopInfoDisplay}
        setInfoDisplay={setShopInfoDisplay}
        // address={locationData[selectedLocation].address}
        // contact={locationData[selectedLocation].contactNumber}
        // email={locationData[selectedLocation].email}
        // type={locationData[selectedLocation].type}
        // openInfo={locationData[selectedLocation].startedDate}
      />
    </div>
  );
};

export default DirectLink;
