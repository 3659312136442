import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputField,
} from "../../../../../../components/atoms";

const AddProductPopUp = ({ display, onCloseClick, denomination }) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        backgroundColor: "#00000066",
        height: display ? "100%" : 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        overflow: "hidden",
        transition: "all 0.3s",
        bottom: 0,
        zIndex: 300000,
      }}
    >
      <div
        style={{
          flex: 0.7,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          backgroundColor: BlackAndWhite.secondary,
          borderTopRightRadius: 20,
          borderTopLeftRadius: 20,
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 24,
            fontWeight: "bold",
            marginTop: 15,
          }}
        >
          SELECT AMOUNT
        </div>
        <div style={{ width: "80%", textAlign: "center", marginTop: 20 }}>
          Let us know how much you want to add to your cart:
        </div>
        <TextInputField
          //   labelTitle={"Add amount in " + "" + denomination ? denomination : "g"}
          labelTitle={
            "Add amount in " + "" + (denomination ? denomination : "g")
          }
          invert={true}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BaseButtons label={"ADD"} />
        </div>
        <div style={{ position: "absolute", top: 10, left: 10 }}>
          <CloseButton label={"X"} onClick={onCloseClick} />
        </div>
      </div>
    </div>
  );
};

export default AddProductPopUp;
