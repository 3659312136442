import React, { useState } from "react";

const UpsaleCard = ({ onDeleteClick, use }) => {
  const [deleted, setDelete] = useState(false);
  return (
    <div
      style={{
        width: "90%",
        height: 60,
        // WebkitBoxShadow: "0px 0px 5px 1px #00000030",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0.5,
        flexDirection: "row",
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          marginLeft: 10,
          fontSize: 12,
          marginBottom: 5,
          // width: '100%',
          // textAlign:'left'
        }}
      >
        {use.question}
      </div>
      <div onClick={() => setDelete(true)}>
        <div onClick={onDeleteClick} style={{ fontSize: 12, marginRight: 5 }}>
          {deleted ? "Busy removing" : "REMOVE"}
        </div>
      </div>
    </div>
  );
};

export default UpsaleCard;
