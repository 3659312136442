import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  TextAreaInput,
  TextInputField,
} from "../../../../components/atoms";
import AWS from "aws-sdk";
import { ImageUploadAWS, ToastNotification } from "../../../../components";
import { ImageSavePopUp } from "./components";

const validFileTypes = ["image/jpg", "image/jpeg", "image/png"];

const DoctorPracticeProfile = ({
  setLoading,

  admin,
}) => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [logoLink, setLogoLink] = useState(null);
  const [hspcafile, setHspcaFile] = useState(null);
  const [previewHspcaUrl, setPreviewHspcaUrl] = useState(null);
  const [hspcaLink, setHspcaLink] = useState(null);
  const [previewSigUrl, setPreviewSigUrl] = useState(null);
  const [sigfile, setSigFile] = useState(null);
  const [sigLink, setSigLink] = useState(null);

  const [message, setMessage] = useState(null);
  const [toastPop, setToastPop] = useState(null);

  const [logoArray, setLogoArray] = useState([]);
  const [signitureArray, setSignitureArray] = useState([]);
  const [hspcaArray, setHspcaArray] = useState([]);

  // this is varibles for the text that can be editted

  const [practiceName, setPracticeName] = useState("");
  const [practiceNo, setPracticeNp] = useState("");
  const [hspcaRegNo, setHspcaRegNo] = useState("");
  const [startingmessage, setStartingMessage] = useState("");
  const [fullTitle, setFullTitle] = useState("");
  const [footerStatement, setFooterStatement] = useState("");
  const [email, setEmal] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [address, setAddress] = useState("");
  const [expiryTerm, setExpiryTerm] = useState("");

  const [imageError, setImageError] = useState("");

  const handleFileChange = (e) => {
    // Uploaded file
    const file = e.target.files[0];
    // Changing file state
    setFile(file);
  };
  const handleHspcaFileChange = (e) => {
    // Uploaded file
    const file = e.target.files[0];
    // Changing file state
    setHspcaFile(file);
  };
  const handleSigFileChange = (e) => {
    // Uploaded file
    const file = e.target.files[0];
    // Changing file state
    setSigFile(file);
  };
  const uploadLogoImage = async (e) => {
    setLoading(true);

    if (!validFileTypes.find((type) => type === file.type)) {
      setImageError("File type must be JPG or PNG format");
      return;
    } else {
      setImageError("");
    }
    try {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("userId", admin.userId);

      const response = await fetch(
        "https://merry-jane-api.onrender.com/prescription/addLogo",
        {
          method: "POST",
          body: formData,
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setToastPop(true);
        setMessage(respnseData.success);
        setFile(null);
        setLoading(false);

        // setVehicleSearchArray(respnseData.enatisVehicles);
      } else {
        setToastPop(true);
        setMessage(respnseData.error);

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  const uploadHspcaImage = async (e) => {
    setLoading(true);

    if (!validFileTypes.find((type) => type === file.type)) {
      setImageError("File type must be JPG or PNG format");
      return;
    } else {
      setImageError("");
    }
    try {
      const formData = new FormData();
      formData.append("image", hspcafile);
      formData.append("userId", admin.userId);

      const response = await fetch(
        "https://merry-jane-api.onrender.com//buzzPost/createPost",
        {
          method: "POST",
          body: formData,
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setToastPop(true);
        setMessage(respnseData.success);

        setLoading(false);

        // setVehicleSearchArray(respnseData.enatisVehicles);
      } else {
        setToastPop(true);
        setMessage(respnseData.error);

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  const uploadSignitureImage = async (e) => {
    setLoading(true);

    if (!validFileTypes.find((type) => type === sigfile.type)) {
      setImageError("File type must be JPG or PNG format");
      return;
    } else {
      setImageError("");
    }
    try {
      const formData = new FormData();
      formData.append("image", sigfile);
      formData.append("userId", admin.userId);

      const response = await fetch(
        "https://merry-jane-api.onrender.com/prescription/addSigniture",
        {
          method: "POST",
          body: formData,
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setToastPop(true);
        setMessage(respnseData.success);
        setSigFile(null);
        setLoading(false);

        // setVehicleSearchArray(respnseData.enatisVehicles);
      } else {
        setToastPop(true);
        setMessage(respnseData.error);

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const getUplaodImage = async ({}) => {
    const S3_BUCKET = process.env.REACT_APP_BUCKET;
    const apiKey = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secrectapiKey = process.env.REACT_APP_AWS_SECRET_KEY_ID;
    AWS.config.update({
      accessKeyId: apiKey,
      secretAccessKey: secrectapiKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: process.env.REACT_APP_REGION,
      signatureVersion: "v4",
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: logoArray.length >= 1 ? logoArray[0].imageId : null,
    };

    var promise = s3.getSignedUrlPromise("getObject", params);
    // return promise;
    promise.then(
      function (url) {
        if (url) {
          setLogoLink(url);
        }
        return url;
      },
      function (err) {
        console.log(err);
      }
    );
  };

  const getSigImage = async () => {
    const S3_BUCKET = process.env.REACT_APP_BUCKET;
    const apiKey = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secrectapiKey = process.env.REACT_APP_AWS_SECRET_KEY_ID;
    AWS.config.update({
      accessKeyId: apiKey,
      secretAccessKey: secrectapiKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: process.env.REACT_APP_REGION,
      signatureVersion: "v4",
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: signitureArray[0].imageId,
    };

    var promise = s3.getSignedUrlPromise("getObject", params);
    // return promise;
    promise.then(
      function (url) {
        if (url) {
          setSigLink(url);
        }
        return url;
      },
      function (err) {
        console.log(err);
      }
    );
  };

  const getHspcaImage = async () => {
    const S3_BUCKET = process.env.REACT_APP_BUCKET;
    const apiKey = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secrectapiKey = process.env.REACT_APP_AWS_SECRET_KEY_ID;
    AWS.config.update({
      accessKeyId: apiKey,
      secretAccessKey: secrectapiKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: process.env.REACT_APP_REGION,
      signatureVersion: "v4",
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: signitureArray[0].imageId,
    };

    var promise = s3.getSignedUrlPromise("getObject", params);
    // return promise;
    promise.then(
      function (url) {
        if (url) {
          setSigLink(url);
        }
        return url;
      },
      function (err) {
        console.log(err);
      }
    );
  };

  const GetPracticeInformation = async (status, id) => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/prescription/getPracticeInformation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: admin.userId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setLoading(false);
        setLogoArray(responseData.logoImage);
        setHspcaArray(responseData.hspcaImage);
        setSignitureArray(responseData.sigImage);
        if (responseData.practiceInformation.practiceName !== "none") {
          setPracticeName(responseData.practiceInformation.practiceName);
        } else {
          setPracticeName("");
        }
        if (responseData.practiceInformation.practiceNo !== "none") {
          setPracticeNp(responseData.practiceInformation.practiceNo);
        } else {
          setPracticeNp("");
        }
        if (responseData.practiceInformation.hspcaRegNo !== "none") {
          setHspcaRegNo(responseData.practiceInformation.hspcaRegNo);
        } else {
          setHspcaRegNo("");
        }
        if (responseData.practiceInformation.startingMessage !== "none") {
          setStartingMessage(responseData.practiceInformation.startingMessage);
        } else {
          setStartingMessage("");
        }
        if (responseData.practiceInformation.fullTitle !== "none") {
          setFullTitle(responseData.practiceInformation.fullTitle);
        } else {
          setFullTitle("");
        }
        if (responseData.practiceInformation.footerStatement !== "none") {
          setFooterStatement(responseData.practiceInformation.footerStatement);
        } else {
          setFooterStatement("");
        }
        setEmal(responseData.practiceInformation.email);
        setContactNo(responseData.practiceInformation.contactNo);
        setAddress(responseData.practiceInformation.address);
        setExpiryTerm(responseData.practiceInformation.expiryOfTerm);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const UpdatePracticeProfile = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/prescription/updatePracticeInformation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: admin.userId,
            practiceName: practiceName,
            practiceNo: practiceNo,
            hspcaRegNo: hspcaRegNo,
            startingMessage: startingmessage,
            fullTitle: fullTitle,
            footerStatement: footerStatement,
            email: email,
            contactNo: contactNo,
            address: address,
            expiryOfTerm: expiryTerm,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        setToastPop(true);
        setMessage(responseData.success);

        setLoading(false);

        // setVehicleSearchArray(respnseData.enatisVehicles);
      } else {
        setToastPop(true);
        setMessage(responseData.error);

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!file) {
      //   if (!sigfile) {
      //     return;
      //   }
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);
  useEffect(() => {
    if (!hspcafile) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewHspcaUrl(fileReader.result);
    };
    fileReader.readAsDataURL(hspcafile);
  }, [hspcafile]);
  useEffect(() => {
    if (!sigfile) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSigUrl(fileReader.result);
    };
    fileReader.readAsDataURL(sigfile);
  }, [sigfile]);
  useEffect(() => {
    GetPracticeInformation();
  }, [message]);

  useEffect(() => {
    if (logoArray.length >= 1) {
      getUplaodImage(logoArray[0].imageId, setLogoLink);
    }
  }, [logoArray]);
  useEffect(() => {
    if (signitureArray.length >= 1) {
      getSigImage();
    }
  }, [signitureArray]);

  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 3000);
    }
  }, [toastPop]);

  return (
    <div
      style={{
        height: "93%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#00000010",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          flex: 0.5,
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: BlackAndWhite.secondary,
            // borderTopLeftRadius: 10,
            // borderBottomLeftRadius: 10,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "92%",
              marginTop: 20,
              fontSize: 18,
              marginBottom: 10,
              position: "relative",
            }}
          >
            PRACTICE INFORMATION
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                display: "flex",
              }}
            >
              <BaseButtons
                mini={true}
                marginTop={-10}
                label={"HSPCA DOC"}
                height={45}
                borderRadius={8}
                size={100}
              />
              <div style={{ minWidth: 10 }} />
              <BaseButtons
                mini={true}
                marginTop={-10}
                label={"SAVE CHANGES"}
                height={45}
                borderRadius={8}
                size={100}
                onClick={() => UpdatePracticeProfile()}
              />
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <TextInputField
              mini={true}
              labelTitle={"PRACTICE NAME"}
              invert={true}
              width={"100%"}
              size={"80%"}
              height={20}
              value={practiceName}
              onChange={(e) => setPracticeName(e.target.value)}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextInputField
              mini={true}
              labelTitle={"PRACTICE NO"}
              invert={true}
              width={"44.5%"}
              size={"80%"}
              height={20}
              value={practiceNo}
              onChange={(e) => setPracticeNp(e.target.value)}
            />
            <TextInputField
              mini={true}
              labelTitle={"HSPCA REG NO"}
              invert={true}
              width={"44.5%"}
              size={"80%"}
              height={20}
              value={hspcaRegNo}
              onChange={(e) => setHspcaRegNo(e.target.value)}
            />
          </div>

          <div style={{ width: "100%" }}>
            <TextInputField
              mini={true}
              labelTitle={"PREFFERED STARTING MESSAGE"}
              invert={true}
              width={"100%"}
              size={"80%"}
              height={20}
              value={startingmessage}
              onChange={(e) => setStartingMessage(e.target.value)}
            />
          </div>
          <div style={{ width: "100%" }}>
            <TextInputField
              mini={true}
              labelTitle={"FULL TITLE AND NAME"}
              invert={true}
              width={"100%"}
              size={"80%"}
              height={20}
              value={fullTitle}
              onChange={(e) => setFullTitle(e.target.value)}
            />
          </div>
          <div style={{ width: "100%" }}>
            <TextInputField
              mini={true}
              labelTitle={"FOOTER STATEMENT"}
              invert={true}
              width={"100%"}
              size={"80%"}
              height={20}
              value={footerStatement}
              onChange={(e) => setFooterStatement(e.target.value)}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextInputField
              mini={true}
              labelTitle={"EMAIL"}
              invert={true}
              width={"44.5%"}
              size={"80%"}
              height={20}
              value={email}
              onChange={(e) => setEmal(e.target.value)}
            />
            <TextInputField
              mini={true}
              labelTitle={"CONTACT NO"}
              invert={true}
              width={"44.5%"}
              size={"80%"}
              height={20}
              value={contactNo}
              onChange={(e) => setContactNo(e.target.value)}
            />
          </div>
          <div style={{ width: "100%" }}>
            <TextAreaInput
              mini={true}
              labelTitle={"ADDRESS | LOCATION"}
              invert={true}
              width={"100%"}
              size={"82%"}
              color={BlackAndWhite.primary}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div style={{ width: "100%" }}>
            <TextInputField
              mini={true}
              labelTitle={"EXIPRY OF TERM"}
              invert={true}
              width={"100%"}
              size={"80%"}
              height={20}
              value={expiryTerm}
              onChange={(e) => setExpiryTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 0.5,
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: BlackAndWhite.secondary,
            // borderTopRightRadius: 10,
            // borderBottomRightRadius: 10,
            WebkitBoxShadow: "4px 0px 10px 0px #00000033",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {/* <div
            style={{
              height: 50,
              backgroundColor: BlackAndWhite.primary,
              display: "flex",
              alignItems: "center",
              width: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                color: BlackAndWhite.secondary,
                marginLeft: 20,
                fontSize: 14,
              }}
            >
              PRESCRIPTION PDF EXAMPLE
            </div>
          </div> */}
          <div
            style={{
              flex: 1,
              width: "100%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "80%",
                width: "80%",

                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 100,
                backgroundColor: BlackAndWhite.secondary,
                WebkitBoxShadow: "0px 0px 10px 1px #00000033",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", width: "90%" }}>
                <img
                  alt="Preview"
                  src={logoLink ? logoLink : previewUrl ? previewUrl : null}
                  style={{ height: 50, marginTop: 10 }}
                />
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{ minWidth: 5, marginLeft: 10 }} />
                  {practiceName === ""
                    ? "EXAMPLE PRACTICE NAME HERE"
                    : practiceName}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  fontSize: 12,
                  marginTop: 10,
                }}
              >
                <div>
                  {" "}
                  Practice no:{" "}
                  {practiceNo === ""
                    ? "EXAMPLE"
                    : practiceNo.toLocaleUpperCase()}
                </div>
                <div>
                  {hspcaRegNo === ""
                    ? "EXAMPLE NO"
                    : hspcaRegNo.toLocaleUpperCase()}
                </div>
              </div>
              <div
                style={{
                  width: "90%",
                  height: 3,
                  backgroundColor: BlackAndWhite.primary,
                  marginTop: 10,
                }}
              />
              <div
                style={{
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flex: 0.7,
                    width: "90%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginTop: 10, fontSize: 14 }}>
                    PRESCRIPTION
                  </div>
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      fontSize: 12,
                    }}
                  >
                    <div style={{}}>To: Pharmacy</div>
                    <div style={{ flex: 1 }} />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginTop: 10,
                      fontSize: 12,
                    }}
                  >
                    <div style={{}}>RE: PATIENT NAME</div>
                    <div style={{ flex: 1 }} />
                    <div style={{}}>Dated: {new Date().toDateString()}</div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      width: "100%",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 20,
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      {startingmessage}
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                        fontSize: 12,
                        marginLeft: 5,
                      }}
                    >
                      -Some randome medication
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: 0.3,
                    width: "90%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      alt="Preview"
                      src={
                        sigLink ? sigLink : previewSigUrl ? previewSigUrl : null
                      }
                      style={{ height: 50, marginTop: 10, marginLeft: 45 }}
                    />
                    <div
                      style={{
                        marginBottom: 40,
                        // marginLeft: 20,
                        height: 2,
                        width: 200,
                        backgroundColor: BlackAndWhite.primary,
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom: -20,
                          fontSize: 12,
                        }}
                      >
                        {fullTitle}
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: -60,
                          fontSize: 12,
                        }}
                      >
                        Regards
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: 100,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: 12,
                      width: "80%",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {footerStatement}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      fontSize: 8,
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      Location: {address}
                    </div>
                    <div style={{ marginLeft: 2, marginRight: 2 }}>
                      Contact: {contactNo}
                    </div>
                    <div>email: {email}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              //   right: 10,
              bottom: 20,
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <ImageUploadAWS
              onChange={(e) => handleFileChange(e)}
              labelTitle={"UPLOAD LOGO "}
              mini={true}
              errorDisplay={imageError}
              value={file}
            />
            <ImageUploadAWS
              onChange={(e) => handleSigFileChange(e)}
              labelTitle={"UPLOAD SIGNITURE "}
              mini={true}
              errorDisplay={imageError}
            />
            <ImageSavePopUp
              display={file}
              image={previewUrl}
              onSubmitClick={() => uploadLogoImage()}
              onCancelClick={() => {
                setFile(null);
              }}
            />
            <ImageSavePopUp
              display={sigfile}
              image={previewSigUrl}
              onSubmitClick={() => uploadSignitureImage()}
              onCancelClick={() => {
                setSigFile(null);
              }}
            />
          </div>
        </div>
      </div>
      <ToastNotification
        display={toastPop}
        message={message ? message.toUpperCase() : "loading"}
      />
    </div>
  );
};

export default DoctorPracticeProfile;
