import React, { useState, useContext } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import { BaseButtons, TextInputField } from "../../components/atoms";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../context/admin-context";
import { LoadingPopUp } from "../../components";

const CannaPosPortal = () => {
  const { width, height } = useWindowDimensions();

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const admin = useContext(AdminContext);
  const navigate = useNavigate();

  const HandleSubmit = async () => {
    setLoading(true);
    setError("Loading");
    // authentication or login function heal
    //this code is used for Ui test
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: username,
            password: password,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.message === "logged in") {
        // console.log("response dtat", respnseData);
        admin.login();
        admin.adminUserInfo(respnseData);
        // setLoading(false);
        navigate("/cannaPosAccess");
      } else {
        navigate("/");
        setError("invalid credentials");
        setLoading(false);
        console.log("error data is missing");
      }
    } catch (err) {
      setError("invalid credentials");
    }
  };
  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: BlackAndWhite.secondary,
        position: "relative",
      }}
    >
      <div
        style={{
          height: 600,
          width: width > 899 ? "40%" : "80%",
          display: "flex",
          flexDirection: width > 899 ? "row" : "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="logo"
            src={require("../../images/merryjanelogooptions/mainLogo.png")}
            style={{
              height: width > 899 ? 245 : 145,
              marginRight: width > 899 ? 40 : 0,
            }}
          />
          <div
            style={{
              color: BlackAndWhite.primary,
              fontSize: 24,
              marginTop: 20,
              marginBottom: 30,
              marginRight: width > 899 ? 40 : 0,
              width: 250,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            CANNA-POS LOGIN
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextInputField
            labelTitle={"username"}
            onChange={(event) => setUsername(event.target.value)}
            invert={true}
          />
          <TextInputField
            labelTitle={"password"}
            onChange={(event) => setPassword(event.target.value)}
            showPassword={true}
            invert={true}
          />
          <div style={{ height: 30 }} />
          <BaseButtons
            label={"LOGIN"}
            invert={false}
            onClick={() => HandleSubmit()}
          />
        </div>

        <div style={{ height: 10 }} />
      </div>
      <LoadingPopUp display={loading} />
    </div>
  );
};

export default CannaPosPortal;
