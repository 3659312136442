import React, { useContext, useEffect } from "react";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../themes/Colors";
import PcLayout from "./components/pc-layout/PcLayout";
import MobileLayout from "./components/mobile-layout/MobileLayout";
import { AuthContext } from "../../context/auth-context";
import { useNavigate } from "react-router-dom";

const CustomerPortal = () => {
  const { width, height } = useWindowDimensions();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.isloggedIn) {
      navigate("/");
    }
  }, []);

  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: BlackAndWhite.secondary,
        position: "relative",
      }}
    >
      {width > 899 ? (
        // <PcLayout
        //   customerName={
        //     auth.customerUserData.firstName +
        //     " " +
        //     auth.customerUserData.lastName
        //   }
        //   width={width}
        // />
        <div>must use mobile device to login </div>
      ) : (
        <MobileLayout width={width} />
      )}
      {width > 899 ? (
        <img
          alt="logo"
          src={require("../../images/merryjanelogooptions/7.jpeg")}
          style={{
            position: "absolute",
            zIndex: 0,
            height: 500,
            left: 20,
            opacity: 0.7,
          }}
        />
      ) : null}

      <img
        alt="logo"
        src={require("../../images/merryjanelogooptions/7.jpeg")}
        style={{
          position: "absolute",
          zIndex: 0,
          height: width > 899 ? 500 : 350,
          right: width > 899 ? 20 : null,
          opacity: 0.7,
        }}
      />
    </div>
  );
};

export default CustomerPortal;
