import React, { useState, useContext } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import {
  BaseButtons,
  SectionTitle,
  TextInputField,
} from "../../components/atoms";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../context/admin-context";
import { LoadingPopUp } from "../../components";

const SupportPortal = () => {
  const { width, height } = useWindowDimensions();

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const admin = useContext(AdminContext);
  const navigate = useNavigate();

  const HandleSubmit = async () => {
    setLoading(true);
    setError("Loading");
    // authentication or login function heal
    //this code is used for Ui test
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: username,
            password: password,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.message === "logged in") {
        // console.log("response dtat", respnseData);
        admin.login();
        admin.adminUserInfo(respnseData);
        // setLoading(false);
        navigate("/supportAccess");
      } else {
        navigate("/");
        setError("invalid credentials");
        setLoading(false);
        console.log("error data is missing");
      }
    } catch (err) {
      setError("invalid credentials");
    }
  };
  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: BlackAndWhite.secondary,
        position: "relative",
      }}
    >
      <div
        style={{
          height: 600,
          width: width > 899 ? "40%" : "80%",
          borderStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: BlackAndWhite.primary,
          borderRadius: 8,
          position: "relative",
        }}
      >
        <img
          alt="logo"
          src={require("../../images/merryjanelogooptions/Merry-Jane Plain Light Logo.png")}
          style={{ height: 145 }}
        />
        <div
          style={{
            color: "#FFFFFF",
            fontSize: 22,
            marginTop: 20,
            marginBottom: 30,
          }}
        >
          Support login
        </div>
        <TextInputField
          labelTitle={"username"}
          onChange={(event) => setUsername(event.target.value)}
        />
        <TextInputField
          labelTitle={"password"}
          onChange={(event) => setPassword(event.target.value)}
        />
        <div style={{ height: 40 }} />
        <BaseButtons
          label={"LOGIN"}
          invert={true}
          onClick={() => HandleSubmit()}
        />
      </div>
      <LoadingPopUp display={loading} />
    </div>
  );
};

export default SupportPortal;
