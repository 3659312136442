import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const SelectDay = ({ display, setDay }) => {
  const [hover, setHover] = useState(null);
  const data = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  return (
    <div
      style={{
        padding: display ? 20 : null,
        width: 215,
        height: display ? null : 0,
        backgroundColor: BlackAndWhite.secondary,
        borderRadius: 10,
        WebkitBoxShadow: "0px 0px 10px 1px #00000066",
        position: "absolute",
        borderStyle: "solid",
        borderWidth: display ? 0.1 : 0,
        top: 0,
        display: "flex",
        flexWrap: "wrap",
        zIndex: 40000000,
        overflow: "hidden",
        transition: "all 0.3s",
        left: 20,
      }}
    >
      {data.map((day, index) => {
        return (
          <div
            onClick={() => setDay(day)}
            onMouseOver={() => setHover(index)}
            onMouseOut={() => setHover(null)}
            style={{
              color:
                hover === index
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
              height: 30,
              width: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 8,
              borderStyle: "solid",
              borderWidth: 0.1,
              margin: 3,
              backgroundColor:
                hover === index
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary,
              cursor: "pointer",
            }}
            key={index}
          >
            {day}
          </div>
        );
      })}
    </div>
  );
};

export default SelectDay;
