import React from "react";

const TextDisplayOnly = ({ title, value, marginTop }) => {
  return (
    <>
      <div
        style={{
          width: "90%",
          fontSize: 12,
          fontWeight: "bold",
          opacity: 0.6,
          marginTop: marginTop ? marginTop : 10,
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          width: "90%",
          fontSize: 14,
          marginTop: 5,
          textAlign: "left",
        }}
      >
        {value}
      </div>
    </>
  );
};

export default TextDisplayOnly;
