import React, { useState } from "react";
import useWindowDimensions from "../../../../../../components/get-diemensions/UseWindowDiemensions";
import {
  BaseButtons,
  CloseButton,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";
import QuestionaireDropDown from "../../../medical-history/components/questionaire-drop-down/QuestionareDropDown";
import QuestionButton from "../../../medical-history/components/button/QuestionButton";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { LoadingPopUp } from "../../../../../../components";

const PrescriptionRequestPopUp = ({
  display,
  onCloseClick,
  auth,
  setNewRequest,
  setToastPop,
  setMessage,
  setScreenDisplayed,
}) => {
  const { width, height } = useWindowDimensions();
  const [prescriptRequest, setPrecriptRequest] = useState("Other");
  const [other, setOther] = useState("");
  const [loading, setLoading] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(
    "Oops something went wrong"
  );
  // console.log("prescriptRequest", prescriptRequest);

  const preciptValueCheck = () => {
    if (prescriptRequest === "Other") {
      return prescriptRequest + " " + other;
    } else {
      return prescriptRequest;
    }
  };

  const requestNewPrescriptionHandler = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/requestPrescriptionUser/createRequest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `bearer ${token}`,
          },
          body: JSON.stringify({
            idDoc: auth.customerUserData.idDoc,

            email: auth.customerUserData.email,
            patientId: auth.customerUserData.idDoc,
            patientUserId: auth.customerUserData.userId,
            requestReason: other,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.success) {
        setMessage("You request has been sent");
        setLoading(false);
        setNewRequest(false);
        setScreenDisplayed("profile");
        setToastPop(true);
      } else {
        setMessage(respnseData.message);
        setTimeout(() => {
          setLoading(false);
          setToastPop(true);
        }, 1000);
      }
    } catch (err) {
      // setRegistration(false);
      // setDisplayButtons(true);
      setMessage("Unable to request prescription");
      setTimeout(() => {
        setLoading(false);
        setToastPop(true);
      }, 3000);
      console.log("error", err);
    }
  };
  return (
    <div
      style={{
        height: display ? height : 0,
        width: width,
        overflow: "hidden",
        transition: "height 0.6s",
        backgroundColor: "#00000066",
        position: "fixed",
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {loading ? (
        <LoadingPopUp display={loading} />
      ) : (
        <div
          style={{
            height: "95%",
            width: "100%",
            backgroundColor: "#000000cc",
            position: "relative",
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <div style={{ position: "absolute", top: 20, left: 20 }}>
            <CloseButton label={"X"} onClick={onCloseClick} invert={true} />
          </div>
          <div
            style={{
              width: "80%",
              fontSize: 18,
              color: BlackAndWhite.secondary,
              textAlign: "center",
              fontWeight: "bold",
              marginTop: 25,
            }}
          >
            REQUEST PRESCRIPTION
          </div>
          <div
            style={{
              color: BlackAndWhite.secondary,
              width: "80%",
              textAlign: "center ",
              marginTop: 20,
              marginBottom: 20,
              fontSize: 15,
            }}
          >
            Weed can assist with various health issues. Let us know which
            concerns you? Approval can take up to 48 hours.
          </div>

          {prescriptRequest === "" ? null : (
            <QuestionButton
              title={"How can we help?"}
              backgroundColor={BlackAndWhite.secondary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"80%"}
              height={60}
              borderRadius={200}
              onClick={() => setPrecriptRequest("")}
            />
          )}
          {/* <div
            style={{
              height: prescriptRequest === "" ? "100%" : 0,
              transition: "height 0.4s",
              overflow: prescriptRequest === "" ? "scroll" : "hidden",
              width: "100%",
            }}
          >
            <QuestionaireDropDown
              setItem={setPrecriptRequest}
              data={[
                // { text: "Pain & Inflamation" },
                // { text: "Anxiety, PTSD & Stress relief" },
                // { text: "Sleep / Insomnia treatment" },
                // { text: "Fatigue" },
                // { text: "Tension or muscle spasms" },
                // { text: "Appetite / Weight loss" },
                { text: "Other" },
              ]}
            />
          </div> */}
          <div
            style={{
              height: prescriptRequest === "Other" ? 250 : 0,
              transition: "height 0.4s",
              overflow: "hidden",
              width: "100%",
            }}
          >
            <TextAreaInput
              height={170}
              labelTitle={"Describe your condition"}
              onChange={(e) => setOther(e.target.value)}
              size={"70%"}
            />
          </div>
          <BaseButtons
            label={"SUBMIT"}
            disable={prescriptRequest === "" ? true : false}
            //   height={80}
            invert={true}
            onClick={() => requestNewPrescriptionHandler()}
          />
        </div>
      )}
    </div>
  );
};

export default PrescriptionRequestPopUp;
