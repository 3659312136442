import { createContext } from "react";

const AuthContext = createContext({
  isloggedIn: false,
  login: () => {},
  logout: () => {},
  customerLoadData: () => {},
  customerData: {},
  isValidated: false,
});

export { AuthContext };
