import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { BaseButtons, TextAreaInput } from "../../../../../../components/atoms";
import useWindowDimensions from "../../../../../../components/get-diemensions/UseWindowDiemensions";
import { LoadingPopUp, SmallButton } from "../../../../../../components";

const DocPrescriptionCard = ({
  prescriptionNumber,
  prescriptionText,
  reasonText,
  healerName,
  address,
  onDownloadClick,
  patientName,
  dob,
  idNumber,
  diagnosis,
  date,
  idKey,
  statusCheck,
  sizeWidth,
  isDoc,
  isCustomer,
  data,
  onApproveClick,
  onDeclineClick,
  onClick,
  heightCheck,
  invert,
  onEditClick,
  onChange,
  idCheckMark,
  UpdateRefeshlist,
  onExipreClick,
  validDate,
  startedDate,
  widthNew,
}) => {
  const { width, height } = useWindowDimensions();
  const [pdfDisplay, setPdfDisplay] = useState(false);
  const [textInput, setTextInput] = useState(false);
  const [medicine, setMedicine] = useState("");
  const [loading, setLoading] = useState(false);

  const UpdateDiagnosis = async () => {
    setLoading(true);
    // https://merry-jane-api.onrender.com/prescription/updateprescriptionbyId
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/prescription/updateprescriptionbyIdforDiagnosis",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: idCheckMark,
            medicine: medicine,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.data) {
        setTextInput(false);
      } else {
        setMedicine("could not update, please try again in 5 minutes");
      }
      setLoading(false);
      return responseData;
    } catch (err) {
      setLoading(false);

      console.log("error", err);
      return err;
    }
  };

  return (
    <div
      style={{
        height: 300,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // marginLeft: 40,
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          borderBottomStyle: "solid",
          //   borderBottomRightRadius: 8,
          //   borderBottomLeftRadius: 8,

          borderColor: invert ? BlackAndWhite.primary : BlackAndWhite.secondary,
          // marginLeft: 40,
          display: "flex",
          overflow: "hidden",
          flexDirection: width > 899 ? "row" : "column",
          borderWidth: 1,
        }}
      >
        {loading ? (
          <LoadingPopUp display={loading} />
        ) : (
          <div
            style={{
              flex: 0.7,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              color: invert ? BlackAndWhite.primary : BlackAndWhite.secondary,
            }}
          >
            <div
              style={{
                width: "90%",
                marginLeft: 20,
                fontSize: 14,
                display: "flex",
              }}
            >
              <div style={{ fontWeight: "bold" }}> PRESCRIPTION NO. :</div>
              <div style={{ marginLeft: 10 }}>{prescriptionNumber}</div>
            </div>
            <div
              style={{
                width: "90%",
                marginLeft: 20,
                fontWeight: "bold",
                fontSize: 14,
                position: "relative",
              }}
            >
              PRESCRIPTION:
              {isDoc ? (
                <SmallButton
                  onClick={() => setTextInput(true)}
                  right={width > 899 ? -40 : 100}
                />
              ) : null}
            </div>
            {textInput ? (
              medicine === "could not update, please try again in 5 minutes" ? (
                <div>{medicine}</div>
              ) : (
                <TextAreaInput
                  // invert={true}
                  color={BlackAndWhite.primary}
                  height={120}
                  labelTitle={"Please provide details:"}
                  width={widthNew > 899 ? "90%" : "100%"}
                  size={"90%"}
                  onChange={(e) => setMedicine(e.target.value)}
                  mini={true}
                />
              )
            ) : (
              <>
                <div
                  style={{
                    width: "80%",
                    marginLeft: 40,
                    fontSize: 14,
                    position: "relative",
                  }}
                >
                  {prescriptionText}
                </div>
                <div
                  style={{
                    width: "90%",
                    marginLeft: 20,
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  DIAGNOSIS:
                </div>
                <div style={{ width: "80%", marginLeft: 40, fontSize: 16 }}>
                  {reasonText}
                </div>
              </>
            )}
          </div>
        )}

        <div
          style={{
            flex: width > 899 ? 0.3 : 0.35,
            maxWidth: 400,
            minWidth: 400,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            color: BlackAndWhite.secondary,
            backgroundColor: BlackAndWhite.primary,
            fontSize: widthNew > 899 ? null : 12,
          }}
        >
          {textInput ? null : (
            <div
              style={{
                height: 150,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 5,
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 130,
                    marginBottom: 15,
                  }}
                >
                  <div style={{ fontSize: 14, fontWeight: "bold" }}>
                    STATUS:
                  </div>
                  <div style={{ color: BlackAndWhite.secondary }}>
                    {statusCheck}
                  </div>
                </div>
                {isDoc ? (
                  <>
                    <div
                      style={{
                        width: 130,
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        VALID UNTIL:{" "}
                      </div>
                      <div
                        style={{
                          width: 160,
                          marginBottom: 10,
                        }}
                      >
                        {validDate}
                      </div>
                    </div>
                    <div>
                      <div style={{ fontSize: 14, fontWeight: "bold" }}>
                        STARTED DATE:{" "}
                      </div>
                      <div style={{ width: 130 }}>{startedDate}</div>
                    </div>
                  </>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",

                    justifyContent: "center",
                    alignItems: "center",
                    // marginTop: 10,
                    fontSize: 14,
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                >
                  DOCTOR/HEALER:
                </div>
                <div
                  style={{
                    display: "flex",

                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {healerName}
                </div>
              </div>
            </div>
          )}

          {/* <div>LOCATION:</div>
          <div>{address}</div> */}

          {isDoc ? (
            textInput ? (
              <>
                <BaseButtons
                  label={"SUBMIT"}
                  size={160}
                  invert={true}
                  onClick={() => {
                    UpdateDiagnosis();
                    UpdateRefeshlist();
                  }}
                  mini={true}
                  //   marginTopFalse={true}
                />
                <BaseButtons
                  label={"CANCEL"}
                  size={160}
                  invert={true}
                  onClick={() => setTextInput(false)}
                  mini={true}
                  marginTopFalse={true}
                />
              </>
            ) : (
              <>
                <div style={{ display: "flex" }}>
                  <BaseButtons
                    label={"APPROVE"}
                    size={100}
                    invert={true}
                    onClick={onApproveClick}
                    mini={true}
                    marginTopFalse={true}
                    borderRadius={4}
                  />
                  <div style={{ width: 10 }} />
                  <BaseButtons
                    label={"DECLINE"}
                    size={100}
                    invert={true}
                    onClick={onDeclineClick}
                    mini={true}
                    marginTopFalse={true}
                    borderRadius={4}
                  />
                  <div style={{ width: 10 }} />
                  <BaseButtons
                    label={"EXPIRE"}
                    size={100}
                    invert={true}
                    onClick={onExipreClick}
                    mini={true}
                    marginTopFalse={true}
                    borderRadius={4}
                  />
                </div>
              </>
            )
          ) : null}
          <div style={{ height: 5 }} />
        </div>
      </div>
    </div>
  );
};

export default DocPrescriptionCard;
