import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const DisplayButton = ({ title, value, image, imageHover, onClick }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      // onMouseOver={() => setHover(true)}
      // onMouseOut={() => setHover(false)}
      // onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        backgroundColor: hover
          ? BlackAndWhite.primary
          : BlackAndWhite.secondary,

        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: hover ? 15 : 10,
        paddingRight: hover ? 15 : 10,
        WebkitBoxShadow: hover
          ? "0px 0px 5px 2px #00000050"
          : "0px 0px 0px 0px #00000050",
        transition: "all 0.3s",
      }}
    >
      <div style={{ flex: 0.5 }}>
        <img
          style={{
            height: hover ? 90 : 80,
            width: hover ? 90 : 80,
            marginRight: 20,
            transition: "all 0.3s",
          }}
          alt="logo"
          src={hover ? imageHover : image}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: hover ? BlackAndWhite.secondary : BlackAndWhite.primary,
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: 20, marginBottom: 10 }}>
          {value}
        </div>
        <div style={{ fontWeight: "bold", fontSize: 16 }}>{title}</div>
      </div>
    </div>
  );
};
export default DisplayButton;
