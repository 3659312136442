import React, { useState, useContext } from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../components/atoms";
import QuestionaireDropDown from "../questionaire-drop-down/QuestionareDropDown";
import QuestionButton from "../button/QuestionButton";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { AuthContext } from "../../../../../../context/auth-context";
import LoadingPopUp from "../../../../../../components/loading-pop-up/LoadingPopUp";

const OccupationalInfo = ({
  setSelectedSection,
  setOccupationalDetails,
  occupationaleInfo,
  answer1Complete,
  answer2Complete,
}) => {
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("no data");
  const auth = useContext(AuthContext);

  const lifeStyleHandler = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/medicalHistory/createAnswerSheet",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `bearer ${token}`,
          },
          body: JSON.stringify({
            id: auth.customerUserData.idDoc,
            section: "occupational",
            answer1: answer1,
            answer2: answer2,
            answer3: "no data",
            answer4: "no data",
            answer5: "no data",
            answer6: "no data",
            answer7: "no data",
            answer8: "no data",
            answer9: "no data",
            answer10: "no data",
            answer11: "no data",
            answer12: "no data",
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.data) {
        setLoading(false);
        setSelectedSection("none");
        setOccupationalDetails(true);
      }
    } catch (err) {
      setErrorMessage("Unable to upload answer sheet");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      console.log("error", err);
    }
  };

  return (
    <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
      {loading ? (
        <LoadingPopUp display={loading} />
      ) : errorMessage !== "no data" ? (
        <div
          onClick={() => setErrorMessage("no data")}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {errorMessage}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 30 }}>OCCUPATIONAL INFO</div>
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            1. We're not here to help you pay the bills and pry into what you do
            for a living. We are however curious about you groove so we can
            serve up alternative health goodies that match your daily
            shenanigans.
          </div>

          <div style={{ height: 20 }} />
          {occupationaleInfo ? (
            <>
              <div style={{ height: 20 }} />
              <QuestionButton
                borderOn={true}
                title={answer1Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : answer1 === "" ? (
            <QuestionaireDropDown
              setItem={setAnswer1}
              data={[
                { text: "I am a professional" },
                { text: "I work for myself." },
                { text: "I work for others, the 9-5 thing." },
                { text: "I am retired." },
                { text: "I am a student." },
                { text: "I work in and around the home." },
                { text: "Inbetween jobs." },
              ]}
            />
          ) : (
            <QuestionButton
              onClick={() => setAnswer1("")}
              title={answer1}
              color={BlackAndWhite.secondary}
              colorAlt={BlackAndWhite.primary}
              backgroundColor={BlackAndWhite.primary}
              backgroundColorAlt={BlackAndWhite.secondary}
              width={"80%"}
              height={60}
              borderRadius={200}
            />
          )}
          <div
            style={{
              width: "80%",
              textAlign: "left",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            2. Merry-Jane wants to know what industry you groove in?
          </div>
          {occupationaleInfo ? (
            <>
              <div style={{ height: 20 }} />
              <QuestionButton
                borderOn={true}
                title={answer2Complete}
                height={60}
                width={"80%"}
                backgroundColor={BlackAndWhite.secondary}
                backgroundColorAlt={BlackAndWhite.secondary}
                borderRadius={200}
              />
            </>
          ) : (
            <TextInputField
              invert={true}
              labelTitle={"Please provide details"}
              onChange={(event) => setAnswer2(event.target.value)}
            />
          )}
          <div
            style={{
              marginTop: 20,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {occupationaleInfo ? null : (
              <BaseButtons
                label={"SUBMIT"}
                size={"80%"}
                onClick={() => lifeStyleHandler()}
                disable={
                  answer1.length >= 2 && answer2.length >= 2 ? false : true
                }
              />
            )}
          </div>
          <div style={{ height: 80 }} />
        </div>
      )}
    </div>
  );
};
export default OccupationalInfo;
