import React, { useState } from "react";
import {
  BaseButtons,
  CheckInput,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { ImageCheckMark, LoadingPopUp } from "../../../../../../components";

const AddSubCategory = ({
  setMessage,
  setToastPop,
  setSelectedAdd,
  categorySelected,
  setSectionDisplay,
}) => {
  const [loading, setLoading] = useState(false);

  const [subCategoryName, setSubCategoryName] = useState(null);
  const [description, setDescription] = useState(null);
  const [moreInfo, setMoreInfo] = useState(null);
  const [subCategoryType, setSubCategoryType] = useState(null);

  // subCategoryName,
  // categoryId,
  // description,
  // moreInfo,
  // subCategoryType,

  const uploadSubCategory = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/createSubCategories",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subCategoryName,
            categoryId: categorySelected.categoryId,
            description,
            moreInfo,
            subCategoryType,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.subCategory) {
        // const wait = await updateListFetch();
        setToastPop(true);
        setMessage("Sub Category uploaded");
        setLoading(false);
        setSelectedAdd(null);
        setSectionDisplay("cat");
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ flex: 0.5, position: "relative" }}>
        <div style={{ height: 40 }} />
        <TextInputField
          labelTitle={" Sub Category name:"}
          invert
          size={"70%"}
          onChange={(e) => setSubCategoryName(e.target.value)}
        />
        <TextAreaInput
          labelTitle={"Description :"}
          invert
          size={"70%"}
          color={BlackAndWhite.primary}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextInputField
          labelTitle={"Extra info:"}
          invert
          size={"70%"}
          onChange={(e) => setMoreInfo(e.target.value)}
        />
      </div>
      <div style={{ flex: 0.5 }}>
        <div style={{ height: 40 }} />
        <TextInputField
          labelTitle={"Sub Category Type:"}
          invert
          size={"70%"}
          onChange={(e) => setSubCategoryType(e.target.value)}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <BaseButtons label={"SUBMIT"} onClick={() => uploadSubCategory()} />
          <BaseButtons label={"CANCEL"} onClick={() => setSelectedAdd(null)} />
        </div>
      </div>
      <LoadingPopUp display={loading} />
    </>
  );
};

export default AddSubCategory;
