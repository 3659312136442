import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../../../themes/Colors";
import {
  CloseButton,
  TextInputFieldSearch,
} from "../../../../../../../../../../components/atoms";
import { ProductListHeader } from "../../../../../../../product-uploader/components/list-view/components";
import ProductListCard from "../../../../../../../product-uploader/components/list-view/components/product-list-card/ProductListCard";

const AddProductPopUp = ({
  display,
  search,
  setSearch,
  productsArray,
  setAddProductPopUpDisplay,
  productsSearchArray,
  AddNewItemToArray,
  auth,
}) => {
  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;
      return name;
    }
  };
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 200,
        backgroundColor: BlackAndWhite.secondary,
        width: "80%",
        padding: display ? 10 : 0,

        bottom: display ? "15%" : 0,
        height: display ? "70%" : 0,
        transition: "all 0.3s",
        borderRadius: 10,
        overflow: "hidden",
        WebkitBoxShadow: display
          ? "0px 0px 7px 2px #00000050"
          : "0px 0px 0px 0px #00000050",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          height: 60,
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            // width: 300,
            marginLeft: 60,
            borderWidth: 0.5,
            marginBottom: 5,
          }}
        >
          <TextInputFieldSearch
            placeholder={"SEARCH PRODUCT TO ADD"}
            height={25}
            borderRadius={4}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <div
            style={{
              height: 30,
              width: 70,
              backgroundColor: BlackAndWhite.primary,
              borderRadius: 4,
              display: "flex",
              color: BlackAndWhite.secondary,
              justifyContent: "center",
              alignItems: "center",
              //   marginRight: 20,
            }}
          >
            <div
              onClick={() => setSearch("")}
              style={{ fontSize: 12, fontWeight: "bold" }}
            >
              CLEAR
            </div>
          </div>
        </div>
      </div>

      <ProductListHeader
        warehouseSelect={true}
        transferDisplay={true}
        isProdycDisplay={true}
      />
      <div
        style={{
          flex: 1,

          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {productsSearchArray
          ? productsSearchArray.map((product, index) => {
              return NameAssemble({ product })
                .toLocaleLowerCase()
                .match(search) ? (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    minHeight: 55,
                    // borderStyle: "solid",
                  }}
                >
                  <ProductListCard
                    noRemoveOption={true}
                    transferDisplay={true}
                    warehouseSelect={true}
                    productName={NameAssemble({ product })}
                    image={product.imageUrl}
                    product={product}
                    sku={product.sku}
                    unitMeasure={product.quantityDenomiation}
                    subCat={product.productSubType}
                    size={product.size}
                    thcType={product.thcType}
                    category={product.productType}
                    productPref={product.flavour}
                    productFeature={product.strain}
                    retail={product.retailPriceInc}
                    // removeSelectedItemsQty={removeSelectedItemsQty}
                    //   onRemoveClick={() => {
                    //     removeSelectedItems(product, index);
                    //     removeSelectedItemsQty(product, index);
                    //   }}
                    auth={auth}
                    // AddNewItemToArrayQty={AddNewItemToArrayQty}
                    AddNewItemToArray={AddNewItemToArray}
                    wareHouseCheck={true}
                    productId={product._id}
                  />
                </div>
              ) : null;
            })
          : null}
      </div>
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <CloseButton
          label={"X"}
          onClick={() => setAddProductPopUpDisplay(false)}
        />
      </div>
    </div>
  );
};

export default AddProductPopUp;
