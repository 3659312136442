import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputField,
  TextInputFieldSearch,
} from "../../../../components/atoms";
import { InventoryManageCard } from "../inventory-pos/components";
import {
  CartItem,
  CompleteSalePopUp,
  FilterDropDown,
  SelectedCustomerPopUp,
} from "./components";

const ProcessSale = ({
  user,
  refresh,
  setLoading,

  setToastPop,
  setMessage,
  setRefresh,
  selectedUser,
  setSelectedUser,
  storeInfo,
  setStoreInfo,
}) => {
  const [search, setSearch] = useState("");

  const [productsArray, setProductsArray] = useState(null);
  const [customers, setCustomers] = useState(null);

  //discount in rand value
  const [discount, setDiscount] = useState(0);

  const [completeSale, setCompleteSale] = useState(false);

  const [discountPopUp, setDiscountPopUp] = useState(false);
  const [discountIsPercentage, setDiscountIsPercentage] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [filterOption, setFilterOption] = useState("CATEGORY");

  //discount in percent

  const [discountAmount, setDiscountAmount] = useState(0);
  const [exDiscountAmount, setExDiscountAmount] = useState(0);

  const [totalIncluding, setTotalIncluding] = useState(0.0);

  const [stateCheck, setStateCheck] = useState(false);

  const [recieptList, setRecieptList] = useState([]);

  let productsArrayCheck = [];
  const removeSelectedItem = (index) => {
    var array = [...productsArray]; // make a separate copy of the array

    array.splice(index, 1);
    setProductsArray(array);
  };
  const removeFromRecipets = (index) => {
    var array = [...recieptList]; // make a separate copy of the array

    array.splice(index, 1);
    setRecieptList(array);
  };
  const AddNewItemToArray = (product) => {
    let baseArray = recieptList;
    // qtyArray.push(product);
    baseArray.push(product);

    setStateCheck(stateCheck ? false : true);
  };

  const AddBackToProducts = (product) => {
    let baseArray = [...productsArray];
    // qtyArray.push(product);
    baseArray.push(product);
    setProductsArray(baseArray);
    setStateCheck(stateCheck ? false : true);
  };

  const ReturnArrayRecieptArray = () => {
    CalculateTotal();
    return recieptList;
  };

  let productPriceArray = [];
  let totalArray = [];

  const CalculateTotal = () => {
    productPriceArray = [];
    totalArray = [];
    var array = [...recieptList];

    array.map((item) =>
      productPriceArray.push({
        productPrice: item.product.retailPriceInc,
        productId: item.product._id,
        qty: item.qtyCard.qty,
        total: Number(item.qtyCard.qty) * Number(item.product.retailPriceInc),
      })
    );

    productPriceArray.map((item) => totalArray.push(item.total));

    let valueCheck = totalArray.reduce((a, b) => a + b, 0);
    let discountcheck = (valueCheck.toFixed(2) / 100) * discount;
    if (discountIsPercentage) {
      setTotalIncluding(valueCheck.toFixed(2) - discountcheck);
      setDiscountAmount(
        valueCheck.toFixed(2) - (valueCheck.toFixed(2) - discountcheck)
      );
      setExDiscountAmount(valueCheck.toFixed(2));
    } else {
      setTotalIncluding(valueCheck.toFixed(2) - discount);
      setDiscountAmount(
        valueCheck.toFixed(2) - (valueCheck.toFixed(2) - discount)
      );
      setExDiscountAmount(valueCheck.toFixed(2));
    }
  };

  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;
      return name;
    }
  };

  const getProducts = async () => {
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsAdded",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setProductsArray(respnseData.product);
        setLoading(false);
      } else if (respnseData.message) {
        setProductsArray([]);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("err", err);
    }
  };
  const EditQty = ({ idIndex, qty }) => {
    const array = [...recieptList];

    //find index of item to be replaced
    const targetIndex = recieptList.findIndex((f, index) => index === idIndex);

    //replace the object with a new one.
    array[targetIndex].qtyCard.qty = qty;
    // console.log("calcutlate check trigger", recieptList);
    CalculateTotal();
  };

  useEffect(() => {
    getProducts();
  }, [refresh]);
  useEffect(() => {
    ReturnArrayRecieptArray();
  }, [stateCheck]);
  return (
    <div
      style={{
        flex: 1,
        // backgroundColor: "#00000010",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: 1,

          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: 60,
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 0 }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  // width: 300,

                  borderWidth: 0.5,
                }}
              >
                <TextInputFieldSearch
                  placeholder={"Type here to search"}
                  height={30}
                  borderRadius={4}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <div
                  style={{
                    height: 35,
                    width: 70,
                    backgroundColor: BlackAndWhite.primary,
                    borderRadius: 4,
                    display: "flex",
                    color: BlackAndWhite.secondary,
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginLeft: 5,
                  }}
                >
                  <div
                    onClick={() => setSearch("")}
                    style={{ fontSize: 12, fontWeight: "bold" }}
                  >
                    CLEAR
                  </div>
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }} />
            <div style={{ marginRight: 20 }}>
              <FilterDropDown
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedSubCategory={selectedSubCategory}
                setSelectedSubCategory={setSelectedSubCategory}
                filterOption={filterOption}
                setFilterOption={setFilterOption}
              />
            </div>
          </div>
          <div
            style={{
              maxHeight: 800,
              minHeight: 400,

              overflowX: "hidden",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              scrollbarWidth: "none",
            }}
          >
            {productsArray
              ? productsArray.map((product, index) => {
                  return selectedCategory ? (
                    selectedCategory.categoryName === product.productType ? (
                      selectedSubCategory ? (
                        selectedSubCategory.subCategoryName ===
                        product.productSubType ? (
                          NameAssemble({ product })
                            .toLocaleLowerCase()
                            .match(search.toLocaleLowerCase()) ? (
                            <div key={index} style={{ width: "100%" }}>
                              <InventoryManageCard
                                productsArray={productsArray}
                                index={index}
                                priceDisplayed={true}
                                price={product.retailPriceInc}
                                product={product}
                                productName={NameAssemble({ product })}
                                sku={product.sku}
                                supplier={product.supplier}
                                image={product.imageUrl}
                                isStore={true}
                                processSale={true}
                                onAddClick={() => {
                                  AddNewItemToArray({
                                    product,
                                    qtyCard: {
                                      qty: 1,
                                      productId: product._id,
                                    },
                                  });
                                  removeSelectedItem(index);
                                }}
                              />
                            </div>
                          ) : null
                        ) : null
                      ) : NameAssemble({ product })
                          .toLocaleLowerCase()
                          .match(search.toLocaleLowerCase()) ? (
                        <div key={index} style={{ width: "100%" }}>
                          <InventoryManageCard
                            productsArray={productsArray}
                            index={index}
                            priceDisplayed={true}
                            price={product.retailPriceInc}
                            product={product}
                            productName={NameAssemble({ product })}
                            sku={product.sku}
                            supplier={product.supplier}
                            image={product.imageUrl}
                            isStore={true}
                            processSale={true}
                            onAddClick={() => {
                              AddNewItemToArray({
                                product,
                                qtyCard: {
                                  qty: 1,
                                  productId: product._id,
                                },
                              });
                              removeSelectedItem(index);
                            }}
                          />
                        </div>
                      ) : null
                    ) : null
                  ) : NameAssemble({ product })
                      .toLocaleLowerCase()
                      .match(search.toLocaleLowerCase()) ? (
                    <div key={index} style={{ width: "100%" }}>
                      <InventoryManageCard
                        productsArray={productsArray}
                        index={index}
                        priceDisplayed={true}
                        product={product}
                        productName={NameAssemble({ product })}
                        sku={product.sku}
                        supplier={product.supplier}
                        image={product.imageUrl}
                        isStore={true}
                        processSale={true}
                        price={product.retailPriceInc}
                        onAddClick={() => {
                          AddNewItemToArray({
                            product,
                            qtyCard: {
                              qty: 1,
                              productId: product._id,
                            },
                          });
                          removeSelectedItem(index);
                        }}
                      />
                    </div>
                  ) : null;
                })
              : "LOADING"}
          </div>
        </div>
        <div
          style={{
            width: 450,
            height: "100%",

            // backgroundColor: BlackAndWhite.secondary,
            backgroundColor: "#00000020",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            WebkitBoxShadow: "0px 0px 7px 2px #00000040",
            position: "relative",
            overflow: "hidden",
            borderTopLeftRadius: 10,
          }}
        >
          <div
            style={{
              width: "100%",
              padding: 10,
              backgroundColor: BlackAndWhite.secondary,
              display: "flex",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 22,
                marginTop: 10,
                marginBottom: 10,
                marginLeft: 25,
                marginRight: 20,
              }}
            >
              CART
            </div>
            <div
              onClick={() => {
                setSelectedUser(null);
                setCustomers(null);
                setLoading(false);
              }}
              style={{
                flex: 1,

                marginRight: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: 10,
                  backgroundColor: BlackAndWhite.primary,
                  color: BlackAndWhite.secondary,
                  width: "90%",
                  borderRadius: 8,
                }}
              >
                {selectedUser
                  ? selectedUser.firstName + " " + selectedUser.lastName
                  : "loading"}
              </div>
            </div>
          </div>

          <div
            style={{
              flex: 1,
              maxHeight: 500,
              minHeight: 400,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              overflowY: "scroll",
              overflowX: "hidden",
              width: "100%",
              scrollbarWidth: "none",
            }}
          >
            {recieptList
              ? recieptList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: index === recieptList.length - 1 ? 40 : 0,
                      }}
                    >
                      <CartItem
                        index={index}
                        item={item}
                        NameAssemble={NameAssemble}
                        EditQty={EditQty}
                        AddBackToProducts={AddBackToProducts}
                        removeFromRecipets={removeFromRecipets}
                      />
                    </div>
                  );
                })
              : "SELECT PRODUCTS"}
          </div>
          <div
            style={{
              height: 300,
              width: "100%",

              marginBottom: 20,
              backgroundColor: BlackAndWhite.secondary,
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                height: 50,
                marginTop: 10,
              }}
            >
              <div style={{ marginLeft: 30, fontWeight: "bold" }}>Discount</div>
              <div style={{ flex: 1 }}></div>
              <div style={{ marginRight: 30, fontWeight: "normal" }}>
                {discountIsPercentage ? "R " : "R "}{" "}
                {discountIsPercentage
                  ? Number(discountAmount).toFixed(2)
                  : Number(discount).toFixed(2)}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                height: 50,
              }}
            >
              <div style={{ marginLeft: 30, fontWeight: "bold" }}>Subtotal</div>
              <div style={{ flex: 1 }}></div>
              <div style={{ marginRight: 30, fontWeight: "normal" }}>
                R {(totalIncluding - (totalIncluding / 100) * 15).toFixed(2)}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                height: 50,
              }}
            >
              <div style={{ marginLeft: 30, fontWeight: "bold" }}>
                Sales Tax
              </div>
              <div style={{ flex: 1 }}></div>
              <div style={{ marginRight: 30, fontWeight: "normal" }}>
                R {((totalIncluding / 100) * 15).toFixed(2)}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                height: 50,
              }}
            >
              <div style={{ marginLeft: 30, fontWeight: "bold" }}>
                Total Incl.
              </div>
              <div style={{ flex: 1 }}></div>
              <div style={{ marginRight: 30, fontWeight: "normal" }}>
                R {totalIncluding.toFixed(2)}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: 80,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BaseButtons
                size={"65%"}
                mini={true}
                marginTopFalse={true}
                borderRadius={8}
                label={"PROCEED TO CHECKOUT"}
                onClick={() => setCompleteSale(true)}
              />
              {/* <div style={{ width: "10%" }} /> */}
              <BaseButtons
                size={"25%"}
                mini={true}
                marginTopFalse={true}
                borderRadius={8}
                label={"DISCOUNT"}
                marginLeft={10}
                onClick={() => setDiscountPopUp(true)}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: -150,
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                overflow: "hidden",
                height: discountPopUp ? 150 : 0,
                backgroundColor: discountPopUp ? "#FFFFFF80" : null,
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  onClick={() => setDiscountIsPercentage(true)}
                  style={{
                    flex: 0.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 40,
                    backgroundColor: discountIsPercentage
                      ? BlackAndWhite.primary
                      : null,
                    color: discountIsPercentage
                      ? BlackAndWhite.secondary
                      : null,
                  }}
                >
                  {" "}
                  PERCENTAGE
                </div>
                <div
                  onClick={() => setDiscountIsPercentage(false)}
                  style={{
                    flex: 0.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 40,
                    backgroundColor: !discountIsPercentage
                      ? BlackAndWhite.primary
                      : null,
                    color: !discountIsPercentage
                      ? BlackAndWhite.secondary
                      : null,
                  }}
                >
                  {" "}
                  RAND
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ minWidth: 20 }} />
                <TextInputField
                  invert={true}
                  labelTitle={
                    discountIsPercentage ? "Amount in %" : "Amount in R"
                  }
                  mini={true}
                  type={"number"}
                  onChange={(e) => setDiscount(e.target.value)}
                  value={discount}
                />
                <BaseButtons
                  label={"DONE"}
                  marginLeft={10}
                  mini={true}
                  onClick={() => {
                    setDiscountPopUp(false);
                    CalculateTotal();
                  }}
                  // marginTopFalse={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SelectedCustomerPopUp
        display={!selectedUser}
        setSelectedUser={setSelectedUser}
        admin={user}
        customers={customers}
        setCustomers={setCustomers}
      />
      <CompleteSalePopUp
        setDiscount={setDiscount}
        setDiscountAmount={setDiscountAmount}
        tax={((totalIncluding / 100) * 15).toFixed(2)}
        subTotal={(totalIncluding - (totalIncluding / 100) * 15).toFixed(2)}
        discount={
          discountIsPercentage
            ? Number(discountAmount).toFixed(2)
            : Number(discount).toFixed(2)
        }
        productsArray={recieptList}
        refresh={refresh}
        setRefresh={setRefresh}
        selectedUser={selectedUser}
        display={completeSale}
        total={totalIncluding}
        setCompleteSale={setCompleteSale}
        setLoading={setLoading}
        user={user}
        setToastPop={setToastPop}
        setMessage={setMessage}
        setRecieptList={setRecieptList}
        setSelectedUser={setSelectedUser}
        setSelectedSubCategory={setSelectedSubCategory}
        selectedSubCategory={selectedSubCategory}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        setSearch={setSearch}
        setCustomers={setCustomers}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        storeInfo={storeInfo}
        setStoreInfo={setStoreInfo}
      />
    </div>
  );
};

export default ProcessSale;
