import React, { useState, useEffect, useContext } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import { BaseButtons } from "../../components/atoms";
import { AdminContext } from "../../context/admin-context";
import { useNavigate } from "react-router-dom";
import AdminCustomerTab from "../admin-portal/components/AdminCustomerTab";
import QuestionButton from "../customer-portal/components/medical-history/components/button/QuestionButton";
import {
  DoctorPracticeProfile,
  PatientDisplay,
  PrescriptionDisplay,
  RequestDisplay,
} from "./components";
import PatientPopUp from "./components/patient-pop-up/PatientPopUp";
import { LoadingPopUp } from "../../components";
import { CannaMenuButton, ProfileMenu } from "../canna-pos/components";
import SearchBar from "../canna-pos/components/patient-display/SearchBar";
import FilterPopUp from "../canna-pos/components/patient-display/FilterPopUp";
import { TopMenuBar } from "./components/mobile-components";

// import { useNavigate } from "react-router-dom";

const DocAccess = () => {
  const { width, height } = useWindowDimensions();
  const [shrink, setShrink] = useState(false);

  const [onMenu, setOnMenu] = useState(false);
  const [tab, setTab] = useState("patients");

  const [patientPop, setPatientPop] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  //add product info
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [productNo, setProductNo] = useState("");

  const [websiteData, setWebsiteData] = useState(null);
  // const [urlPath, setUrlPath] = useState("");
  const admin = useContext(AdminContext);
  const navigate = useNavigate();

  //website welcome update fields

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!admin.isloggedIn || admin.adminUserData.type !== "Doctor") {
      // console.log("path check", admin.adminUserData.type);
      navigate("/adminPortal");
    } else if (!admin.isloggedIn || admin.adminUserData.type === "Customer") {
      navigate("/");
    }

    if (width > 899) {
      setTab("patients");
    }
  }, []);
  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        flexDirection: width > 899 ? "row" : "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: BlackAndWhite.secondary,
        position: "relative",
      }}
    >
      {/* top header */}
      {width > 899 ? (
        <div
          style={{
            fontSize: 30,
            fontWeight: "700",
            color: BlackAndWhite.secondary,
            position: "fixed",
            width: "100%",
            backgroundColor: BlackAndWhite.primary,
            top: 0,
            height: 50,
            display: "flex",
            alignItems: "center",
            zIndex: 2000,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          }}
        >
          <div
            onClick={() => setShrink(shrink ? false : true)}
            style={{
              marginLeft: shrink ? 15 : 5,
              backgroundColor: shrink ? "#FFFFFFcc" : BlackAndWhite.secondary,
              height: shrink ? 95 : 135,
              width: shrink ? 95 : 202.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: shrink ? 200 : 20,
              position: "absolute",
              top: shrink ? 10 : 5,
              WebkitBoxShadow: "0px 0px 10px 1px #00000033",
              transition: "all 0.3s",
              // borderStyle: "solid",
              // borderColor: BlackAndWhite.primary,
              // borderWidth: 5,
            }}
          >
            <div
              onClick={() => setShrink(shrink ? false : true)}
              style={{
                // marginLeft: shrink ? 5 : 5,
                backgroundColor: shrink
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary,
                height: shrink ? 95 : 135,
                width: shrink ? 95 : 202.5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: shrink ? 200 : 8,
                // position: "absolute",
                // top: shrink ? 10 : 5,
                WebkitBoxShadow: "0px 0px 10px 1px #00000033",
                transition: "all 0.3s",
                // borderStyle: "solid",
                // borderColor: BlackAndWhite.primary,
                // borderWidth: 5,
              }}
            >
              <img
                alt="load"
                src={
                  shrink
                    ? require("../../images/newLogos/2. Merry-Jane Logo White for Dark Background.png")
                    : require("../../images/newLogos/1. MJ Logo Black with White Surround.png")
                }
                style={{
                  height: shrink ? 65 : 120,
                  transition: "all 0.3s",
                }}
              />
            </div>
          </div>
          <div
            style={{
              flex: 1,
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: shrink ? 125 : 235,
                fontWeight: "bolder",
                transition: "all 0.3s",
                fontSize: 16,
              }}
            >
              DOCTORS PORTAL
            </div>
          </div>

          <div
            style={{
              marginRight: 20,
              fontSize: 14,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: 7 }}>
              {admin.adminUserData ? admin.adminUserData.firstName : "loading"}
            </div>
            <div style={{ marginRight: 12 }}>
              {admin.adminUserData ? admin.adminUserData.lastName : "loading"}
            </div>

            <div
              onClick={() => setOnMenu(onMenu ? false : true)}
              style={{
                height: 25,
                width: 25,
                backgroundColor: onMenu
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary,
                borderRadius: 100,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                transition: "0.3s",
              }}
            >
              <div
                style={{
                  height: 5,
                  width: 5,
                  borderRadius: 20,
                  backgroundColor: onMenu
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                  transition: "0.3s",
                }}
              />
              <div
                style={{
                  height: 5,
                  width: 5,
                  borderRadius: 20,
                  backgroundColor: onMenu
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                  transition: "0.3s",
                }}
              />
              <div
                style={{
                  height: 5,
                  width: 5,
                  borderRadius: 20,
                  backgroundColor: onMenu
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
                  transition: "0.3s",
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <TopMenuBar />
      )}

      {width > 899 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: shrink ? 80 : 210,
            backgroundColor: "#FFFFFF",
            height: "100%",
            marginTop: 10,
            alignItems: "center",
            transition: "all 0.3s",
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            zIndex: 1000,
            marginLeft: shrink ? 22.5 : 0,
          }}
        >
          <div style={{ height: shrink ? 110 : 140, transition: "all 0.3s" }} />
          {shrink ? (
            <CannaMenuButton
              width={"90%"}
              iconImage={
                tab === "patients"
                  ? require("../../images/material-icons/profileWhite.png")
                  : require("../../images/material-icons/profileBlack.png")
              }
              height={40}
              onClick={() => setTab("patients")}
              selected={tab === "patients" ? true : false}
            />
          ) : (
            <QuestionButton
              title={"Patients"}
              height={45}
              onClick={() => setTab("patients")}
              backgroundColor={
                tab === "patients"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "patients"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"95%"}
              icon={
                tab === "patients"
                  ? require("../../images/material-icons/profileWhite.png")
                  : require("../../images/material-icons/profileBlack.png")
              }
              questionAmount={true}
              borderOn={tab === "patients" ? true : false}
              borderRadius={8}
            />
          )}
          <div style={{ height: 5 }} />
          {shrink ? (
            <CannaMenuButton
              width={"90%"}
              height={40}
              onClick={() => setTab("prescriptions")}
              selected={tab === "prescriptions" ? true : false}
              iconImage={
                tab === "prescriptions"
                  ? require("../../images/material-icons/usersWhite.png")
                  : require("../../images/material-icons/userBlack.png")
              }
            />
          ) : (
            <QuestionButton
              borderOn={tab === "prescriptions" ? true : false}
              title={"Prescriptions"}
              height={45}
              onClick={() => setTab("prescriptions")}
              backgroundColor={
                tab === "prescriptions"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "prescriptions"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"95%"}
              icon={
                tab === "prescriptions"
                  ? require("../../images/material-icons/usersWhite.png")
                  : require("../../images/material-icons/userBlack.png")
              }
              // selected={tab === "register" ? true : false}
              questionAmount={true}
              borderRadius={8}
            />
          )}
          <div style={{ height: 5 }} />
          {shrink ? (
            <CannaMenuButton
              width={"90%"}
              height={40}
              onClick={() => setTab("requests")}
              selected={tab === "requests" ? true : false}
              iconImage={
                tab === "requests"
                  ? require("../../images/material-icons/usersWhite.png")
                  : require("../../images/material-icons/userBlack.png")
              }
            />
          ) : (
            <QuestionButton
              borderOn={tab === "requests" ? true : false}
              title={"Requests"}
              height={45}
              onClick={() => setTab("requests")}
              backgroundColor={
                tab === "requests"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "requests"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"95%"}
              icon={
                tab === "requests"
                  ? require("../../images/material-icons/prescriptionWhite.png")
                  : require("../../images/material-icons/prescriptionBlack.png")
              }
              // selected={tab === "register" ? true : false}
              questionAmount={true}
              borderRadius={8}
            />
          )}
          <div style={{ height: 5 }} />
          {shrink ? (
            <CannaMenuButton
              width={"90%"}
              height={40}
              onClick={() => setTab("profile")}
              selected={tab === "profile" ? true : false}
              iconImage={
                tab === "profile"
                  ? require("../../images/material-icons/usersWhite.png")
                  : require("../../images/material-icons/userBlack.png")
              }
            />
          ) : (
            <QuestionButton
              borderOn={tab === "profile" ? true : false}
              title={"Profile"}
              height={45}
              onClick={() => setTab("profile")}
              backgroundColor={
                tab === "profile"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "profile"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"95%"}
              icon={
                tab === "profile"
                  ? require("../../images/standard-icons/rightArrowWhite.png")
                  : require("../../images/standard-icons/rightArrowBlack.png")
              }
              // selected={tab === "register" ? true : false}
              questionAmount={true}
              borderRadius={8}
            />
          )}

          <div style={{ height: 5 }} />
        </div>
      ) : null}
      <div style={{ flex: 1, height: "100%" }}>
        <div
          style={{
            height: "100%",
            width: "100%",
            overflowY: tab === "patients" ? "hidden" : "scroll",
            marginTop: tab === "patients" ? 50 : width > 899 ? 55 : 0,
            position: "relative",
            overflowX: "hidden",
          }}
        >
          {tab === "patients" ? (
            width > 899 ? (
              <PatientDisplay
                setSelectedPatient={setSelectedPatient}
                setLoading={setLoading}
                loading={loading}
                setPatientPop={setPatientPop}
                admin={admin}
              />
            ) : null
          ) : null}
          {tab === "prescriptions" ? (
            <PrescriptionDisplay
              width={width}
              setSelectedPatient={setSelectedPatient}
              setLoading={setLoading}
              loading={loading}
              setPatientPop={setPatientPop}
              admin={admin ? admin.adminUserData : null}
            />
          ) : null}
          {tab === "requests" ? (
            <RequestDisplay
              width={width}
              setSelectedPatient={setSelectedPatient}
              setLoading={setLoading}
              loading={loading}
              setPatientPop={setPatientPop}
              admin={admin ? admin.adminUserData : null}
            />
          ) : null}
          {tab === "profile" ? (
            <DoctorPracticeProfile
              admin={admin ? admin.adminUserData : null}
              setLoading={setLoading}
            />
          ) : null}
          {/* <div style={{ height: 100 }} /> */}
        </div>
      </div>
      <PatientPopUp
        width={width}
        setSelectedPatient={setSelectedPatient}
        setPatientPop={setPatientPop}
        display={selectedPatient}
        setLoading={setLoading}
        loading={loading}
      />
      <LoadingPopUp display={loading} />
      {width > 899 ? (
        <ProfileMenu
          width={width}
          display={onMenu}
          username={
            admin.adminUserData
              ? admin.adminUserData.firstName +
                " " +
                admin.adminUserData.lastName
              : "loading"
          }
          idNum={admin.adminUserData ? admin.adminUserData.idDoc : "loading"}
          store={admin.adminUserData ? "ALL" : "loading"}
          province={admin.adminUserData ? "ALL" : "loading"}
          onCancelClick={() => setOnMenu(false)}
        />
      ) : null}
    </div>
  );
};

export default DocAccess;
