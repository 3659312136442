import React, { useState } from "react";
import { BlackAndWhite } from "../../../themes/Colors";

const CloseButton = ({ width, label, onClick, marginTop, height, invert }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={onClick}
      style={{
        height: 35,
        width: 35,
        backgroundColor: invert
          ? hover
            ? BlackAndWhite.primary
            : BlackAndWhite.secondary
          : hover
          ? BlackAndWhite.secondary
          : BlackAndWhite.primary,
        marginTop: marginTop,
        borderRadius: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all .3s",
        borderStyle: hover ? "solid" : null,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          color: invert
            ? hover
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary
            : hover
            ? BlackAndWhite.primary
            : BlackAndWhite.secondary,
          fontWeight: "bold",
        }}
      >
        {label ? label : "X"}
      </div>
    </div>
  );
};

export default CloseButton;
