import React from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import BaseButtons from "../base-buttons/BaseButtons";

const DropDown = ({
  drop,
  selected,
  width,
  selection,
  onDropClick,
  onGautengClick,
  onFreeStateClick,
  onLimpopoClick,
  onWesternCapeClick,
  onKwaZuluNatalClick,
  onEasternCapeClick,
  onNorthernCapeClick,
  onNorthWestClick,
  onMpumalangaClick,
  size,
}) => {
  return (
    <div
      style={{
        height: 80,
        width: size,
        display: "flex",
        flexDirection: "row",
        justifyContent: width > 899 ? "flex-start" : "center",
        alignItems: "center",
        marginTop: 40,
        position: "relative",

        // marginLeft: 20,
      }}
    >
      <div
        style={{
          color: BlackAndWhite.secondary,
          marginLeft: width > 899 ? 120 : null,
        }}
      >
        SELECT PROVINCE
      </div>
      <div style={{ flex: 1 }} />
      <div
        onClick={onDropClick}
        style={{
          borderStyle: "solid",
          height: 60,
          width: 200,
          marginRight: width > 899 ? 120 : null,
          borderRadius: 8,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {selection}
      </div>
      <div
        style={{
          position: "absolute",
          height: drop ? 620 : 0,
          width: 220,
          backgroundColor: "#000000cc",
          marginLeft: 95,
          top: 0,
          right: width > 899 ? 110 : 0,
          transition: "height 0.3s",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: drop ? 20 : 0,
        }}
      >
        <BaseButtons
          height={50}
          label={"gauteng"}
          invert={true}
          onClick={onGautengClick}
        />
        <BaseButtons
          height={50}
          label={"Free State"}
          invert={true}
          onClick={onFreeStateClick}
        />
        <BaseButtons
          height={50}
          label={"Limpopo"}
          invert={true}
          onClick={onLimpopoClick}
        />
        <BaseButtons
          height={50}
          label={"Western Cape"}
          invert={true}
          onClick={onWesternCapeClick}
        />
        <BaseButtons
          height={50}
          label={"Kwa-zulu natal"}
          invert={true}
          onClick={onKwaZuluNatalClick}
        />
        <BaseButtons
          height={50}
          label={"Eastern Cape"}
          invert={true}
          onClick={onEasternCapeClick}
        />
        <BaseButtons
          height={50}
          label={"Northern Cape"}
          invert={true}
          onClick={onNorthernCapeClick}
        />
        <BaseButtons
          height={50}
          label={"North West"}
          invert={true}
          onClick={onNorthWestClick}
        />
        <BaseButtons
          height={50}
          label={"Mpumalanga"}
          invert={true}
          onClick={onMpumalangaClick}
        />
      </div>
    </div>
  );
};

export default DropDown;
