import { createContext } from "react";

const AdminContext = createContext({
  isloggedIn: false,
  login: () => {},
  logout: () => {},
  adminUserInfo: () => {},
  adminUserData: {},
});

export { AdminContext };
