import React from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";

const InviteHeader = ({ marginTop }) => {
  return (
    <div
      style={{
        width: "100%",
        height: 50,
        display: "flex",
        borderTopColor: BlackAndWhite.primary,
        backgroundColor: BlackAndWhite.primary,
        color: BlackAndWhite.secondary,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginTop: marginTop,
      }}
    >
      <div
        style={{
          flex: 0.1,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 25, width: "100%", textAlign: "left" }}>
          INDEX
        </div>
      </div>
      <div
        style={{
          flex: 0.2,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
          FIRST NAME
        </div>
      </div>
      <div
        style={{
          flex: 0.2,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
          LAST NAME
        </div>
      </div>
      <div
        style={{
          flex: 0.25,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ width: "100%", textAlign: "left" }}>EMAIL</div>
      </div>
      <div
        style={{
          flex: 0.15,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ width: "100%", textAlign: "left" }}>CONTACT NR</div>
      </div>
      <div
        style={{
          flex: 0.1,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ width: "100%", textAlign: "left" }}>CODE</div>
      </div>
      <div
        style={{
          flex: 0.1,
          borderBottomStyle: "solid",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        ACTIONS
        {/* <div
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onClick={onClick}
          style={{
            backgroundColor: hover
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
            borderRadius: 4,
            paddingTop: 7.5,
            paddingBottom: 7.5,
            paddingLeft: 10,
            paddingRight: 15,
            color: hover ? BlackAndWhite.secondary : BlackAndWhite.primary,
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {hover ? (
            <img
              alt="add"
              src={require("../../../../images/material-icons/addWhite.png")}
              style={{ height: 25, marginRight: 5 }}
            />
          ) : (
            <img
              alt="add"
              src={require("../../../../images/material-icons/addBlack.png")}
              style={{ height: 25, marginRight: 5, opacity: 0.7 }}
            />
          )}
          ADD FACILITIES
        </div> */}
        <div style={{ width: 20 }} />
        {/* <div> Edit users</div>
        <div style={{ width: 20 }} /> */}
      </div>
    </div>
  );
};

export default InviteHeader;
