import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";
import UserItem from "../../table-blocks/UserItem";

const UserStoreCard = ({
  title,
  store,
  index,
  users,
  selectedOpen,
  setSelectedOpen,
  setUserSelected,
  allOption,
  count,
}) => {
  const [drop, setDrop] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <div style={{ width: "100%" }}>
      <div
        onMouseOver={() => setHover(index)}
        onMouseOut={() => setHover(null)}
        onClick={() => setDrop(drop ? false : true)}
        style={{
          width: "100%",
          height: 50,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor:
            hover === index
              ? drop
                ? "#Faa91980"
                : "#00000075"
              : drop
              ? "#FAA91970"
              : "#00000040",
          marginTop: 2,
          transition: "all 0.3s",
          fontWeight: hover === index ? "bold" : "normal",
          cursor: "pointer",
        }}
      >
        <div style={{ marginLeft: 10 }}>{title}</div>
        <div style={{ flex: 1 }}></div>
        <div>{count}</div>
        <div style={{ marginRight: 10, fontSize: 14 }}>
          {store ? store.status : "ALL ADMIN USERS"}
        </div>
      </div>
      <div style={{ height: drop ? null : 0, overflow: "hidden" }}>
        {allOption
          ? users
            ? users.data.map((user, index) => {
                return user.storeName === "ALL" ? (
                  <div key={index} style={{ width: "100%" }}>
                    <UserItem
                      onManageClick={() => setUserSelected(user)}
                      firstName={user.firstName}
                      surname={user.lastName}
                      role={user.type}
                      store={user.storeName}
                      email={user.email}
                      cell={user.cellNumber}
                      status={user.status}
                      province={user.province}
                    />
                  </div>
                ) : null;
              })
            : null
          : users
          ? users.data.map((user, index) => {
              return store.storeId === user.storeId ? (
                <div key={index} style={{ width: "100%" }}>
                  <UserItem
                    onManageClick={() => setUserSelected(user)}
                    firstName={user.firstName}
                    surname={user.lastName}
                    role={user.type}
                    store={user.storeName}
                    email={user.email}
                    cell={user.cellNumber}
                    status={user.status}
                    province={user.province}
                  />
                </div>
              ) : null;
            })
          : null}
      </div>
    </div>
  );
};
export default UserStoreCard;
