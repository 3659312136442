import React from "react";
import ItemCard from "../item-card/ItemCard";

const Page3Display = ({
  startAnationThree,
  page5,
  refresh,
  selectedStore,
  page6,
}) => {
  return (
    <div
      style={{
        // backgroundColor: "#FFFFFF",
        width: "99%",
        height: startAnationThree ? "99%" : 0,
        overflow: "hidden",
        // borderRadius: 8,
        transition: "all 1.4s",
        display: "flex",
        flexDirection: "row",
        zIndex: 100,
        opacity: startAnationThree ? 1 : 0,
      }}
    >
      <div
        style={{
          flex: 0.5,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          // borderStyle: "solid",
        }}
      >
        {/* left side */}
        <div
          style={{
            flex: 0.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ display: "flex", flex: 0.5 }}>
            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[0].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[0].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].langabaan === "true"
                        ? false
                        : true
                      : false
                    : page5.length >= 1
                    ? page5[0].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[0].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[0].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].woodstock === "true"
                        ? false
                        : true
                      : false
                    : page5.length >= 1
                    ? page5[0].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[0].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[0].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].storefield1 === "malmesbury"
                        ? false
                        : true
                      : false
                    : page5.length >= 1
                    ? page5[0].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[0].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[0].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].storefield2 === "mosselBay"
                        ? false
                        : true
                      : false
                    : page5.length >= 1
                    ? page5[0].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[0].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[0].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].storefield3 === "woester"
                        ? false
                        : true
                      : false
                    : page5.length >= 1
                    ? page5[0].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[0].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[0].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].storefield4 === "panorama"
                        ? false
                        : true
                      : false
                    : page5.length >= 1
                    ? page5[0].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[0].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[0].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].storefield5 === "hermanus"
                        ? false
                        : true
                      : false
                    : page5.length >= 1
                    ? page5[0].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[0].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[0].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].storefield6 === "wellington"
                        ? false
                        : true
                      : false
                    : page5.length >= 1
                    ? page5[0].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[1].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[1].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[1].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[1].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[1].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[1].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[1].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[1].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[1].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[1].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[1].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[1].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[1].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[1].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[1].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[1].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[1].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[1].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[1].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[1].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[1].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[1].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[1].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[1].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[1].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[1].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[1].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[1].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[1].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[1].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[1].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[1].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[1].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[1].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
          </div>
          <div style={{ display: "flex", flex: 0.5 }}>
            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[2].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[2].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[2].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[2].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[2].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[2].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[2].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[2].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[2].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[2].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[2].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[2].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[2].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[2].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[2].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[2].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[2].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[2].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[2].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[2].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[2].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[2].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[2].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[2].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[2].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[2].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[2].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[2].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[2].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[2].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[2].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[2].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[2].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[2].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[3].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[3].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[3].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[3].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[3].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[3].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[3].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[3].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[3].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[3].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[3].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[3].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[3].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[3].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[3].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[3].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[3].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[3].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[3].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[3].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[3].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[3].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[3].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[3].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[3].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[3].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[3].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[3].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[3].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[3].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[3].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[3].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[3].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[3].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
          </div>
        </div>
        {/* right side */}
        <div
          style={{
            flex: 0.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ display: "flex", flex: 0.5 }}>
            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[4].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[4].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[4].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[4].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[4].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[4].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[4].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[4].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[4].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[4].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[4].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[4].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[4].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[4].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[4].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[4].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[4].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[4].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[4].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[4].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[4].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[4].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[4].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[4].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[4].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[4].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[4].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[4].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[4].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[4].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[4].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[4].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[4].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[4].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[5].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[5].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[5].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[5].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[5].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[5].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[5].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[5].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[5].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[5].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[5].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[5].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[5].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[5].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[5].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[5].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[5].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[5].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[5].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[5].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[5].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[5].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[5].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[5].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[5].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[5].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[5].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[5].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[5].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[5].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[5].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[5].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[5].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[5].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
          </div>
          <div style={{ display: "flex", flex: 0.5 }}>
            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[6].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[6].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[6].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[6].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[6].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[6].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[6].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[6].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[6].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[6].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[6].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[6].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[6].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[6].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[6].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[6].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[6].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[6].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[6].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[6].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[6].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[6].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[6].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[6].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[6].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[6].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[6].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[6].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[6].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[6].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[6].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[6].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[6].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[6].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[7].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[7].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[7].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[7].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[7].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[7].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[7].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[7].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[7].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[7].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[7].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[7].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[7].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[7].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[7].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[7].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[7].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[7].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[7].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[7].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[7].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[7].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[7].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[7].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[7].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[7].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[7].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[7].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[7].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[7].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[7].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[7].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[7].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[7].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 0.5,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          // borderStyle: "solid",
        }}
      >
        <div
          style={{
            flex: 0.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ display: "flex", flex: 0.5 }}>
            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[8].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[8].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[8].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[8].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[8].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[8].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[8].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[8].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[8].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[8].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[8].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[8].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[8].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[8].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[8].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[8].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[8].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[8].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[8].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[8].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[8].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[8].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[8].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[8].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[8].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[8].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[8].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[8].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[0].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[0].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[8].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[8].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[8].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[8].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[8].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[8].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[9].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[9].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[9].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[9].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[9].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[9].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[9].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[9].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[9].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[9].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[9].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[9].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[9].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[9].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[9].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[9].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[9].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[9].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[9].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[9].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[9].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[9].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[9].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[9].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[9].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[9].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[9].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[9].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[9].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[9].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[9].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[9].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[9].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[9].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
          </div>
          <div style={{ display: "flex", flex: 0.5 }}>
            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[10].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[10].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[10].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[10].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[10].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[10].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[10].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[10].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[10].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[10].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[10].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[10].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[10].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[10].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[10].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[10].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[10].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[10].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[10].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[10].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[10].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[10].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[10].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[10].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[10].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[10].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[10].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[10].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[10].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[10].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[10].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[10].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[10].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[10].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[11].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[11].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[11].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[11].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[11].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[11].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[11].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[11].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[11].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[11].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[11].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[11].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[11].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[11].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[11].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[11].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[11].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[11].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[11].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[11].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[11].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[11].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[11].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[11].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[11].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[11].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[11].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[11].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[11].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[11].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[11].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[11].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[11].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[11].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
          </div>
        </div>
        <div
          style={{
            flex: 0.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ display: "flex", flex: 0.5 }}>
            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[12].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[12].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[12].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[12].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[12].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[12].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[12].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[12].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[12].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[12].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[12].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[12].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[12].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[12].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[12].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[12].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[12].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[12].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[12].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[12].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[12].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[12].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[12].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[12].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[12].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[12].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[12].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[12].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[12].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[12].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[12].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[12].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[12].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[12].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[13].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[13].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[13].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[13].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[13].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[13].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[13].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[13].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[13].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[13].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[13].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[13].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[13].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[13].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[13].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[13].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[13].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[13].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[13].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[13].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[13].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[13].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[13].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[13].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[13].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[13].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[13].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[13].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[13].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[13].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[13].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[13].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[13].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[13].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
          </div>
          <div style={{ display: "flex", flex: 0.5 }}>
            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[14].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[14].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[14].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[14].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[14].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[14].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[14].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[14].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[14].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[14].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[14].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[14].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[14].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[14].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[14].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[14].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[14].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[14].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[14].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[14].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[14].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[14].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[14].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[14].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[14].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[14].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[14].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[14].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[14].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[14].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[14].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[14].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[14].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[14].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "langabaan" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[15].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[15].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[15].langabaan === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[15].langabaan === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woodstock" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[15].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[15].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[15].woodstock === "true"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[15].woodstock === "true"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "malmesbury" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[15].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[15].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[15].storefield1 === "malmesbury"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[15].storefield1 === "malmesbury"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "mosselBay" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[15].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[15].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[15].storefield2 === "mosselBay"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[15].storefield2 === "mosselBay"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "woester" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[15].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[15].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[15].storefield3 === "woester"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[15].storefield3 === "woester"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "panorama" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[15].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[15].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[15].storefield4 === "panorama"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[15].storefield4 === "panorama"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}

            {selectedStore === "hermanus" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[15].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[15].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[15].storefield5 === "hermanus"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[15].storefield5 === "hermanus"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
            {selectedStore === "wellington" ? (
              <ItemCard
                timer={36000}
                refresh={refresh}
                label={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].productName
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].productName
                    : "TBA"
                }
                strain={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].strain
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].strain
                    : "TBA"
                }
                type={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].type
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].type
                    : "TBA"
                }
                price={
                  refresh
                    ? page6.length >= 1
                      ? "R" + " " + page6[15].price
                      : "TBA"
                    : page5.length >= 1
                    ? "R" + " " + page5[15].price
                    : "TBA"
                }
                thcPercent={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].thcContent
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].thcContent
                    : "TBA"
                }
                description={
                  refresh
                    ? page6.length >= 1
                      ? page6[15].description
                      : "TBA"
                    : page5.length >= 1
                    ? page5[15].description
                    : "TBA"
                }
                notIn={
                  refresh
                    ? page6[15].storefield6 === "wellington"
                      ? false
                      : true
                    : page5.length >= 1
                    ? page5[15].storefield6 === "wellington"
                      ? false
                      : true
                    : true
                }
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Page3Display;
