import React from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../components/atoms";

const EmailConfirmPopUp = ({
  display,
  email,
  onChangeConfirm,
  onUpdateClick,
  onCancelClick,
  confirmEmail,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ width: 300, textAlign: "center" }}>
        Your about to change your email please confirm your email address again
        to update...
      </div>
      <TextInputField
        labelTitle={"Confirm email:"}
        invert={true}
        onChange={onChangeConfirm}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: 10,
        }}
      >
        <BaseButtons
          label={"UPDATE"}
          disable={confirmEmail === email ? false : true}
          onClick={onUpdateClick}
        />
        <BaseButtons
          label={"BACK"}
          // disable={email ? false : true}
          onClick={onCancelClick}
        />
      </div>
    </div>
  );
};

export default EmailConfirmPopUp;
