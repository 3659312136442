import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";
import { BaseButtons } from "../../../../../components/atoms";

const InviteListItem = ({
  index,
  firstName,
  lastName,
  email,
  contactNumber,
  code,
}) => {
  const [hover, setHover] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: "100%",
        height: 50,
        borderBottomStyle: "solid",
        display: "flex",
        fontSize: 12,
        backgroundColor: hover ? "#9E3AC350" : BlackAndWhite.secondary,
        cursor: "pointer",
        position: "relative",
        transition: "all 0.3s",
        borderColor: "#00000030",
        borderBottomWidth: 1,
      }}
    >
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 25, width: "100%", textAlign: "left" }}>
          {index}
        </div>
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
          {firstName}
        </div>
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
          {lastName}
        </div>
      </div>
      <div
        style={{
          flex: 0.25,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ width: "100%", textAlign: "left" }}>{email}</div>
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ width: "100%", textAlign: "left" }}>{contactNumber}</div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ width: "100%", textAlign: "left" }}>{code}</div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <BaseButtons
          label={"RESEND"}
          mini={true}
          marginTopFalse={true}
          height={30}
          size={80}
          width={"100%"}
          borderRadius={4}
        />
        <div style={{ width: 20 }} />
        {/* <div> Edit users</div>
        <div style={{ width: 20 }} /> */}
      </div>
    </div>
  );
};

export default InviteListItem;
