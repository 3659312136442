import React, { useState, useEffect } from "react";
import { ItemDisplayCard } from "../dashboard-pos/stat-card/components";
import { BlackAndWhite } from "../../../../themes/Colors";
import { BaseButtons } from "../../../../components/atoms";
import PinPopUpCheck from "../pin-pop-up-check/PinPopUpCheck";
import { CashRegisterViewPopUp } from "./components";

const CashRegister = ({
  user,
  refresh,
  setLoading,

  setToastPop,
  setMessage,
  setRefresh,
  storeInfo,
}) => {
  const [cashRegisterOpen, setCashRegisterOpen] = useState(false);
  const [pinCheckPopUp, setPinCheckPopUp] = useState(false);
  const [floatAmount, setFloatAmount] = useState(false);
  const [closeCashRegister, setCloseCashRegister] = useState(false);
  const [cashRegsiterStatus, setCashRegsiterStatus] = useState("closed");
  const [openedBy, setOPenedBy] = useState("LOADING");
  const [dateAndTimeOpened, setDateAndTimeOpened] = useState("LOADING");
  const [hover, setHover] = useState(false);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: 120,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        {" "}
        <div
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          style={{
            height: "90%",
            width: "85%",
            backgroundColor: BlackAndWhite.secondary,
            borderRadius: 8,
            display: "flex",
            position: "relative",
            overflow: "hidden",
            WebkitBoxShadow: hover
              ? "0px 0px 12px 2px #00000083"
              : "0px 0px 15px 2px #00000050 ",
            // borderWidth: hover ? 4 : 0,
            transition: "all 0.3s",
            // borderStyle: "solid",
            cursor: "pointer",
            // borderColor: "#00000090",
            borderColor: "#00000090",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* card title */}
          <div
            style={{
              // position: "absolute",

              fontWeight: "bold",
              fontSize: 20,
              height: "100%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 300,
              textAlign: "left",
              marginRight: 10,
              color: "#00000090",
            }}
          >
            {/* <img
              alt="Icon"
              //   src={icon}
              style={{
                height: iconHeight,
                width: iconWidth,
                marginLeft: 20,
                opacity: 0.6,
              }}
            /> */}
            <div style={{ width: "100%", marginLeft: 20, fontSize: 18 }}>
              CASH REGISTER STATUS
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",

              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <ItemDisplayCard
              title={"STATUS"}
              value={cashRegsiterStatus}
              width={100}
            />
            <ItemDisplayCard title={"OPEN BY"} value={openedBy} width={100} />
            <ItemDisplayCard
              title={"OPENED ON"}
              value={dateAndTimeOpened}
              width={100}
            />
            {cashRegsiterStatus === "open" ? (
              <BaseButtons
                label={"VIEW"}
                mini={true}
                marginTopFalse={true}
                borderRadius={10}
              />
            ) : (
              <BaseButtons
                label={"OPEN"}
                mini={true}
                marginTopFalse={true}
                borderRadius={10}
                onClick={() => setCashRegisterOpen(true)}
              />
            )}
          </div>
        </div>
      </div>
      {/* {cashRegisterOpen ? (
        <div
          style={{
            height: 120,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          {" "}
          <div
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            style={{
              height: "90%",
              width: "85%",
              backgroundColor: BlackAndWhite.secondary,
              borderRadius: 8,
              display: "flex",
              position: "relative",
              overflow: "hidden",
              WebkitBoxShadow: hover
                ? "0px 0px 12px 2px #00000083"
                : "0px 0px 15px 2px #00000050 ",
              // borderWidth: hover ? 4 : 0,
              transition: "all 0.3s",
              // borderStyle: "solid",
              cursor: "pointer",
              // borderColor: "#00000090",
              borderColor: "#00000090",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* card title */}
      {/* <div
              style={{
                // position: "absolute",

                fontWeight: "bold",
                fontSize: 20,
                height: "100%",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 300,
                textAlign: "left",
                marginRight: 10,
                color: "#00000090",
              }}
            > */}
      {/* <img
              alt="Icon"
              //   src={icon}
              style={{
                height: iconHeight,
                width: iconWidth,
                marginLeft: 20,
                opacity: 0.6,
              }}
            /> */}
      {/* <div style={{ width: "100%", marginLeft: 20, fontSize: 18 }}>
                CASH REGISTER NO
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",

                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <ItemDisplayCard
                //   title={item1Title}
                //   value={item1Value}
                width={100}
              />
              <ItemDisplayCard
                //   title={item2Title}
                //   value={item2Value}
                width={100}
              />
              <ItemDisplayCard
                //   title={item3Title}
                //   value={item3Value}
                width={100}
              />
              {/* {item4Title ? (
              <ItemDisplayCard
                // title={item4Title}
                // value={item4Value}
                width={100}
              />
            ) : null} */}
      {/* {item4Title ? <div style={{ width: 15 }} /> : null} */}
      {/* </div>
          </div>
        </div>
      ) : null} */}
      {/* <PinPopUpCheck
        display={pinCheckPopUp}
        setPinCheckPopUp={setPinCheckPopUp}
      /> */}
      <CashRegisterViewPopUp
        display={cashRegisterOpen}
        setPinCheckPopUp={setPinCheckPopUp}
        setCashRegisterOpen={setCashRegisterOpen}
        user={user}
        setLoading={setLoading}
        setToastPop={setToastPop}
        setMessage={setMessage}
      />
    </div>
  );
};

export default CashRegister;
