import React, { useState } from "react";
import { BlackAndWhite } from "../../themes/Colors";

const SmallButton = ({ onClick, right, top }) => {
  const [hover, setHover] = useState(false);
  return (
    <img
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      alt="logo"
      src={
        hover
          ? require("../../images/material-icons/editWhite.png")
          : require("../../images/material-icons/editWhite.png")
      }
      style={{
        height: 25,
        position: "absolute",
        right: right ? right : -40,
        top: top ? top : 0,
        backgroundColor: hover ? "#00000066" : BlackAndWhite.primary,
        padding: 10,
        borderRadius: 4,
        cursor: "pointer",
      }}
    />
  );
};

export default SmallButton;
