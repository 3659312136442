import React, { useState, useEffect } from "react";
import "../../App.css";
import { BaseButtons, FallingLeaf } from "../atoms";
import useWindowDimensions from "../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../themes/Colors";
import { useSpring, animated } from "react-spring";

const OptionLandingPage = ({
  onLoginClick,
  display,
  onABoutClick,
  displayButtons,
}) => {
  const { width, height } = useWindowDimensions();
  const [fall, setFall] = useState(false);
  const [fallinGroup, setFallinGroup] = useState(false);

  const styles = useSpring({
    // loop: true,
    from: { rotateZ: 0 },
    to: { rotateZ: 360 },
    delay: 500,
    config: {
      tension: 50,
      friction: 8,
    },
  });
  useEffect(() => {
    if (fall) {
      setTimeout(() => {
        setFall(false);
      }, 10000);
    } else {
      setFall(true);
      setFallinGroup(true);
    }
  }, [fall]);

  return (
    <div
      style={{
        height: display ? 0 : height <= 600 ? height + 80 : height + 20,
        width: width,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.5s",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div
        style={{
          height: 400,
          width: 400,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <animated.div
          style={{
            ...styles,
          }}
        >
          <img
            src={require("../../images/newLogos/stonedarkwithName.png")}
            className="App-logo"
            alt="logo"
            style={{ height: width > 899 ? 400 : 340 }}
          />
        </animated.div>
      </div>
      <div
        style={{
          width: width,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          overflow: "hidden",
          height: displayButtons ? 150 : 0,
          transition: "height 0.5s",
        }}
      >
        <BaseButtons
          width={width > 899 ? "30%" : "55%"}
          label={"WELCOME"}
          onClick={onABoutClick}
          height={50}
        />
        <BaseButtons
          width={width > 899 ? "30%" : "55%"}
          label={"LOGIN"}
          onClick={onLoginClick}
          height={50}
        />
      </div>

      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"3%"}
        delay={0}
        friction={25}
        tension={18}
        from={-400}
        to={480}
        timer={9000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"20%"}
        delay={100}
        friction={20}
        tension={10}
        from={-450}
        to={500}
        timer={9000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"40%"}
        delay={0}
        friction={20}
        tension={22}
        from={-500}
        to={450}
        timer={9000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"90%"}
        delay={0}
        friction={18}
        tension={12}
        from={-400}
        to={600}
        timer={9000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"60%"}
        delay={0}
        friction={30}
        tension={11}
        from={-400}
        to={480}
        timer={9000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"70%"}
        delay={0}
        friction={25}
        tension={17}
        from={-450}
        to={500}
        timer={9000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"80%"}
        delay={0}
        friction={30}
        tension={12}
        from={-500}
        to={450}
        timer={9000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"100%"}
        delay={0}
        friction={28}
        tension={18}
        from={-400}
        to={450}
        timer={9000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"10%"}
        delay={0}
        friction={20}
        tension={15}
        from={-350}
        to={450}
        timer={9000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"30%"}
        delay={0}
        friction={25}
        tension={12}
        from={-450}
        to={500}
        timer={9000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"0%"}
        delay={0}
        friction={12}
        tension={15}
        from={-450}
        to={450}
        changeSize={40}
        timer={5000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"15%"}
        delay={0}
        friction={15}
        tension={15}
        from={-350}
        to={750}
        changeSize={40}
        timer={5000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"34%"}
        delay={0}
        friction={22}
        tension={15}
        from={-450}
        to={600}
        changeSize={40}
        timer={5000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"45%"}
        delay={0}
        friction={30}
        tension={15}
        from={-350}
        to={500}
        changeSize={40}
        timer={5000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"56%"}
        delay={0}
        friction={20}
        tension={15}
        from={-300}
        to={450}
        changeSize={40}
        timer={5000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"66%"}
        delay={0}
        friction={25}
        tension={15}
        from={-300}
        to={450}
        changeSize={40}
        timer={5000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"76%"}
        delay={0}
        friction={18}
        tension={15}
        from={-300}
        to={500}
        changeSize={40}
        timer={5000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"88%"}
        delay={0}
        friction={21}
        tension={15}
        from={-300}
        to={460}
        changeSize={40}
        timer={5000}
      />
      <FallingLeaf
        fall={fall}
        setFall={setFall}
        setFallinGroup={setFallinGroup}
        postion={"105%"}
        delay={0}
        friction={25}
        tension={15}
        from={-300}
        to={600}
        changeSize={40}
        timer={1000}
      />
    </div>
  );
};

export default OptionLandingPage;
