import React from "react";
import { BlackAndWhite } from "../../themes/Colors";
import { RouterProvider } from "react-router-dom";

const NewPrescriptionCard = ({
  patientNo,
  patientId,
  doctor,
  prescriptionNo,
  createdDate,
  validUntil,
  status,
  diagonosis,
  prescribed,
  width,
  height,
}) => {
  return (
    <div
      style={{
        height: height ? height : width > 899 ? 250 : 550,
        borderStyle: "solid",
        borderRadius: 20,
        width: "90%",
        marginTop: 15,
        borderWidth: 0.01,
        borderColor: "#00000012",
        display: "flex",
        WebkitBoxShadow: "0px 0px 10px 1px #00000033",
        flexDirection: width > 899 ? "row" : "column",
      }}
    >
      <div
        style={{
          flex: width > 899 ? 0.3 : 0.4,
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div style={{ fontSize: 12, marginLeft: 15, marginTop: 15 }}>
          PATIENT NO:
        </div>
        <div
          style={{
            fontSize: 12.5,
            marginLeft: 15,
            marginTop: 5,
            fontWeight: "bold",
          }}
        >
          {patientNo}
        </div>
        <div style={{ fontSize: 12, marginLeft: 15, marginTop: 15 }}>
          PRESCRIPTION NO:
        </div>
        <div
          style={{
            fontSize: 12.5,
            marginLeft: 15,
            marginTop: 5,
            fontWeight: "bold",
          }}
        >
          {prescriptionNo}
        </div>
        <div style={{ fontSize: 12, marginLeft: 15, marginTop: 15 }}>
          PATIENT ID:
        </div>
        <div
          style={{
            fontSize: 12.5,
            marginLeft: 15,
            marginTop: 5,
            fontWeight: "bold",
          }}
        >
          {patientId}
        </div>
        <div style={{ fontSize: 12, marginLeft: 15, marginTop: 15 }}>
          DOCTOR:
        </div>
        <div
          style={{
            fontSize: 12.5,
            marginLeft: 15,
            marginTop: 5,
            fontWeight: "bold",
          }}
        >
          {doctor}
        </div>
      </div>
      <div
        style={{
          flex: width > 899 ? 0.5 : 0.4,
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            fontSize: 16,
            marginLeft: 15,
            marginTop: 15,
            fontWeight: "bold",
          }}
        >
          Diagonosis :
        </div>
        <div
          style={{
            fontSize: 14,
            marginLeft: 15,
            marginTop: 5,
          }}
        >
          {diagonosis}
        </div>
        <div
          style={{
            fontSize: 16,
            marginLeft: 15,
            marginTop: 15,
            fontWeight: "bold",
          }}
        >
          Prescription :
        </div>
        <div
          style={{
            fontSize: 14,
            marginLeft: 15,
            marginTop: 5,
          }}
        >
          {prescribed}
        </div>
      </div>
      <div
        style={{
          flex: 0.2,
          height: "100%",
          overflow: "hidden",
          backgroundColor: BlackAndWhite.primary,
          borderRadius: 20,
          color: BlackAndWhite.secondary,
          display: "flex",
          flexDirection: width > 899 ? "column" : "row",
          justifyContent: width > 899 ? null : "space-evenly",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 12, marginLeft: 15, marginTop: 15 }}>
            DATE REQUESTED:
          </div>
          <div
            style={{
              fontSize: 12.5,
              marginLeft: 15,
              marginTop: 5,
              fontWeight: "bold",
            }}
          >
            {createdDate}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 12, marginLeft: 15, marginTop: 15 }}>
            VALID UNTIL:
          </div>
          <div
            style={{
              fontSize: 12.5,
              marginLeft: 15,
              marginTop: 5,
              fontWeight: "bold",
            }}
          >
            {validUntil}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 12, marginLeft: 15, marginTop: 15 }}>
            STATUS:
          </div>
          <div
            style={{
              fontSize: 12.5,
              marginLeft: 15,
              marginTop: 5,
              fontWeight: "bold",
            }}
          >
            {status}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPrescriptionCard;
