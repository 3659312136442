import React, { useState, useEffect } from "react";
import useWindowDimensions from "../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../themes/Colors";
// import { useSpring, animated } from "react-spring";

const LoadingPopUp = ({ display, newWidth }) => {
  const { width, height } = useWindowDimensions();
  const [animationStart, setAnimationStart] = useState(false);
  const [animationReturn, setAnimationReturn] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);
  const [step6, setStep6] = useState(false);
  const [step7, setStep7] = useState(false);
  const [step8, setStep9] = useState(false);

  useEffect(() => {
    setAnimationStart(true);
    setTimeout(() => {
      setAnimationStart(false);
    }, 1500);
  }, [display]);
  useEffect(() => {
    if (!animationStart) {
      setTimeout(() => {
        setAnimationStart(true);
      }, 1500);
    } else {
      setTimeout(() => {
        setAnimationStart(false);
      }, 1500);
    }
  }, [animationStart]);
  useEffect(() => {
    //shadows to go up and down
    // if (animationReturn) {
    //   setTimeout(() => {
    //     setAnimationStart(false);
    //   }, 5000);
    // }
  }, [animationReturn]);
  // const styles = useSpring({
  //   loop: true,
  //   from: { rotateZ: 0 },
  //   to: { rotateZ: 360 },
  //   // delay: 0,
  //   config: {
  //     tension: 2,
  //     friction: 1,
  //   },
  // });
  return (
    <div
      style={{
        position: "absolute",
        height: display ? height : 0,
        width: display ? (newWidth ? newWidth : width) : 0,
        backgroundColor: "#00000005",
        display: "flex",
        flexDirection: "column",
        // color: BlackAndWhite.secondary,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000000000009,
        opacity: display ? 1 : 0,
        transition: "all 0.5s",
      }}
    >
      {/* {display ? (
        <animated.div
          style={{
            ...styles,
          }}
        >
          <img
            src={require("../../images/merryjanelogooptions/Merry-Jane Logo Light Plain.png")}
            className="App-logo"
            alt="logo"
            style={{ height: width > 899 ? 200 : 140 }}
          />
        </animated.div>
      ) : null} */}
      <div>
        <img
          src={require("../../images/newLogos/1. MJ Logo Black with White Surround.png")}
          className="App-logo"
          alt="logo"
          style={{
            height:
              width > 899
                ? animationStart
                  ? 200
                  : 40
                : animationStart
                ? 140
                : 20,
            transition: "all 1.5s",
            opacity: 0.5,
          }}
        />
      </div>
    </div>
  );
};

export default LoadingPopUp;
