import React, { useState } from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import { BaseButtons, TextAreaInput } from "../../../components/atoms";

const InquiryCard = ({
  firstName,
  keyChild,
  lastName,
  emailAddress,
  cellNumber,
  inquiryMessage,
  whatsapp,
  idData,
  inquiries,
  loading,
  setLoading,
  responded,
}) => {
  const [errorMessage, setErrorMessage] = useState("error has happened");
  const checkedDisplay = "yes";
  const [responseReply, setResponse] = useState("");

  const updateInquiryResponseHandler = async () => {
    setLoading(true);

    try {
      const returnInfo = await fetch(
        "https://merry-jane-api.onrender.com/support/updateSla",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            checked: checkedDisplay,
            response: responseReply,
            id: idData,
          }),
        }
      );

      const responseData = await returnInfo.json();
      setLoading(false);

      console.log("upadte response", responseData.data);
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  return (
    <div
      key={keyChild}
      style={{
        height: 350,
        width: "100%",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: BlackAndWhite.secondary,
      }}
    >
      <div
        style={{
          height: 280,
          width: "80%",
          borderStyle: "solid",
          overflow: "hidden",
          borderRadius: 10,
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* //customer name info tab */}
        <div
          style={{
            height: "100%",
            flex: 0.3,
            borderStyle: "solid",
            backgroundColor: BlackAndWhite.secondary,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: BlackAndWhite.primary,
              marginTop: 20,
              fontSize: 20,
              fontWeight: "bolder",
              marginBottom: 20,
              width: "100%",
              marginLeft: 20,
            }}
          >
            Contact Name:
          </div>
          <div
            style={{
              // padding: 20,
              // borderStyle: "solid",
              // width: "80%",
              borderRadius: 10,
              borderColor: BlackAndWhite.primary,
              // marginBottom: 20,
              textAlign: "center",
              display: "flex",
              width: "100%",
              marginLeft: 20,
            }}
          >
            <div style={{ color: BlackAndWhite.primary, fontSize: 20 }}>
              {firstName}
            </div>
            <div
              style={{
                color: BlackAndWhite.primary,
                fontSize: 20,
                // marginTop: 10,
                marginLeft: 10,
              }}
            >
              {lastName}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "row",
              width: "90%",
            }}
          >
            <div
              style={{
                color: BlackAndWhite.primary,
                marginBottom: 10,
                marginTop: 20,
                fontSize: 16,
                fontWeight: "300",
                // width: "100%",
                textAlign: "left",
              }}
            >
              Email address:
            </div>
            <div
              style={{
                color: BlackAndWhite.primary,
                // width: "100%",
                textAlign: "left",
                marginLeft: 20,
                fontSize: 16,
                fontWeight: "400",
                marginTop: 10,
              }}
            >
              {emailAddress}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "row",
              width: "90%",
            }}
          >
            <div
              style={{
                color: BlackAndWhite.primary,
                // marginBottom: 10,
                marginTop: 10,
                fontSize: 16,
                fontWeight: "300",
                // width: "100%",
                textAlign: "left",
                // marginLeft: 20,
              }}
            >
              Cell number:
            </div>
            <div
              style={{
                color: BlackAndWhite.primary,
                // width: "100%",
                textAlign: "left",
                marginLeft: 20,
                fontSize: 16,
                fontWeight: "400",
                marginTop: 10,
              }}
            >
              {cellNumber}
            </div>
          </div>
        </div>
        <div
          style={{
            height: "100%",
            flex: 0.5,
            backgroundColor: BlackAndWhite.primary,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                color: BlackAndWhite.secondary,
                // marginBottom: 10,
                marginTop: 10,
                fontSize: 16,
                fontWeight: "300",
                // width: "100%",
                textAlign: "left",
                marginLeft: 20,
              }}
            >
              Whatsapp me :
            </div>
            <div
              style={{
                color: BlackAndWhite.secondary,
                // width: "100%",
                textAlign: "left",
                marginLeft: 20,
                fontSize: 16,
                fontWeight: "400",
                marginTop: 10,
              }}
            >
              {whatsapp}
            </div>
          </div>
          {/* //in quiry message */}
          <div style={{ marginTop: 20, width: "100%" }}>
            <div style={{ marginLeft: 20 }}>Requested info :</div>
          </div>
          <div
            style={{
              marginTop: 20,
              width: "90%",
              height: 170,
              borderRadius: 10,
              backgroundColor: BlackAndWhite.secondary,
              overflow: "scroll",
            }}
          >
            <div
              style={{
                marginLeft: 20,
                color: BlackAndWhite.primary,
                marginTop: 10,
              }}
            >
              {inquiryMessage}
            </div>
          </div>
        </div>
        <div
          style={{
            height: "100%",
            flex: 0.2,
            // borderStyle: "solid",
            backgroundColor: BlackAndWhite.secondary,
            display: "flex",
            justifyContent: responded ? "flex-start" : "center",
            alignItems: "center",
            flexDirection: "column",
            color: BlackAndWhite.primary,
          }}
        >
          {responded ? (
            <>
              <div
                style={{
                  width: "90%",
                  textAlign: "left",
                  marginBottom: 20,
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                Agents Action:
              </div>
              <div style={{ width: "90%", textAlign: "left", fontWeight: 12 }}>
                {responded}
              </div>
            </>
          ) : (
            <TextAreaInput
              labelTitle={"how you responded"}
              size={"70%"}
              height={100}
              color={BlackAndWhite.primary}
              onChange={(event) => setResponse(event.target.value)}
            />
          )}
          {responded ? null : (
            <BaseButtons
              disable={loading}
              label={loading ? "LOADING" : "DONE"}
              size={"80%"}
              onClick={() => updateInquiryResponseHandler()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InquiryCard;
