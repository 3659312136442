import React from "react";
import ItemCard from "../../item-card/ItemCard";

const SecondPageGroup3 = ({ selectedStore, page2, page4, refresh }) => {
  return (
    <>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[8].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[8].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[8].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[8].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[8].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[8].description
                  : "TBA"
                : page2.length >= 1
                ? page2[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[8].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[8].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[8].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[8].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[8].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[8].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[8].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[8].description
                  : "TBA"
                : page2.length >= 1
                ? page2[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[8].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[8].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[8].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[8].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[8].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[8].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[8].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[8].description
                  : "TBA"
                : page2.length >= 1
                ? page2[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[8].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[8].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[8].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[8].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[8].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[8].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[8].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[8].description
                  : "TBA"
                : page2.length >= 1
                ? page2[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[8].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[8].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[8].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[8].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[8].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[8].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[8].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[8].description
                  : "TBA"
                : page2.length >= 1
                ? page2[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[8].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[8].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[8].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[8].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[8].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[8].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[8].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[8].description
                  : "TBA"
                : page2.length >= 1
                ? page2[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[8].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[8].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[8].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[8].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[8].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[8].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[8].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[8].description
                  : "TBA"
                : page2.length >= 1
                ? page2[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[8].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[8].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[8].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[8].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[8].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[0].type
                  : "TBA"
                : page2.length >= 1
                ? page2[8].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[8].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[8].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[8].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[8].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[8].description
                  : "TBA"
                : page2.length >= 1
                ? page2[8].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[8].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[8].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}

        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[9].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[9].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[9].type
                  : "TBA"
                : page2.length >= 1
                ? page2[9].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[9].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[9].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[9].description
                  : "TBA"
                : page2.length >= 1
                ? page2[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[9].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[9].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[9].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[9].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[9].type
                  : "TBA"
                : page2.length >= 1
                ? page2[9].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[9].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[9].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[9].description
                  : "TBA"
                : page2.length >= 1
                ? page2[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[9].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[9].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[9].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[9].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[9].type
                  : "TBA"
                : page2.length >= 1
                ? page2[9].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[9].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[9].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[9].description
                  : "TBA"
                : page2.length >= 1
                ? page2[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[9].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[9].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[9].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[9].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[9].type
                  : "TBA"
                : page2.length >= 1
                ? page2[9].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[9].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[9].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[9].description
                  : "TBA"
                : page2.length >= 1
                ? page2[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[9].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[9].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[9].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[9].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[9].type
                  : "TBA"
                : page2.length >= 1
                ? page2[9].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[9].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[9].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[9].description
                  : "TBA"
                : page2.length >= 1
                ? page2[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[9].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[9].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[9].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[9].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[9].type
                  : "TBA"
                : page2.length >= 1
                ? page2[9].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[9].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[9].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[9].description
                  : "TBA"
                : page2.length >= 1
                ? page2[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[9].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[9].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[9].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[9].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[9].type
                  : "TBA"
                : page2.length >= 1
                ? page2[9].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[9].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[9].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[9].description
                  : "TBA"
                : page2.length >= 1
                ? page2[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[9].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[9].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[9].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[9].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[9].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[9].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[9].type
                  : "TBA"
                : page2.length >= 1
                ? page2[9].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[9].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[9].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[9].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[9].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[9].description
                  : "TBA"
                : page2.length >= 1
                ? page2[9].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[9].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[9].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[10].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[10].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[10].type
                  : "TBA"
                : page2.length >= 1
                ? page2[10].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[10].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[10].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[10].description
                  : "TBA"
                : page2.length >= 1
                ? page2[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[10].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[10].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[10].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[10].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[10].type
                  : "TBA"
                : page2.length >= 1
                ? page2[10].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[10].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[10].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[10].description
                  : "TBA"
                : page2.length >= 1
                ? page2[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[10].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[10].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[10].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[10].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[10].type
                  : "TBA"
                : page2.length >= 1
                ? page2[10].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[10].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[10].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[10].description
                  : "TBA"
                : page2.length >= 1
                ? page2[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[10].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[10].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[10].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[10].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[10].type
                  : "TBA"
                : page2.length >= 1
                ? page2[10].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[10].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[10].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[10].description
                  : "TBA"
                : page2.length >= 1
                ? page2[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[10].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[10].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[10].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[10].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[10].type
                  : "TBA"
                : page2.length >= 1
                ? page2[10].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[10].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[10].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[10].description
                  : "TBA"
                : page2.length >= 1
                ? page2[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[10].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[10].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[10].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[10].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[10].type
                  : "TBA"
                : page2.length >= 1
                ? page2[10].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[10].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[10].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[10].description
                  : "TBA"
                : page2.length >= 1
                ? page2[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[10].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[10].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[10].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[10].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[10].type
                  : "TBA"
                : page2.length >= 1
                ? page2[10].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[10].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[10].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[10].description
                  : "TBA"
                : page2.length >= 1
                ? page2[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[10].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[10].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[10].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[10].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[10].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[10].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[10].type
                  : "TBA"
                : page2.length >= 1
                ? page2[10].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[10].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[10].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[10].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[10].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[10].description
                  : "TBA"
                : page2.length >= 1
                ? page2[10].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[10].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[10].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[11].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[11].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[11].type
                  : "TBA"
                : page2.length >= 1
                ? page2[11].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[11].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[11].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[11].description
                  : "TBA"
                : page2.length >= 1
                ? page2[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[11].langabaan === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[11].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[11].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[11].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[11].type
                  : "TBA"
                : page2.length >= 1
                ? page2[11].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[11].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[11].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[11].description
                  : "TBA"
                : page2.length >= 1
                ? page2[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[11].woodstock === "true"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[11].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[11].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[11].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[11].type
                  : "TBA"
                : page2.length >= 1
                ? page2[11].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[11].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[11].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[11].description
                  : "TBA"
                : page2.length >= 1
                ? page2[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[11].storefield1 === "malmesbury"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[11].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[11].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[11].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[11].type
                  : "TBA"
                : page2.length >= 1
                ? page2[11].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[11].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[11].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[11].description
                  : "TBA"
                : page2.length >= 1
                ? page2[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[11].storefield2 === "mosselBay"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[11].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[11].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[11].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[11].type
                  : "TBA"
                : page2.length >= 1
                ? page2[11].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[11].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[11].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[11].description
                  : "TBA"
                : page2.length >= 1
                ? page2[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[11].storefield3 === "woester"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[11].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[11].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[11].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[11].type
                  : "TBA"
                : page2.length >= 1
                ? page2[11].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[11].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[11].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[11].description
                  : "TBA"
                : page2.length >= 1
                ? page2[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[11].storefield4 === "panorama"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[11].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[11].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[11].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[11].type
                  : "TBA"
                : page2.length >= 1
                ? page2[11].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[11].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[11].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[11].description
                  : "TBA"
                : page2.length >= 1
                ? page2[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[11].storefield5 === "hermanus"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[11].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={18650}
            refresh={refresh}
            label={
              refresh
                ? page4.length >= 1
                  ? page4[11].productName
                  : "TBA"
                : page2.length >= 1
                ? page2[11].productName
                : "TBA"
            }
            strain={
              refresh
                ? page4.length >= 1
                  ? page4[11].strain
                  : "TBA"
                : page2.length >= 1
                ? page2[11].strain
                : "TBA"
            }
            type={
              refresh
                ? page4.length >= 1
                  ? page4[11].type
                  : "TBA"
                : page2.length >= 1
                ? page2[11].type
                : "TBA"
            }
            price={
              refresh
                ? page4.length >= 1
                  ? "R" + " " + page4[11].price
                  : "TBA"
                : page2.length >= 1
                ? "R" + " " + page2[11].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page4.length >= 1
                  ? page4[11].thcContent
                  : "TBA"
                : page2.length >= 1
                ? page2[11].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page4.length >= 1
                  ? page4[11].description
                  : "TBA"
                : page2.length >= 1
                ? page2[11].description
                : "TBA"
            }
            notIn={
              refresh
                ? page4[11].storefield6 === "wellington"
                  ? false
                  : true
                : page2.length >= 1
                ? page2[11].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
    </>
  );
};

export default SecondPageGroup3;
