import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";
import TabCategoryOptions from "./tab-category-options/TabCategoryOptions";
import { LoadingPopUp } from "../../../../../components";
import SubCategoryCard from "./SubCategoryCard";
import ProductCard from "./ProductCard";
import { BaseButtons } from "../../../../../components/atoms";
import ProductInfoPopUp from "./product-info-pop-up/ProductInfoPopUp";
import { KnowledgeBasePanel } from "../../../../canna-pos/components";
import { ProductKnowledgeDisplayModal } from "../../../../canna-pos/components/knowledge-base-panel/components";

const ProductKnowlegde = ({ setScreenDisplay, shrink }) => {
  const [refresh, setRefresh] = useState(false);

  const [displayEditPopUp, setDisplayEditPopUp] = useState(false);

  const [allProducts, setAllProducts] = useState(null);

  const [sectionDisplay, setSectionDisplay] = useState("cat");
  const [category, setCategory] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [subCategoryArray, setSubCategoryArray] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [subCategoryProductsArray, setSubCategoryProductsArray] =
    useState(null);
  const [selectedProductDisplay, setSelectedProductDisplay] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [message, setMessage] = useState(null);
  const [toastPop, setToastPop] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedAdd, setSelectedAdd] = useState(null);
  const updateSubCategorydisplay = async ({ info }) => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getSubCatergoriesId",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId: info.categoryId,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.subCategory) {
        // const wait = await updateListFetch();
        setCategorySelected(info.categoryId);
        setSubCategoryArray(respnseData.subCategory);
        setLoading(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  const updateProductDisplay = async ({ info }) => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsBySubCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subCategoryId: info.subCategoryId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setSubCategoryProductsArray(respnseData.product);
        setLoading(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };
  // console.log("check", subCategoryProductsArray);
  // console.log("slelected sub categpry", selectedSubCategory);

  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/productUpload/getCatergories")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setCategory(resData);
      });
  }, [refresh]);
  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/productUpload/getAllProducts")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setAllProducts(resData.product);
      });
  }, [refresh]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        // backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        position: "relative",
        overflowY: "scroll",
        scrollbarWidth: "none",
      }}
    >
      <KnowledgeBasePanel
        setToastPop={setToastPop}
        setMessage={setMessage}
        setLoading={setLoading}
        setSelectedProductDisplay={setSelectedProductDisplay}
        setRefresh={setRefresh}
        refresh={refresh}
      />

      <LoadingPopUp display={loading} />
      <ProductKnowledgeDisplayModal
        display={selectedProductDisplay}
        onCloseClick={() => setSelectedProductDisplay(null)}
        selectedProductDisplay={selectedProductDisplay}
      />
    </div>
  );
};
export default ProductKnowlegde;
