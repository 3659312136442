import React, { useState, useEffect } from "react";
import {
  BaseButtons,
  FallingLeaf,
  TextAreaInput,
  TextInputField,
} from "../../../components/atoms";
import { LoadingPopUp, SmallButton } from "../../../components";

const DescriptionCard = ({ description, displayEdit, refresh, setRefresh }) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const [paragraph, setParagraph] = useState("");

  const UpdateParagraphHandler = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/website/updateDescription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: description._id,
            paragraph: paragraph,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.data) {
        setRefresh(refresh ? false : true);
        setEdit(false);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const DeleteThisParagraph = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/website/deleteDescription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: description._id,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.data) {
        setRefresh(refresh ? false : true);
        setEdit(false);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (description) {
      setParagraph(description.paragraph);
    }
  }, [displayEdit, description]);
  return (
    <div
      style={{
        borderStyle: displayEdit ? (edit ? null : "solid") : null,
        padding: displayEdit ? 20 : 5,
        transition: "all 0.3s",
        borderRadius: 8,
        position: "relative",
      }}
    >
      {loading ? (
        "loading"
      ) : (
        <>
          {edit ? (
            <TextAreaInput
              value={paragraph}
              height={150}
              width={"100%"}
              size={"90%"}
              onChange={(e) => setParagraph(e.target.value)}
            />
          ) : (
            description.paragraph
          )}
          {displayEdit ? (
            <div
              style={{
                position: "absolute",
                right: -100,
                top: -10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {edit ? (
                <BaseButtons
                  label={"CANCEL"}
                  size={80}
                  mini={false}
                  onClick={() => setEdit(false)}
                />
              ) : null}
              {areYouSure ? (
                <>
                  <BaseButtons
                    label={"YES"}
                    size={80}
                    mini={false}
                    onClick={() => DeleteThisParagraph()}
                  />
                  <BaseButtons
                    label={"NO"}
                    size={80}
                    mini={false}
                    onClick={() => setAreYouSure(false)}
                  />
                </>
              ) : (
                <>
                  {edit ? (
                    <BaseButtons
                      label={"SAVE CHANGE"}
                      size={80}
                      mini={false}
                      onClick={() => {
                        UpdateParagraphHandler();
                      }}
                    />
                  ) : (
                    <BaseButtons
                      label={"EDIT"}
                      size={80}
                      mini={false}
                      onClick={() => {
                        setEdit(true);
                      }}
                    />
                  )}

                  {edit ? null : (
                    <BaseButtons
                      label={"DELETE"}
                      size={80}
                      mini={false}
                      onClick={() => setAreYouSure(true)}
                    />
                  )}
                </>
              )}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default DescriptionCard;
