import React from "react";
import { BaseButtons, CloseButton, TextInputField } from "../atoms";
import { BlackAndWhite } from "../../themes/Colors";

const DoctorForm = ({ onCloseClick }) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            flex: 0.7,
            height: "100%",
            position: "relative",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              height: "100%",
            }}
          >
            <TextInputField labelTitle={"First name:"} invert={true} />
            <TextInputField labelTitle={"Last name:"} invert={true} />
            <TextInputField labelTitle={"Id-Num / Passport:"} invert={true} />
            <TextInputField labelTitle={"Date of birth:"} invert={true} />
            <TextInputField labelTitle={"Email:"} invert={true} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              height: "100%",
            }}
          >
            <TextInputField labelTitle={"Cell number:"} invert={true} />
            <TextInputField labelTitle={"Store :"} invert={true} />
            <TextInputField labelTitle={"Account Num:"} invert={true} />
            <TextInputField labelTitle={"Password:"} invert={true} />
            <TextInputField labelTitle={"Confirm password:"} invert={true} />
          </div>
          <div style={{ position: "absolute", top: 20, left: 20 }}>
            <CloseButton label={"X"} onClick={onCloseClick} />
          </div>
        </div>
        <div
          style={{
            flex: 0.3,
            backgroundColor: BlackAndWhite.primary,
            borderTopLeftRadius: 20,
            height: "99%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 60,
              display: "flex",
              fontSize: 22,
              alignItems: "center",
              color: BlackAndWhite.secondary,
            }}
          >
            <div style={{ marginLeft: 20 }}>Create a Admin user</div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              color: BlackAndWhite.secondary,
            }}
          >
            <div style={{ width: "80%", textAlign: "left", marginTop: 20 }}>
              1. Please fill out all the information on the left starting with
              first name.
            </div>
            <div style={{ width: "80%", textAlign: "left", marginTop: 20 }}>
              2. Make sure the email address is correct as the invite for the
              User will be sent to them.
            </div>

            <BaseButtons label={"SUBMIT"} disable={true} invert={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorForm;
