import React from "react";
import ItemCard from "../item-card/ItemCard";

const SecondGroup = ({ selectedStore, page3, page1, refresh }) => {
  return (
    <>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[4].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[4].strain
                  : "TBA"
                : page1.length > 1
                ? page1[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[4].type
                  : "TBA"
                : page1.length > 1
                ? page1[4].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[4].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[4].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[0].description
                  : "TBA"
                : page1.length > 1
                ? page1[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[0].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[4].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[4].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[4].strain
                  : "TBA"
                : page1.length > 1
                ? page1[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[4].type
                  : "TBA"
                : page1.length > 1
                ? page1[4].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[4].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[4].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[4].description
                  : "TBA"
                : page1.length > 1
                ? page1[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[4].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[4].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[4].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[4].strain
                  : "TBA"
                : page1.length > 1
                ? page1[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[4].type
                  : "TBA"
                : page1.length > 1
                ? page1[4].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[4].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[4].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[4].description
                  : "TBA"
                : page1.length > 1
                ? page1[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[4].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[4].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[4].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[4].strain
                  : "TBA"
                : page1.length > 1
                ? page1[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[4].type
                  : "TBA"
                : page1.length > 1
                ? page1[4].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[4].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[4].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[4].description
                  : "TBA"
                : page1.length > 1
                ? page1[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[4].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[4].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[4].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[4].strain
                  : "TBA"
                : page1.length > 1
                ? page1[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[4].type
                  : "TBA"
                : page1.length > 1
                ? page1[4].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[4].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[4].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[4].description
                  : "TBA"
                : page1.length > 1
                ? page1[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[4].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[4].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[4].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[4].strain
                  : "TBA"
                : page1.length > 1
                ? page1[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[4].type
                  : "TBA"
                : page1.length > 1
                ? page1[4].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[4].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[4].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[4].description
                  : "TBA"
                : page1.length > 1
                ? page1[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[4].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[4].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[4].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[4].strain
                  : "TBA"
                : page1.length > 1
                ? page1[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[4].type
                  : "TBA"
                : page1.length > 1
                ? page1[4].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[4].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[4].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[4].description
                  : "TBA"
                : page1.length > 1
                ? page1[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[4].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[4].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[4].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[4].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[4].strain
                  : "TBA"
                : page1.length > 1
                ? page1[4].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[4].type
                  : "TBA"
                : page1.length > 1
                ? page1[4].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[4].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[4].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[4].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[4].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[4].description
                  : "TBA"
                : page1.length > 1
                ? page1[4].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[4].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[4].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[5].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[5].strain
                  : "TBA"
                : page1.length > 1
                ? page1[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[5].type
                  : "TBA"
                : page1.length > 1
                ? page1[5].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[5].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[5].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[5].description
                  : "TBA"
                : page1.length > 1
                ? page1[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[5].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[5].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[5].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[5].strain
                  : "TBA"
                : page1.length > 1
                ? page1[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[5].type
                  : "TBA"
                : page1.length > 1
                ? page1[5].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[5].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[5].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[5].description
                  : "TBA"
                : page1.length > 1
                ? page1[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[5].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[5].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[5].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[5].strain
                  : "TBA"
                : page1.length > 1
                ? page1[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[5].type
                  : "TBA"
                : page1.length > 1
                ? page1[5].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[5].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[5].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[5].description
                  : "TBA"
                : page1.length > 1
                ? page1[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[5].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[5].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[5].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[5].strain
                  : "TBA"
                : page1.length > 1
                ? page1[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[5].type
                  : "TBA"
                : page1.length > 1
                ? page1[5].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[5].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[5].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[5].description
                  : "TBA"
                : page1.length > 1
                ? page1[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[5].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[5].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[5].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[5].strain
                  : "TBA"
                : page1.length > 1
                ? page1[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[5].type
                  : "TBA"
                : page1.length > 1
                ? page1[5].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[5].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[5].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[5].description
                  : "TBA"
                : page1.length > 1
                ? page1[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[5].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[5].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[5].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[5].strain
                  : "TBA"
                : page1.length > 1
                ? page1[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[5].type
                  : "TBA"
                : page1.length > 1
                ? page1[5].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[5].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[5].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[5].description
                  : "TBA"
                : page1.length > 1
                ? page1[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[5].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[5].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[5].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[5].strain
                  : "TBA"
                : page1.length > 1
                ? page1[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[5].type
                  : "TBA"
                : page1.length > 1
                ? page1[5].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[5].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[5].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[5].description
                  : "TBA"
                : page1.length > 1
                ? page1[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[5].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[5].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[5].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[5].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[5].strain
                  : "TBA"
                : page1.length > 1
                ? page1[5].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[5].type
                  : "TBA"
                : page1.length > 1
                ? page1[5].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[5].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[5].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[5].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[5].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[5].description
                  : "TBA"
                : page1.length > 1
                ? page1[5].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[5].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[5].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[6].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[6].strain
                  : "TBA"
                : page1.length > 1
                ? page1[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[6].type
                  : "TBA"
                : page1.length > 1
                ? page1[6].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[6].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[6].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[6].description
                  : "TBA"
                : page1.length > 1
                ? page1[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[6].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[6].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[6].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[6].strain
                  : "TBA"
                : page1.length > 1
                ? page1[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[6].type
                  : "TBA"
                : page1.length > 1
                ? page1[6].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[6].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[6].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[6].description
                  : "TBA"
                : page1.length > 1
                ? page1[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[6].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[6].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[6].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[6].strain
                  : "TBA"
                : page1.length > 1
                ? page1[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[6].type
                  : "TBA"
                : page1.length > 1
                ? page1[6].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[6].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[6].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[6].description
                  : "TBA"
                : page1.length > 1
                ? page1[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[6].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[6].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[6].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[6].strain
                  : "TBA"
                : page1.length > 1
                ? page1[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[6].type
                  : "TBA"
                : page1.length > 1
                ? page1[6].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[6].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[6].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[6].description
                  : "TBA"
                : page1.length > 1
                ? page1[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[6].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[6].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[6].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[6].strain
                  : "TBA"
                : page1.length > 1
                ? page1[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[6].type
                  : "TBA"
                : page1.length > 1
                ? page1[6].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[6].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[6].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[6].description
                  : "TBA"
                : page1.length > 1
                ? page1[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[6].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[6].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[6].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[6].strain
                  : "TBA"
                : page1.length > 1
                ? page1[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[6].type
                  : "TBA"
                : page1.length > 1
                ? page1[6].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[6].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[6].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[6].description
                  : "TBA"
                : page1.length > 1
                ? page1[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[6].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[6].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[6].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[6].strain
                  : "TBA"
                : page1.length > 1
                ? page1[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[6].type
                  : "TBA"
                : page1.length > 1
                ? page1[6].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[6].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[6].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[6].description
                  : "TBA"
                : page1.length > 1
                ? page1[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[6].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[6].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[6].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[6].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[6].strain
                  : "TBA"
                : page1.length > 1
                ? page1[6].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[6].type
                  : "TBA"
                : page1.length > 1
                ? page1[6].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[6].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[6].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[6].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[6].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[6].description
                  : "TBA"
                : page1.length > 1
                ? page1[6].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[6].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[6].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[7].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[7].strain
                  : "TBA"
                : page1.length > 1
                ? page1[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[7].type
                  : "TBA"
                : page1.length > 1
                ? page1[7].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[7].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[7].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[7].description
                  : "TBA"
                : page1.length > 1
                ? page1[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[7].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[7].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[7].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[7].strain
                  : "TBA"
                : page1.length > 1
                ? page1[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[7].type
                  : "TBA"
                : page1.length > 1
                ? page1[7].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[7].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[7].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[7].description
                  : "TBA"
                : page1.length > 1
                ? page1[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[7].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[7].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[7].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[7].strain
                  : "TBA"
                : page1.length > 1
                ? page1[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[7].type
                  : "TBA"
                : page1.length > 1
                ? page1[7].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[7].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[7].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[7].description
                  : "TBA"
                : page1.length > 1
                ? page1[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[7].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[7].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[7].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[7].strain
                  : "TBA"
                : page1.length > 1
                ? page1[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[7].type
                  : "TBA"
                : page1.length > 1
                ? page1[7].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[7].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[7].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[7].description
                  : "TBA"
                : page1.length > 1
                ? page1[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[7].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[7].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[7].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[7].strain
                  : "TBA"
                : page1.length > 1
                ? page1[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[7].type
                  : "TBA"
                : page1.length > 1
                ? page1[7].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[7].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[7].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[7].description
                  : "TBA"
                : page1.length > 1
                ? page1[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[7].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[7].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[7].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[7].strain
                  : "TBA"
                : page1.length > 1
                ? page1[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[7].type
                  : "TBA"
                : page1.length > 1
                ? page1[7].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[7].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[7].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[7].description
                  : "TBA"
                : page1.length > 1
                ? page1[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[7].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[7].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[7].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[7].strain
                  : "TBA"
                : page1.length > 1
                ? page1[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[7].type
                  : "TBA"
                : page1.length > 1
                ? page1[7].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[7].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[7].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[7].description
                  : "TBA"
                : page1.length > 1
                ? page1[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[7].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[7].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[7].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[7].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[7].strain
                  : "TBA"
                : page1.length > 1
                ? page1[7].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[7].type
                  : "TBA"
                : page1.length > 1
                ? page1[7].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[7].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[7].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[7].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[7].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[7].description
                  : "TBA"
                : page1.length > 1
                ? page1[7].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[7].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[7].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
    </>
  );
};

export default SecondGroup;
