import React, { useState } from "react";
import { BaseButtons, TextInputField } from "../../../../components/atoms";
import Categories from "./components/Categories";
import ProductKnowlegde from "./components/ProductKnowlegde";

const MainScreenHolder = ({
  subSelection,
  userDisplay,
  setUserdisplay,
  setSubSelection,
  shrink,
}) => {
  const [screenDisplay, setScreenDisplay] = useState("dashboard");
  return (
    //add product info

    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        {subSelection === "productList" ? (
          <Categories setScreenDisplay={setSubSelection} />
        ) : null}

        {subSelection === "encyclopedia" ? (
          <ProductKnowlegde
            setScreenDisplay={setSubSelection}
            shrink={shrink}
          />
        ) : null}
        {subSelection === "inventory" ? (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BaseButtons
                label={"back"}
                onClick={() => setSubSelection("productList")}
              />
            </div>
          </>
        ) : null}
        {subSelection === "suppliers" ? (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BaseButtons
                label={"back"}
                onClick={() => setSubSelection("productList")}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default MainScreenHolder;
