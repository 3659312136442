import React from "react";
import { BlackAndWhite } from "../../themes/Colors";
import { CloseButton } from "../atoms";

const HelpModal = ({
  display,
  onCloseClick,
  title,
  point1,
  point2,
  point3,
}) => {
  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: "#00000066",
        overflow: "hidden",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100000,
        transition: "all 0.4s",
        opacity: display ? 1 : 0,
      }}
    >
      <div
        style={{
          backgroundColor: BlackAndWhite.secondary,
          width: "90%",
          height: display ? "90%" : 0,
          borderRadius: 20,
          position: "relative",
          transition: "all 0.5s",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "80%",
            textAlign: "center",
            fontSize: 21,
            marginTop: 20,
          }}
        >
          {title}
        </div>

        <div
          style={{
            width: "80%",
            textAlign: "left",
            fontSize: 16,
            marginTop: 20,
          }}
        >
          {"1." + " " + point1}
        </div>
        {point2 ? (
          <div
            style={{
              width: "80%",
              textAlign: "left",
              fontSize: 16,
              marginTop: 10,
            }}
          >
            {"2." + " " + point2}
          </div>
        ) : null}

        {point3 ? (
          <div
            style={{
              width: "80%",
              textAlign: "left",
              fontSize: 16,
              marginTop: 10,
            }}
          >
            {"3." + " " + point3}
          </div>
        ) : null}
        <div style={{ position: "absolute", top: 10, left: 8 }}>
          <CloseButton label={"X"} onClick={onCloseClick} />
        </div>
      </div>
    </div>
  );
};

export default HelpModal;
