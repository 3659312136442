import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const StoreSelectorHeader = ({ onClick, marginTop }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        minHeight: 50,
        display: "flex",
        borderTopColor: BlackAndWhite.primary,
        backgroundColor: BlackAndWhite.primary,
        color: BlackAndWhite.secondary,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginTop: marginTop,
      }}
    >
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 25, width: "100%", textAlign: "left" }}>
          STORENAME
        </div>
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
          PRODUCT NAME
        </div>
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "center" }}>
          STORE STATUS
        </div>
      </div>
      <div
        style={{
          flex: 0.3,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 0, width: "100%", textAlign: "left" }}>
          STOCK
        </div>
      </div>

      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        ACTIONS
        {/* <div> Edit users</div>
        <div style={{ width: 20 }} /> */}
      </div>
    </div>
  );
};

export default StoreSelectorHeader;
