import React from "react";
import ItemCard from "../item-card/ItemCard";

const FirstGroup = ({ selectedStore, page1, page3, refresh }) => {
  return (
    <>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[0].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[0].strain
                  : "TBA"
                : page1.length > 1
                ? page1[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[0].type
                  : "TBA"
                : page1.length > 1
                ? page1[0].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[0].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[0].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[0].description
                  : "TBA"
                : page1.length > 1
                ? page1[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[0].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[0].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[0].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[0].strain
                  : "TBA"
                : page1.length > 1
                ? page1[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[0].type
                  : "TBA"
                : page1.length > 1
                ? page1[0].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[0].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[0].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[0].description
                  : "TBA"
                : page1.length > 1
                ? page1[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[0].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[0].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[0].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[0].strain
                  : "TBA"
                : page1.length > 1
                ? page1[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[0].type
                  : "TBA"
                : page1.length > 1
                ? page1[0].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[0].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[0].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[0].description
                  : "TBA"
                : page1.length > 1
                ? page1[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[0].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[0].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[0].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[0].strain
                  : "TBA"
                : page1.length > 1
                ? page1[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[0].type
                  : "TBA"
                : page1.length > 1
                ? page1[0].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[0].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[0].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[0].description
                  : "TBA"
                : page1.length > 1
                ? page1[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[0].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[0].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[0].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[0].strain
                  : "TBA"
                : page1.length > 1
                ? page1[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[0].type
                  : "TBA"
                : page1.length > 1
                ? page1[0].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[0].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[0].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[0].description
                  : "TBA"
                : page1.length > 1
                ? page1[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[0].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[0].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[0].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[0].strain
                  : "TBA"
                : page1.length > 1
                ? page1[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[0].type
                  : "TBA"
                : page1.length > 1
                ? page1[0].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[0].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[0].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[0].description
                  : "TBA"
                : page1.length > 1
                ? page1[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[0].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[0].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[0].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[0].strain
                  : "TBA"
                : page1.length > 1
                ? page1[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[0].type
                  : "TBA"
                : page1.length > 1
                ? page1[0].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[0].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[0].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[0].description
                  : "TBA"
                : page1.length > 1
                ? page1[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[0].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[0].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[0].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[0].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[0].strain
                  : "TBA"
                : page1.length > 1
                ? page1[0].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[0].type
                  : "TBA"
                : page1.length > 1
                ? page1[0].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[0].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[0].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[0].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[0].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[0].description
                  : "TBA"
                : page1.length > 1
                ? page1[0].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[0].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[0].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[1].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[1].strain
                  : "TBA"
                : page1.length > 1
                ? page1[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[1].type
                  : "TBA"
                : page1.length > 1
                ? page1[1].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[1].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[1].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[1].description
                  : "TBA"
                : page1.length > 1
                ? page1[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[1].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[1].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[1].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[1].strain
                  : "TBA"
                : page1.length > 1
                ? page1[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[1].type
                  : "TBA"
                : page1.length > 1
                ? page1[1].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[1].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[1].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[1].description
                  : "TBA"
                : page1.length > 1
                ? page1[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[1].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[1].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[1].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[1].strain
                  : "TBA"
                : page1.length > 1
                ? page1[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[1].type
                  : "TBA"
                : page1.length > 1
                ? page1[1].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[1].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[1].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[1].description
                  : "TBA"
                : page1.length > 1
                ? page1[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[1].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[1].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[1].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[1].strain
                  : "TBA"
                : page1.length > 1
                ? page1[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[1].type
                  : "TBA"
                : page1.length > 1
                ? page1[1].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[1].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[1].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[1].description
                  : "TBA"
                : page1.length > 1
                ? page1[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[1].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[1].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[1].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[1].strain
                  : "TBA"
                : page1.length > 1
                ? page1[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[1].type
                  : "TBA"
                : page1.length > 1
                ? page1[1].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[1].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[1].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[1].description
                  : "TBA"
                : page1.length > 1
                ? page1[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[1].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[1].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[1].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[1].strain
                  : "TBA"
                : page1.length > 1
                ? page1[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[1].type
                  : "TBA"
                : page1.length > 1
                ? page1[1].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[1].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[1].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[1].description
                  : "TBA"
                : page1.length > 1
                ? page1[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[1].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[1].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[1].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[1].strain
                  : "TBA"
                : page1.length > 1
                ? page1[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[1].type
                  : "TBA"
                : page1.length > 1
                ? page1[1].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[1].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[1].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[1].description
                  : "TBA"
                : page1.length > 1
                ? page1[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[1].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[1].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[1].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[1].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[1].strain
                  : "TBA"
                : page1.length > 1
                ? page1[1].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[1].type
                  : "TBA"
                : page1.length > 1
                ? page1[1].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[1].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[1].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[1].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[1].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[1].description
                  : "TBA"
                : page1.length > 1
                ? page1[1].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[1].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[1].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
      <div style={{ display: "flex", flex: 0.5 }}>
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[2].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[2].strain
                  : "TBA"
                : page1.length > 1
                ? page1[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[2].type
                  : "TBA"
                : page1.length > 1
                ? page1[2].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[2].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[2].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[2].description
                  : "TBA"
                : page1.length > 1
                ? page1[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[2].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[2].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[2].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[2].strain
                  : "TBA"
                : page1.length > 1
                ? page1[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[2].type
                  : "TBA"
                : page1.length > 1
                ? page1[2].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[2].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[2].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[2].description
                  : "TBA"
                : page1.length > 1
                ? page1[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[2].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[2].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[2].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[2].strain
                  : "TBA"
                : page1.length > 1
                ? page1[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[2].type
                  : "TBA"
                : page1.length > 1
                ? page1[2].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[2].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[2].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[2].description
                  : "TBA"
                : page1.length > 1
                ? page1[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[2].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[2].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[2].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[2].strain
                  : "TBA"
                : page1.length > 1
                ? page1[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[2].type
                  : "TBA"
                : page1.length > 1
                ? page1[2].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[2].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[2].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[2].description
                  : "TBA"
                : page1.length > 1
                ? page1[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[2].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[2].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[2].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[2].strain
                  : "TBA"
                : page1.length > 1
                ? page1[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[2].type
                  : "TBA"
                : page1.length > 1
                ? page1[2].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[2].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[2].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[2].description
                  : "TBA"
                : page1.length > 1
                ? page1[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[2].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[2].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[2].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[2].strain
                  : "TBA"
                : page1.length > 1
                ? page1[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[2].type
                  : "TBA"
                : page1.length > 1
                ? page1[2].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[2].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[2].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[2].description
                  : "TBA"
                : page1.length > 1
                ? page1[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[2].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[2].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[2].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[2].strain
                  : "TBA"
                : page1.length > 1
                ? page1[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[2].type
                  : "TBA"
                : page1.length > 1
                ? page1[2].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[2].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[2].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[2].description
                  : "TBA"
                : page1.length > 1
                ? page1[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[2].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[2].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[2].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[2].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[2].strain
                  : "TBA"
                : page1.length > 1
                ? page1[2].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[2].type
                  : "TBA"
                : page1.length > 1
                ? page1[2].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[2].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[2].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[2].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[2].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[2].description
                  : "TBA"
                : page1.length > 1
                ? page1[2].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[2].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[2].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "langabaan" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[3].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[3].strain
                  : "TBA"
                : page1.length > 1
                ? page1[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[3].type
                  : "TBA"
                : page1.length > 1
                ? page1[3].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[3].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[3].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[3].description
                  : "TBA"
                : page1.length > 1
                ? page1[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[3].langabaan === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[3].langabaan === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woodstock" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[3].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[3].strain
                  : "TBA"
                : page1.length > 1
                ? page1[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[3].type
                  : "TBA"
                : page1.length > 1
                ? page1[3].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[3].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[3].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[3].description
                  : "TBA"
                : page1.length > 1
                ? page1[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[3].woodstock === "true"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[3].woodstock === "true"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "malmesbury" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[3].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[3].strain
                  : "TBA"
                : page1.length > 1
                ? page1[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[3].type
                  : "TBA"
                : page1.length > 1
                ? page1[3].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[3].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[3].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[3].description
                  : "TBA"
                : page1.length > 1
                ? page1[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[3].storefield1 === "malmesbury"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[3].storefield1 === "malmesbury"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "mosselBay" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[3].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[3].strain
                  : "TBA"
                : page1.length > 1
                ? page1[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[3].type
                  : "TBA"
                : page1.length > 1
                ? page1[3].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[3].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[3].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[3].description
                  : "TBA"
                : page1.length > 1
                ? page1[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[3].storefield2 === "mosselBay"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[3].storefield2 === "mosselBay"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "woester" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[3].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[3].strain
                  : "TBA"
                : page1.length > 1
                ? page1[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[3].type
                  : "TBA"
                : page1.length > 1
                ? page1[3].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[3].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[3].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[3].description
                  : "TBA"
                : page1.length > 1
                ? page1[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[3].storefield3 === "woester"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[3].storefield3 === "woester"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "panorama" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[3].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[3].strain
                  : "TBA"
                : page1.length > 1
                ? page1[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[3].type
                  : "TBA"
                : page1.length > 1
                ? page1[3].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[3].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[3].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[3].description
                  : "TBA"
                : page1.length > 1
                ? page1[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[3].storefield4 === "panorama"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[3].storefield4 === "panorama"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "hermanus" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[3].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[3].strain
                  : "TBA"
                : page1.length > 1
                ? page1[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[3].type
                  : "TBA"
                : page1.length > 1
                ? page1[3].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[3].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[3].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[3].description
                  : "TBA"
                : page1.length > 1
                ? page1[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[3].storefield5 === "hermanus"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[3].storefield5 === "hermanus"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
        {selectedStore === "wellington" ? (
          <ItemCard
            timer={2700}
            refresh={refresh}
            label={
              refresh
                ? page3.length > 1
                  ? page3[3].productName
                  : "TBA"
                : page1.length >= 1
                ? page1[3].productName
                : "TBA"
            }
            strain={
              refresh
                ? page3.length > 1
                  ? page3[3].strain
                  : "TBA"
                : page1.length > 1
                ? page1[3].strain
                : "TBA"
            }
            type={
              refresh
                ? page3.length > 1
                  ? page3[3].type
                  : "TBA"
                : page1.length > 1
                ? page1[3].type
                : "TBA"
            }
            price={
              refresh
                ? page3.length > 1
                  ? "R" + " " + page3[3].price
                  : "TBA"
                : page1.length > 1
                ? "R" + " " + page1[3].price
                : "TBA"
            }
            thcPercent={
              refresh
                ? page3.length > 1
                  ? page3[3].thcContent
                  : "TBA"
                : page1.length > 1
                ? page1[3].thcContent
                : "TBA"
            }
            description={
              refresh
                ? page3.length > 1
                  ? page3[3].description
                  : "TBA"
                : page1.length > 1
                ? page1[3].description
                : "TBA"
            }
            notIn={
              refresh
                ? page3.length >= 1
                  ? page3[3].storefield6 === "wellington"
                    ? false
                    : true
                  : true
                : page1.length >= 1
                ? page1[3].storefield6 === "wellington"
                  ? false
                  : true
                : true
            }
          />
        ) : null}
      </div>
    </>
  );
};

export default FirstGroup;
