import React from "react";

const ReadOnlyDisplay = ({ categorySelected }) => {
  return (
    <div>
      <div style={{ fontSize: 10 }}>Product type</div>
      <div style={{ marginTop: 5, fontSize: 14, fontWeight: "bold" }}>
        {categorySelected ? categorySelected.categoryName : "loading"}
      </div>
    </div>
  );
};

export default ReadOnlyDisplay;
