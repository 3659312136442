import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";

const CategoryEditPopUp = ({
  display,
  onCloseClick,
  categoryEditPopUp,
  subcategoryEditPopUp,
  setSubcategoryEditPop,
  setCategoryEditPop,
  setLoading,
  setMessage,
  setToastPop,
  setRefresh,
  refresh,
}) => {
  const [catName, setCatName] = useState("");
  const [catDescription, setCatDescription] = useState("");
  const [subCatName, setSubCatName] = useState("");
  const [subCatDescription, setSubCatDescription] = useState("");

  const [deleteChange, setDeleteChange] = useState(false);
  const [updateChange, setUpdateChange] = useState(false);

  const updateCategory = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/updateCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId: categoryEditPopUp.categoryId,
            categoryName: catName,
            description: catDescription,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.category) {
        // const wait = await updateListFetch();
        setMessage("Category has been update");
        setToastPop(true);
        setCategoryEditPop(null);
        setLoading(false);
        setUpdateChange(false);
        setRefresh(refresh ? false : true);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(
          "Category could not be updated, contact support is this happens again"
        );
        setLoading(false);
        setUpdateChange(false);
      }
    } catch (err) {
      setMessage("Category could pt be updated");
      setUpdateChange(false);
      setLoading(false);
    }
  };
  const deleteCategory = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/deleteCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryId: categoryEditPopUp.categoryId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.message === "category was deleted") {
        // const wait = await updateListFetch();
        setMessage("Category has been deleted");
        setToastPop(true);
        setCategoryEditPop(null);
        setLoading(false);
        setDeleteChange(false);
        setRefresh(refresh ? false : true);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
        setDeleteChange(false);
      }
    } catch (err) {
      setMessage("Category could not be deleted ");
      setDeleteChange(false);
      setLoading(false);
    }
  };
  const updateSubCategory = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/updateSubCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subCategoryId: subcategoryEditPopUp.subCategoryId,
            subCategoryName: subCatName,
            description: subCatDescription,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.subCategory) {
        // const wait = await updateListFetch();
        setMessage("Sub-Category has been update");
        setToastPop(true);
        setSubcategoryEditPop(null);
        setLoading(false);
        setUpdateChange(false);
        setRefresh(refresh ? false : true);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(
          "Sub Category could not be updated, contact support is this happens again"
        );
        setLoading(false);
        setUpdateChange(false);
      }
    } catch (err) {
      setMessage(" Sub Category could pt be updated");
      setUpdateChange(false);
      setLoading(false);
    }
  };
  const deleteSubCategory = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/deleteSubCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subCategoryId: subcategoryEditPopUp.subCategoryId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.message === "sub category was deleted") {
        // const wait = await updateListFetch();
        setMessage("Sub-Category has been deleted");
        setToastPop(true);
        setSubcategoryEditPop(null);
        setLoading(false);
        setDeleteChange(false);
        setRefresh(refresh ? false : true);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
        setDeleteChange(false);
      }
    } catch (err) {
      setMessage("Sub-Category could not be deleted ");
      setDeleteChange(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (categoryEditPopUp) {
      setCatName(categoryEditPopUp.categoryName);
      setCatDescription(categoryEditPopUp.description);
    }

    if (subcategoryEditPopUp) {
      setSubCatName(subcategoryEditPopUp.subCategoryName);
      setSubCatDescription(subcategoryEditPopUp.description);
    }
  }, [categoryEditPopUp, subcategoryEditPopUp]);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        width: "100%",
        height: display ? "100%" : 0,
        overflow: "hidden",
        backgroundColor: BlackAndWhite.primary,
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: display ? 1 : 0,
        transition: "all 0.3s",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          padding: 20,
          borderRadius: 10,
          backgroundColor: BlackAndWhite.secondary,
          transition: "all 0.2s",
        }}
      >
        {categoryEditPopUp && !deleteChange && !updateChange ? (
          <>
            <div>Edit Category</div>
            <TextInputField
              labelTitle={"Category Name"}
              mini={true}
              value={catName}
              invert={true}
              size={300}
              onChange={(e) => setCatName(e.target.value)}
            />
            <TextAreaInput
              labelTitle={"Description"}
              mini={true}
              value={catDescription}
              invert={true}
              color={BlackAndWhite.primary}
              size={310}
              onChange={(e) => setCatDescription(e.target.value)}
            />
            <div style={{ display: "flex", marginTop: 20 }}>
              <BaseButtons label={"Cancel"} onClick={onCloseClick} />
              <div style={{ width: 15 }} />
              <BaseButtons
                label={"Update"}
                onClick={() => setUpdateChange(true)}
              />
              <div style={{ width: 15 }} />
              <BaseButtons
                label={"Delete"}
                onClick={() => setDeleteChange(true)}
              />
            </div>
          </>
        ) : null}
        {subcategoryEditPopUp && !deleteChange && !updateChange ? (
          <>
            <div>Edit Sub-Category</div>
            <TextInputField
              labelTitle={"Sub-Category Name"}
              mini={true}
              value={subCatName}
              invert={true}
              size={300}
              onChange={(e) => setSubCatName(e.target.value)}
            />
            <TextAreaInput
              labelTitle={"Description"}
              mini={true}
              value={subCatDescription}
              invert={true}
              color={BlackAndWhite.primary}
              size={310}
              onChange={(e) => setSubCatDescription(e.target.value)}
            />
            <div style={{ display: "flex", marginTop: 20 }}>
              <BaseButtons label={"Cancel"} onClick={onCloseClick} />
              <div style={{ width: 15 }} />
              <BaseButtons
                label={"Update"}
                onClick={() => setUpdateChange(true)}
              />
              <div style={{ width: 15 }} />
              <BaseButtons
                label={"Delete"}
                onClick={() => setDeleteChange(true)}
              />
            </div>
          </>
        ) : null}
        {deleteChange ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>Are you sure you want to delete this?</div>
            <div style={{ display: "flex" }}>
              {subcategoryEditPopUp ? (
                <>
                  {" "}
                  <BaseButtons
                    label={"Yes"}
                    onClick={() => deleteSubCategory()}
                  />
                  <div style={{ width: 15 }} />
                  <BaseButtons
                    label={"No"}
                    onClick={() => setDeleteChange(false)}
                  />
                </>
              ) : null}
              {categoryEditPopUp ? (
                <>
                  {" "}
                  <BaseButtons label={"Yes"} onClick={() => deleteCategory()} />
                  <div style={{ width: 15 }} />
                  <BaseButtons
                    label={"No"}
                    onClick={() => setDeleteChange(false)}
                  />
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        {updateChange ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>Are you sure you want to update this?</div>
            <div style={{ display: "flex" }}>
              {subcategoryEditPopUp ? (
                <>
                  {" "}
                  <BaseButtons
                    label={"Yes"}
                    onClick={() => updateSubCategory()}
                  />
                  <div style={{ width: 15 }} />
                  <BaseButtons
                    label={"No"}
                    onClick={() => setUpdateChange(false)}
                  />
                </>
              ) : null}
              {categoryEditPopUp ? (
                <>
                  {" "}
                  <BaseButtons label={"Yes"} onClick={() => updateCategory()} />
                  <div style={{ width: 15 }} />
                  <BaseButtons
                    label={"No"}
                    onClick={() => setUpdateChange(false)}
                  />
                </>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CategoryEditPopUp;
