import React, { useState } from "react";
import useWindowDimensions from "../../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../../themes/Colors";
import CloseButton from "../close-button/CloseButton";
import LocationCard from "../location-card/LocationCard";
import MobileShopDisplay from "../mobile-shop-display/MobileShopDisplay";
import BaseButtons from "../base-buttons/BaseButtons";
import NavArrow from "./components/NavArrow";

const locationData = [
  {
    id: 1,
    startedDate: "Open Since: April 2023",
    name: "Franshoek, Western Cape",
    address:
      "Merry-Jane Franschhoek,Farm 1012/16, R45 Main Road, Franschhoek, Western Cape, South Africa",
    contactNumber: "+71 042 8930",
    type: "Dispensary,Cocktail Lounge and Eatery , Smokers Friendly.",
    email: "franschhoek@merryjane.online",
    images: [
      {
        image: require("../../../images/locationArt/franshoek/Franschhoek.png"),
      },
    ],
    paragraph1:
      "Welcome to Insangu Franschhoek, nestled in the picturesque valley. As proud pioneers, we invite you to our inaugural facility, the first of many.",
    paragraph2:
      "Our haven welcomes cannabis enthusiasts to a private and serene atmosphere, offering a curated selection of high-quality products. Enjoy our dedicated space for members to unwind, socialize, and connect. Whether you're a seasoned connoisseur or new to cannabis, our experienced team ensures an educational and enjoyable visit. Discover the harmony between nature, community, and the world of cannabis. We look forward to welcoming new members and sharing the wonders within our doors.",
    operatingHours: [
      {
        day: "monday",
        time: "09:00 - 20:00",
      },
      {
        day: "tuesday",
        time: "09:00 - 20:00",
      },
      {
        day: "wednesday",
        time: "09:00 - 20:00",
      },
      {
        day: "thursday",
        time: "09:00 - 20:00",
      },
      {
        day: "friday",
        time: "09:00 - 20:00",
      },
      {
        day: "saturday",
        time: "09:00 - 20:00",
      },
      {
        day: "sunday",
        time: "09:00 - 20:00",
      },
    ],
  },
  {
    id: 2,
    startedDate: "Open",
    name: "Merry-Jane Langebaan, Western Cape",
    address:
      "Merry-Jane Langebaan, Shop 19, Madriko Building, 11 Bree Street, Langebaan, Western Cape, South Africa.",
    contactNumber: "+27 79 680 6006",
    type: "Dispensary and Collection Point, Walk-inn only.",
    email: "store.langebaan@merry-jane.com",
    images: [
      { image: require("../../../images/locationArt/langebaan/Langebaan.png") },
    ],
    paragraph1:
      "Welcome to Merry-Jane Langebaan, where the sea breeze meets cannabis ease! Our walk-in spot is nestled on the captivating West Coast of South Africa, offering a beachy escape. Dive into our facility for a private and serene atmosphere where members can ride the wave of a carefully curated selection of high-quality cannabis products.",
    paragraph2:
      "Join our exclusive family and ride the tide on a journey of relaxation, exploration, and indulgence in this idyllic coastal haven. Merry-Jane Langebaan invites you to experience the perfect blend of cannabis charm and seaside serenity. Kitesurfing’s up, and so is the good vibe!",
    operatingHours: [
      {
        day: "monday",
        time: "08:00 - 20:00",
      },
      {
        day: "tuesday",
        time: "08:00 - 20:00",
      },
      {
        day: "wednesday",
        time: "08:00 - 20:00",
      },
      {
        day: "thursday",
        time: "08:00 - 20:00",
      },
      {
        day: "friday",
        time: "08:00 - 20:00",
      },
      {
        day: "saturday",
        time: "08:00 - 20:00",
      },
      {
        day: "sunday",
        time: "10:00 - 17:00",
      },
    ],
  },
  {
    id: 3,
    name: "Paarl, Western Cape",
    startedDate: "Opening Soon",
    address:
      "Merry-Jane Paarl, Unit 4, Zandwijk Industrial Park, Old Paarl Road, Paarl, Western Cape, South Africa",
    contactNumber: "+27 71 042 8930",
    email: "mailto:paarl@merryjane.online",
    type: "Dispensary and Cocktail Lounge, Smokers Friendly",
    images: [
      {
        image: require("../../../images/locationArt/paarl/Paarl.png"),
      },
    ],
    paragraph1:
      "Step into Merry-Jane Paarl Cannabis Dispensary and Cocktail Lounge, strategically nestled east of Cape Town along the Groot-Berg River, embraced by the grandeur of Paarl Mountain and the Drakenstein Range. Our establishment is meticulously crafted to provide a secluded and tranquil ambiance, forming a private haven where patients and clients alike can explore a diverse array of high-quality cannabis products.",
    paragraph2:
      "Conveniently located just 5 minutes off the N1, our facility effortlessly caters to both Paarl patrons and passersby. Treat yourself to an added pleasure. relish a delightful cup of coffee in our smoker-friendly environment. Merry-Jane Paarl invites you to partake in the perfect fusion of cannabis and the Boland surroundings.",
    operatingHours: [
      {
        day: "monday",
        time: "09:00 - 20:00",
      },
      {
        day: "tuesday",
        time: "09:00 - 20:00",
      },
      {
        day: "wednesday",
        time: "09:00 - 20:00",
      },
      {
        day: "thursday",
        time: "09:00 - 20:00",
      },
      {
        day: "friday",
        time: "09:00 - 20:00",
      },
      {
        day: "saturday",
        time: "09:00 - 20:00",
      },
      {
        day: "sunday",
        time: "09:00 - 20:00",
      },
    ],
  },
  {
    id: 4,
    name: "Merry-Jane Malmesbury, Western Cape",
    startedDate: "OPEN",
    address:
      "Merry-Jane Malmesbury, Corner Piet Retief and Hill Street, Malmesbury, Western Cape, South Africa.",
    contactNumber: "+27 65 537 2095",
    type: "Dispensary and Cocktail Lounge, Smokers Friendly",
    email: "store.malmesbury@merry-jane.com",
    images: [
      {
        image: require("../../../images/locationArt/malmesbury/Malmesbury.png"),
      },
    ],
    paragraph1:
      "Welcome to Merry Jane Malmesbury Cannabis Retreat and Lounge, nestled in the heart of the Swartland, Western Cape. Explore a curated selection of top-tier cannabis products in our cozy spot.",
    paragraph2:
      "Relax in the laid-back atmosphere while enjoying infused coffees and non-alcoholic Cannabis cocktails in our smoker-friendly setting. With a comfortable 200 square meters, there's ample space for patrons and friends to unwind. Welcome to Merry Jane Malmesbury, where relaxation meets good company.",
    operatingHours: [
      {
        day: "monday",
        time: "09:00 - 20:00",
      },
      {
        day: "tuesday",
        time: "09:00 - 20:00",
      },
      {
        day: "wednesday",
        time: "09:00 - 20:00",
      },
      {
        day: "thursday",
        time: "09:00 - 20:00",
      },
      {
        day: "friday",
        time: "09:00 - 20:00",
      },
      {
        day: "saturday",
        time: "09:00 - 20:00",
      },
      {
        day: "sunday",
        time: "10:00 - 20:00",
      },
    ],
  },
  {
    id: 5,
    startedDate: "Open",
    name: "Merry-Jane Woodstock, Western Cape",
    address:
      "Merry-Jane Woodstock, Woodstock Brewery, 252 Albert Road, Cape Town, Western Cape, South Africa.",
    contactNumber: "+27 63 314 1035",
    type: "Dispensary and Collection Point, Walk-inn only",
    email: "store.woodstock@merry-jane.com",
    images: [
      {
        image: require("../../../images/locationArt/woodstock/Woodstock.png"),
      },
    ],
    paragraph1:
      "Get ready to hop into the good times at our rad spot inside the famous Woodstock Brewery, just a breezy 5-minute drive from Cape Town City Centre. Our walk-in joint is smack dab in the middle of a lively scene filled with good vibes and all kinds of brewer's shenanigans.",
    paragraph2:
      "Here at our place, patrons and pals can dive into a haven of top-tier cannabis products. Soak up the lively vibes around you and relish the awesome experience of a buzzing atmosphere,all just a stone's throw away from Cape Town City Centre. Come join the fun!",
    operatingHours: [
      {
        day: "monday",
        time: "09:00 - 20:00",
      },
      {
        day: "tuesday",
        time: "09:00 - 20:00",
      },
      {
        day: "wednesday",
        time: "09:00 - 20:00",
      },
      {
        day: "thursday",
        time: "09:00 - 20:00",
      },
      {
        day: "friday",
        time: "09:00 - 22:00",
      },
      {
        day: "saturday",
        time: "09:00 - 22:00",
      },
      {
        day: "sunday",
        time: "10:00 - 18:00",
      },
    ],
  },
  {
    id: 6,
    startedDate: "SOON",
    name: "MOSSEL BAY | WESTERN CAPE ",
    address: "8 Cuff Street Mossel Bay.  Western Cape, South Africa.",
    contactNumber: "TBA",
    type: "Dispensary and Collection Point, Walk-inn only",
    email: "store.mosselbay@merry-jane.com",
    images: [
      {
        image: require("../../../images/locationArt/woodstock/Woodstock.png"),
      },
    ],
    paragraph1: "TBA",
    paragraph2: "TBA",
    operatingHours: [
      {
        day: "monday",
        time: "coming soon",
      },
      {
        day: "tuesday",
        time: "coming soon",
      },
      {
        day: "wednesday",
        time: "coming soon",
      },
      {
        day: "thursday",
        time: "coming soon",
      },
      {
        day: "friday",
        time: "coming soon",
      },
      {
        day: "saturday",
        time: "coming soon",
      },
      {
        day: "sunday",
        time: "coming soon",
      },
    ],
  },
  {
    id: 7,
    startedDate: "SOON",
    name: "WORCESTER  | WESTERN CAPE ",
    address:
      "59 Stockenstrohmn Street, Worcester, Western Cape, Western Cape, South Africa",
    contactNumber: "TBA",
    type: "Dispensary and Collection Point, Lounge, Smokers Friendly",
    email: "store.worcester@merry-jane.com",
    images: [
      {
        image: require("../../../images/locationArt/woodstock/Woodstock.png"),
      },
    ],
    paragraph1: "TBA",
    paragraph2: "TBA",
    operatingHours: [
      {
        day: "monday",
        time: "coming soon",
      },
      {
        day: "tuesday",
        time: "coming soon",
      },
      {
        day: "wednesday",
        time: "coming soon",
      },
      {
        day: "thursday",
        time: "coming soon",
      },
      {
        day: "friday",
        time: "coming soon",
      },
      {
        day: "saturday",
        time: "coming soon",
      },
      {
        day: "sunday",
        time: "coming soon",
      },
    ],
  },
];

const LocationTab = ({ display, onCloseClick, width, height }) => {
  const [shopBreakDown, setShopBreakDown] = useState(false);
  const [infoDisplay, setInfoDisplay] = useState(false);
  const [shopInfoDisplay, setShopInfoDisplay] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(1);
  const [imageSelected, setImageSelected] = useState(0);
  return (
    <div
      style={{
        height: display ? height : 0,
        width: width,
        backgroundColor: "#00000033",
        overflow: "hidden",
        transition: "all 0.5s",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
        top: 0,
      }}
    >
      <div style={{ color: BlackAndWhite.secondary, flex: 0.15 }} />
      <div
        style={{
          flex: 0.85,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          //   borderStyle: "solid",
          backgroundColor: "#000000cc",
          width: width > 899 ? "98%" : "95%",
          position: "relative",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
        }}
      >
        <div style={{ position: "absolute", top: 20, left: 20 }}>
          <CloseButton onClick={onCloseClick} label={"X"} width={50} invert />
        </div>
        <h2
          style={{
            marginTop: 35,
            color: BlackAndWhite.secondary,
            fontFamily: "Montserrat",
          }}
        >
          FACILITIES
        </h2>
        {width > 899 ? (
          <div
            style={{
              maxHeight: 600,
              height: "100%",
              width: "100%",
              color: BlackAndWhite.secondary,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                flex: 0.5,
                height: "100%" + 20,
                display: "flex",
                flexDirection: "column",

                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: "100%",
                  // width: width > 1399 ? 800 : 600,
                  overflow: "scroll",
                  paddingBottom: 200,
                }}
              >
                {/* <LocationCard
                  width={width}
                  href={"https://maps.app.goo.gl/ZR5uNB7PWSAVWvGy7"}
                  locationImage={require("../../../images/LangebannLocation.png")}
                  shopName={"Franschhoek , Western Cape"}
                  address={
                    "Franschhoek,Farm 1012/16, R45 Main Road, Franschhoek, Western Cape, South Africa"
                  }
                  contactInfo={"cell: +71 042 8930"}
                  email={"franschhoek@merryjane.online"}
                  onClick={() => setSelectedLocation(0)}
                /> */}
                <LocationCard
                  width={width}
                  href={
                    "https://www.google.com/maps/place/33%C2%B005'29.5%22S+18%C2%B002'00.9%22E/@-33.09153,18.033569,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-33.09153!4d18.033569?entry=ttu"
                  }
                  locationImage={require("../../../images/LangebannLocation.png")}
                  shopName={"Langebaan, Western Cape"}
                  address={
                    "Merry-Jane Langebaan, Shop 19, Madriko Building, 11 Bree Street, Langebaan, Western Cape, South Africa."
                  }
                  contactInfo={"cell: +27 79 680 6006"}
                  email={"store.langebaan@merry-jane.com"}
                  onClick={() => setSelectedLocation(1)}
                  openInfo={"OPEN"}
                />
                <LocationCard
                  width={width}
                  href={
                    "https://www.google.com/maps/place/Woodstock+Brewery/@-33.9275068,18.4537733,17z/data=!3m1!4b1!4m6!3m5!1s0x1dcc5da184c8c8dd:0x3d7a19960fb0f3f5!8m2!3d-33.9275068!4d18.4537733!16s%2Fg%2F11bxc570l0?authuser=0&entry=ttu"
                  }
                  locationImage={require("../../../images/woodstocklocation.png")}
                  shopName={"Woodstock, Western Cape"}
                  address={
                    "Merry-Jane Woodstock, Woodstock Brewery, 252 Albert Road, Cape Town, Western Cape, South Africa."
                  }
                  contactInfo={"cell: +27 63 314 1035"}
                  email={"store.woodstock@merry-jane.com"}
                  onClick={() => {
                    setSelectedLocation(4);
                  }}
                  openInfo={"OPEN"}
                />
                <LocationCard
                  width={width}
                  locationImage={require("../../../images/malmsberryLocation.png")}
                  href={
                    "https://www.google.com/maps/place/33%C2%B027'41.8%22S+18%C2%B043'50.3%22E/@-33.461605,18.730638,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-33.461605!4d18.730638?entry=ttu"
                  }
                  shopName={"Malmesbury, Western Cape"}
                  address={
                    "Merry-Jane Malmesbury, Corner Piet Retief and Hill Street, Malmesbury, Western Cape, South Africa."
                  }
                  contactInfo={"cell: +27 65 537 2095"}
                  email={"store.malmesbury@merry-jane.com"}
                  onClick={() => setSelectedLocation(3)}
                  openInfo={"OPEN"}
                />
                <LocationCard
                  locationImage={require("../../../images/paarlLocation.png")}
                  href={
                    "https://www.google.com/maps/place/33%C2%B047'08.7%22S+18%C2%B056'45.9%22E/@-33.785736,18.946089,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-33.785736!4d18.946089?entry=ttu"
                  }
                  width={width}
                  shopName={"Mossel Bay, Western Cape"}
                  address={
                    "Merry-Jane Mossel Bay, 8 Cuff Street Mossel Bay.  Western Cape, South Africa"
                  }
                  contactInfo={"TBA"}
                  email={"store.mosselbay@merry-jane.com"}
                  onClick={() => {
                    setSelectedLocation(5);
                  }}
                  openInfo={"SOON"}
                />
                <LocationCard
                  locationImage={require("../../../images/paarlLocation.png")}
                  href={
                    "https://www.google.com/maps/place/33%C2%B047'08.7%22S+18%C2%B056'45.9%22E/@-33.785736,18.946089,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-33.785736!4d18.946089?entry=ttu"
                  }
                  width={width}
                  shopName={"Worcester, Western Cape"}
                  address={
                    "Merry-Jane Worcester, 59 Stockenstrohmn Street, Worcester, Western Cape, Western Cape, South Africa"
                  }
                  contactInfo={"TBA"}
                  email={"store.worcester@merry-jane.com"}
                  onClick={() => {
                    setSelectedLocation(6);
                  }}
                  openInfo={"SOON"}
                />
                <LocationCard
                  locationImage={require("../../../images/paarlLocation.png")}
                  href={
                    "https://www.google.com/maps/place/33%C2%B047'08.7%22S+18%C2%B056'45.9%22E/@-33.785736,18.946089,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-33.785736!4d18.946089?entry=ttu"
                  }
                  width={width}
                  shopName={"Paarl, Western Cape"}
                  address={
                    "Merry-Jane Paarl, Unit 4, Zandwijk Industrial Park, Old Paarl Road, Paarl, Western Cape, South Africa"
                  }
                  contactInfo={"cell: +27 71 042 8930"}
                  email={"paarl@merryjane.online"}
                  onClick={() => {
                    setSelectedLocation(2);
                  }}
                  openInfo={"SOON"}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.5,
                height: "100%",
                borderRadius: 10,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  flex: 0.2,
                  width: "90%",
                  backgroundColor: BlackAndWhite.primary,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    color: BlackAndWhite.secondary,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: 40,
                      width: 40,

                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      position: "absolute",
                      left: 10,
                      backgroundColor: "#000000cc",
                      borderRadius: 100,
                    }}
                  >
                    {imageSelected === 0 ? null : (
                      <NavArrow
                        icon={require("../../../images/standard-icons/leftArrowWhite.png")}
                        onClick={() =>
                          setImageSelected(
                            imageSelected === 0 ? 0 : imageSelected - 1
                          )
                        }
                      />
                    )}
                  </div>

                  <img
                    alt="shop image"
                    src={
                      locationData[selectedLocation].images[imageSelected].image
                    }
                    style={{
                      height: "70%",
                      width: "70%",
                      position: "relative",
                    }}
                  />

                  <div
                    style={{
                      height: 40,
                      width: 40,
                      marginLeft: 20,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      position: "absolute",
                      right: 10,
                      backgroundColor: "#000000cc",
                      borderRadius: 100,
                    }}
                  >
                    {locationData[selectedLocation].images.length - 1 ===
                    imageSelected ? null : (
                      <NavArrow
                        onClick={() =>
                          setImageSelected(
                            locationData[selectedLocation].images.length - 1 ===
                              imageSelected
                              ? locationData[selectedLocation].images.length - 1
                              : imageSelected + 1
                          )
                        }
                        icon={require("../../../images/standard-icons/rightArrowWhite.png")}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  flex: 0.6,
                  width: "90%",
                  backgroundColor: "#ffffff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  minHeight: 650,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 30,
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    backgroundColor: "#00000033",
                  }}
                >
                  <BaseButtons
                    label={"DESCRIPTION"}
                    size={150}
                    height={30}
                    onClick={() => setInfoDisplay(true)}
                    invert={infoDisplay ? false : true}
                  />
                  <BaseButtons
                    label={"SHOP INFO"}
                    size={150}
                    height={30}
                    onClick={() => setInfoDisplay(false)}
                    invert={infoDisplay ? true : false}
                  />
                </div>

                {infoDisplay ? (
                  <div
                    style={{
                      height: 300,
                      width: "100%",
                      color: BlackAndWhite.primary,
                      overflow: "scroll",
                      paddingBottom: 200,
                    }}
                  >
                    <div
                      style={{ marginTop: 40, marginLeft: 20, fontSize: 20 }}
                    >
                      {locationData[selectedLocation].name}
                    </div>
                    {/* <div
                      style={{ marginTop: 40, marginLeft: 20, fontSize: 17 }}
                    >
                      {selectedLocation.type}
                    </div> */}
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        fontSize: 14,
                        width: "90%",
                      }}
                    >
                      {locationData[selectedLocation].paragraph1}
                    </div>
                    <div
                      style={{
                        marginTop: 5,
                        marginLeft: 20,
                        fontSize: 14,
                        width: "90%",
                      }}
                    >
                      {locationData[selectedLocation].paragraph2}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      color: BlackAndWhite.primary,
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 20,
                    }}
                  >
                    <div
                      style={{
                        flex: 0.5,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        // overflow: "scroll",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        Address:
                      </div>
                      <div
                        style={{ marginTop: 10, marginLeft: 20, fontSize: 14 }}
                      >
                        {locationData[selectedLocation].address}
                      </div>

                      <div
                        style={{ marginTop: 10, marginLeft: 20, fontSize: 14 }}
                      >
                        {locationData[selectedLocation].email}
                      </div>
                      <div
                        style={{ marginTop: 10, marginLeft: 20, fontSize: 14 }}
                      >
                        {locationData[selectedLocation].contactNumber}
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 20,
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        {locationData[selectedLocation].startedDate}
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 20,
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        {locationData[selectedLocation].type}
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 0.5,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 20,
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        Operating hours:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 20,
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        Monday:{" "}
                        {locationData[selectedLocation].operatingHours[0].time}
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 20,
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        Tuesday:{" "}
                        {locationData[selectedLocation].operatingHours[1].time}
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 20,
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        Wednesday:{" "}
                        {locationData[selectedLocation].operatingHours[2].time}
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 20,
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        Thursday:{" "}
                        {locationData[selectedLocation].operatingHours[3].time}
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 20,
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        Friday:{" "}
                        {locationData[selectedLocation].operatingHours[4].time}
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 20,
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        Saturday:{" "}
                        {locationData[selectedLocation].operatingHours[5].time}
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 20,
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        Sunday:{" "}
                        {locationData[selectedLocation].operatingHours[6].time}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              height: 450,
              width: "100%",
              overflow: "scroll",
            }}
          >
            <LocationCard
              href={
                "https://www.google.com/maps/place/33%C2%B005'29.5%22S+18%C2%B002'00.9%22E/@-33.09153,18.033569,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-33.09153!4d18.033569?entry=ttu"
              }
              locationImage={require("../../../images/LangebannLocation.png")}
              shopName={"Langebaan, Western Cape"}
              address={
                "Merry-Jane Langebaan, Shop 19, Madriko Building, 11 Bree Street, Langebaan, Western Cape, South Africa."
              }
              contactInfo={"cell: +27 79 680 6006"}
              email={"store.langebaan@merry-jane.com"}
              onClick={() => {
                setSelectedLocation(1);
                setShopBreakDown(true);
              }}
              openInfo={"OPEN"}
            />
            <LocationCard
              href={
                "https://www.google.com/maps/place/Woodstock+Brewery/@-33.9275068,18.4537733,17z/data=!3m1!4b1!4m6!3m5!1s0x1dcc5da184c8c8dd:0x3d7a19960fb0f3f5!8m2!3d-33.9275068!4d18.4537733!16s%2Fg%2F11bxc570l0?authuser=0&entry=ttu"
              }
              locationImage={require("../../../images/woodstocklocation.png")}
              shopName={" Woodstock, Western Cape"}
              address={
                "Merry-Jane Woodstock, Woodstock Brewery, 252 Albert Road, Cape Town, Western Cape, South Africa."
              }
              contactInfo={"cell: +27 63 314 1035"}
              email={"store.woodstock@merry-jane.com"}
              onClick={() => {
                setShopBreakDown(true);
                setSelectedLocation(4);
              }}
              openInfo={"OPEN"}
            />
            <LocationCard
              locationImage={require("../../../images/malmsberryLocation.png")}
              href={
                "https://www.google.com/maps/place/33%C2%B027'41.8%22S+18%C2%B043'50.3%22E/@-33.461605,18.730638,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-33.461605!4d18.730638?entry=ttu"
              }
              shopName={"Malmesbury, Western Cape"}
              address={
                "Merry-Jane Malmesbury, Corner Piet Retief and Hill Street, Malmesbury, Western Cape, South Africa."
              }
              contactInfo={"cell: +27 65 537 2095"}
              email={"store.malmesbury@merry-jane.com"}
              onClick={() => {
                setShopBreakDown(true);
                setSelectedLocation(3);
              }}
              openInfo={"OPEN"}
            />
            <LocationCard
              locationImage={require("../../../images/paarlLocation.png")}
              href={
                "https://www.google.com/maps/place/33%C2%B047'08.7%22S+18%C2%B056'45.9%22E/@-33.785736,18.946089,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-33.785736!4d18.946089?entry=ttu"
              }
              width={width}
              shopName={"Mossel Bay, Western Cape"}
              address={
                "Merry-Jane Mossel Bay, 8 Cuff Street Mossel Bay.  Western Cape, South Africa"
              }
              contactInfo={"TBA"}
              email={"store.mosselbay@merry-jane.com"}
              onClick={() => {
                setSelectedLocation(5);
              }}
              openInfo={"SOON"}
            />
            <LocationCard
              locationImage={require("../../../images/paarlLocation.png")}
              href={
                "https://www.google.com/maps/place/33%C2%B047'08.7%22S+18%C2%B056'45.9%22E/@-33.785736,18.946089,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-33.785736!4d18.946089?entry=ttu"
              }
              width={width}
              shopName={"Worcester, Western Cape"}
              address={
                "Merry-Jane Worcester, 59 Stockenstrohmn Street, Worcester, Western Cape, Western Cape, South Africa"
              }
              contactInfo={"TBA"}
              email={"store.worcester@merry-jane.com"}
              onClick={() => {
                setSelectedLocation(6);
              }}
              openInfo={"SOON"}
            />
            <LocationCard
              locationImage={require("../../../images/paarlLocation.png")}
              href={
                "https://www.google.com/maps/place/33%C2%B047'08.7%22S+18%C2%B056'45.9%22E/@-33.785736,18.946089,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-33.785736!4d18.946089?entry=ttu"
              }
              width={width}
              shopName={"Paarl, Western Cape"}
              address={
                "Merry-Jane Paarl, Unit 4, Zandwijk Industrial Park, Old Paarl Road, Paarl, Western Cape, South Africa"
              }
              contactInfo={"cell: +27 71 042 8930"}
              email={"paarl@merryjane.online"}
              onClick={() => {
                setSelectedLocation(2);
                setShopBreakDown(true);
              }}
              openInfo={"SOON"}
            />
          </div>
        )}
      </div>
      <MobileShopDisplay
        onLeftClick={() =>
          setImageSelected(imageSelected === 0 ? 0 : imageSelected - 1)
        }
        onRightClick={() =>
          setImageSelected(
            locationData[selectedLocation].images.length - 1 === imageSelected
              ? locationData[selectedLocation].images.length - 1
              : imageSelected + 1
          )
        }
        shopImage={locationData[selectedLocation].images[imageSelected].image}
        display={shopBreakDown}
        onCloseClick={() => setShopBreakDown(false)}
        desTitle={locationData[selectedLocation].name}
        paragraph1={locationData[selectedLocation].paragraph1}
        paragraph2={locationData[selectedLocation].paragraph2}
        monday={locationData[selectedLocation].operatingHours[0].time}
        tuesday={locationData[selectedLocation].operatingHours[1].time}
        wednesday={locationData[selectedLocation].operatingHours[2].time}
        thursday={locationData[selectedLocation].operatingHours[3].time}
        friday={locationData[selectedLocation].operatingHours[4].time}
        saturday={locationData[selectedLocation].operatingHours[5].time}
        sunday={locationData[selectedLocation].operatingHours[6].time}
        infoDisplay={shopInfoDisplay}
        setInfoDisplay={setShopInfoDisplay}
        address={locationData[selectedLocation].address}
        contact={locationData[selectedLocation].contactNumber}
        email={locationData[selectedLocation].email}
        type={locationData[selectedLocation].type}
        openInfo={locationData[selectedLocation].startedDate}
      />
    </div>
  );
};

export default LocationTab;
