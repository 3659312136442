import React, { useEffect, useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { CreateMessagePopUp, SupportMessageView } from "./components";
import { LoadingPopUp } from "../../../../../../components";

const MessageSupportDisplay = ({
  messageCreatePopUp,
  setMessageCreatePopUp,

  setMessage,
  setToastPop,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [messageArray, setMessageArray] = useState([]);
  const getAdminMessages = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/adminMessageRoutes/getAdminMessages",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.userId,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        setLoading(false);
        setMessageArray(responseData.messages);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (user) {
      getAdminMessages();
    }
  }, [refresh]);
  return (
    <div
      style={{
        width: "100%",
        height: "99%",

        // top: 150,
        // zIndex: 100000000,
        // right: 10,
        transition: "all 0.5s",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        color: BlackAndWhite.primary,
        overflowX: "hidden",
        position: "relative",
      }}
    >
      <div style={{ minHeight: 62 }} />
      {messageArray.length >= 1
        ? messageArray.map((message, index) => {
            return (
              <div key={index} style={{ width: "100%" }}>
                <SupportMessageView
                  image={require("../../../../../../images/marijuana-buds-with-marijuana-joints-cannabis-oil.jpg")}
                  messageTitle={message.messageTitle}
                  type={message ? message.type.toLocaleUpperCase() : "loading"}
                  date={message.createdOn}
                  fullDescription={message.fullMessage}
                  quickMessage={message.quickMessage}
                  signOff={message.signOff}
                  tags={message.tags}
                  createdBy={message.cratedBy}
                  imageId={message.image}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  setLoading={setLoading}
                  messageId={message.id}
                />
              </div>
            );
          })
        : null}

      <CreateMessagePopUp
        user={user}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
        display={messageCreatePopUp}
        setMessageCreatePopUp={setMessageCreatePopUp}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <LoadingPopUp display={loading} />
    </div>
  );
};

export default MessageSupportDisplay;
