import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextAreaInput,
  TextInputField,
} from "../../../../../components/atoms";

const AddFacilityPopUp = ({
  display,
  onCloseClick,
  width,
  height,
  onSubmitClick,
  onStoreChange,
  storeName,
  ownerName,
  setOwnerObject,
  cellNumber,
  onCellNumberChange,
  landlineNumber,
  onLandLineChange,
  streetNumber,
  onStreetNumberChange,
  googleLocation,
  onGoolgeLinkChange,
  email,
  onEmailChange,
  streetName,
  onStreetNameChange,
  suburb,
  onSuburbChange,
  onCityChange,
  city,
  postal,
  province,
  onPostalChange,
  onProvinceChange,
  onPara1Change,
  para1,
  para2,
  onPara2Change,
  imageLink,
  setImageLink,
  onChangeImage,
  remuValue,
  onRemuChange,
  onStoreTypeChange,
  storeTypeValue,
  onMonfromChange,
  onMonToChange,
  onTueFromChange,
  onTueToChange,
  onWedFromChange,
  onWedToChange,
  onThurFromChange,
  onThurToChange,
  onFriFromChange,
  onFriToChange,
  onSatFromChange,
  onSatToChange,
  onSunFromChange,
  onSunToChange,
  onPublicFromChange,
  onPublicToChange,
  ownerArray,
  ownerSlectedObject,
  statusValue,
  onStatusChange,
}) => {
  const [ownerPopUp, setOwnerPop] = useState(false);

  useEffect(() => {}, [display]);
  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: "100%",
        position: "absolute",
        bottom: 0,
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        transition: "all 0.5s",
        opacity: display ? 1 : 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        // position: "relative",
      }}
    >
      <div style={{ position: "absolute", left: 10, top: 10 }}>
        <CloseButton label={"X"} onClick={onCloseClick} />
      </div>
      {/* title header display */}
      <div
        style={{
          width: "100%",
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          height: 60,
        }}
      >
        <div style={{ marginLeft: 80, fontSize: 20, fontWeight: "bold" }}>
          Add Facility
        </div>
        <div style={{ flex: 1 }}></div>
        <div>
          <BaseButtons
            label={"SUBMIT"}
            borderRadius={80}
            marginTopFalse={false}
            marginRight={15}
            marginTop={5}
            mini={true}
            height={35}
            onClick={onSubmitClick}
          />
        </div>
      </div>
      <div
        style={{
          flex: 1,

          width: "100%",
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "flex-start",
          display: "flex",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            flex: 0.7,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            // height: "100%",
            // maxHeight: height > 700 ? 700 : 550,
            // minHeight: 550,
          }}
        >
          {/* primary informatioon */}
          <div
            style={{
              height: 500,
              width: "90%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginBottom: 30,
                }}
              >
                PRIMARY INFORMATION
              </div>
            </div>
            <TextInputField
              labelTitle={"Store Name"}
              mini={true}
              invert={true}
              width={"100%"}
              size={"90%"}
              value={storeName}
              onChange={onStoreChange}
            />
            <div style={{ marginTop: 10, width: "90%", fontSize: 14 }}>
              Select Owner
            </div>
            <div
              onClick={() => setOwnerPop(true)}
              style={{
                width: "94.1%",
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderStyle: "solid",
                borderWidth: 0.5,
                marginTop: 10,
              }}
            >
              {ownerSlectedObject
                ? ownerSlectedObject.firstName +
                  " " +
                  ownerSlectedObject.lastName
                : "Click here to select owner"}
            </div>

            <TextInputField
              labelTitle={"Store Cell Number"}
              mini={true}
              invert={true}
              width={"100%"}
              size={"90%"}
              value={cellNumber}
              onChange={onCellNumberChange}
            />
            <TextInputField
              labelTitle={"Store Landline"}
              mini={true}
              invert={true}
              width={"100%"}
              size={"90%"}
              value={landlineNumber}
              onChange={onLandLineChange}
            />
            <TextInputField
              labelTitle={"Store Email"}
              mini={true}
              invert={true}
              width={"100%"}
              size={"90%"}
              value={email}
              onChange={onEmailChange}
            />
          </div>
          {/* secodary informatioon */}
          <div
            style={{
              height: 520,
              width: "90%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 30,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                }}
              >
                ADDRESS INFORMATION | TYPE
              </div>
            </div>
            <TextInputField
              labelTitle={"Google location link"}
              mini={true}
              invert={true}
              size={"90%"}
              width={"100%"}
              value={googleLocation}
              onChange={onGoolgeLinkChange}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <TextInputField
                labelTitle={"Unit | Street Number"}
                mini={true}
                invert={true}
                size={"80%"}
                width={"50%"}
                value={streetNumber}
                onChange={onStreetNumberChange}
              />
              <TextInputField
                labelTitle={"Street | Road Name"}
                mini={true}
                invert={true}
                size={"80%"}
                width={"50%"}
                value={streetName}
                onChange={onStreetNameChange}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <TextInputField
                labelTitle={"Suburb"}
                mini={true}
                invert={true}
                size={"80%"}
                width={"50%"}
                value={suburb}
                onChange={onSuburbChange}
              />
              <TextInputField
                labelTitle={"City"}
                mini={true}
                invert={true}
                size={"80%"}
                width={"50%"}
                value={city}
                onChange={onCityChange}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <TextInputField
                labelTitle={"Postal code"}
                mini={true}
                invert={true}
                size={"80%"}
                width={"50%"}
                value={postal}
                onChange={onPostalChange}
              />
              <TextInputField
                labelTitle={"Province"}
                mini={true}
                invert={true}
                size={"80%"}
                width={"50%"}
                value={province}
                onChange={onProvinceChange}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <TextInputField
                labelTitle={"Store Type"}
                mini={true}
                invert={true}
                size={"90%"}
                width={"100%"}
                value={storeTypeValue}
                onChange={onStoreTypeChange}
              />
            </div>
          </div>
          {/* descriptions */}
          <div
            style={{
              height: 420,
              width: "90%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 30,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%", display: "flex", marginBottom: 10 }}>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                }}
              >
                DESCRIPTIONS
              </div>
            </div>
            <TextAreaInput
              height={150}
              labelTitle={"Paragraph one"}
              color={BlackAndWhite.primary}
              size={"90%"}
              width={"100%"}
              mini={true}
              value={para1}
              onChange={onPara1Change}
            />
            <div style={{ height: 20 }} />
            <TextAreaInput
              height={150}
              labelTitle={"Paragraph Two"}
              color={BlackAndWhite.primary}
              size={"90%"}
              width={"100%"}
              mini={true}
              value={para2}
              onChange={onPara2Change}
            />
          </div>

          <div style={{ marginTop: 50 }} />
        </div>
        <div
          style={{
            flex: 0.3,
            // borderStyle: "solid",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {/* images */}
          <div
            style={{
              height: 375,
              width: "95%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                }}
              >
                IMAGES
              </div>
            </div>
            <div
              style={{
                height: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 300,
                marginTop: 20,
                borderStyle: "solid",
                marginBottom: 10,
                borderWidth: 0.5,
              }}
            >
              <img
                alt="icon"
                src={imageLink}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <TextInputField
              labelTitle={"Image Link"}
              mini={true}
              invert={true}
              size={"75%"}
              width={"100%"}
              onChange={onChangeImage}
            />
          </div>

          {/* Operating hours */}
          <div
            style={{
              height: 645,
              width: "95%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 30,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                }}
              >
                OPERATING HOURS
              </div>
            </div>
            {/* MOnday */}
            {/* <div style={{ width: "100%", textAlign: "left" }}>
              <div style={{ marginLeft: 20, marginTop: 10 }}>Monday</div>
            </div> */}
            <div style={{ display: "flex" }}>
              <TextInputField
                labelTitle={"Monday: "}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"e.g 08:00"}
                height={20}
                onChange={onMonfromChange}
              />
              <TextInputField
                labelTitle={"To"}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"to e.g 20:00"}
                height={20}
                onChange={onMonToChange}
                // marginTop={28}
              />
            </div>
            {/* tuesday */}
            {/* <div style={{ width: "100%", textAlign: "left" }}>
              <div style={{ marginLeft: 20, marginTop: 10 }}>Tuesday</div>
            </div> */}
            <div style={{ display: "flex" }}>
              <TextInputField
                labelTitle={"Tuesday: "}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"e.g 08:00"}
                height={20}
                onChange={onTueFromChange}
              />
              <TextInputField
                labelTitle={"To"}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"to e.g 20:00"}
                height={20}
                onChange={onTueToChange}
                // marginTop={28}
              />
            </div>
            <div style={{ display: "flex" }}>
              <TextInputField
                labelTitle={"Wednesday: "}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"e.g 08:00"}
                height={20}
                onChange={onWedFromChange}
              />
              <TextInputField
                labelTitle={"To"}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"to e.g 20:00"}
                height={20}
                onChange={onWedToChange}
                // marginTop={28}
              />
            </div>
            <div style={{ display: "flex" }}>
              <TextInputField
                labelTitle={"Thursday: "}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"e.g 08:00"}
                height={20}
                onChange={onThurFromChange}
              />
              <TextInputField
                labelTitle={"To"}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"to e.g 20:00"}
                height={20}
                onChange={onThurToChange}

                // marginTop={28}
              />
            </div>
            {/* friday */}
            <div style={{ display: "flex" }}>
              <TextInputField
                labelTitle={"Friday: "}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"e.g 08:00"}
                height={20}
                onChange={onFriFromChange}
              />
              <TextInputField
                labelTitle={"To"}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"to e.g 20:00"}
                height={20}
                onChange={onFriToChange}

                // marginTop={28}
              />
            </div>
            {/* saturday */}
            <div style={{ display: "flex" }}>
              <TextInputField
                labelTitle={"Saturday: "}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"e.g 08:00"}
                height={20}
                onChange={onSatFromChange}
              />
              <TextInputField
                labelTitle={"To"}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"to e.g 20:00"}
                height={20}
                onChange={onSatToChange}
                // marginTop={28}
              />
            </div>
            <div style={{ display: "flex" }}>
              <TextInputField
                labelTitle={"Sunday: "}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"e.g 08:00"}
                height={20}
                onChange={onSunFromChange}
              />
              <TextInputField
                labelTitle={"To"}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"to e.g 20:00"}
                height={20}
                onChange={onSunToChange}
                // marginTop={28}
              />
            </div>
            <div style={{ display: "flex" }}>
              <TextInputField
                labelTitle={"Public holidays: "}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"e.g 08:00"}
                height={20}
                labelWidth={100}
                onChange={onPublicFromChange}
              />
              <TextInputField
                labelTitle={"To"}
                mini={true}
                invert={true}
                size={"65%"}
                width={"50%"}
                placeholder={"to e.g 20:00"}
                height={20}
                marginTop={28}
                onChange={onPublicToChange}
              />
            </div>
          </div>
          <div
            style={{
              height: 420,
              width: "95%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 30,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginBottom: 20,
                }}
              >
                STORE REMUNIRATION
              </div>
            </div>
            <TextInputField
              labelTitle={"Percentage:"}
              mini={true}
              invert={true}
              width={"100%"}
              size={"80%"}
              value={remuValue}
              onChange={onRemuChange}
            />
            <TextInputField
              labelTitle={"Status:"}
              mini={true}
              invert={true}
              width={"100%"}
              size={"80%"}
              value={statusValue}
              onChange={onStatusChange}
            />
          </div>
        </div>
      </div>
      {/* owner slection pop up */}
      {ownerPopUp ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
            backgroundColor: BlackAndWhite.secondary,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div style={{ position: "absolute", top: 10, left: 10 }}>
            <CloseButton label={"X"} onClick={() => setOwnerPop(false)} />
          </div>
          {ownerArray
            ? ownerArray.data.map((owner, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setOwnerObject(owner);
                      setOwnerPop(false);
                    }}
                    style={{
                      height: 200,
                      width: 300,
                      borderStyle: "solid",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      borderRadius: 8,
                    }}
                  >
                    <div style={{ width: "80%", marginBottom: 10 }}>
                      {owner.firstName}
                    </div>
                    <div style={{ width: "80%", marginBottom: 10 }}>
                      {owner.lastName}
                    </div>
                    <div style={{ width: "80%", marginBottom: 10 }}>
                      {owner.cellNumber}
                    </div>
                    <div style={{ width: "80%", marginBottom: 10 }}>
                      {owner.id}
                    </div>
                  </div>
                );
              })
            : "NO ONWERS AVALIBLE"}
        </div>
      ) : null}
    </div>
  );
};
export default AddFacilityPopUp;
