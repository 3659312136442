import React, { useState } from "react";
import FacilityItem from "../table-blocks/FacilityItem";
import { BlackAndWhite } from "../../../../themes/Colors";

const RegionDropDown = ({
  data,
  open,
  setOpen,
  title,
  setSelectedFacility,
  setOpenFacilityDisplay,
}) => {
  const [drop, setDrop] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.3s",
        marginTop: 2,
      }}
    >
      <div
        onClick={() => setDrop(drop ? false : true)}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          width: "100%",
          height: 50,
          backgroundColor: hover
            ? drop
              ? "#Faa91980"
              : "#00000075"
            : drop
            ? "#FAA91970"
            : "#00000040",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          transition: "all 0.3s",
          fontWeight: hover ? "bold" : "normal",
        }}
      >
        <div style={{ marginLeft: 20 }}> {title}</div>
        <div style={{ flex: 1 }}></div>
        <div
          style={{
            marginRight: 10,
            fontSize: 14,
            fontWeight: "bold",
            display: "flex",
          }}
        >
          <div style={{ marginRight: 5 }}>Stores:</div> {data.length}
        </div>
      </div>
      {drop
        ? data.map((store, index) => {
            return (
              <div
                style={{ width: "100%", transition: "all 0.3s" }}
                key={index}
              >
                <FacilityItem
                  onManageClick={() => {
                    setSelectedFacility(store);
                    setOpenFacilityDisplay(true);
                  }}
                  //   onDeleteClick={() => {
                  //     setSelectedFacility(store);
                  //     setOpenFacilityDisplay(true);
                  //     setIsDeleting(true);
                  //   }}
                  noUsers={true}
                  storeName={store.storeName}
                  province={store.province}
                  city={store.city}
                  type={store.type}
                  email={store.email}
                  cell={store.cellNumber}
                  owner={"Eaxmple name here"}
                />
              </div>
            );
          })
        : null}
    </div>
  );
};
export default RegionDropDown;
