import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputFieldSearch,
} from "../../../../../../../../components/atoms";
import { AddProductPopUp, LineItemCard, RecievePopUp } from "./components";

const TransferManageView = ({
  display,
  setSelectedTransfer,
  setRefresh,
  refresh,
  auth,
  setLoading,
  setMessage,
  setToastPop,
  isPos,
}) => {
  const [search, setSearch] = useState("");

  const [productsArray, setProductArray] = useState(null);

  const [stateCheck, setStateCheck] = useState(null);

  const [productsSearchArray, setProductsSearchArray] = useState(null);

  const [addProductPopUpDisplay, setAddProductPopUpDisplay] = useState(false);

  const [storeInfo, setStoreInfo] = useState(null);

  const [comment, setComment] = useState("");

  const [recievePopUp, setRecievePopUp] = useState(false);
  console.log("display data", display);
  const AddNewItemToArray = (product) => {
    let baseArray = productsArray;

    baseArray.push(product);
    setStateCheck(stateCheck ? false : true);
    // setAddProductPopUpDisplay(false);
    // getProducts();
  };

  const ReturnArray = () => {
    return productsArray;
  };

  const removeSelectedItems = ({ index }) => {
    var array = [...productsArray]; // make a separate copy of the array

    array.splice(index, 1);
    setProductArray(array);
    setStateCheck(stateCheck ? false : true);
  };

  const EditQty = ({ idIndex, qty }) => {
    const array = [...productsArray];

    //find index of item to be replaced
    const targetIndex = productsArray.findIndex(
      (f, index) => index === idIndex
    );

    //replace the object with a new one.
    array[targetIndex].qtyCard.qty = qty;
  };

  const getProducts = async () => {
    // setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsAdded",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setProductsSearchArray(respnseData.product);
        // setLoading(false);
      } else if (respnseData.message) {
        setProductsSearchArray([]);
        // setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      // setLoading(false);
    }
  };

  const UpdateAndSaveTransferDoc = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/updateTransferDoc",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            editedBy: auth.firstName + " " + auth.lastName,
            recievingStockProducts: productsArray,
            transferDocId: display.transferId,
            status: "pending",
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        // setTransferDocsArray(responseData.tansferDocs);
        setSelectedTransfer(null);
        setRefresh(refresh ? false : true);
        setLoading(false);
        setMessage(responseData.success);
        setToastPop(true);
        console.log("response", responseData);
      } else {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  const ChangetoInTransit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/updateTransferDoc",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            editedBy: auth.firstName + " " + auth.lastName,
            recievingStockProducts: productsArray,
            transferDocId: display.transferId,
            status: "in-transit",
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        // setTransferDocsArray(responseData.tansferDocs);
        setSelectedTransfer(null);
        setRefresh(refresh ? false : true);
        setLoading(false);
        setMessage(responseData.success);
        setToastPop(true);
        console.log("response", responseData);
      } else {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const GetStoreInfo = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getStoreInfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeId: display ? display.storeTransferId : "",
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setStoreInfo(responseData.storeFound);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const RecieveStockAndComment = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/AcceptTransferFromWarehouse",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            editedBy: auth.firstName + " " + auth.lastName,
            recievingStockProducts: display.productsArray,
            transferDocId: display.transferId,
            status: "complete",
            comments: comment,
            storeId: storeInfo.storeId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setToastPop(true);
        setMessage(responseData.success);
        setRefresh(refresh ? false : true);
        setLoading(false);
        setRecievePopUp(false);
        setSelectedTransfer(null);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  useEffect(() => {
    ReturnArray();
  }, [stateCheck]);
  useEffect(() => {
    if (display) {
      setProductArray(display.productsArray);
      getProducts();
      GetStoreInfo();
    } else {
      setProductArray(null);
    }
  }, [display]);
  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        zIndex: 100000000000000,
        backgroundColor: BlackAndWhite.secondary,
        height: display ? "100%" : 0,
        width: "100%",
        bottom: 0,
        left: 0,
        transition: "all 0.3s",
      }}
    >
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <CloseButton
          label={"X"}
          onClick={() => {
            setSelectedTransfer(null);
            setRefresh(refresh ? false : true);
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: 20,
          left: 75,
          backgroundColor: "#ffffff70",
          padding: 5,
        }}
      >
        <div style={{ fontSize: 20, fontWeight: "bold" }}>MANAGE TRANSFER</div>
      </div>
      <div
        style={{ position: "absolute", top: 20, right: 20, display: "flex" }}
      >
        {display ? (
          display.status === "pending" ? (
            <BaseButtons
              label={"SAVE"}
              onClick={() => UpdateAndSaveTransferDoc()}
              mini={true}
              marginTopFalse={true}
              height={40}
              borderRadius={8}
              marginRight={10}
            />
          ) : null
        ) : null}
        {display ? (
          display.status === "in-transit" ? (
            <BaseButtons
              label={"RECIEVE"}
              onClick={() => setRecievePopUp(true)}
              mini={true}
              marginTopFalse={true}
              height={40}
              borderRadius={8}
              marginRight={10}
            />
          ) : null
        ) : null}

        {display ? (
          display.status === "pending" ? (
            <BaseButtons
              label={"IN-TRANSIT"}
              onClick={() => ChangetoInTransit()}
              mini={true}
              marginTopFalse={true}
              height={40}
              borderRadius={8}
              //   marginRight={10}
            />
          ) : null
        ) : null}
      </div>
      <div
        style={{
          flex: 1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 80,
            textAlign: "left",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 10,
              marginLeft: 80,
            }}
          >
            <div>DATE ADDED</div>
            <div>{display ? display.dateAdded : "loading"}</div>
          </div>
          <div style={{ flex: 1 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 10,
              width: 150,

              textAlign: "center",
              marginRight: 20,
            }}
          >
            <div>CREATED BY</div>
            <div>{display ? display.createdBy : "loading"}</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 20,
            textAlign: "left",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 10,
              marginLeft: 80,
            }}
          >
            <div>FROM</div>
            <div>{display ? display.from : "loading"}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 10,
              marginLeft: 80,
            }}
          >
            <div>To</div>
            <div>
              {display
                ? storeInfo
                  ? storeInfo.storeName
                  : "loading"
                : "loading"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 10,
              //   width: 150,

              textAlign: "left",
              marginRight: 20,
              marginLeft: 20,
            }}
          >
            <div>TRANSFER ID</div>
            <div style={{ fontSize: 14 }}>
              {display ? display.transferId : "loading"}
            </div>
          </div>

          <div style={{ flex: 1 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 10,
              width: 150,

              textAlign: "center",
              marginRight: 20,
            }}
          >
            <div>STATUS</div>
            <div>{display ? display.status : "loading"}</div>
          </div>
        </div>
        <div
          style={{
            width: "90%",
            // minHeight: 400,
            // maxHeight: 2000,
            borderRadius: 8,
            marginTop: 20,
            // overflow: "hidden",
            padding: 20,
            backgroundColor: BlackAndWhite.secondary,
            WebkitBoxShadow: "0px 0px 7px 2px #00000050",
            marginBottom: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {/* //title on top */}
          <div style={{ fontSize: 18, width: "100%", display: "flex" }}>
            TRANSFER LINE ITEMS AND QTY: <div style={{ flex: 1 }} />{" "}
            {display ? (
              display.status !== "pending" ? null : (
                <BaseButtons
                  mini={true}
                  label={"ADD PRODUCT"}
                  borderRadius={10}
                  // marginRight={20}
                  height={35}
                  // marginTopFalse={true}
                  marginTop={-5}
                  onClick={() => setAddProductPopUpDisplay(true)}
                />
              )
            ) : null}
          </div>
          {productsArray
            ? productsArray.map((prdouct, index) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: index === 0 ? 20 : 0,
                      marginBottom:
                        index === productsArray.length - 1 ? 20 : 10,
                    }}
                    key={index}
                  >
                    <LineItemCard
                      index={index}
                      EditQty={EditQty}
                      data={prdouct}
                      statusCheck={
                        display
                          ? display.status === "pending"
                            ? true
                            : false
                          : true
                      }
                      onRemoveClick={() => removeSelectedItems({ index })}
                    />
                  </div>
                );
              })
            : "loading"}
        </div>
        <AddProductPopUp
          display={addProductPopUpDisplay}
          setAddProductPopUpDisplay={setAddProductPopUpDisplay}
          productsSearchArray={productsSearchArray}
          AddNewItemToArray={AddNewItemToArray}
          search={search}
          setSearch={setSearch}
          productsArray={productsArray}
        />
        {isPos ? (
          <RecievePopUp
            comment={comment}
            setComment={setComment}
            display={recievePopUp}
            setRecievePopUp={setRecievePopUp}
            RecieveStockAndComment={RecieveStockAndComment}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TransferManageView;
