import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const TabCategoryOptions = ({ title, onClick, selected }) => {
  return (
    <div
      onClick={onClick}
      style={{
        cursor: "pointer",
        width: 140,
        height: 40,
        borderRadius: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: selected
          ? BlackAndWhite.primary
          : BlackAndWhite.secondary,
        color: selected ? BlackAndWhite.secondary : BlackAndWhite.primary,
      }}
    >
      {title}
    </div>
  );
};

export default TabCategoryOptions;
