import React, { useState } from "react";
import { BaseButtons } from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import Filter from "./filter/Filter";

const TopTabController = ({
  setFilterState,
  selectedDisplay,
  setSelectedDisplay,
  onHelpClick,
}) => {
  const [helpHover, setHelpHover] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        height: 55,
        minHeight: 60,
        maxHeight: 60,

        borderWidth: 2,
        display: "flex",
        alignItems: "center",
        backgroundColor: BlackAndWhite.secondary,
        borderBottomStyle: "solid",
        borderColor: "#00000030",
      }}
    >
      <div style={{ width: 10 }} />
      <BaseButtons
        mini={true}
        marginTopFalse={true}
        label={"CUSTOMER"}
        borderRadius={8}
        height={35}
        size={120}
        onClick={() => setSelectedDisplay("customer")}
        invert={selectedDisplay === "customer" ? false : true}
      />
      <div style={{ width: 10 }} />
      <BaseButtons
        mini={true}
        marginTopFalse={true}
        label={"SYSTEM BUGS"}
        borderRadius={8}
        height={35}
        size={120}
        onClick={() => setSelectedDisplay("bugs")}
        invert={selectedDisplay === "bugs" ? false : true}
      />
      <div style={{ width: 10 }} />
      <BaseButtons
        mini={true}
        marginTopFalse={true}
        label={"PRESCRIPTIONS"}
        borderRadius={8}
        height={35}
        size={130}
        onClick={() => setSelectedDisplay("prescriptions")}
        invert={selectedDisplay === "prescriptions" ? false : true}
      />

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        <Filter filterName={"FILTER"} setScreen={setFilterState} />
      </div>
      <div
        onClick={onHelpClick}
        onMouseOver={() => setHelpHover(true)}
        onMouseOut={() => setHelpHover(false)}
        style={{
          marginRight: 10,
          height: 35,
          width: 35,
          backgroundColor: helpHover ? "#00000050" : BlackAndWhite.primary,
          borderRadius: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: BlackAndWhite.secondary,
          fontSize: 20,
          transition: "all 0.2s",
          cursor: "pointer",
        }}
      >
        ?
      </div>
    </div>
  );
};
export default TopTabController;
