import React, { useState, useContext } from "react";
import useWindowDimensions from "../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../themes/Colors";
import { BaseButtons, CloseButton, TextInputField } from "../atoms";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import QuestionButton from "../../modules/customer-portal/components/medical-history/components/button/QuestionButton";

const LoginPopUp = ({
  onBackClick,
  login,
  onRegClick,
  onSubmitClick,
  error,
  setError,
  onForgotClick,
}) => {
  const { width, height } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  //user login info
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(true);

  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const HandleSubmit = async () => {
    setLoading(true);
    setError("Loading");
    // authentication or login function heal
    //this code is used for Ui test
    //
    // "https://merry-jane-api.onrender.com/user/login"
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: username.toLowerCase(),
            password: password,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.message === "logged in") {
        setLoading(false);
        auth.login();
        auth.customerLoadData({
          acceptedTandC: respnseData.acceptedTandC,
          cellNumber: respnseData.cellNumber,
          dateOfBirth: respnseData.dateOfBirth,
          email: respnseData.email,
          firstName: respnseData.firstName,
          gender: respnseData.gender,
          idDoc: respnseData.idDoc,
          lastName: respnseData.lastName,
          message: respnseData.message,
          street: respnseData.street,
          suburb: respnseData.suburb,
          town: respnseData.town,
          userId: respnseData.userId,
          rewardPoints: respnseData.rewardPoints,
          profileProgress: respnseData.profileProgress,
          status: respnseData.status,
          type: respnseData.type,
          signedOn: respnseData.dateCreated,
          agreement: respnseData.agreement,
        });
        navigate("/customer");
      } else {
        setError("invalid credentials");
        setLoading(false);
        console.log("error data is missing");
      }
    } catch (err) {
      setError("invalid credentials");
    }
  };

  const CheckLogin = () => {
    if (username !== "" && password !== "") {
      HandleSubmit();
    } else {
      setError("your missing your credentials");
      console.log("error", "2");
    }
  };

  return (
    <div
      style={{
        height: login ? height : 0,
        width: width - 80,
        // backgroundColor: "#00000066",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.5s",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          height: width > 899 ? "80%" : "100%",
          width: width > 899 ? 500 : height >= 550 ? 400 : 300,
          backgroundColor: "#000000cc",
          borderRadius: 10,
          // borderStyle: "solid",
          // borderWidth: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div style={{ position: "absolute", left: 20, top: 20 }}>
          {error !== "" ? null : (
            <CloseButton
              width={80}
              onClick={onBackClick}
              height={30}
              invert={true}
            />
          )}
        </div>
        <h1
          style={{
            marginTop: 30,
            color: BlackAndWhite.secondary,
            fontFamily: "Montserrat",
            textAlign: "center",
            lineHeight: 1.6,
          }}
        >
          LOGIN
        </h1>
        {error !== "" ? (
          <>
            <div
              style={{
                color: BlackAndWhite.secondary,
                fontFamily: "Montserrat",
                textAlign: "center",
                lineHeight: 1.6,
                fontSize: 30,
                width: "90%",
                marginTop: 80,
                marginBottom: 10,
              }}
            >
              {error}
            </div>
            <BaseButtons
              width={"80%"}
              label={"OKAY"}
              onClick={() => setError("")}
              invert={true}
            />
          </>
        ) : (
          <>
            <TextInputField
              labelTitle={"EMAIL"}
              onChange={(e) => setUsername(e.target.value)}
              width={width - 80}
            />
            <div
              style={{
                position: "relative",
                width: width > 899 ? "80%" : "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextInputField
                labelTitle={"PASSWORD"}
                onChange={(event) => setPassword(event.target.value)}
                width={width - 60}
                showPassword={showPassword}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: -30,
                  right: width > 899 ? 80 : height >= 550 ? 45 : 10,
                }}
              >
                <div
                  onClick={onForgotClick}
                  style={{
                    color: BlackAndWhite.secondary,
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                >
                  FORGOT PASSWORD?
                </div>
              </div>

              <div
                onClick={() => setShowPassword(showPassword ? false : true)}
                style={{
                  position: "absolute",
                  top: "60%",
                  right: width > 899 ? 90 : height >= 550 ? 45 : 10,
                }}
              >
                {showPassword ? (
                  <img
                    alt="logo"
                    src={require("../../images/material-icons/visible.png")}
                    style={{ height: 30 }}
                  />
                ) : (
                  <img
                    alt="logo"
                    src={require("../../images/material-icons/hide.png")}
                    style={{ height: 30 }}
                  />
                )}
              </div>
            </div>

            <div style={{ flex: 0.9 }} />

            <BaseButtons
              width={"80%"}
              label={"SUBMIT"}
              invert={true}
              onClick={() => CheckLogin()}
            />

            <BaseButtons
              width={"80%"}
              label={" REGISTER"}
              onClick={onRegClick}
              invert={true}
            />
            <div style={{ height: 40 }} />
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPopUp;
