const errorSpec = [
  {
    code: "h01",
    title: "Your missing information",
    message:
      "Your profile history is still incomplete. Please make sure to fill out all necessary information and consent forms to expedite the processing of your upcoming prescription.",
    messagePart2: "this process can take up to 48 hrs",
  },
  {
    code: "h02",
    title: "Prescription has expired",
    message:
      "Your prescription has expired, please request a new if you are in need. Dont forget the process can take up to 48 hrs",
    messagePart2: "",
  },
  {
    code: "P01",
    title: "Could not reresh data",
    message:
      "your refresh did not work, please wait for a few minutes and then try again",
    messagePart2: "",
  },
];

export default errorSpec;
