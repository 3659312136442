import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const FacilityItem = ({
  storeName,
  province,
  type,
  city,
  email,
  cell,
  onManageClick,
  onDeleteClick,
}) => {
  const [hover, setHover] = useState(false);
  const [manageHover, setManageHover] = useState(false);
  const [dleeteHover, setDeleteHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: "98%",
        height: 50,
        // borderStyle: "solid",
        display: "flex",
        fontSize: 12,
        backgroundColor: hover ? "#faa91966" : BlackAndWhite.secondary,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {storeName}
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {province}
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {type}
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {city}
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {email}
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {cell}
      </div>
      <div
        style={{
          flex: 0.3,

          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          onClick={onDeleteClick}
          onMouseOver={() => setDeleteHover(true)}
          onMouseOut={() => setDeleteHover(false)}
          style={{
            backgroundColor: dleeteHover
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
            borderRadius: 4,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
            color: dleeteHover
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
            cursor: "pointer",
          }}
        >
          delete
        </div>
        <div style={{ width: 20 }} />
        <div
          onClick={onManageClick}
          onMouseOver={() => setManageHover(true)}
          onMouseOut={() => setManageHover(false)}
          style={{
            backgroundColor: manageHover
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
            borderRadius: 4,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
            color: manageHover
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
            cursor: "pointer",
          }}
        >
          Manage
        </div>
        <div style={{ width: 20 }} />
      </div>
    </div>
  );
};

export default FacilityItem;
