import React from "react";

const AdBanner = ({ width, img }) => {
  return (
    <div
      style={{
        height: width > 899 ? 400 : 300,
        width: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: width > 899 ? 500 : 300,
          height: width > 899 ? 600 : 400,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          alt="loading"
          src={img}
          style={{
            // position: "absolute",
            zIndex: 0,
            width: width > 899 ? "100%" : "100%",
            // height: width > 899 ? "100%" : null,
            // opacity: 0.4,
            // left: 0,
          }}
        />
      </div>
    </div>
  );
};

export default AdBanner;
