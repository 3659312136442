import React, { useState } from "react";
import { BaseButtons, CloseButton, TextInputField } from "../atoms";
import { BlackAndWhite } from "../../themes/Colors";
import QuestionaireDropDown from "../../modules/customer-portal/components/medical-history/components/questionaire-drop-down/QuestionareDropDown";
import QuestionButton from "../../modules/customer-portal/components/medical-history/components/button/QuestionButton";

const AdminForm = ({
  onCloseClick,
  firstName,
  lastName,
  idNumber,
  email,
  password,
  onFirstNameChange,
  onLastNameChange,
  onIdNumChange,
  onEmailChange,
  isBussinesClick,
  onSubmitClick,
  onPasswordChange,
  onAccountNameChange,
  setProvince,
  province,
  onCellChange,
}) => {
  const [confirmPassword, setConfirmPassword] = useState("none");
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            flex: 0.7,
            height: "100%",
            position: "relative",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              height: "100%",
            }}
          >
            <TextInputField
              labelTitle={"First name:"}
              invert={true}
              value={firstName}
              onChange={onFirstNameChange}
              mini={true}
            />
            <TextInputField
              labelTitle={"Last name:"}
              invert={true}
              value={lastName}
              onChange={onLastNameChange}
              mini={true}
            />
            <div style={{ position: "relative" }}>
              <TextInputField
                labelTitle={"Id-Num / Passport:"}
                invert={true}
                onChange={onIdNumChange}
                value={idNumber}
                mini={true}
              />
            </div>

            <TextInputField
              labelTitle={"Email:"}
              invert={true}
              onChange={onEmailChange}
              value={email}
              mini={true}
            />
            <TextInputField
              labelTitle={"Cell number:"}
              invert={true}
              onChange={onCellChange}
              mini={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "50%",
              height: "100%",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginBottom: 15 }}>Select a province:</div>
              <QuestionButton
                title={
                  province === "none"
                    ? "Click here to select Province"
                    : province
                }
                width={260}
                height={50}
                borderRadius={1}
                backgroundColor={BlackAndWhite.primary}
                borderOn={true}
                color={BlackAndWhite.secondary}
                onClick={
                  province === "none"
                    ? () => setProvince("")
                    : () => setProvince("")
                }
              />
              <div
                style={{
                  position: "absolute",
                  top: -0,
                  width: province === "" ? 300 : 0,
                  left: -15,
                  zIndex: 100,
                  overflow: "hidden",
                  height: province === "" ? null : 0,
                  transition: "width 0.5s",
                  backgroundColor: BlackAndWhite.primary,
                  borderRadius: 10,
                  paddingBottom: 10,
                }}
              >
                <QuestionaireDropDown
                  setItem={setProvince}
                  data={[{ text: "ALL" }, { text: "Merry-Jane only" }]}
                />
              </div>
            </div>
            <TextInputField
              labelTitle={"Account Num:"}
              invert={true}
              onChange={onAccountNameChange}
              mini={true}
            />
            <TextInputField
              labelTitle={"Password:"}
              value={password}
              invert={true}
              onChange={onPasswordChange}
              mini={true}
            />
            <TextInputField
              labelTitle={"Confirm password:"}
              invert={true}
              onChange={(e) => setConfirmPassword(e.target.value)}
              mini={true}
            />
          </div>
          <div style={{ position: "absolute", top: 20, left: 20 }}>
            <CloseButton label={"X"} onClick={onCloseClick} />
          </div>
        </div>
        <div
          style={{
            flex: 0.3,
            backgroundColor: BlackAndWhite.primary,
            borderTopLeftRadius: 20,
            height: "99%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 60,
              display: "flex",
              fontSize: 22,
              alignItems: "center",
              color: BlackAndWhite.secondary,
            }}
          >
            <div style={{ marginLeft: 20 }}>Create a Sub-admin user</div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              color: BlackAndWhite.secondary,
            }}
          >
            <div style={{ width: "80%", textAlign: "left", marginTop: 20 }}>
              1. Please fill out all the information on the left starting with
              first name.
            </div>
            <div style={{ width: "80%", textAlign: "left", marginTop: 20 }}>
              2. Make sure the email address is correct as the invite for the
              User will be sent to them.
            </div>
            <div style={{ width: "80%", textAlign: "left", marginTop: 20 }}>
              3. A admin user will have access to the same screen as admin just
              not all the permission to make edits and wont be able to view
              stats
            </div>

            <BaseButtons
              label={"SUBMIT"}
              disable={
                firstName.length >= 2 &&
                lastName.length >= 2 &&
                email.length >= 6 &&
                confirmPassword === password
                  ? false
                  : true
              }
              invert={true}
              onClick={onSubmitClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminForm;
