import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const ItemDisplayCard = ({ value, title, height }) => {
  return (
    <div
      style={{
        height: height ? height : "100%",
        width: 120,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: BlackAndWhite.primary,
      }}
    >
      <div
        style={{
          fontSize: value ? (value.length >= 10 ? 22 : 26) : 26,
          fontWeight: "bold",
          marginBottom: 5,
          textAlign: "center",
          width: value ? (value.length > 10 ? 200 : null) : null,
        }}
      >
        {value}
      </div>
      <div
        style={{
          fontSize: 14,
          fontWeight: "bold",
          marginBottom: 5,
          textAlign: "center",
          width: 160,
          color: "#00000090",
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default ItemDisplayCard;
