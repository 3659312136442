import React, { useState, useEffect } from "react";
import { BaseButtons, TextInputField } from "../../components/atoms";

const Banner = ({
  websiteData,
  edit,
  setEdit,
  setLoading,
  setRefresh,
  refresh,
}) => {
  const [bannerImage, setBannerImage] = useState("");

  const [logoIcon, setLogoIcon] = useState("");

  const UpdateHandler = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/website/updateMainBanner",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: "664da26f4ecd04a28bda5163",
            bannerLink: bannerImage,
            logoLink: logoIcon,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.message === "banner information updated") {
        setRefresh(refresh ? false : true);
        setEdit(false);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (websiteData) {
      setBannerImage(websiteData.data.landingPage.bannerItem.bannerImageLink);
      setLogoIcon(websiteData.data.landingPage.bannerItem.displayIcon);
    }
  }, [edit, websiteData]);
  return (
    <div
      style={{
        height: 250,
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img style={{ height: "100%", width: "100%" }} src={bannerImage} />
      <div style={{ position: "absolute", bottom: -100 }}>
        <img src={logoIcon} alt="logo" style={{ height: 300 }} />
      </div>
      <div
        style={{
          position: "absolute",
          right: edit ? 0 : 10,
          bottom: edit ? 0 : 10,
        }}
      >
        {edit ? (
          <div style={{ backgroundColor: "#FFFFFF95", padding: 10 }}>
            <TextInputField
              labelTitle={"banner image link"}
              mini={true}
              invert={true}
              onChange={(e) => setBannerImage(e.target.value)}
            />
            <TextInputField
              labelTitle={"logo link"}
              mini={true}
              invert={true}
              onChange={(e) => setLogoIcon(e.target.value)}
            />
            <div style={{ display: "flex" }}>
              <BaseButtons
                label={"CANCEL"}
                invert={false}
                onClick={() => setEdit(false)}
                marginRight={10}
              />
              <BaseButtons
                label={"SUBMIT"}
                invert={false}
                onClick={() => UpdateHandler()}
              />
            </div>
          </div>
        ) : (
          <BaseButtons
            label={"EDIT"}
            invert={true}
            onClick={() => setEdit(true)}
            size={120}
          />
        )}
      </div>
    </div>
  );
};

export default Banner;
