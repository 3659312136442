import React from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";

const MessageDisplayItem = ({ isUser, name, message, dateSent }) => {
  return (
    <div
      style={{
        height: "99%",
        minWidth: 150,

        display: "flex",
        flexDirection: "column",
        justifyContent: isUser ? "flex-end" : "flex-start",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          //   height: "70%",
          width: "90%",
          //   overflow: "hidden",
          borderRadius: 4,
          WebkitBoxShadow: "0px 0px 5px 1px #00000073",
          display: "flex",
          flexDirection: "column",
          backgroundColor: isUser
            ? BlackAndWhite.secondary
            : BlackAndWhite.blue50,
          marginBottom: isUser ? 10 : -null,
          marginTop: !isUser ? 10 : -null,
          justifyContent: "flex-start",
          alignItems: "center",
          paddingBottom: 10,
          position: "relative",
        }}
      >
        <div
          style={{
            width: "90%",
            fontSize: 12,
            marginTop: 5,
            fontWeight: "bold",
            display: "flex",
          }}
        >
          {name}
        </div>
        <div
          style={{
            width: "100%",
            fontSize: 8,
            marginTop: 5,
            // fontWeight: "bold",
            display: "flex",
            position: "absolute",
            top: isUser ? -20 : null,
            bottom: !isUser ? -13 : null,
            textAlign: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          {dateSent}
        </div>
        <div style={{ width: "90%", fontSize: 12, marginTop: 5 }}>
          {message}
        </div>
      </div>
    </div>
  );
};

export default MessageDisplayItem;
