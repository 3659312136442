import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const Page2 = ({ menuData, setSelectedMenuItem }) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {menuData.length >= 2
        ? menuData.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => setSelectedMenuItem(item)}
                onMouseOver={() => setHoverIndex(index)}
                onMouseOut={() => setHoverIndex(null)}
                style={{
                  cursor: "pointer",
                  height: 120,
                  width: 250,
                  borderStyle: "solid",
                  margin: 5,
                  backgroundColor:
                    hoverIndex === index
                      ? "#FAA91940"
                      : BlackAndWhite.secondary,
                  borderRadius: 8,
                  borderWidth: 0.5,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ fontSize: 18, fontWeight: "bold", marginLeft: 5 }}
                >
                  {item.productName}
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ fontSize: 14, marginLeft: 5 }}>
                    {item.strain}
                  </div>
                  <div style={{ fontSize: 15, marginLeft: 5 }}>
                    {item.price}
                  </div>
                </div>
                <div style={{ fontSize: 12, marginLeft: 5 }}>{item.type}</div>
                <div style={{ fontSize: 10, marginLeft: 5 }}>
                  {item.description}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default Page2;
