import React, { useEffect, useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import { CloseButton } from "../../../../components/atoms";
import { LoadingPopUp, NewPrescriptionCard } from "../../../../components";

const PatientPopUp = ({
  display,
  onCloseClick,
  loading,
  setLoading,
  setSelectedPatient,
  setPatientPop,
  width,
}) => {
  const [data, setData] = useState(null);
  const [patientInfo, setPatientInfo] = useState(null);
  const [medicalCheck, setMedicalCheck] = useState(null);
  const [displayInfo, setDisplayInfo] = useState(null);
  const [selectedPrescription, setSelectedPrescription] = useState("Approved");

  const checkForm = (sec) => {
    if (sec.section === "consent") {
      return BlackAndWhite.primary;
    } else if (sec.section === "lifestyle") {
      return BlackAndWhite.primary;
    } else if (sec.section === "medical history") {
      return BlackAndWhite.primary;
    } else if (sec.section === "occupational") {
      return BlackAndWhite.primary;
    } else {
      return BlackAndWhite.secondary;
    }
  };

  useEffect(() => {
    if (display) {
      setDisplayInfo(null);
      setPatientInfo(null);
      fetch("https://merry-jane-api.onrender.com/user/getPatintientInfo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          patientId: display.patientId || display.idDoc,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((resData) => {
          setPatientInfo(resData.data);
        });
      fetch(
        "https://merry-jane-api.onrender.com/medicalHistory/getMedicalHistoryByUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: display.patientId || display.idDoc,
          }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((resData) => {
          setMedicalCheck(resData.lifestyleData);
        });
    }
  }, [display]);
  useEffect(() => {
    if (medicalCheck) {
      setDisplayInfo(medicalCheck[2]);
    }
  }, [medicalCheck]);

  // console.log("response", patientInfo);
  useEffect(() => {
    if (display) {
      fetch(
        "https://merry-jane-api.onrender.com/prescription/fetchUserPrescriptions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: display.patientId || display.idDoc,
          }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((resData) => {
          setData(resData);
        });
    }
  }, [display]);
  return (
    <div
      style={{
        position: "absolute",
        overflow: "hidden",
        width: "100%",
        height: display ? "100%" : 0,
        transition: "all 0.5s",
        top: 0,
        opacity: display ? 1 : 0,
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 50000000000000000,
      }}
    >
      <div
        style={{
          position: "relative",
          borderStyle: "solid",
          height: "100%",
          width: "100%",
          display: "flex",
          borderWidth: 0.5,
          borderRadius: width > 899 ? 20 : null,
          overflow: "hidden",
          flexDirection: width > 899 ? "row" : "column",
        }}
      >
        {/* section one */}
        <div
          style={{
            flex: 0.35,
            overflow: width > 899 ? "hidden" : "scroll",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: BlackAndWhite.primary,
            color: BlackAndWhite.secondary,
          }}
        >
          <div style={{ fontSize: 24, marginTop: 22, fontWeight: "bold" }}>
            Prescription info
          </div>
          <div style={{ fontSize: 18, marginTop: 22, width: "80%" }}>
            Name :{" "}
            {patientInfo
              ? patientInfo.firstName + " " + patientInfo.lastName
              : "loading"}
          </div>
          <div style={{ fontSize: 18, marginTop: 22, width: "80%" }}>
            status : {display ? display.approved : "loading"}
          </div>
          <div style={{ fontSize: 16, marginTop: 22, width: "80%" }}>
            requested : {display ? display.date : "loading"}
          </div>
          <div style={{ fontSize: 16, marginTop: 22, width: "80%" }}>
            diagnosis : {display ? display.diagnosis : "loading"}
          </div>
          <div style={{ fontSize: 16, marginTop: 22, width: "80%" }}>
            precription : {display ? display.prescription : "loading"}
          </div>
          <div
            style={{
              fontSize: 24,
              marginTop: 22,
              fontWeight: "bold",
              width: "80%",
              textAlign: "left",
            }}
          >
            Contact informtion
          </div>
          <div
            style={{
              fontSize: 15,
              marginTop: 22,
              width: "80%",
              textAlign: "left",
            }}
          >
            CELL NUMBER:
          </div>
          <div
            style={{
              fontSize: 17,
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            {patientInfo ? patientInfo.cellNumber : "loading"}
          </div>
          <div
            style={{
              fontSize: 15,
              marginTop: 22,
              width: "80%",
              textAlign: "left",
            }}
          >
            EMAIL:
          </div>
          <div
            style={{
              fontSize: 17,
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            {patientInfo ? patientInfo.email : "loading"}
          </div>
          <div
            style={{
              fontSize: 15,
              marginTop: 22,
              width: "80%",
              textAlign: "left",
            }}
          >
            DATE OF BIRTH:
          </div>
          <div
            style={{
              fontSize: 17,
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            {patientInfo ? patientInfo.dateOfBirth : "loading"}
          </div>
          <div
            style={{
              fontSize: 15,
              marginTop: 22,
              width: "80%",
              textAlign: "left",
            }}
          >
            GENDER:
          </div>
          <div
            style={{
              fontSize: 17,
              marginTop: 10,
              width: "80%",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            {patientInfo ? patientInfo.gender : "loading"}
          </div>
        </div>
        <div
          style={{
            flex: 0.65,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: 60,
              width: "100%",

              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              fontSize: width > 899 ? null : 12,
              marginTop: width > 899 ? null : 5,
            }}
          >
            {medicalCheck
              ? medicalCheck.map((form, index) => {
                  return form.section === "consent" ? null : (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: displayInfo
                          ? displayInfo.section === form.section
                            ? BlackAndWhite.primary
                            : BlackAndWhite.secondary
                          : null,
                        color: displayInfo
                          ? displayInfo.section === form.section
                            ? BlackAndWhite.secondary
                            : BlackAndWhite.primary
                          : null,
                        transition: "all 0.3s",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      onClick={() => setDisplayInfo(form)}
                      key={index}
                    >
                      {form.section}
                    </div>
                  );
                })
              : null}
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: displayInfo
                  ? displayInfo === "Prescriptions"
                    ? BlackAndWhite.primary
                    : BlackAndWhite.secondary
                  : null,
                color: displayInfo
                  ? displayInfo === "Prescriptions"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary
                  : null,
                transition: "all 0.3s",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setDisplayInfo("Prescriptions")}
            >
              Prescriptions
            </div>
          </div>
          <div
            style={{
              height: displayInfo === "Prescriptions" ? 30 : 0,
              width: "100%",

              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              transition: "all 0.2s",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "25%",
                backgroundColor: displayInfo
                  ? selectedPrescription === "Approved"
                    ? BlackAndWhite.primary
                    : BlackAndWhite.secondary
                  : null,
                color: displayInfo
                  ? selectedPrescription === "Approved"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary
                  : null,
                transition: "all 0.3s",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 13,
              }}
              onClick={() => setSelectedPrescription("Approved")}
            >
              Approved
            </div>
            <div
              style={{
                height: "100%",
                width: "25%",
                backgroundColor: displayInfo
                  ? selectedPrescription === "Pending"
                    ? BlackAndWhite.primary
                    : BlackAndWhite.secondary
                  : null,
                color: displayInfo
                  ? selectedPrescription === "Pending"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary
                  : null,
                transition: "all 0.3s",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 13,
              }}
              onClick={() => setSelectedPrescription("Pending")}
            >
              Pending
            </div>
            <div
              style={{
                height: "100%",
                width: "25%",
                backgroundColor: displayInfo
                  ? selectedPrescription === "Declined"
                    ? BlackAndWhite.primary
                    : BlackAndWhite.secondary
                  : null,
                color: displayInfo
                  ? selectedPrescription === "Declined"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary
                  : null,
                transition: "all 0.3s",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 13,
              }}
              onClick={() => setSelectedPrescription("Declined")}
            >
              Declined
            </div>
            <div
              style={{
                height: "100%",
                width: "25%",
                backgroundColor: displayInfo
                  ? selectedPrescription === "Expired"
                    ? BlackAndWhite.primary
                    : BlackAndWhite.secondary
                  : null,
                color: displayInfo
                  ? selectedPrescription === "Expired"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary
                  : null,
                transition: "all 0.3s",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 13,
              }}
              onClick={() => setSelectedPrescription("Expired")}
            >
              Expired
            </div>
          </div>
          <div style={{ flex: 1, overflow: "scroll" }}>
            {displayInfo ? (
              <>
                {displayInfo.section === "lifestyle" ? (
                  <div
                    style={{ width: "100%", height: "100%", display: "flex" }}
                  >
                    <div
                      style={{
                        width: "50%",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Patient weight:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer1}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Patient Height:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer2}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Smoking habits:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer3}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Drinking habits:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer4}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        How often the patinet drinks:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer5}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Exercise habits:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer6}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Sleeping habits:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer7}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Eating habits:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer8}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Stress levels:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer9}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Social Habits:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer10}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Screen Time habits:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer11}
                      </div>
                    </div>
                  </div>
                ) : null}
                {displayInfo.section === "consent" ? <div>consent</div> : null}
                {displayInfo.section === "medical history" ? (
                  <div
                    style={{ width: "100%", height: "100%", display: "flex" }}
                  >
                    <div
                      style={{
                        width: "50%",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Past medical procedures / surgeries:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer1}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Recovering from medical procedures / surgeries:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer2}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Any prescribed medicine:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer3}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Family medical condition history:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer4}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Major / Chronic conditions:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer5}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        condition Info:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer6}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Other conditions:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer7}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        More Info about condition:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer8}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          marginLeft: 20,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        Addictions:
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: 23,
                          fontSize: 16,
                        }}
                      >
                        Answer : {displayInfo.answer9}
                      </div>
                    </div>
                  </div>
                ) : null}
                {displayInfo.section === "occupational" ? (
                  <div style={{ width: "100%", height: "100%" }}>
                    <div
                      style={{
                        marginTop: 20,
                        marginLeft: 20,
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                    >
                      Employment status:
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: 23,
                        fontSize: 16,
                      }}
                    >
                      Answer : {displayInfo.answer1}
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                    >
                      Industry :
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: 23,
                        fontSize: 16,
                      }}
                    >
                      {" "}
                      Answer : {displayInfo.answer2}
                    </div>
                  </div>
                ) : null}
                {displayInfo === "Prescriptions" ? (
                  <>
                    {selectedPrescription === "Approved" ? (
                      data ? (
                        data.data.map((prescription, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <NewPrescriptionCard
                                width={width}
                                patientNo={
                                  prescription ? prescription.id : "loading"
                                }
                                patientId={
                                  prescription
                                    ? prescription.patientId
                                    : "loading"
                                }
                                doctor={
                                  prescription ? prescription.doctor : "loading"
                                }
                                prescriptionNo={
                                  prescription
                                    ? prescription.prescriptionNum
                                    : "loading"
                                }
                                createdDate={
                                  prescription ? prescription.date : "loading"
                                }
                                validUntil={
                                  prescription
                                    ? prescription.validUntil
                                    : "loading"
                                }
                                status={
                                  prescription
                                    ? prescription.approved
                                    : "loading"
                                }
                                diagonosis={
                                  prescription
                                    ? prescription.diagnosis
                                    : "loading"
                                }
                                prescribed={
                                  prescription
                                    ? prescription.prescription
                                    : "loading"
                                }
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            marginTop: 50,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          No History
                        </div>
                      )
                    ) : null}
                    {selectedPrescription === "Pending" ? (
                      data ? (
                        data.dataPending.map((prescription, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <NewPrescriptionCard
                                patientNo={
                                  prescription ? prescription.id : "loading"
                                }
                                patientId={
                                  prescription
                                    ? prescription.patientId
                                    : "loading"
                                }
                                doctor={
                                  prescription ? prescription.doctor : "loading"
                                }
                                prescriptionNo={
                                  prescription
                                    ? prescription.prescriptionNum
                                    : "loading"
                                }
                                createdDate={
                                  prescription ? prescription.date : "loading"
                                }
                                validUntil={
                                  prescription
                                    ? prescription.validUntil
                                    : "loading"
                                }
                                status={
                                  prescription
                                    ? prescription.approved
                                    : "loading"
                                }
                                diagonosis={
                                  prescription
                                    ? prescription.diagnosis
                                    : "loading"
                                }
                                prescribed={
                                  prescription
                                    ? prescription.prescription
                                    : "loading"
                                }
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            marginTop: 50,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          No History
                        </div>
                      )
                    ) : null}
                    {selectedPrescription === "Declined" ? (
                      data ? (
                        data.dataDeclined.map((prescription, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <NewPrescriptionCard
                                width={width}
                                patientNo={
                                  prescription ? prescription.id : "loading"
                                }
                                patientId={
                                  prescription
                                    ? prescription.patientId
                                    : "loading"
                                }
                                doctor={
                                  prescription ? prescription.doctor : "loading"
                                }
                                prescriptionNo={
                                  prescription
                                    ? prescription.prescriptionNum
                                    : "loading"
                                }
                                createdDate={
                                  prescription ? prescription.date : "loading"
                                }
                                validUntil={
                                  prescription
                                    ? prescription.validUntil
                                    : "loading"
                                }
                                status={
                                  prescription
                                    ? prescription.approved
                                    : "loading"
                                }
                                diagonosis={
                                  prescription
                                    ? prescription.diagnosis
                                    : "loading"
                                }
                                prescribed={
                                  prescription
                                    ? prescription.prescription
                                    : "loading"
                                }
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            marginTop: 50,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          No History
                        </div>
                      )
                    ) : null}
                    {selectedPrescription === "Expired" ? (
                      data ? (
                        data.dataExpired.map((prescription, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <NewPrescriptionCard
                                width={width}
                                patientNo={
                                  prescription ? prescription.id : "loading"
                                }
                                patientId={
                                  prescription
                                    ? prescription.patientId
                                    : "loading"
                                }
                                doctor={
                                  prescription ? prescription.doctor : "loading"
                                }
                                prescriptionNo={
                                  prescription
                                    ? prescription.prescriptionNum
                                    : "loading"
                                }
                                createdDate={
                                  prescription ? prescription.date : "loading"
                                }
                                validUntil={
                                  prescription
                                    ? prescription.validUntil
                                    : "loading"
                                }
                                status={
                                  prescription
                                    ? prescription.approved
                                    : "loading"
                                }
                                diagonosis={
                                  prescription
                                    ? prescription.diagnosis
                                    : "loading"
                                }
                                prescribed={
                                  prescription
                                    ? prescription.prescription
                                    : "loading"
                                }
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            marginTop: 50,
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          No History
                        </div>
                      )
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
        <div style={{ position: "absolute", top: 20, left: 20 }}>
          <CloseButton
            label={"X"}
            onClick={() => {
              setSelectedPatient(null);
              setPatientPop(false);
              setDisplayInfo(medicalCheck[0]);
            }}
          />
        </div>
        <LoadingPopUp display={patientInfo ? false : true} />
      </div>
    </div>
  );
};

export default PatientPopUp;
