import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import { WeekdayButton } from "./micro";

const MultiSelectorDate = ({
  day,
  setDay,
  month,
  setMonth,
  dayDate,
  setDayDate,
  year,
}) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState("");
  const [animate, setAnimate] = useState(false);
  const [select, setSelect] = useState("");
  useEffect(() => {
    setAnimate(animate ? false : true);
  }, [open]);
  return (
    <div
      style={{
        height: open ? 280 : 110,
        width: open ? "95%" : "100%",
        marginTop: 10,
        display: "flex",
        zIndex: 2000,
        backgroundColor: BlackAndWhite.secondary,
        position: open ? "absolute" : null,
        flexDirection: open ? "column" : "row",
        borderRadius: 8,
      }}
    >
      {open ? (
        <>
          {select === "day" ? <div>WEEK DAY</div> : null}
          {select === "month" ? <div>MONTH</div> : null}
        </>
      ) : null}
      {open ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {select === "month" ? (
            <>
              <WeekdayButton
                height={60}
                width={80}
                title={"JAN"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("Jan");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                height={60}
                width={80}
                title={"FEB"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("Feb");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                height={60}
                width={80}
                title={"MAR"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("Mar");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                height={60}
                width={80}
                title={"APR"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("Apr");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                height={60}
                width={80}
                title={"MAY"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("May");
                  setOpen(false);
                }}
              />

              <WeekdayButton
                height={60}
                width={80}
                title={"JUN"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("Jun");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                height={60}
                width={80}
                title={"JUL"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("Jul");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                height={60}
                width={80}
                title={"AUG"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("Aug");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                height={60}
                width={80}
                title={"SEP"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("Sep");
                  setOpen(false);
                }}
              />

              <WeekdayButton
                height={60}
                width={80}
                title={"OCT"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("Oct");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                height={60}
                width={80}
                title={"NOV"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("Nov");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                height={60}
                width={80}
                title={"DEC"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setMonth("Dec");
                  setOpen(false);
                }}
              />
            </>
          ) : null}
          {select === "dayDate" ? (
            <>
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"1"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("01");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"2"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("02");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"3"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("03");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"4"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("04");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"5"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("05");
                  setOpen(false);
                }}
              />

              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"6"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("06");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"7"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("07");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"8"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("08");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"09"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("09");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"10"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("10");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"11"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("11");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"12"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("12");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"13"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("13");
                  setOpen(false);
                }}
              />

              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"14"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("14");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"15"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("15");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"16"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("16");
                  setOpen(false);
                }}
              />

              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"17"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("17");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"18"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("18");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"19"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("19");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"20"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("20");
                  setOpen(false);
                }}
              />

              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"21"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("21");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"22"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("22");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"23"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("24");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"25"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("25");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"26"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("26");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"27"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("27");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"28"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("28");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"29"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("29");
                  setOpen(false);
                }}
              />

              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"30"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("30");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                margin={5}
                height={35}
                width={35}
                title={"31"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("31");
                  setOpen(false);
                }}
              />

              <WeekdayButton
                width={120}
                title={"NONE"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDayDate("");
                  setOpen(false);
                }}
              />
            </>
          ) : null}
          {select === "day" ? (
            <>
              <WeekdayButton
                title={"MON"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDay("Mon");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                title={"TUE"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDay("Tue");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                title={"WED"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDay("Wed");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                title={"THU"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDay("Thu");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                title={"FRI"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDay("Fri");
                  setOpen(false);
                }}
              />

              <WeekdayButton
                title={"SAT"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDay("Sat");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                title={"SUN"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDay("Sun");
                  setOpen(false);
                }}
              />
              <WeekdayButton
                title={"NONE"}
                hover={hover}
                setHover={setHover}
                onClick={() => {
                  setDay("");
                  setOpen(false);
                }}
              />
            </>
          ) : null}
        </div>
      ) : (
        <>
          <div
            onClick={() => {
              setOpen(true);
              setSelect("day");
            }}
            style={{
              flex: 0.25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onMouseOver={() => setHover("day")}
              onMouseOut={() => setHover("")}
              style={{
                cursor: "pointer",
                height: "90%",
                width: "90%",
                borderStyle: "solid",
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transition: "all 0.3s",
                backgroundColor:
                  hover === "day"
                    ? BlackAndWhite.primary
                    : BlackAndWhite.secondary,
                color:
                  hover === "day"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
              }}
            >
              <div style={{ marginBottom: 5 }}>{day ? day : "WEEK DAY"}</div>
              <div
                style={{
                  fontSize: 10,
                  textAlign: "center",
                  width: "80%",
                }}
              >
                Click here to select weekday
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setOpen(true);
              setSelect("month");
            }}
            style={{
              flex: 0.25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onMouseOver={() => setHover("month")}
              onMouseOut={() => setHover("")}
              style={{
                cursor: "pointer",
                height: "90%",
                width: "90%",
                borderStyle: "solid",
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transition: "all 0.3s",
                backgroundColor:
                  hover === "month"
                    ? BlackAndWhite.primary
                    : BlackAndWhite.secondary,
                color:
                  hover === "month"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
              }}
            >
              <div style={{ marginBottom: 5 }}>{month ? month : "MONTH"}</div>
              <div
                style={{
                  fontSize: 10,
                  textAlign: "center",
                  width: "80%",
                }}
              >
                Click here to select month
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setOpen(true);
              setSelect("dayDate");
            }}
            style={{
              flex: 0.25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onMouseOver={() => setHover("dayDate")}
              onMouseOut={() => setHover("")}
              style={{
                cursor: "pointer",
                height: "90%",
                width: "90%",
                borderStyle: "solid",
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transition: "all 0.3s",
                backgroundColor:
                  hover === "dayDate"
                    ? BlackAndWhite.primary
                    : BlackAndWhite.secondary,
                color:
                  hover === "dayDate"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
              }}
            >
              <div style={{ marginBottom: 5 }}>
                {dayDate ? dayDate : "DATE"}
              </div>
              <div
                style={{
                  fontSize: 10,
                  textAlign: "center",
                  width: "80%",
                }}
              >
                Click here to select day date
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 0.25,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onMouseOver={() => setHover("year")}
              onMouseOut={() => setHover("")}
              style={{
                cursor: "pointer",
                height: "90%",
                width: "90%",
                borderStyle: "solid",
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transition: "all 0.3s",
                backgroundColor:
                  hover === "year"
                    ? BlackAndWhite.primary
                    : BlackAndWhite.secondary,
                color:
                  hover === "year"
                    ? BlackAndWhite.secondary
                    : BlackAndWhite.primary,
              }}
            >
              <div style={{ marginBottom: 5 }}>{year ? year : "YEAR"}</div>
              <div
                style={{
                  fontSize: 10,
                  textAlign: "center",
                  width: "80%",
                }}
              >
                Click here to select Year
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MultiSelectorDate;
