import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const months = [
  "Gauteng",
  "Free-State",
  "Western Cape",
  "Northern Cape",
  "Kwa-zulu Natal",
  "Mpumalanga",
  "Limpopo",
  "North-west",
  "Eastern Cape",
];

const ProvinceSelector = ({
  province,
  setProvince,
  open,
  onProvinceClick,
  setProvinceOpen,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ marginTop: 20, marginBottom: open ? 0 : 20 }}>
        Click Province to select
      </div>
      <div
        onClick={onProvinceClick}
        style={{
          height: open ? 0 : 45,
          width: 250,
          borderStyle: "solid",
          borderRadius: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderWidth: open ? 0 : 0.5,
          transition: "all 0.3s",
          overflow: "hidden",
        }}
      >
        {province ? province : "Click here"}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: open ? 550 : 0,
          overflow: "hidden",

          flexWrap: "wrap",
        }}
      >
        {months.map((month, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                setProvince(month);
                setProvinceOpen(false);
              }}
              style={{
                height: 45,
                width: 250,
                borderStyle: "solid",
                margin: 5,
                borderRadius: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderWidth: 0.5,
                transition: "all 0.3s",
                overflow: "hidden",
                fontSize: 14,
                textAlign: "center",
              }}
            >
              {month ? month : "loading"}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProvinceSelector;
