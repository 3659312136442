import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { BaseButtons } from "../../../../../../components/atoms";

const SupportTicketItem = ({ ticket, onViewClick }) => {
  const [lastviewedHover, setLastViewedHover] = useState(false);
  return (
    <div
      style={{
        height: 50,
        width: "100%",
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        flexDirection: "row",
        // borderRadius: 8,
        overflow: "hidden",
        // marginTop: 10,
        backgroundColor: BlackAndWhite.secondary,
        position: "relative",
        marginTop: 5,
        fontSize: 12,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#00000050",
      }}
    >
      <div style={{ marginLeft: 20, flex: 0.3, textAlign: "left" }}>
        {ticket.id}
      </div>
      <div style={{ flex: 0.2, textAlign: "center" }}>{ticket.issueType}</div>
      <div style={{ flex: 0.2 }}>{ticket.lastAccesed}</div>

      <div style={{ flex: 0.15, textAlign: "center", marginRight: 5 }}>
        {ticket.status}
      </div>
      <div
        style={{
          flex: 0.15,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BaseButtons
          height={30}
          label={"VIEW"}
          mini={true}
          marginTopFalse={true}
          size={60}
          fontSize={12}
          borderRadius={4}
          onClick={onViewClick}
        />
      </div>

      {/* <div style={{ position: "absolute", top: 10, right: 10, backgroundColor: }}>comments</div> */}
    </div>
  );
};

export default SupportTicketItem;
