import React from "react";

const FieldDisplay = ({ field, fieldName, cantEdit }) => {
  return (
    <div
      style={{
        width: "85%",
        height: "90%",
        // borderBottomStyle: "solid",
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        position: "relative",
      }}
    >
      <div style={{ position: "absolute", top: 0, left: 10, fontSize: 12 }}>
        {fieldName}
      </div>
      <div style={{ position: "absolute", left: 10, fontSize: 16, bottom: 10 }}>
        {field}
      </div>
      {cantEdit ? null : (
        <img
          alt="Edit"
          src={require("../../../../images/material-icons/editWhite.png")}
          style={{ height: 25, position: "absolute", right: 10, bottom: 10 }}
        />
      )}
    </div>
  );
};

export default FieldDisplay;
