import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  CloseButton,
  TextInputField,
  TextInputFieldSearch,
} from "../../../../components/atoms";

const SearchBar = ({ filterOption, onFilterClick, onChange, width }) => {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={open ? null : () => setOpen(true)}
      style={{
        width: open ? (width > 899 ? 490 : 300) : 76,
        height: width > 899 ? 65 : open ? 100 : 30,
        borderRadius: width > 899 ? 60 : open ? 10 : 8,
        borderStyle: "solid",
        backgroundColor: open
          ? BlackAndWhite.secondary
          : hover
          ? BlackAndWhite.primary
          : BlackAndWhite.primary,
        transition: "all 0.3s",
        WebkitBoxShadow: "0px 0px 10px 1px #00000066",
        borderWidth: open ? 0.5 : 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderColor: BlackAndWhite.secondary,
        overflow: "hidden",
        flexDirection: width > 899 ? "row" : "column",
      }}
    >
      {open ? (
        <TextInputFieldSearch
          placeholder={"Type here to search"}
          onChange={onChange}
        />
      ) : null}
      {width > 899 ? (
        <div
          style={{
            color: BlackAndWhite.secondary,
            fontSize: open ? 12 : 14,
            fontWeight: "bold",
            width: open ? 80 : null,
            height: open ? 50 : null,
            borderRadius: open ? 60 : null,
            backgroundColor: open ? BlackAndWhite.primary : null,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: open ? 5 : null,
            marginLeft: open ? 10 : null,
          }}
        >
          SEARCH
        </div>
      ) : open ? null : (
        <div
          style={{
            color: BlackAndWhite.secondary,
            fontSize: open ? 12 : 14,
            fontWeight: "bold",
            width: open ? 80 : null,
            height: open ? 50 : null,
            borderRadius: open ? 60 : null,
            backgroundColor: open ? BlackAndWhite.primary : null,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: open ? 5 : null,
            marginLeft: open ? 10 : null,
          }}
        >
          SEARCH
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: width > 899 ? null : open ? 5 : 0,
        }}
      >
        {open ? (
          <div
            onClick={onFilterClick}
            style={{
              width: open ? 80 : null,
              height: open ? (width > 899 ? 50 : 35) : null,
              backgroundColor: open ? BlackAndWhite.primary : null,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 60,
              marginRight: 7,
              color: BlackAndWhite.secondary,
              fontWeight: "bold",
              position: "relative",
              fontSize: 12,
            }}
          >
            {filterOption ? filterOption : "FILTER"}
          </div>
        ) : null}
        {open ? (
          <CloseButton label={"X"} onClick={() => setOpen(false)} />
        ) : null}
      </div>
    </div>
  );
};

export default SearchBar;
