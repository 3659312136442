import * as Filesaver from "file-saver";
import XLSX from "sheetjs-style";
import { BaseButtons } from "../../../components/atoms";

const ExcelExport = ({
  excelData,
  fileName,
  title,
  mini,
  marginTop,
  borderRadius,
  height,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async ({ fileName }) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    Filesaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <BaseButtons
      label={title}
      onClick={() => exportToExcel({ fileName })}
      mini={mini}
      marginTop={marginTop}
      borderRadius={borderRadius}
      height={height}
    />
  );
};

export default ExcelExport;
