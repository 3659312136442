import React from "react";
import DropDownButton from "../drop-down-button/DropDownButton";

const MenuDropDown = ({
  display,
  buttonData,
  setSubSelection,
  setUserdisplay,
}) => {
  return (
    <div
      style={{
        height: display ? (buttonData.length === 1 ? 70 : 190) : 0,
        width: "100%",
        overflow: "hidden",
        transition: "all 1s",
        opacity: display ? 1 : 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      {buttonData.map((button, index) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DropDownButton
              title={button}
              width={"95%"}
              onClick={() => {
                setSubSelection(button);
                setUserdisplay(true);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MenuDropDown;
