import React, { useEffect, useState } from "react";
import { BlackAndWhite } from "../../themes/Colors";

const CategoryButton = ({
  src,
  top,
  altTop,
  left,
  altLeft,
  sizeImage,
  title,
  description,
  onClick,
  small,
  comingSoon,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onClick={comingSoon ? null : onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        height: 200,
        width: small ? 200 : 350,
        backgroundColor: hover ? "#00000066" : BlackAndWhite.primary,
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        borderRadius: 20,
        cursor: "pointer",

        position: "relative",
        WebkitBoxShadow: comingSoon ? null : "0px 0px 10px 1px #00000066",
        // borderWidth: 3,
        // borderStyle: "solid",
        // borderColor: "#000000",
        // transition: "all 0.5s",
      }}
    >
      <div style={{ flex: 0.5, position: "relative" }}>
        <img
          alt="category"
          src={src}
          style={{
            height: sizeImage,
            position: "absolute",
            top: hover ? top : altTop,
            left: hover ? left : altLeft,
            transition: "all 0.5s",
            opacity: comingSoon ? 0.3 : 1,
          }}
        />
      </div>
      <div
        style={{
          flex: 0.5,
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          opacity: comingSoon ? 0.3 : 1,
        }}
      >
        <div
          style={{
            color: BlackAndWhite.secondary,
            display: "flex",
            textAlign: "center",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 20,
            marginTop: 20,
            marginBottom: 10,
            fontWeight: "bold",
            marginRight: 20,
          }}
        >
          {title}
        </div>
        <div
          style={{
            color: BlackAndWhite.secondary,
            display: "flex",
            textAlign: "center",
            width: "80%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {small ? null : (
            <div style={{ marginBottom: 10, width: "100%", textAlign: "left" }}>
              Access to:
            </div>
          )}

          <div style={{ width: "100%", textAlign: "left" }}>{description}</div>
        </div>
      </div>
      {comingSoon ? (
        <div
          style={{
            color: "#000000",
            position: "absolute",
            zIndex: 20,
            left: 100,
            top: 80,
            fontSize: 20,
            fontWeight: "bold",
            WebkitBoxShadow: "0px 0px 10px 1px #FFFFFF",
            borderRadius: 100,
            padding: 5,
            backgroundColor: BlackAndWhite.secondary,
            overflow: "hidden",
          }}
        >
          {comingSoon ? "COMING SOON" : null}
        </div>
      ) : null}
    </div>
  );
};

export default CategoryButton;
